export const BAR_CHART_CONTEXT = 'bar';
export const LINE_CHART_CONTEXT = 'line';
export const TREE_MAP_CONTEXT = 'tree';

// Download types
export const DOWNLOAD_MONTHLY_DATA = 'monthly';
export const DOWNLOAD_YEARLY_DATA = 'yearly';

// Colors
export const ALTERNATE_GRID_COLOR = 'rgba(255, 255, 255, 0.1)';
export const ALTERNATE_BG_COLOR = 'rgba(255, 255, 255, 0.1)';
export const DEFAULT_COLOR = '#444';
export const DEFAULT_GRID_COLOR = '#EEE';
export const TRANSPARENT = 'rgba(0, 0, 0, 0)';
export const WHITE = '#FFF';
export const NO_VALUE = 'NO VALUE';

// Max the name (legend) length to 20 characters:
export const MAX_NAME_LENGTH = 20;

// Plotly default values
export const PLOTLY_DEFAULT_YAXIS_NTICKS = 5;

const THOUSAND = 1000;
export const MILLION = 1000000;

// Creates a sequence of numbers starting with first,
// incrementing by incr, and ending after len numbers.
const seq = (first, incr, len, round = false) => {
  return Array.from({ length: len }, (_, i) => {
    let value = first + i * incr;
    if (round) value = parseInt(value * 10) / 10.0;
    return value;
  });
};

// Default sequence
export const DEFAULT_YAXIS_VALUES = seq(10, 10, 7);

// Returns a sequence based on the zoomed range of chart
export const getYAxisSequenceFromRange = (range) =>
  seq(parseInt((range[0] / MILLION) * 10) / 10.0, (range[1] - range[0]) / 4 / MILLION, 4, true);

const thresholds = new Map([
  [5 * MILLION, seq(1, 1, 5)],
  [12 * MILLION, seq(2, 2, 6)],
  [22 * MILLION, seq(2, 4, 6)],
  [30 * MILLION, seq(5, 5, 6)],
  [50 * MILLION, seq(10, 10, 5)],
  [60 * MILLION, seq(10, 10, 6)],
  [70 * MILLION, seq(10, 10, 7)],
]);

// Returns a sequence based on the max value of chart
export const getYAxisSequenceFromMax = (maxY) => {
  // If max is greater than 70 million, calculate the threshold
  if (maxY >= 70 * MILLION) {
    // Round max to nearest 10 million
    const maxThreshold = parseInt((maxY / MILLION) * 10) / 10.0;
    // Divide the threshold by 7 to get the step and round up to nearest 10 million
    const step = Math.ceil(maxThreshold / 7 / 10) * 10;

    return seq(step, step, 7, true);
  } else {
    // Otherwise threshold can be found in our map
    // Loop through thresholds and find the first value that maxY is less than
    for (const [limit, seqArray] of thresholds) {
      if (maxY < limit) {
        return seqArray;
      }
    }
  }
};

// Takes in a number in millions and rounds accordingly to nearest 10, 100 million or billion
const roundToNearest = (n) => {
  let displayedNum = n;
  let unit = 'MM';

  if (n >= THOUSAND) {
    displayedNum = n / THOUSAND;
    unit = 'B';
  }

  const roundedText = `${displayedNum} ${unit}`;
  const roundedValue = n * MILLION;
  return { roundedValue, roundedText };
};

export const getTickValuesAndText = (yAxisValues = []) => {
  const tickvals = [];
  const ticktext = [];

  yAxisValues.forEach((n) => {
    const { roundedValue, roundedText } = roundToNearest(n);
    tickvals.push(roundedValue);
    ticktext.push(roundedText);
  });

  return { tickvals, ticktext };
};

export const DEFAULT_TRACE_LIMIT = '20';
export const NO_TRACE_LIMIT = '';

export const TOP_SORT_DIRECTION = {
  label: 'Highest',
  value: 'DESC', // SQL sort desc if we want the top results
  plotlyCategoryOrder: 'total descending',
};

export const BOTTOM_SORT_DIRECTION = {
  label: 'Lowest',
  value: 'ASC', // SQL sort asc if we want the bottom results
  plotlyCategoryOrder: 'total ascending',
};

const ALL_SORT_DIRECTION = {
  label: 'All',
  value: 'DESC', // If we show all data, default sort descending
  limit: NO_TRACE_LIMIT, // Override user input limit
  plotlyCategoryOrder: 'total descending',
};

export const SORT_DIRECTIONS = [TOP_SORT_DIRECTION, BOTTOM_SORT_DIRECTION, ALL_SORT_DIRECTION];
