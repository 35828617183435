import React from 'react';

import { COUNTRIES_QUERY } from './operations';
import { AutoComplete } from '../AutoComplete';

export default ({ multiple, onChange, value, label, styles }) => {
  return (
    <AutoComplete
      getData={COUNTRIES_QUERY}
      menuItems={null}
      onChange={onChange}
      value={value}
      label={label}
      optionKey="displayName"
      runQueryAfterOnChange={false}
      queryVariable={null}
      multiple={multiple}
      styles={styles}
      globalSearchType="COUNTRY"
      id="country-search"
    />
  );
};
