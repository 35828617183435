import { gql } from '@apollo/client';

export const ASSETS_BY_SEARCH_KEY = gql`
  query assetsBySearchKey($search: [String!]!) {
    assetsBySearchKey(search: $search) {
      id
      legacyId
      displayName
      name
      reportUpdatedDate
      country {
        isoCode
      }
    }
  }
`;

export const LEAD_ANALYST_QUERY = gql`
  query leadAnalyst($countryIsoCode: String!) {
    leadAnalyst(countryIsoCode: $countryIsoCode) {
      name
      email
    }
  }
`;
