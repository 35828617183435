import React from 'react';
import { useQuery } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import { LineGraph } from '../../../Graphs';
import { PIPELINE_NETWORK_THROUGHPUT_GRAPH_QUERY } from './operations';

const useStyles = makeStyles((theme) => ({
  graphsContainer: {
    ...theme.palette.mapInfoSection,
  },
  graph: {
    backgroundColor: 'white',
    margin: '5px',
    padding: '5px',
  },
}));

export default ({ pipelineNetworkId }) => {
  const classes = useStyles();

  const { loading, data } = useQuery(PIPELINE_NETWORK_THROUGHPUT_GRAPH_QUERY, {
    variables: {
      id: pipelineNetworkId,
    },
  });

  // Render a graph of pipeline network throuhput data containing:
  // - x-axis: time in months
  // - y-axis: throughput in ??? units
  return (
    <div className={classes.graphsContainer}>
      {!loading && data.graphPipelineNetworkThroughputs.throughputs.length > 0 ? (
        <>
          <div className={classes.graph}>
            <LineGraph
              key={`pdp-graph-${pipelineNetworkId}`}
              csv={data.graphPipelineNetworkThroughputs.throughputs}
              labels={data.graphPipelineNetworkThroughputs.labels}
              title="Pipeline Network Throughput"
              ylabel={data.graphPipelineNetworkThroughputs.ylabel}
              stackedGraph={true}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};
