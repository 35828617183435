import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { AutoComplete } from '../../AutoComplete';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.palette.mapInfoSection,
  },
  label: {
    ...theme.palette.secondaryAccentText,
    margin: '-3px',
  },
}));

export default ({ displayedItem, loading, data, onChange }) => {
  const classes = useStyles();
  const assets = data?.pipelineNetwork?.assets;

  /**
   * This componenet should only be rendered when:
   * - there is an item object to be displayed
   * - data is not loading
   * - data exists
   * - a list of assets exists
   * - count of dropdown options includes more than the item to be displayed
   */
  if (!displayedItem || loading || !data || !assets || !(assets.length > 1)) {
    return null;
  }
  const menuAssets = assets.map((asset) => {
    return {
      ...asset,
      menuName: `${asset.displayName} (${asset?.country?.isoCode})`,
    };
  });

  // const menuItems = [{ name: '' }, ...assets];
  // This component should be showing for a asset network, so the displayed item should never be in the list of menu items
  // const asset = menuItems.find((asset) => asset.name === displayedItem.name);
  return (
    <div className={classes.root}>
      <AutoComplete
        getData={null}
        menuItems={menuAssets}
        onChange={onChange}
        value={null}
        label="Assets"
        optionKey="menuName"
        runQueryAfterOnChange={false}
        queryVariable={null}
        multiple={false}
      />
    </div>
  );
};
