import { gql } from '@apollo/client';

export const SAVE_FILTER = gql`
  mutation CreateUserFilter($filter: JSON!, $name: String!, $type: String!) {
    createUserFilter(filter: $filter, name: $name, type: $type) {
      userFilter {
        id
        filter
        name
      }
    }
  }
`;
