import makeStyles from '@mui/styles/makeStyles';

export const useMobileTrayStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    backgroundColor: 'white',
    bottom: 0,
    zIndex: 1301,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  content: {
    width: '300px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  link: {
    fontWeight: 'bold',
    ...theme.menuButtonFontStyles,
  },
  selectedLink: {
    ...theme.palette.accentText,
    fontWeight: 'bold',
    ...theme.menuButtonFontStyles,
  },
  header: {
    height: '64px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
