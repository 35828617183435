import React from 'react';
import Typography from '@mui/material/Typography';
import { PASSWORD_RULES } from './constants';

export default () => {
  return (
    <div>
      <ul>
        {PASSWORD_RULES.map((rule) => (
          <li key={rule}>
            <Typography variant="body1">{rule}</Typography>
          </li>
        ))}
      </ul>
    </div>
  );
};
