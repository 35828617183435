import { gql } from '@apollo/client';

export const MA_TRANSACTIONS_SUMMARY_QUERY = gql`
  query maTransactionsSummary(
    $assets: [Int!]
    $countries: [String!]
    $buyers: [Int!]
    $sellers: [Int!]
    $eventTypes: [String!]
    $startYear: Int
    $endYear: Int
  ) {
    maTransactionsSummary(
      assets: $assets
      countries: $countries
      buyers: $buyers
      sellers: $sellers
      eventTypes: $eventTypes
      startYear: $startYear
      endYear: $endYear
    ) {
      numDeals
      medianValuation
      medianReserves
      medianReservesAcquisitionCost
      medianProductionAcquisitionCost
    }
  }
`;

export const MA_TRANSACTIONS_AGGREGATES_QUERY = gql`
  query maTransactionsAggregates(
    $by: MaAggregateByEnum!
    $assets: [Int!]
    $countries: [String!]
    $buyers: [Int!]
    $sellers: [Int!]
    $eventTypes: [String!]
    $startYear: Int
    $endYear: Int
    $valuationsSum: Boolean!
    $reservesSum: Boolean!
    $productionsSum: Boolean!
    $acquisitionCostSum: Boolean!
  ) {
    maTransactionsAggregates(
      by: $by
      assets: $assets
      countries: $countries
      buyers: $buyers
      sellers: $sellers
      eventTypes: $eventTypes
      startYear: $startYear
      endYear: $endYear
    ) {
      type
      group
      valuationsSum @include(if: $valuationsSum)
      reservesSum @include(if: $reservesSum)
      productionsSum @include(if: $productionsSum)
      acquisitionCostSum @include(if: $acquisitionCostSum)
    }
  }
`;

export const MA_TRANSACTIONS_AGGREGATES_STACKED_QUERY = gql`
  query maTransactionsAggregatesStacked(
    $by: MaAggregateByEnum!
    $stackBy: MaStackByEnum!
    $fields: [String!]!
    $assets: [Int!]
    $countries: [String!]
    $buyers: [Int!]
    $sellers: [Int!]
    $eventTypes: [String!]
    $startYear: Int
    $endYear: Int
  ) {
    maTransactionsAggregatesStacked(
      by: $by
      stackBy: $stackBy
      fields: $fields
      assets: $assets
      countries: $countries
      buyers: $buyers
      sellers: $sellers
      eventTypes: $eventTypes
      startYear: $startYear
      endYear: $endYear
    ) {
      stacks
      data {
        type
        group
        values
      }
    }
  }
`;

export const MA_DEAL_COUNT_BY_TYPE_QUERY = gql`
  query maDealCountByType(
    $by: MaAggregateByEnum!
    $assets: [Int!]
    $countries: [String!]
    $buyers: [Int!]
    $sellers: [Int!]
    $eventTypes: [String!]
    $startYear: Int
    $endYear: Int
  ) {
    maDealCountByType(
      by: $by
      assets: $assets
      countries: $countries
      buyers: $buyers
      sellers: $sellers
      eventTypes: $eventTypes
      startYear: $startYear
      endYear: $endYear
    ) {
      stacks
      data {
        type
        group
        values
      }
    }
  }
`;
