export const NAME_STORAGE_SITE_SUMMARY_ITEM = {
  label: 'Block Name',
  accessor: 'storageSiteName',
};

export const SHORE_STATUS_STORAGE_SITE_SUMMARY_ITEM = {
  label: 'Shore Status',
  accessor: 'shoreStatus',
};

export const STATUS_STORAGE_SITE_SUMMARY_ITEM = {
  label: 'Status',
  accessor: 'blockStatus',
};
