import { EXPORT_MA_TRANSACTIONS_ENDPOINT } from '../operations';
import { FilterKeys } from '../../../../Filters';
import { downloadXlsx } from '../../../../Downloads/helpers';
import { useCallback } from 'react';
import { useFilters } from '../../../FiltersContext';

export const useDownloadMaTransactions = () => {
  const { filters } = useFilters();

  const downloadMaTransactions = useCallback(async () => {
    const downloadName = `M_A_Transactions_Data.xlsx`;
    const [startYear, endYear] = filters?.dateSlider || [];

    const filtersVariables = {
      assets: filters[FilterKeys.assets]?.map((a) => Number(a.entityId)) || [],
      countries: filters[FilterKeys.countries]?.map((c) => c.isoCode) || [],
      buyers: filters[FilterKeys.companiesBuyer]?.map((b) => Number(b.id)) || [],
      sellers: filters[FilterKeys.companiesSeller]?.map((s) => Number(s.id)) || [],
      eventTypes: filters[FilterKeys.eventType]?.map((e) => e.name) || [],
      startYear: startYear,
      endYear: endYear,
    };

    const result = await downloadXlsx(
      EXPORT_MA_TRANSACTIONS_ENDPOINT,
      filtersVariables,
      downloadName,
    );

    let errors = [];
    if (!result.ok) {
      const json = await result.json();
      errors = json?.errors || [];
    }

    return { ok: result.ok, status: result.status, errors };
  }, [filters]);

  return { downloadMaTransactions };
};
