import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useStyles } from './EntityRow.style';

export const EntityRow = ({ displayName, isChosen, setOptionIsChosen }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <FormControlLabel
        control={
          <Checkbox
            className={classes.checkbox}
            checked={isChosen}
            onChange={setOptionIsChosen}
            inputProps={{ 'data-display-name': displayName }}
          />
        }
        label={<span className={classes.label}>{displayName}</span>}
      />
    </div>
  );
};
