export const mapStyleItems = [
  { label: 'Monochrome', value: 'monochrome' },
  { label: 'Satellite', value: 'satellite' },
];

// HEAT MAPS
export const TOTAL_PRODUCTION_HEAT_MAP = {
  label: 'Total Production',
  value: 'totalProductionHeatMap',
};
export const OIL_AND_CONDENSATE_PRODUCTION_HEAT_MAP = {
  label: 'Oil and Condensate Production',
  value: 'oilAndCondensateProductionHeatMap',
};
export const GAS_PRODUCTION_HEAT_MAP = {
  label: 'Gas Production',
  value: 'gasProductionHeatMap',
};
export const TOTAL_RESERVES_HEAT_MAP = {
  label: 'Total Reserves',
  value: 'totalReservesHeatMap',
};
export const OIL_AND_CONDENSATE_RESERVES_HEAT_MAP = {
  label: 'Oil and Condensate Reserves',
  value: 'oilAndCondensateReservesHeatMap',
};
export const GAS_RESERVES_HEAT_MAP = {
  label: 'Gas Reserves',
  value: 'gasReservesHeatMap',
};
export const OPERATORS_HEAT_MAP = { label: 'Operators', value: 'operatorsHeatMap' };
export const PARTICIPANTS_HEAT_MAP = {
  label: 'Participants',
  value: 'participantsHeatMap',
};

export const heatMapItems = [
  TOTAL_PRODUCTION_HEAT_MAP,
  TOTAL_RESERVES_HEAT_MAP,
  OIL_AND_CONDENSATE_PRODUCTION_HEAT_MAP,
  OIL_AND_CONDENSATE_RESERVES_HEAT_MAP,
  GAS_PRODUCTION_HEAT_MAP,
  GAS_RESERVES_HEAT_MAP,
  OPERATORS_HEAT_MAP,
  PARTICIPANTS_HEAT_MAP,
];

// BUBBLE MAPS
export const PRODUCTION_BUBBLE_MAP = {
  label: 'Production (Bubble Map)',
  value: 'productionBubbleMap',
};
export const RESERVES_BUBBLE_MAP = { label: 'Reserves (Bubble Map)', value: 'reservesBubbleMap' };

export const bubbleMapItems = [
  // PRODUCTION_BUBBLE_MAP,
  // RESERVES_BUBBLE_MAP
];

// CONTRACT TYPES
export const contractTypeItems = [
  { label: 'Concession', value: 'concession' },
  { label: 'PSC', value: 'psc' },
  { label: 'Service', value: 'service' },
  { label: 'Mixed', value: 'mixed' },
];

export const layerItems = [...heatMapItems, ...bubbleMapItems];

// CCUS MAP TYPES TODO: FIGURE OUT WHAT TYPE OF MAP THEY ARE
export const BLOCKS = {
  label: 'Upstream Blocks',
  value: 'blocks',
};
export const CCUS_PROJECTS = {
  label: 'CCUS Projects',
  value: 'ccus projects',
};
export const CO2_EMITTERS = {
  label: 'CO₂ Emitters',
  value: 'co2 emitters',
};
export const FIELDS = {
  label: 'Fields',
  value: 'fields',
};
export const PIPELINES = {
  label: 'Pipelines',
  value: 'pipelines',
};
export const STORAGE_LOCATIONS = {
  label: 'Storage Sites',
  value: 'storage sites',
};
export const WELLS = {
  label: 'Wells',
  value: 'wells',
};
export const ccusLayerItems = [
  CO2_EMITTERS,
  CCUS_PROJECTS,
  STORAGE_LOCATIONS,
  BLOCKS,
  FIELDS,
  PIPELINES,
  WELLS,
];
export const defaultSelectedCcusLayerItems = [CO2_EMITTERS, CCUS_PROJECTS, STORAGE_LOCATIONS];
