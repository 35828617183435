import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { isGraphTypeEmission } from '../../GHG/Charts/utils';
import CheckList from '../../CheckList';

const useStyles = makeStyles((theme) => ({
  checkListRoot: {
    height: 'auto',
    padding: 0,
  },
  checkListForm: {
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flexDirection: 'row',
    '& > :first-child': {
      marginLeft: '-6px',
    },
  },
  label: {
    ...theme.palette.alternateText,
    fontWeight: 'bold',
  },
  parentAndChildContainer: {
    margin: '0 5px',
  },
  checkboxLabel: {
    '& > .MuiFormControlLabel-label': {
      fontWeight: 'bold',
    },
  },
}));

export default ({
  emissionSourceTypes,
  graphType,
  handleSetState,
  itemIsDisabledParams,
  value,
}) => {
  const classes = useStyles();

  const callback = (newSources) => handleSetState(newSources, 'emissionsSource');

  return (
    <>
      {emissionSourceTypes && (
        <CheckList
          callback={callback}
          checked={value}
          includeAll
          allSelectableWhenChildrenDisabled
          selectAllOnNoneChecked
          list={emissionSourceTypes}
          label="Emissions Source"
          nameKey="label"
          isDisabled={!isGraphTypeEmission(graphType)}
          itemIsDisabledParams={itemIsDisabledParams}
          styles={classes}
          color="primary"
        />
      )}
    </>
  );
};
