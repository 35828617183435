import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  participationTable: {
    padding: '.5rem',
  },
  participationRow: {
    '&:nth-child(odd)': {
      backgroundColor: 'white !important',
    },
    '&:nth-child(even)': {
      backgroundColor: theme.palette.baseColors.cloudGray.c100,
    },
    '&:nth-child(1)': {
      backgroundColor: `${theme.palette.baseColors.deepSeaNavy.c100}  !important`,
    },
  },
  participationHeaderCell: {
    color: 'white',
  },
  participationCell: {
    fontWeight: '400',
  },
  participationTableBlock: {
    padding: '1rem',
    paddingTop: '.2rem',
  },
}));
