import { gql } from '@apollo/client';
import {
  COUNTRY_LEVEL_ASSETS,
  ASSET_LEVEL_WELL_PROPERTIES,
  ASSET_LEVEL_WELL_PRODUCTION,
} from '../constants';

const COUNTRY_LEVEL_ASSETS_QUERY = gql`
  query dataDownloads($downloadType: String!, $countryIsoCodes: [String!]) {
    dataDownloads(downloadType: $downloadType, countryIsoCodes: $countryIsoCodes) {
      assets {
        id
        legacyId
        activeWellCount
        api
        currentDate
        developmentWellCount
        discoveryDate
        displayName
        firstProductionDate
        fiscalRegime
        formattedAcreage
        formattedApi
        formattedHydrocarbonType
        formattedNpv
        formattedNpvBoe
        formattedPeakProductionBoed
        formattedRecentProdLiquidsBbld
        formattedRecentProdGasMmcfd
        formattedReservoirDepthM
        formattedWaterDepthM
        formattedXFactor
        lastTwelveMonthsAverageProdBoed
        licenseAwardDate
        licenseExpirationDate
        licensingRound
        name
        originalGasInPlaceBcf
        originalOilInPlaceMmbbl
        peakProductionDate
        pipelineNetwork
        pipelineNetworkSegment
        remainingCapex
        status
        transportationMethod
        truckingDestination
        typeCurveEur
        updatedAt
        verticalDepthM
        waterDepthM
        weaRecoveryFactor
        country {
          id
          name
          displayName
          isoCode
        }
        basin {
          name
        }
        geologies {
          primaryFormation
          formation {
            name
          }
        }
        participations {
          id
          effectiveDate
          formattedPercentStake
          percentStake
          isCompanyOperator
          company {
            name
          }
        }
        reserve {
          accumulatedGasBcf
          accumulatedLiquidsMmbbl
          formattedTotalRemainingReservesLiquidsMmbbl
          formattedTotalRemainingReservesOilMmbbl
          formattedTotalRemainingReservesGasBcf
          pctRemainingReserves
          totalReservesLiquidMmbbl
          totalRemainingReservesMmboe
          totalRemainingReservesLiquidsMmbbl
          totalReservesGasBcf
          totalReservesMmboe
          totalRemainingReservesGasBcf
        }
        resourceType {
          displayName
        }
        shoreStatus {
          name
        }
      }
    }
  }
`;

const ASSET_LEVEL_WELL_PROPERTIES_QUERY = gql`
  query dataDownloads($downloadType: String!, $countryIsoCodes: [String!]) {
    dataDownloads(downloadType: $downloadType, countryIsoCodes: $countryIsoCodes) {
      wells {
        id
        legacyId
      }
    }
  }
`;

const ASSET_LEVEL_WELL_PRODUCTION_QUERY = gql`
  query dataDownloads($downloadType: String!, $countryIsoCodes: [String!]) {
    dataDownloads(downloadType: $downloadType, countryIsoCodes: $countryIsoCodes) {
      wells {
        id
        legacyId
      }
    }
  }
`;

export const DATA_DOWNLOADS_QUERY = (downloadType) => {
  switch (downloadType) {
    case COUNTRY_LEVEL_ASSETS:
      return COUNTRY_LEVEL_ASSETS_QUERY;
    case ASSET_LEVEL_WELL_PROPERTIES:
      return ASSET_LEVEL_WELL_PROPERTIES_QUERY;
    case ASSET_LEVEL_WELL_PRODUCTION:
      return ASSET_LEVEL_WELL_PRODUCTION_QUERY;
    default:
      return COUNTRY_LEVEL_ASSETS_QUERY;
  }
};
