import Button from '@mui/material/Button';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import Grid from '@mui/material/Grid2';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    ...theme.palette.button,
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} size={12}>
      <Grid size={2} />
      <Grid container spacing={2} size={8}>
        <Grid>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => window.open('https://welligence.com/intelligence', '_blank')}
          >
            <EmojiObjectsIcon fontSize="small" />
            &nbsp;explore intelligence
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
