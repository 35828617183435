export const DISTANCE_IMPERIAL = 'imperial';
export const DISTANCE_METRIC = 'metric';

const isImperial = (metric) => metric === DISTANCE_IMPERIAL;

export const getDistanceUnitsShort = (metric) => {
  if (isImperial(metric)) {
    return 'mi';
  }
  return 'km';
};

export const getDistanceUnitsLong = (metric) => {
  if (isImperial(metric)) {
    return 'miles';
  }
  return 'kilometers';
};
