import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Modal from '../index';
import { IconButton, Tooltip } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

const useStyles = makeStyles((theme) => ({
  altButton: {
    ...theme.palette.altButton,
  },
  button: {
    ...theme.palette.button,
    margin: '10px 0 20px',
  },
  modal: {
    height: 'fit-content',
    width: '500px',
  },
}));

export const ModalWithButtonTrigger = ({
  children,
  buttonTitle,
  handleOpen,
  handleClose,
  open,
  title,
  styles = {},
  iconClass,
  iconButtonClass,
}) => {
  const classes = useStyles();

  return (
    <>
      <Tooltip title={buttonTitle}>
        <IconButton onClick={handleOpen} className={iconButtonClass} size="large">
          <SaveIcon className={iconClass} />
        </IconButton>
      </Tooltip>
      <Modal
        title={title}
        open={open}
        handleClose={handleClose}
        styles={styles ? { ...classes, ...styles } : classes}
      >
        {children}
      </Modal>
    </>
  );
};
