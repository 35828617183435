import React from 'react';

import SummaryListGroup from './summaryListGroup';

// items - array of summary groups to render
// data - data associated to the summary
export default ({ items = [], data = [] }) =>
  items.map((item) => (
    <SummaryListGroup
      key={`summary-list-group-${item.label}`}
      data={data}
      group={item.group}
      label={item.label}
    />
  ));
