import { COMP_GRAPH_TYPES, COMP_Y_GRAPH_TYPES } from '../TransactionAnalytics.constant';
import { STACK_BY_TYPES } from '../../../helpers/stackByConstatnts';
import { getChartDataXY } from '../../../helpers/getChartDataXY';
import { getSecondaryYAxisChartData } from '../../../helpers/getSecondaryYAxisChartData';
import { capitalizeFirstLetter } from '../../../../../utils/catitalizeFirstLetter';

export const getChart3Data = (
  graph3XType,
  graph3YType,
  workingInterest,
  graph3XAxisList,
  stackBy,
  secondaryY,
  graphYSecondaryType,
) => {
  const key =
    graph3XType === COMP_GRAPH_TYPES.YEAR
      ? 'Year'
      : graph3XType === COMP_GRAPH_TYPES.BUYER_NAME
      ? 'Buyer name'
      : graph3XType === COMP_GRAPH_TYPES.SELLER_NAME
      ? 'Seller name'
      : 'Asset name';

  const value =
    graph3YType === COMP_Y_GRAPH_TYPES.VALUATION
      ? 'Working interest valuation'
      : graph3YType === COMP_Y_GRAPH_TYPES.RESERVE
      ? 'Working interest reserves'
      : 'Working interest production';

  const unit =
    graph3YType === COMP_Y_GRAPH_TYPES.VALUATION
    ? 'US$MM'
    : graph3YType === COMP_Y_GRAPH_TYPES.RESERVE
    ? 'MMboe'
    : 'boe/d';

  const xAxisList = graph3XAxisList;

  const allChart2XAxisListFiltered = Object.keys(xAxisList).filter((x) => xAxisList[x].value);
  const allChart2XAxisList =
    graph3XType === COMP_GRAPH_TYPES.YEAR
      ? allChart2XAxisListFiltered
      : allChart2XAxisListFiltered.sort((a, b) => {
          const { value: aValue } = xAxisList[a];
          const { value: bValue } = xAxisList[b];
          return bValue - aValue;
        });

  const allowStacking = allChart2XAxisList.length < 30;
  const isTop20 = allowStacking && stackBy !== STACK_BY_TYPES.NONE;
  const buyerTypes = Object.keys(workingInterest);

  const secondaryYAxisData = getSecondaryYAxisChartData(
    allChart2XAxisList,
    secondaryY,
    graphYSecondaryType,
    key,
  );

  let chart2X;

  const buyers = [];
  const buyersByType = {};
  allChart2XAxisList.forEach((x) => {
    if (isTop20) {
      buyerTypes.forEach((companyName) => {
        buyersByType[companyName] = [
          ...(buyersByType[companyName] || []),
          workingInterest[companyName][x] || 0,
        ];
      });
    } else {
      buyers.push(xAxisList[x].value);
    }
  });

  chart2X = allChart2XAxisList;

  const chart3Data = isTop20
    ? [
        ...getChartDataXY(
          workingInterest,
          chart2X,
          (outerKeyStackedBy) =>
            `${key}: %{x}<br>Name: ${outerKeyStackedBy}<br>${value} (${unit}): %{y}<extra></extra>`,
          { type: 'bar' },
        ),
        secondaryYAxisData,
      ]
    : [
        {
          x: chart2X,
          y: buyers,
          type: 'bar',
          name: `${value} (${unit})`,
          hovertemplate: `${key}: %{x}<br>${value} (${unit}): %{y}<extra></extra>`,
        },
        secondaryYAxisData,
      ];

  return {
    chart3Data,
    chart3Unit: unit,
    chart3Name: `Production and Reserves Transacted Per ${capitalizeFirstLetter(key)} (${unit})`,
    stackByWarning: allowStacking
      ? null
      : "Too many bars present in the chart, please filter in more specific data set to enable 'Stack By' feature.",
  };
};
