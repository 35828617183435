import { formatAsInt, formatTwoDecimals } from '../../../../utils/numberFormatter';

import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import { PageShell } from '../PageShell';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { downloadXlsx } from '../../../Downloads/helpers';
import { useStyles } from './TransactionTable.style';
import { useTransactionTableController } from './TransactionTable.controller';

export const TransactionTable = ({ data }) => {
  const classes = useStyles();
  const { filters, transactions } = useTransactionTableController(data);
  if (!filters) return null;

  const hoverStyles = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const columns = [
    {
      field: 'countryName',
      headerName: 'Country',
      width: 200,
      filterable: false,
    },
    {
      field: 'regionName',
      headerName: 'Region',
      width: 200,
      filterable: false,
      sortingOrder: ['asc', 'desc'],
    },
    { field: 'assetName', headerName: 'Asset/Block Name', width: 200, filterable: false },
    { field: 'shoreStatus', headerName: 'Shore Status', width: 200, filterable: false },
    { field: 'dealType', headerName: 'Deal Type', width: 200, filterable: false },
    {
      field: 'effectiveDate',
      headerName: 'Effective Date',
      type: 'date',
      width: 200,
      filterable: false,
      renderCell: ({ value }) => (
        <span>
          {new Date(value).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            timeZone: 'UTC',
          })}
        </span>
      ),
      sortComparator: (v1, v2) => new Date(v1) - new Date(v2),
    },
    {
      field: 'buyerCompanyName',
      headerName: 'Buyer',
      width: 200,
      filterable: false,
    },
    {
      field: 'sellerCompanyName',
      headerName: 'Seller',
      width: 200,
      filterable: false,
    },
    {
      field: 'comment',
      headerName: 'Comment',
      width: 200,
      filterable: false,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span style={hoverStyles}>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'workingInterest',
      headerName: 'Stake Transacted (%)',
      width: 200,
      filterable: false,
    },
    {
      field: 'weaWorkingInterestValuation',
      headerName: 'WEA Transacted Valuation (US$MM)',
      width: 200,
      valueFormatter: formatAsInt,
      filterable: false,
    },
    {
      field: 'weaReservesAcquisitionCost',
      headerName: 'WEA Reserves Acquisition Cost ($/boe)',
      width: 200,
      valueFormatter: formatTwoDecimals,
      filterable: false,
    },
    {
      field: 'priceFlowing',
      headerName: 'WEA Price per Flowing Barrel ($/boe/d)',
      width: 200,
      valueFormatter: formatTwoDecimals,
      filterable: false,
    },
    {
      field: 'weaWorkingInterestReserves',
      headerName: 'WEA Transacted Reserves (MMboe)',
      width: 200,
      valueFormatter: formatAsInt,
      filterable: false,
    },
    {
      field: 'weaWorkingInterestLiquidsReserves',
      headerName: 'WEA Transacted Oil/Condensate Reserves (MMbbl)',
      width: 200,
      valueFormatter: formatAsInt,
      filterable: false,
    },
    {
      field: 'weaWorkingInterestGasReserves',
      headerName: 'WEA Transacted Gas Reserves (Bcf)',
      width: 200,
      valueFormatter: formatAsInt,
      filterable: false,
    },
    {
      field: 'weaWorkingInterestProduction',
      headerName: 'WEA Transacted Production (boe/d)',
      width: 200,
      valueFormatter: formatTwoDecimals,
      filterable: false,
    },
    {
      field: 'weaWorkingInterestLiquidsProduction',
      headerName: 'WEA Transacted Oil/Condensate Production (boe/d)',
      width: 200,
      valueFormatter: formatTwoDecimals,
      filterable: false,
    },
    {
      field: 'weaWorkingInterestGasProduction',
      headerName: 'WEA Transacted Gas Production (MMcf/d)',
      width: 200,
      valueFormatter: formatAsInt,
      filterable: false,
    },
    {
      field: 'weaRemainingEmissionsIntensity',
      headerName: 'WEA Remaining Emissions Intensity (kgCO2e/boe)',
      width: 200,
      valueFormatter: formatTwoDecimals,
      filterable: false,
    },
    {
      field: 'weaCurrentEmissionsIntensity',
      headerName: 'WEA Current Emissions Intensity (kgCO2e/boe)',
      width: 200,
      valueFormatter: formatTwoDecimals,
      filterable: false,
    },
  ];

  const exportTransactions = () => {
    const path = 'm-a/export-transaction-table/';
    const body = {
      transactions: transactions,
      headers: columns.map((v) => v.field),
      headerLabels: columns.map((v) => v.headerName),
    };
    const downloadName = `M_A_Transactions_Data.xlsx`;

    downloadXlsx(path, body, downloadName);
  };

  return (
    <PageShell filters={filters}>
      <div>
        <Button variant="contained" onClick={exportTransactions}>
          Download Data
        </Button>
      </div>
      <DataGrid
        className={classes.wrapper}
        rows={transactions}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 1, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
      />
    </PageShell>
  );
};
