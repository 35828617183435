import React from 'react';
import { Box, Typography } from '@mui/material';
import { doubleClickEvent } from '../../../../utils/doubleClickEvent';
import { legendInChartStyle } from './LegendInChart.style';
import clsx from 'clsx';

export const LegendInChart = ({
  legendItems,
  toggleTrace,
  isNextToChart = false,
  offsetFromRight,
}) => {
  const classes = legendInChartStyle({ offsetFromRight });
  return (
    <Box className={clsx(classes.root, { [classes.rootRelative]: isNextToChart })}>
      {legendItems.map((item, index) => (
        <Box
          key={index}
          onClick={(e) =>
            doubleClickEvent(
              e,
              () => toggleTrace(item.title, !item.isHiddenInChart),
              () => toggleTrace(item.title, !item.isHiddenInChart, true),
            )
          }
          className={classes.row}
          sx={{
            opacity: item.isHiddenInChart ? 0.5 : 1,
          }}
        >
          <Box
            sx={{
              width: 10,
              height: 10,
              flexShrink: 0,
              bgcolor: item.color,
            }}
          />
          <Typography className={classes.text} variant="body1">
            {item.title}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
