import { COST_GRAPH_TYPES } from '../../../helpers/countGraphConstatns';
import { getCostGraphSelector } from '../../../helpers/getCostGraphSelector';
import { STACK_BY_TYPES } from '../../../helpers/stackByConstatnts';
import { fillStackedData } from '../../../helpers/fillStackedData';
import { fillSimpleData } from '../../../helpers/fillSimpleData';
import { getDataForPricePaidCalculation } from '../../../helpers/getDataForPricePaidCalculation';

export const getCalculatePageContentDataCb =
  (graphXType, graph2Type, stackBy, graphYSecondaryType) =>
  (pageContentData, year, deal, companyTransaction, assetTransaction) => {
    const dealNotCounted = !pageContentData.dealIds.includes(deal.id);
    if (dealNotCounted) {
      pageContentData.dealIds.push(deal.id);
    }

    pageContentData.count[year] = (pageContentData.count[year] || 0) + 1;

    if (assetTransaction.weaWorkingInterestProduction) {
      pageContentData.dealWorkingInterestProductions[deal.id] =
        (pageContentData.dealWorkingInterestProductions[deal.id] || 0) +
        assetTransaction.weaWorkingInterestProduction;
    }

    if (assetTransaction.weaWorkingInterestReserves) {
      pageContentData.dealWorkingInterestReserves[deal.id] =
        (pageContentData.dealWorkingInterestReserves[deal.id] || 0) +
        assetTransaction.weaWorkingInterestReserves;
    }

    if (assetTransaction.weaWorkingInterestValuation) {
      pageContentData.dealWorkingInterestValuations[deal.id] =
        (pageContentData.dealWorkingInterestValuations[deal.id] || 0) +
        assetTransaction.weaWorkingInterestValuation;
    }

    const secondaryYData = getDataForPricePaidCalculation(assetTransaction, graphYSecondaryType);

    // SECONDARY Y AXIS
    if (graphXType === COST_GRAPH_TYPES.YEAR) {
      fillSimpleData(pageContentData.secondaryYChart1, year, secondaryYData, true);
    } else if (graphXType === COST_GRAPH_TYPES.COUNTRY_NAME) {
      fillSimpleData(
        pageContentData.secondaryYChart1,
        assetTransaction.countryName,
        secondaryYData,
      );
    }
    if (graphXType === COST_GRAPH_TYPES.BUYER_NAME || graphXType === COST_GRAPH_TYPES.SELLER_NAME) {
      const selectorInner =
        graphXType === COST_GRAPH_TYPES.BUYER_NAME
          ? companyTransaction.buyerCompanyName
          : companyTransaction.sellerCompanyName;

      fillSimpleData(pageContentData.secondaryYChart1, selectorInner, secondaryYData);
    }

    // REGULAR Y AXIS
    if (
      assetTransaction.weaWorkingInterestValuation ||
      assetTransaction.weaWorkingInterestValuation === 0
    ) {
      if (graphXType === COST_GRAPH_TYPES.YEAR) {
        fillStackedData(
          pageContentData.sum,
          stackBy === STACK_BY_TYPES.SELLER
            ? companyTransaction.sellerCompanyName
            : companyTransaction.buyerCompanyName,
          year,
          assetTransaction.weaWorkingInterestValuation,
        );
      } else if (graphXType === COST_GRAPH_TYPES.COUNTRY_NAME) {
        fillStackedData(
          pageContentData.sum,
          stackBy === STACK_BY_TYPES.SELLER
            ? companyTransaction.sellerCompanyName
            : companyTransaction.buyerCompanyName,
          assetTransaction.countryName,
          assetTransaction.weaWorkingInterestValuation,
        );
        pageContentData.xAxisList[assetTransaction.countryName] = true;
        pageContentData.assetNamesByX[assetTransaction.countryName] = {
          ...(pageContentData.assetNamesByX[assetTransaction.countryName] || {}),
          [assetTransaction.assetName]: true,
        };
      }

      if (
        graphXType === COST_GRAPH_TYPES.BUYER_NAME ||
        graphXType === COST_GRAPH_TYPES.SELLER_NAME
      ) {
        const companyName =
          graphXType.value === COST_GRAPH_TYPES.BUYER_NAME
            ? companyTransaction.buyerCompanyName
            : companyTransaction.sellerCompanyName;

        if (!pageContentData.sumCompany[companyName]) {
          pageContentData.sumCompany[companyName] = {};
        }
        pageContentData.sumCompany[companyName][deal.id] =
          (pageContentData.sumCompany[companyName][deal.id] || 0) +
          assetTransaction.weaWorkingInterestValuation;
        pageContentData.xAxisList[companyName] = true;
        pageContentData.assetNamesByX[companyName] = {
          ...(pageContentData.assetNamesByX[companyName] || {}),
          [assetTransaction.assetName]: true,
        };

        // stacking buyer/seller
        const selectorOuter =
          graphXType === COST_GRAPH_TYPES.BUYER_NAME
            ? companyTransaction.sellerCompanyName
            : companyTransaction.buyerCompanyName;
        const selectorInner =
          graphXType === COST_GRAPH_TYPES.BUYER_NAME
            ? companyTransaction.buyerCompanyName
            : companyTransaction.sellerCompanyName;

        pageContentData.graph1Y[selectorOuter] = {
          ...(pageContentData.graph1Y[selectorOuter] || {}),
          [selectorInner]: {
            ...((pageContentData.graph1Y[selectorOuter] || {})[selectorInner] || {}),
            [deal.id]:
              (((pageContentData.graph1Y[selectorOuter] || {})[selectorInner] || {})[deal.id] ||
                0) + assetTransaction.weaWorkingInterestValuation,
          },
        };
        pageContentData.graph1X[selectorInner] = {
          name: selectorInner,
          value: {
            ...(pageContentData.graph1X[selectorInner]?.value || {}),
            [deal.id]:
              ((pageContentData.graph1X[selectorInner]?.value || {})[deal.id] || 0) +
              assetTransaction.weaWorkingInterestValuation,
          },
        };
      }

      pageContentData.considerationBox = {
        ...pageContentData.considerationBox,
        [deal.id]:
          (pageContentData.considerationBox[deal.id] || 0) +
          assetTransaction.weaWorkingInterestValuation,
      };
    }

    const selectorInner = getCostGraphSelector(
      year,
      graph2Type,
      assetTransaction,
      companyTransaction,
      dealNotCounted,
    );
    if (selectorInner && dealNotCounted) {
      pageContentData.countByType[deal.dealType] = {
        ...(pageContentData.countByType[deal.dealType] || {}),
        [selectorInner]:
          ((pageContentData.countByType[deal.dealType] || {})[selectorInner] || 0) + 1,
      };
      pageContentData.chart2XAxisList[selectorInner] = true;
    }

    pageContentData.assetNamesByYear[year] = {
      ...(pageContentData.assetNamesByYear[year] || {}),
      [assetTransaction.assetName]: true,
    };
  };
