import { Box, Grid, TablePagination } from '@mui/material';
import { VideoGalleryGridItem, VideoGalleryGridItemSkeleton } from '../VideoGalleryGridItem';

import { PER_PAGE_OPTIONS } from '../../types';
import React from 'react';
import { useStyles } from './styles';
import { useVideoTutorials } from '../../VideoTutorialsContext';

const NUM_SKELETON_ITEMS = 12;

export const VideoGalleryGridSkeleton = () => {
  return (
    <Grid container spacing={3}>
      {Array(NUM_SKELETON_ITEMS)
        .fill(0)
        .map((_, i) => (
          <VideoGalleryGridItemSkeleton key={i} />
        ))}
    </Grid>
  );
};

export const VideoGalleryGrid = ({ videos, totalCount }) => {
  const classes = useStyles();
  const { page, setPage, perPage, setPerPage, setSelectedVideo } = useVideoTutorials();

  if (!videos?.length) {
    return null;
  }

  const onRowsPerPageChange = (e) => {
    setPerPage(e.target.value);
    setPage(0);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <Grid container spacing={3}>
        {videos?.map((video) => (
          <VideoGalleryGridItem
            key={video.id}
            video={video}
            onClick={(video) => setSelectedVideo(video)}
          />
        ))}
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '16px',
        }}
      >
        <TablePagination
          className={classes.pagination}
          component="div"
          count={totalCount}
          labelRowsPerPage="Videos per page"
          page={page}
          color="primary"
          onPageChange={(_, page) => setPage(page)}
          rowsPerPage={perPage}
          onRowsPerPageChange={onRowsPerPageChange}
          rowsPerPageOptions={PER_PAGE_OPTIONS}
        />
      </Box>
    </Box>
  );
};
