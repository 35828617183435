import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../../../utils/constants';
import { LOAD_PORTFOLIO, SHARE_PORTFOLIO } from '../operations';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import Button from '@mui/material/Button';
import { CURRENT_USER_FRIENDS } from '../../../CurrentUserContext/operations';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid2';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SnackbarContext from '../../../SnackbarContext';
import TextField from '@mui/material/TextField';
import { defaultDimensions } from '../../../../utils/analytics';
import { getUserId } from '../../../../utils/auth';
import { joinGqlErrorMessage } from '../../../../utils/gqlErrors';
import makeStyles from '@mui/styles/makeStyles';
import { useCurrentUser } from '../../../CurrentUserContext';

const useStyles = makeStyles((theme) => ({
  modal: {
    minHeight: '300px',
    height: '75%',
    maxHeight: '515px',
    width: '500px',
    paddingBottom: '10px',
    boxSizing: 'border-box',
  },
  container: {
    ...theme.palette.altScrollbarBlue,
    width: '250px',
    padding: '10px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  margin: {
    margin: '10px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'column',
  },
  button: {
    ...theme.palette.button,
    paddingTop: '8px',
    paddingBottom: '8px',
    margin: '10px 0 20px',
  },
  textArea: {
    disableUnderline: true,
    paddingTop: '2.5rem',
  },
}));

export const SharePortfolio = ({ context, handleClose }) => {
  const classes = useStyles();
  const { currentUser } = useCurrentUser();
  const { setSnack } = useContext(SnackbarContext);
  const [selectedPortfolio, setSelectedPortfolio] = useState('');
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [requestLoading, setRequestLoading] = useState(false);

  const {
    loading: portfolioLoading,
    data: portfolioData,
    refetch: portfolioRefetch,
  } = useQuery(LOAD_PORTFOLIO, {
    variables: { context },
    fetchPolicy: 'network-only',
  });
  const {
    loading: friendsLoading,
    data: friendsData,
    refetch: friendsRefetch,
  } = useQuery(CURRENT_USER_FRIENDS, {
    fetchPolicy: 'network-only',
  });

  const sortAndCreateFriendList = (friends) => {
    let menuItemProps = friends.map((user) => {
      const isShared = !!user.sharedUserPortfolios.find((p) => p.id === selectedPortfolio.id);
      const isOwner = !isShared && !!user.userPortfolios.find((p) => p.id === selectedPortfolio.id);
      const friendName = `${user.firstName} ${user.lastName}`;
      const key = `${user.id}-${friendName}`;
      const companyTitle = user.admin
        ? 'Welligence (Not a Client)'
        : user.client
          ? user.client.companyTitle
          : '';
      const sharedSuffix = isShared ? ' (shared)' : isOwner ? ' (owner)' : '';
      const listItemTextPrimary = `${
        currentUser.admin && companyTitle ? `${companyTitle}: ` : ''
      }${friendName}${sharedSuffix}`;
      return {
        isShared,
        isOwner,
        key,
        listItemTextPrimary,
        user,
      };
    });
    menuItemProps.sort((a, b) => {
      if (a.listItemTextPrimary < b.listItemTextPrimary) return -1;
      if (a.listItemTextPrimary > b.listItemTextPrimary) return 1;
      return 0;
    });

    return menuItemProps.map(({ isShared, isOwner, key, listItemTextPrimary, user }) => (
      <MenuItem key={key} value={user} disabled={!!isShared || !!isOwner}>
        <ListItemText primary={listItemTextPrimary} />
      </MenuItem>
    ));
  };

  const friends = useMemo(() => {
    return !friendsLoading && friendsData
      ? sortAndCreateFriendList(friendsData.currentUserFriends)
      : null;
  }, [friendsData, selectedPortfolio]);

  const [sharePortfolio] = useMutation(SHARE_PORTFOLIO, {
    variables: { userPortfolioId: selectedPortfolio.id, users: selectedFriends.map((f) => f.id) },
    onCompleted: () => {
      setRequestLoading(false);
      setSnack({
        open: true,
        severity: 'success',
        message: `Successfully shared portfolio!`,
      });
      handleClose();
    },
    onError: ({ graphQLErrors }) => {
      setSnack({
        open: true,
        message: joinGqlErrorMessage(graphQLErrors),
        severity: 'error',
      });
    },
  });

  const sharePortfolios = () => {
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: 'PortfolioAnalysis',
      event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
      event_label: 'share_portfolio',
      userId: getUserId(),
      ...defaultDimensions,
    });
    setRequestLoading(true);
    sharePortfolio();
  };

  const refetch = () => {
    portfolioRefetch();
    friendsRefetch();
  };

  useEffect(() => {
    refetch && refetch();
  }, []);

  const handleSelectedPortfolio = (e) => {
    setSelectedPortfolio(e.target.value);
    setSelectedFriends([]);
  };

  const handleSelectedFriends = (e) => {
    const { value } = e.target;
    if (value[value.length - 1] === 'all') {
      setSelectedFriends(
        selectedFriends.length === friends.length
          ? []
          : friends.filter((f) => !f.props.disabled).map((f) => f.props.value),
      );
    } else {
      setSelectedFriends(value);
    }
  };

  return (
    <div className={classes.content}>
      <Grid className={classes.container}>
        <FormControl variant="filled" fullWidth className={classes.margin}>
          <InputLabel>Portfolios</InputLabel>
          <Select disableUnderline value={selectedPortfolio} onChange={handleSelectedPortfolio}>
            {!portfolioLoading && portfolioData
              ? portfolioData.userPortfolios.portfolios
                  .map((portfolio) => (
                    <MenuItem key={`share-portfolio-${portfolio.name}`} value={portfolio}>
                      {portfolio.name}
                    </MenuItem>
                  ))
                  .concat(
                    portfolioData.userPortfolios.sharedPortfolios.map((portfolio) => (
                      <MenuItem
                        key={`share-portfolio-shared-portfolio-${portfolio.name}`}
                        value={portfolio}
                      >
                        {portfolio.name} (shared with you)
                      </MenuItem>
                    )),
                  )
              : null}
          </Select>
        </FormControl>
        <TextField
          disabled
          className={classes.margin}
          label="Notes"
          variant="filled"
          fullWidth
          multiline
          rows={6}
          defaultValue={selectedPortfolio ? selectedPortfolio.notes : ''}
          slotProps={{
            input: {
              className: classes.textArea,
            },
          }}
        />
        <FormControl variant="filled" fullWidth className={classes.margin}>
          <InputLabel>Users</InputLabel>
          <Select
            disabled={!selectedPortfolio.portfolio}
            disableUnderline
            multiple
            value={selectedFriends}
            onChange={handleSelectedFriends}
            renderValue={(selected) =>
              selected.map((s) => `${s.firstName} ${s.lastName}`).join(', ')
            }
          >
            <MenuItem value="all">Select All</MenuItem>
            {friends}
          </Select>
        </FormControl>
      </Grid>
      <div className={classes.buttonWrapper}>
        <Button
          disabled={!selectedPortfolio.portfolio || !selectedFriends.length || requestLoading}
          act
          variant="contained"
          className={classes.button}
          onClick={sharePortfolios}
        >
          Share Portfolio
        </Button>
      </div>
    </div>
  );
};
