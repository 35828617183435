import * as React from 'react';
import { Typography } from '@mui/material';

export const HighlightedResultText = ({ inputText, text, variant, classes }) => {
  const words = inputText
    .replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')
    .split(/\s+/)
    .join('|');
  const re = new RegExp(words, 'gi');
  const highlightedText = text.replace(re, (match) => `<mark>${match}</mark>`);

  return (
    <Typography
      className={classes}
      variant={variant}
      component="span"
      dangerouslySetInnerHTML={{ __html: highlightedText }}
    />
  );
};
