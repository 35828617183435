import React, { useContext, useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { PORTFOLIO_ANALYSIS_PATH } from '../../utils/constants';
import {
  ProductionLineGraph,
  ProductionLineGraphContext,
} from '../PortfolioAnalysis/PortfolioGraphing/LineGraphs';
import Modal from '../Modals';
import { setCountryForPortfolioAnalysis } from './Links/utils';

const useStyles = makeStyles(() => ({
  modal: {
    backgroundColor: 'white',
    height: '85%',
    width: '90%',
    maxHeight: '600px',
    maxWidth: '1400px',
  },
}));

export default ({ portfolio }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  // production line graph context
  const { range } = useContext(ProductionLineGraphContext);

  const linkToPage = {
    page: PORTFOLIO_ANALYSIS_PATH,
    title: 'View in Portfolio Analysis',
    callback: () => setCountryForPortfolioAnalysis(portfolio),
  };

  return (
    <>
      <ProductionLineGraph
        open={open}
        setOpen={() => setOpen(true)}
        range={range}
        linkToPage={linkToPage}
        darkTheme
      />
      <Modal
        handleClose={() => setOpen(false)}
        open={open}
        title="Chart Expansion"
        titleAlignedWithClose
        styles={classes}
        disablePortal
      >
        <ProductionLineGraph
          open={open}
          setClose={() => setOpen(false)}
          showLegend
          range={range}
          linkToPage={linkToPage}
        />
      </Modal>
    </>
  );
};
