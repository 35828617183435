import makeStyles from '@mui/styles/makeStyles';

export const useSidePageButtonStyles = makeStyles((theme) => ({
  button: {
    ...theme.palette.transparentBackground,
    color: 'black',
    cursor: 'pointer',
    backgroundColor: '#e3e8eb',
    '&:hover': {
      backgroundColor: '#dae1e7',
    },
    width: '40px',
    padding: '5px 10px',
    boxSizing: 'border-box',
    borderRadius: '5px 0 0 5px',
    writingMode: 'vertical-lr',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  buttonWithClose: {
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
  },
  buttonLabel: {
    transform: 'rotate(180deg)',
  },
  close: {
    position: 'absolute',
    paddingTop: '7px',
    paddingBottom: '7px',
    top: 0,
    left: 0,
    padding: 0,
    width: '100%',
    borderRadius: 0,
  },
  boxy: {
    backgroundColor: 'white',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderLeft: 'none',
    boxShadow:
      '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
  },
  left: {
    borderRadius: '0 5px 5px 0',
  },
}));
