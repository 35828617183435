import Button from '@mui/material/Button';
import React from 'react';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.palette.mapInfoSection,
  },
  item: {
    margin: '5px',
    ...theme.palette.text,
    wordWrap: 'normal',
    whiteSpace: 'pre-line',
  },
  label: {
    color: '#507180',
  },
  button: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
  },
}));

// Show related pipeline network(s) for a pipeline (displayedItem)
export default ({ displayedItem, loading, data, onClick }) => {
  const classes = useStyles();
  const pipelineNetworks = data?.pipeline?.pipelineNetworks || data?.asset?.pipelineNetworks;

  /**
   * This component should only be rendered when:
   * - there is an item object to be displayed
   * - data is not loading
   * - data exists
   * - entity is a `pipeline`
   * - there is a non-zero number of associated `pipeline network`s
   */
  if (!displayedItem || loading || !pipelineNetworks || !(pipelineNetworks.length > 0)) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.item} variant="body1" component="p">
        <Typography className={classes.label} variant="body1" component="span">
          Pipeline Network:
        </Typography>
        {pipelineNetworks.map((pipelineNetwork) => (
          <Button
            key={pipelineNetwork.name}
            variant="outlined"
            className={classes.button}
            onClick={() => onClick(pipelineNetwork)}
          >
            {pipelineNetwork.name}
          </Button>
        ))}
      </Typography>
    </div>
  );
};
