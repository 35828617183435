import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../../utils/constants';
import React, { Fragment, useContext, useEffect, useState } from 'react';

import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { MAP_LAYER_IDS } from '../../Map/Utils/constants';
import MapFilterContext from './filterContext';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { buildLabelsLayerId } from '../../Map/Utils/layers';
import { defaultDimensions } from '../../../utils/analytics';
import { getUserId } from '../../../utils/auth';
import makeStyles from '@mui/styles/makeStyles';
import merge from 'lodash/merge';
import { useCurrentUser } from '../../CurrentUserContext';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.palette.mapTab,
    ...theme.palette.scrollbar,
    padding: '5px',
  },
  radioChildrenContainer: {
    paddingLeft: '15px',
  },
  radio: {
    color: '#2a2e49',
    '&.Mui-checked': {
      color: '#2a2e49',
    },
  },
  formRoot: {
    margin: '5px 0',
  },
}));

const EA_WELLS_LABEL = 'E&A Wells to Watch';
const LNG_FACILITIES_LABEL = 'LNG';

const BASE_LAYERS = {
  Blocks: {
    label: 'Blocks',
    children: {
      Licensed: {
        label: 'Licensed',
        checked: true,
        tag: 'Licensed',
        // TODO: Cleanup the snake_case to camelCase refactor - https://welligence.atlassian.net/browse/XWWP-603
        // customFilter: ['==', ['get', 'licenseStatus'], 'Licensed'],
        customFilter: [
          '==',
          ['coalesce', ['get', 'licenseStatus'], ['get', 'license_status']],
          'Licensed',
        ],
      },
      Unlicensed: {
        label: 'Unlicensed',
        checked: true,
        tag: 'Unlicensed',
        // If licenseStatus is null or empty we attribute it as 'Unlicensed'
        // TODO: Cleanup the snake_case to camelCase refactor - https://welligence.atlassian.net/browse/XWWP-603
        // customFilter: [
        //   'case',
        //   ['!', ['has', 'licenseStatus']],
        //   true,
        //   ['==', ['get', 'licenseStatus'], ''],
        //   true,
        //   ['==', ['get', 'licenseStatus'], 'Unlicensed'],
        //   true,
        //   false,
        // ],
        customFilter: [
          'case',
          ['all', ['!', ['has', 'licenseStatus']], ['!', ['has', 'license_status']]],
          true,
          ['==', ['coalesce', ['get', 'licenseStatus'], ['get', 'license_status']], ''],
          true,
          ['==', ['coalesce', ['get', 'licenseStatus'], ['get', 'license_status']], 'Unlicensed'],
          true,
          false,
        ],
      },
      Labels: {
        label: 'Labels',
        checked: true,
        tag: buildLabelsLayerId('blocks'),
        addDivider: true,
        customFilter: 'labels',
      },
    },
    customFilter: (filters) => {
      // Remove labels layer from filter
      const newFilters = filters.filter((filter) => filter !== 'labels');
      // TODO: Cleanup the snake_case to camelCase refactor - https://welligence.atlassian.net/browse/XWWP-603
      // return newFilters.length > 0
      //   ? ['case', ...newFilters.reduce((acc, filter) => [...acc, filter, true], []), false]
      //   : ['match', ['get', 'licenseStatus'], ['null'], true, false];
      return newFilters.length > 0
        ? ['case', ...newFilters.reduce((acc, filter) => [...acc, filter, true], []), false]
        : [
            'match',
            ['coalesce', ['get', 'licenseStatus'], ['get', 'license_status']],
            ['null'],
            true,
            false,
          ];
    },
    childrenTag: 'licenseStatus',
    checked: true,
    tag: 'blocks',
  },
  Assets: {
    label: 'Assets',
    children: {
      Labels: {
        label: 'Labels',
        checked: true,
        tag: buildLabelsLayerId('assets'),
      },
    },
    checked: true,
    tag: 'assets',
  },
  Fields: {
    label: 'Fields',
    children: {
      Labels: {
        label: 'Labels',
        checked: true,
        tag: buildLabelsLayerId('fields'),
      },
    },
    checked: true,
    tag: 'fields',
  },
  Wells: {
    label: 'Wells',
    children: {
      Exploration: {
        label: 'Exploration',
        checked: true,
        tag: 'Exploration',
      },
      Injection: {
        label: 'Injection',
        checked: true,
        tag: 'Injection',
      },
      Production: {
        label: 'Production',
        checked: true,
        tag: 'Production',
      },
      Other: {
        label: 'Other',
        checked: true,
        tag: 'Other',
      },
      Labels: {
        label: 'Labels',
        checked: true,
        tag: buildLabelsLayerId('wells'),
        addDivider: true,
      },
    },
    childrenTag: 'weaClassification',
    checked: true,
    tag: 'wells',
  },
  [EA_WELLS_LABEL]: {
    label: EA_WELLS_LABEL,
    children: {
      Labels: {
        label: 'Labels',
        checked: true,
        tag: MAP_LAYER_IDS.eaWellLabels,
        hasNoTileset: true,
      },
    },
    checked: true,
    tag: 'eawells',
    skipCountryTilesets: true,
  },
  Basins: {
    label: 'Basins',
    children: {
      Labels: {
        label: 'Labels',
        checked: false,
        tag: MAP_LAYER_IDS.basinsLabels,
        hasNoTileset: true,
      },
    },
    checked: false,
    tag: MAP_LAYER_IDS.basins,
    skipCountryTilesets: true,
  },
  Facilities: {
    label: 'Facilities',
    children: null,
    checked: true,
    tag: 'facilities',
  },
  [LNG_FACILITIES_LABEL]: {
    label: LNG_FACILITIES_LABEL,
    children: {
      Liquefaction: {
        label: 'Liquefaction',
        checked: true,
        tag: 'lng_liquefaction',
      },
      Regasification: {
        label: 'Regasification',
        checked: true,
        tag: 'lng_regasification',
      },
    },
    childrenTag: 'category',
    checked: true,
    tag: MAP_LAYER_IDS.lngFacilities,
    skipCountryTilesets: true,
  },
  Pipelines: {
    label: 'Pipelines',
    children: {
      Gas: {
        label: 'Gas',
        checked: true,
        tag: 'Gas',
      },
      Oil: {
        label: 'Oil',
        checked: true,
        tag: 'Oil',
      },
      Products: {
        label: 'Products',
        checked: true,
        tag: 'Products',
      },
      'Other (grey)': {
        label: 'Other (grey)',
        checked: true,
        tag: 'Other',
      },
    },
    childrenTag: 'product',
    checked: true,
    tag: 'pipelines',
  },
  'Pipeline Segments': {
    label: 'Pipeline Segments',
    children: {
      Gas: {
        label: 'Gas',
        checked: true,
        tag: 'Gas',
      },
      Oil: {
        label: 'Oil',
        checked: true,
        tag: 'Oil',
      },
    },
    childrenTag: 'hydrocarbon',
    checked: true,
    tag: 'pipeline_segments',
  },
  Other: {
    label: 'Other',
    children: null,
    checked: true,
    tag: 'formations',
  },
};

export default ({ map, style, hidden }) => {
  const { currentUser } = useCurrentUser();
  const { showEaWells, setShowEaWells } = useContext(MapFilterContext);
  const classes = useStyles();
  const [prevStyle, setPrevStyle] = useState(null);

  const [layers, setLayers] = useState(merge({}, map?.myLayers || BASE_LAYERS));

  const [userProfile, setUserProfile] = useState({});

  useEffect(() => {
    if (currentUser && currentUser.mapTilesetSources) {
      setUserProfile(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    if (prevStyle === style || prevStyle === null) {
      setLayers(merge({}, map?.myLayers || BASE_LAYERS));
    } else {
      setLayers(merge({}, BASE_LAYERS));
    }
    setPrevStyle(style);
  }, [style]);

  useEffect(() => {
    // Toggle the visibility of the E&A wells labels along with the wells themselves:
    map?.setLayoutProperty(
      MAP_LAYER_IDS.eaWellLabels,
      'visibility',
      showEaWells ? 'visible' : 'none',
    );
    const newLayers = {
      ...layers,
    };
    const newChild = newLayers[EA_WELLS_LABEL].children.Labels;
    newChild.checked = showEaWells;
    setLayers(newLayers);
  }, [showEaWells]);

  const hasLabels = (layer) => {
    return ['blocks', 'assets', 'fields', 'wells'].includes(layer);
  };

  const toggleParentLayers = (layer) => {
    if (!map) return;

    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: 'Maps',
      event_action: ANALYTICS_EVENT_ACTIONS.layer,
      event_label: layer.label,
      userId: getUserId(),
      ...defaultDimensions,
    });

    const checked = !layer.checked;
    const newLayers = {
      ...layers,
    };
    const newLayer = newLayers[layer.label];
    newLayer.checked = checked;

    if (layer.children) {
      Object.values(newLayer.children).map((newChild) => {
        newChild.checked = checked;
      });
    }

    const vis = checked ? 'visible' : 'none';
    if ([MAP_LAYER_IDS.basins, MAP_LAYER_IDS.lngFacilities].includes(layer.tag)) {
      map.setFilter(layer.tag, null);
      map.setLayoutProperty(layer.tag, 'visibility', vis);

      if (layer.tag === MAP_LAYER_IDS.basins) {
        map.setLayoutProperty(MAP_LAYER_IDS.basinsLabels, 'visibility', vis);
      }
    } else if (layer.tag === 'eawells') {
      // The E&A wells layer is a special case: we share the state of the checkbox with the
      // "Screening" tab, so we need to get/set the value using the method passed in as a prop.
      setShowEaWells((showEaWells) => !showEaWells);
      return;
    } else {
      const userTileSources = userProfile.mapTilesetSources;

      for (const source of userTileSources) {
        let regex;
        if (layer.tag === 'blocks' && source.country === 'USA') {
          regex = new RegExp(`.*_(leases|blocks)`, 'g');
        } else {
          regex = new RegExp(`.*_${layer.tag}`, 'g');
        }
        const layerNames = source.layers.filter((l) => l.match(regex));
        layerNames.map((layerName) => {
          if (layerName !== undefined) {
            map.setFilter(layerName, null);
            map.setLayoutProperty(layerName, 'visibility', vis);
            if (hasLabels(layer.tag)) {
              // Toggle the visibility of the labels along with the layer, and clear any filters:
              const labelLayerName = buildLabelsLayerId(layerName);
              map.setLayoutProperty(labelLayerName, 'visibility', vis);
              map.setFilter(labelLayerName, null);
            }
          }
        });
        // if layer is other/formations attempt to toggle presalt_polygons
        if (layer.tag === 'formations') {
          const presaltLayer = source.layers.find((l) =>
            l.match(new RegExp(`.*_presalt_polygons`, 'g')),
          );
          if (presaltLayer) {
            map.setLayoutProperty(`${presaltLayer}`, 'visibility', vis);
          }
        }
      }
    }
    map.myLayers = newLayers;
    setLayers(newLayers);
  };

  const toggleChildLayers = (layer, child) => {
    if (!map) return;

    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: 'Maps',
      event_action: ANALYTICS_EVENT_ACTIONS.layer,
      event_label: child.label,
      userId: getUserId(),
      ...defaultDimensions,
    });

    const checked = !child.checked;
    const newLayers = {
      ...layers,
    };
    const newLayer = newLayers[layer.label];
    newLayer.children[child.label].checked = checked;
    newLayers[layer.label] = newLayer;

    const vis = checked ? 'visible' : 'none';

    // Create a filter for the child layers:
    // e.g. the wells are grouped by their classification (Exploration, Production, ...),
    // but the layer includes all classifications, so we need to filter out the ones that
    // are not checked.
    let newFilter;
    if (layer.childrenTag) {
      let filters = Object.values(newLayer.children)
        .filter((newChild) => newChild.checked)
        .map((newChild) => (newChild.customFilter ? newChild.customFilter : newChild.tag));
      if (layer.customFilter && filters.length !== 0) {
        newFilter = layer.customFilter(filters);
      } else {
        if (filters.length === 0) {
          filters = ['']; // if no children are checked, show nothing
        }
        newFilter = ['match', ['get', layer.childrenTag], filters, true, false];
      }
    }

    const applyFilter = (layerName) => {
      // If the filter is present, make sure the parent layer is visible and set the filter:
      if (newFilter) {
        map.setLayoutProperty(layerName, 'visibility', 'visible');
        map.setFilter(layerName, newFilter);
      }
    };

    if (!layer.skipCountryTilesets) {
      for (const source of userProfile.mapTilesetSources) {
        const layerName = source.layers.find((l) => l.indexOf(layer.tag) !== -1);
        if (layerName !== undefined) {
          if (hasLabels(layer.tag)) {
            const labelLayerName = buildLabelsLayerId(layerName);

            // If the filter is present, we need to update the filter for the labels layer:
            // e.g. if we're unchecking the "Exploration" wells, we need to update the filter
            // for the "Exploration" labels.
            if (newFilter) {
              map.setFilter(labelLayerName, newFilter);
            }

            // Only toggle the visibility of the labels if the selected child is "Labels":
            if (child.tag.includes('labels')) {
              // If block labels were toggled, toggle the lease label layer as well
              if (layer.tag === 'blocks' && source.country === 'USA') {
                const leasesLayerName = buildLabelsLayerId('USA_leases');
                map.setLayoutProperty(leasesLayerName, 'visibility', vis);
              }
              map.setLayoutProperty(labelLayerName, 'visibility', vis);
            }
          }

          applyFilter(layerName);
        }
      }
    }

    // For layers that don't have a tileset, we need to toggle the visibility of the layer itself.
    // e.g. E&A wells
    if (child.hasNoTileset) {
      map.setLayoutProperty(child.tag, 'visibility', vis);
    }

    // The LNG facilities layer is not part of the user's tilesets, so we need to handle it separately:
    if (layer.tag === MAP_LAYER_IDS.lngFacilities) {
      applyFilter(layer.tag);
    }

    map.myLayers = newLayers;
    setLayers(newLayers);
  };

  if (hidden) {
    return null;
  }

  return (
    <div className={classes.root}>
      {Object.values(layers).map((layer) => {
        const checked = layer.tag === 'eawells' ? showEaWells : layer.checked;
        return (
          <FormControl key={layer.label} className={classes.formRoot} component="fieldset">
            <RadioGroup
              aria-label="layerFilter"
              name="layerFilter"
              id={`${layer.label.replace(/[^a-zA-Z0-9]/g, '-')}-radio-group`}
              value={checked}
              onClick={() => toggleParentLayers(layer)}
            >
              <FormControlLabel
                checked={checked}
                value={true}
                control={<Radio className={classes.radio} />}
                label={`${layer.label}`}
              />
              <FormControl className={classes.radioChildrenContainer} component="fieldset">
                {layer.children
                  ? Object.values(layer.children).map((child, i) => (
                      <Fragment key={`${child.label}-${i}`}>
                        {child.addDivider && <Divider />}
                        <RadioGroup
                          key={child.label}
                          aria-label="layerSubFilter"
                          name="layerSubFilter"
                          value={child.checked}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleChildLayers(layer, child);
                          }}
                        >
                          <FormControlLabel
                            checked={child.checked}
                            value={true}
                            control={<Radio className={classes.radio} />}
                            label={`${child.label}`}
                          />
                        </RadioGroup>
                      </Fragment>
                    ))
                  : null}
              </FormControl>
            </RadioGroup>
            <Divider />
          </FormControl>
        );
      })}
    </div>
  );
};
