import { useDefaultFilterOptions } from '../../helpers/useDefaultFilterOptions';
import { useDataOnUpdate } from '../../helpers/useDataOnUpdate';

const round2Decimals = (value) => {
  if (!value) return value;
  return Math.round(value * 100) / 100;
};

export const useTransactionTableController = (data) => {
  const { isLoading } = useDefaultFilterOptions(data);

  const pageContentData = {};

  const local = {};

  const calculatePageContentData = (pageContentData, year, deal, ct, at) => {
    const dealData = {
      dealName: deal.dealName,
      considerationMmusd: round2Decimals(deal.considerationMmusd),
      dealType: deal.dealType,
      effectiveDate: new Date(deal.effectiveDate).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      }).replace(/\s+/g, '-'),
      comment: deal.comment,
      paymentType: deal.paymentType,
      dealStatus: deal.dealStatus,
      source: deal.source,
      developmentStatus: deal.developmentStatus,
      announcedDate: deal.announcedDate,
      closeDate: deal.closeDate,
      acreage: round2Decimals(deal.acreage),
      enterpriseValue: round2Decimals(deal.enterpriseValue),
      reserves1p: round2Decimals(deal.reserves1p),
      reserves2p: round2Decimals(deal.reserves2p),
      liquidesReserves1p: round2Decimals(deal.liquidesReserves1p),
      liquidesReserves2p: round2Decimals(deal.liquidesReserves2p),
      gasReserves1p: round2Decimals(deal.gasReserves1p),
      gasReserves2p: round2Decimals(deal.gasReserves2p),
      production: round2Decimals(deal.production),
      productionLiquids: round2Decimals(deal.productionLiquids),
      productionGas: round2Decimals(deal.productionGas),
      reservesPrice: round2Decimals(deal.reservesPrice),
      priceFlowing: round2Decimals(deal.priceFlowing),
      priceAcre: round2Decimals(deal.priceAcre),
      currentEmissionsIntensity: round2Decimals(deal.currentEmissionsIntensity),
    };

    const companyTransactionData = {
      buyerCompanyName: ct.buyerCompanyName,
      sellerCompanyName: ct.sellerCompanyName,
      soldCompanyName: ct.soldCompanyName,
      buyerMotivation: ct.buyerMotivation,
      sellerMotivation: ct.sellerMotivation,
      stakeSold: round2Decimals(ct.stakeSold),
    };

    local[at.id] = {
      ...dealData,
      ...companyTransactionData,
      // asset transaction data
      countryId: at.countryId,
      regionName: at.regionName,
      countryName: at.countryName,
      id: at.id,
      assetName: at.assetName,
      assetStatus: at.assetStatus,
      shoreStatus: at.shoreStatus,
      weaWorkingInterestReserves: round2Decimals(at.weaWorkingInterestReserves),
      weaWorkingInterestProduction: round2Decimals(at.weaWorkingInterestProduction),
      buyerStakeBefore: round2Decimals(at.buyerStakeBefore),
      buyerStakeAfter: round2Decimals(at.buyerStakeAfter),
      sellerStakeBefore: round2Decimals(at.sellerStakeBefore),
      sellerStakeAfter: round2Decimals(at.sellerStakeAfter),
      workingInterest: round2Decimals(at.workingInterest),
      weaWorkingInterestValuation: round2Decimals(at.weaWorkingInterestValuation),
      weaWorkingInterestLiquidsReserves: round2Decimals(at.weaWorkingInterestLiquidsReserves),
      weaWorkingInterestGasReserves: round2Decimals(at.weaWorkingInterestGasReserves),
      weaWorkingInterestLiquidsProduction: round2Decimals(at.weaWorkingInterestLiquidsProduction),
      weaWorkingInterestGasProduction: round2Decimals(at.weaWorkingInterestGasProduction),
      weaUsdFlowingBbl: round2Decimals(at.weaUsdFlowingBbl),
      weaReservesAcquisitionCost: round2Decimals(at.weaReservesAcquisitionCost),
      weaCurrentEmissionsIntensity: round2Decimals(at.weaCurrentEmissionsIntensity),
      weaRemainingEmissionsIntensity: round2Decimals(at.weaRemainingEmissionsIntensity),
      weaWorkingInterestRemainingEmissions: round2Decimals(at.weaWorkingInterestRemainingEmissions),
      weaWorkingInterestCurrentEmissions: round2Decimals(at.weaWorkingInterestCurrentEmissions),
    };
  };

  const { filters } = useDataOnUpdate(data, pageContentData, calculatePageContentData);

  return {
    isLoading,
    filters,
    transactions: Object.values(local),
  };
};
