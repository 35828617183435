import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import GraphContainer from '../../../Graphs/graphContainer';
import { GraphWithCustomLegend } from '../../../Graphs/GraphWithCustomLegend/GraphWithCustomLegend';
import Grid from '@mui/material/Grid2';
import Modal from '../../../Modals';
import { maChartGraphNewStyle } from './MaChartGraphNew.style';
import { useChartModalStyles } from '../../helpers/useChartModalStyles';

export const MaChartGraphNew = ({
  content,
  submitCb,
  data,
  graphLayoutSettings,
  graphLayoutConfig,
  title,
  enableChartSettingsButton,
  setShowPlotlyLegend,
  showPlotlyLegend,
  showPlotlyLegendToggle,
  openControlsModal,
  setOpenControlsModal,
}) => {
  const classes = maChartGraphNewStyle();
  const classesModal = useChartModalStyles();
  const [open, setOpen] = useState(false);
  const showToggle = false;

  const closeFullscreen = () => {
    setOpen(false);
  };

  if (data[0]?.x?.length === 1) {
    // if there is only one x-axis value, set x-axis type to category to avoid showing time on x-axis ticks
    graphLayoutSettings.xaxis.type = 'category';
  }

  return (
    <div>
      <GraphContainer
        open={open}
        setOpen={() => setOpen(true)}
        setClose={closeFullscreen}
        setOpenControlsModal={setOpenControlsModal}
        showToggle={showToggle}
        title={title}
        loading={false}
        enableChartSettingsButton={enableChartSettingsButton}
        showPlotlyLegendToggle={showPlotlyLegendToggle}
        showPlotlyLegend={showPlotlyLegend}
        setShowPlotlyLegend={setShowPlotlyLegend}
        disableDownload
      >
        <GraphWithCustomLegend
          data={data}
          layout={graphLayoutSettings}
          config={graphLayoutConfig}
          loading={false}
          onRelayout={() => {}}
          onUpdate={() => {}}
        />
        {!!content && (
          <Modal
            handleClose={() => setOpenControlsModal(false)}
            open={openControlsModal}
            title="Chart Settings"
            titleAlignedWithClose={true}
            styles={classesModal}
          >
            <Grid container className={classes.root} direction="column">
              <Divider />
              <Grid className={classes.controlsContainer} container direction="row">
                {content}
              </Grid>
              <Divider />
              <Grid
                style={{ padding: '15px' }}
                container
                sx={{
                  justifyContent: 'center',
                }}
              >
                <Button variant="contained" className={classes.button} onClick={submitCb}>
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Modal>
        )}
      </GraphContainer>
      <Modal
        handleClose={closeFullscreen}
        open={open}
        title="Chart Expansion"
        titleAlignedWithClose={true}
        styles={classesModal}
      >
        <Grid container className={classes?.root} style={{ height: '100%' }}>
          <GraphContainer
            open={open}
            setClose={closeFullscreen}
            setOpenControlsModal={setOpenControlsModal}
            showToggle={showToggle}
            title={title}
            loading={false}
            enableChartSettingsButton={enableChartSettingsButton}
            disableDownload
            // downloadCallback={downloadCallback}
            // googleAnalyticsTag={CCUS_GOOGLE_ANALYTICS_TAG}
          >
            <GraphWithCustomLegend
              data={data}
              layout={{
                ...graphLayoutSettings,
                xaxis: {
                  ...graphLayoutSettings.xaxis,
                  automargin: true,
                },
                margin: {
                  l: graphLayoutSettings.yaxis?.title?.text ? 65 : 50,
                  r: graphLayoutSettings.yaxis2?.title?.text ? 100 : 60,
                  b: 'auto',
                  t: 30,
                },
                showlegend: showPlotlyLegendToggle && showPlotlyLegend,
              }}
              loading={false}
              isInModal
              onRelayout={() => {}}
              onUpdate={() => {}}
            />
          </GraphContainer>
        </Grid>
      </Modal>
    </div>
  );
};
