import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';

import TextField from '@mui/material/TextField';
import { SAVE_PORTFOLIO } from '../operations';
import { defaultDimensions } from '../../../../utils/analytics';
import SnackbarContext from '../../../SnackbarContext';
import Button from '@mui/material/Button';
import { getUserId } from '../../../../utils/auth';
import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../../../utils/constants';
import { joinGqlErrorMessage } from '../../../../utils/gqlErrors';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '250px',
    margin: '10px',
  },
  button: {
    ...theme.palette.button,
    paddingTop: '8px',
    paddingBottom: '8px',
    margin: '10px 0 20px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

export const SavePortfolio = ({ context, filters: portfolio, handleClose }) => {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);
  const [name, setName] = useState('');
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(false);

  const [savePortfolio] = useMutation(SAVE_PORTFOLIO, {
    variables: { context, portfolio, name, notes },
    onCompleted: () => {
      setLoading(false);
      setSnack({
        open: true,
        severity: 'success',
        message: `Successfully saved portfolio '${name}'!`,
      });
      handleClose();
    },
    onError: ({ graphQLErrors }) => {
      setLoading(false);
      setSnack({
        open: true,
        message: joinGqlErrorMessage(graphQLErrors),
        severity: 'error',
      });
    },
  });

  const savePortfolios = () => {
    setLoading(true);
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: 'PortfolioAnalysis',
      event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
      event_label: 'save_portfolio',
      userId: getUserId(),
      ...defaultDimensions,
    });
    savePortfolio();
  };

  return (
    <div className={classes.content}>
      <TextField
        className={classes.input}
        label="Portfolio Name"
        variant="filled"
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        className={classes.input}
        label="Notes"
        variant="filled"
        multiline
        rows={6}
        onChange={(e) => setNotes(e.target.value)}
      />
      <div className={classes.buttonWrapper}>
        <Button
          variant="contained"
          className={classes.button}
          onClick={savePortfolios}
          disabled={loading}
        >
          Save Portfolio
        </Button>
      </div>
    </div>
  );
};
