export const getDoubleChosenMap = (arr, doubleKey, key = 'id', secondKey = 'id') => {
  let noChosen = true;
  const obj = {};
  const secondObj = {};
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].isChosen) {
      obj[arr[i][key]] = true;
      noChosen = false;
    }
    if (arr[i][doubleKey].isChosen) {
      secondObj[arr[i][doubleKey][secondKey]] = true;
    }
  }
  return { obj, secondObj, noChosen };
};

export const getChosenMap = (arr, key = 'id') => {
  let noChosen = true;
  const obj = {};
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].isChosen) {
      obj[arr[i][key]] = true;
      noChosen = false;
    }
  }
  return { obj, noChosen };
};
