export const WORDPRESS_COUNTRY_IDS = {
  algeria: 254,
  angola: 220,
  argentina: 200,
  bolivia: 207,
  brazil: 198,
  chad: 237,
  chile: 209,
  colombia: 201,
  'congo brazzaville': 241,
  'cote d ivoire': 233,
  cuba: 234,
  'democratic republic of congo': 235,
  ecuador: 205,
  egypt: 247,
  'equatorial guinea': 226,
  gabon: 221,
  ghana: 218,
  gom: 203,
  guyana: 204,
  libya: 239,
  mauritania: 228,
  mexico: 199,
  mozambique: 224,
  namibia: 229,
  nigeria: 222,
  norway: 248,
  oman: 238,
  peru: 202,
  senegal: 227,
  'south africa': 217,
  suriname: 206,
  'trinidad and tobago': 232,
  uk: 236,
  uruguay: 208,
  'us gulf of mexico': 203,
};

// TODO: possibly add more special characters found in wordpress
export const replaceSpecialCharacters = (str) => {
  return str
    .replace(/&#038;/g, '&')
    .replace(/&#8217;/g, "'")
    .replace(/&#8211;/g, '–')
    .replace(/&#8220;/g, '"')
    .replace(/&#8221;/g, '"')
    .replace(/&#8230;/g, '...')
    .replace(/&#8242;/g, "'")
    .replace(/&#8243;/g, '"')
    .replace(/&#8249;/g, '<')
    .replace(/&#8250;/g, '>')
    .replace(/&#8216;/g, "'")
    .replace(/&#8212;/g, '--');
};

// 216 = 'news' category in wordpress
// TODO: fix wordpress error here
export const getNewsPosts = (country) => {
  return fetch(
    `https://welligence.com/wp-json/wp/v2/posts?post_country=${country}&categories=216`,
    {
      method: 'GET',
      referrerPolicy: 'no-referrer',
    },
  )
    .then((response) => response.json())
    .catch(() => []);
};

// 215 = 'deep dives' category in wordpress
export const getDeepDives = (country) => {
  return fetch(
    `https://welligence.com/wp-json/wp/v2/posts?post_country=${country}&categories=215`,
    {
      method: 'GET',
      referrerPolicy: 'no-referrer',
    },
  )
    .then((response) => response.json())
    .catch(() => []);
};

export const getNotes = () => {
  return fetch(
    'https://welligence.com/wp-json/wp/v2/posts?post_content=211&per_page=10&_fields=date,link,title.rendered,excerpt.rendered',
    {
      method: 'GET',
      referrerPolicy: 'no-referrer',
    },
  )
    .then((response) => response.json())
    .catch(() => []);
};
