import { getChartOptions } from '../../helpers';

export const BUYER_RESERVES_AGGREGATE_BY_TITLE = 'Buyer Reserves Acquired (MMboe)';
export const SELLER_RESERVES_AGGREGATE_BY_TITLE = 'Seller Reserves Divested (MMboe)';
export const ACQUISITION_COST_AGGREGATE_COMPARE_TO_TITLE = 'Acquisition Cost (US$/boe)';

export const RESERVES_SUM_FIELD = 'reservesSum';

export const RESERVES_AGGREGATES_TYPE_ENUM = {
  BUYER: 'buyer',
  SELLER: 'seller',
};

export const RESERVES_STACK_BY_TYPE_ENUM = {
  BUYER: 'buyer',
  SELLER: 'seller',
};

export const BUYER_RESERVES_AGGREGATE_BY_TYPE_ENUM = {
  YEAR: 'year',
  BUYER: 'buyer',
};

export const SELLER_RESERVES_AGGREGATE_BY_TYPE_ENUM = {
  YEAR: 'year',
  SELLER: 'seller',
};

export const RESERVES_AGGREGATE_BY_TYPE_ENUM = {
  ...BUYER_RESERVES_AGGREGATE_BY_TYPE_ENUM,
  ...SELLER_RESERVES_AGGREGATE_BY_TYPE_ENUM,
};

export const TRANSACTIONS_AGGREGATE_GRAPH_BY_OPTIONS = {
  [RESERVES_AGGREGATES_TYPE_ENUM.BUYER]: getChartOptions(
    Object.values(BUYER_RESERVES_AGGREGATE_BY_TYPE_ENUM),
  ),

  [RESERVES_AGGREGATES_TYPE_ENUM.SELLER]: getChartOptions(
    Object.values(SELLER_RESERVES_AGGREGATE_BY_TYPE_ENUM),
  ),
};

export const RESERVES_STACK_BY_OPTIONS = {
  [RESERVES_AGGREGATES_TYPE_ENUM.BUYER]: getChartOptions([RESERVES_STACK_BY_TYPE_ENUM.SELLER]),
  [RESERVES_AGGREGATES_TYPE_ENUM.SELLER]: getChartOptions([RESERVES_STACK_BY_TYPE_ENUM.BUYER]),
};

export const RESERVES_AGGREGATE_BY_TITLE_OPTIONS = {
  [RESERVES_AGGREGATES_TYPE_ENUM.BUYER]: BUYER_RESERVES_AGGREGATE_BY_TITLE,
  [RESERVES_AGGREGATES_TYPE_ENUM.SELLER]: SELLER_RESERVES_AGGREGATE_BY_TITLE,
};
