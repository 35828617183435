import { gql } from '@apollo/client';

export const MA_RESERVES_ACQUIRED_QUERY = gql`
  query maTransactionsAggregates(
    $by: MaAggregateByEnum!
    $assets: [Int!]
    $countries: [String!]
    $buyers: [Int!]
    $sellers: [Int!]
    $eventTypes: [String!]
    $startYear: Int
    $endYear: Int
    $hasBuyers: Boolean
    $hasSellers: Boolean
    $orderBy: String
    $order: String
  ) {
    maTransactionsAggregates(
      by: $by
      assets: $assets
      countries: $countries
      buyers: $buyers
      sellers: $sellers
      eventTypes: $eventTypes
      startYear: $startYear
      endYear: $endYear
      hasBuyers: $hasBuyers
      hasSellers: $hasSellers
      orderBy: $orderBy
      order: $order
    ) {
      type
      group
      reservesSum
      acquisitionCostSum
    }
  }
`;

export const MA_RESERVES_ACQUIRED_STACKED_QUERY = gql`
  query maTransactionsAggregatesStacked(
    $by: MaAggregateByEnum!
    $stackBy: MaStackByEnum!
    $fields: [String!]!
    $assets: [Int!]
    $countries: [String!]
    $buyers: [Int!]
    $sellers: [Int!]
    $eventTypes: [String!]
    $startYear: Int
    $endYear: Int
    $hasBuyers: Boolean
    $hasSellers: Boolean
    $orderBy: String
    $order: String
  ) {
    maTransactionsAggregatesStacked(
      by: $by
      stackBy: $stackBy
      fields: $fields
      assets: $assets
      countries: $countries
      buyers: $buyers
      sellers: $sellers
      eventTypes: $eventTypes
      startYear: $startYear
      endYear: $endYear
      hasBuyers: $hasBuyers
      hasSellers: $hasSellers
      orderBy: $orderBy
      order: $order
    ) {
      stacks
      data {
        type
        group
        values
      }
    }
  }
`;
