import { gql } from '@apollo/client';

export const MA_START_AND_END_YEAR_QUERY = gql`
  query maTransactionsSummary(
    $assets: [Int!]
    $countries: [String!]
    $buyers: [Int!]
    $sellers: [Int!]
    $eventTypes: [String!]
    $startYear: Int
    $endYear: Int
  ) {
    maTransactionsSummary(
      assets: $assets
      countries: $countries
      buyers: $buyers
      sellers: $sellers
      eventTypes: $eventTypes
      startYear: $startYear
      endYear: $endYear
    ) {
      startYear
      endYear
    }
  }
`;
