import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid2';
import React from 'react';

// chip - chip object consists of parent(origin of value), value, and label (optional)
// deleteChip - callback to deleteChip
// onChipClick - callback when user clicks on the chip

export default ({ chip, deleteChip, onChipClick, styles }) => {
  const { parent, value, label } = chip;
  const chipLabel = label
    ? label
    : value.menuName
      ? `${parent}: ${value.menuName}`
      : `${parent}: ${value.displayName ? value.displayName : value.name}`;
  return (
    <Grid
      className={styles && styles.chipRoot ? styles.chipRoot : ''}
      key={`chip-${parent}-${value.name}`}
    >
      <Chip
        className={styles && styles.chipBase ? styles.chipBase : ''}
        classes={{ label: styles && styles.chipLabel ? styles.chipLabel : '' }}
        onClick={onChipClick ? onChipClick : () => {}}
        label={chipLabel}
        color="primary"
        onDelete={() => deleteChip(chip)}
      />
    </Grid>
  );
};
