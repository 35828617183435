import { FilterKeys, FilterLabels } from '../Filters';
import { COMPANIES_WITH_FILTER_QUERY } from '../Filters/operations';

export const VALUATIONS_CONTEXT = 'valuations';
export const ASSET_TEMPLATE_CONTEXT = 'asset-template';

export const VALUATIONS_GOOGLE_ANALYTICS_TAG = 'AssetOverview';
export const ASSET_TEMPLATE_GOOGLE_ANALYTICS_TAG = 'AssetPage';

export const REPORT_TYPES = {
  VALUATIONS_MODEL: 'report',
  PDF_REPORT: 'pdf_report',
  ENHANCED_EXCEL_REPORT: 'enhanced_report',
  ASSET_REPORT: 'enhanced_pdf_report',
  ENHANCED_HTML_REPORT: 'enhanced_html_report',
};

export const COMPANY_FILTER_TYPE = {
  name: FilterLabels.companies,
  key: FilterKeys.companies,
  query: COMPANIES_WITH_FILTER_QUERY,
  menuItems: null,
  optionKey: 'name',
  runQueryAfterOnChange: false,
  queryVariable: null,
  multiple: true,
  getControlValues: (filters) => ({
    [FilterKeys.operator]: filters[FilterKeys.operator],
  }),
  controls: [
    {
      key: FilterKeys.operator,
      name: 'Operator',
      label: FilterLabels.operator,
      option: {
        name: 'Operator',
      },
    },
  ],
};
