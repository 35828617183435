export const COUNTRY_LEVEL_ASSETS_COLUMNS = [
  { field: 'countryName', headerName: 'Country', width: 300 },
  { field: 'assetName', headerName: 'Asset', width: 300 },
  { field: 'basinName', headerName: 'Basin', width: 300 },
  { field: 'licensingRound', headerName: 'License Round', width: 400 },
  { field: 'hydrocarbonType', headerName: 'Hydrocarbon Type', width: 200 },
  { field: 'api', headerName: 'API Gravity', width: 150 },
  { field: 'shoreStatus', headerName: 'Shore Status', width: 200 },
  { field: 'waterDepth', headerName: 'Water Depth (m)', width: 200 },
  { field: 'primaryResourceType', headerName: 'Primary Resource Type', width: 300 },
  { field: 'primaryFormationType', headerName: 'Primary Formation Type', width: 300 },
  { field: 'reservoirDepth', headerName: 'Reservoir Depth (m)', width: 300 },
  { field: 'acreage', headerName: 'License Area (km2)', width: 300 },
  { field: 'licenseAwardDate', headerName: 'License Award Date', width: 300 },
  { field: 'licenseExpiryDate', headerName: 'License Expiry Date', width: 300 },
  {
    field: 'xFactor',
    headerName: 'Additional Royalty/X Factor/Gvt. Profit Sharing (%)',
    width: 400,
  },
  { field: 'discoveryDate', headerName: 'Discovery Date', width: 300 },
  { field: 'totalReservesMmboe', headerName: 'Total Reserves (MMboe)', width: 400 },
  { field: 'remainingReservesMmboe', headerName: 'Reserves (MMboe)', width: 400 },
  {
    field: 'pctRemainingReserves',
    headerName: 'Percentage of Hydrocarbon Remaining',
    width: 450,
  },
  { field: 'originalOilInPlaceMmbbl', headerName: 'Original Oil in Place (MMbbl)', width: 450 },
  { field: 'totalReservesLiquidMmbbl', headerName: 'Total Liquid Reserves (MMbbl)', width: 450 },
  { field: 'accumulatedLiquidsMmbbl', headerName: 'Accumulated Liquids (MMbbl)', width: 450 },
  {
    field: 'remainingLiquidReservesMmbbl',
    headerName: 'Liquid Reserves (MMbbl)',
    width: 450,
  },
  { field: 'originalGasInPlaceBcf', headerName: 'Original Gas in Place (Bcf)', width: 450 },
  { field: 'totalGasReservesBcf', headerName: 'Total Gas Reserves (Bcf)', width: 450 },
  { field: 'accumulatedGasBcf', headerName: 'Accumulated Gas (Bcf)', width: 450 },
  { field: 'remainingGasReservesBcf', headerName: 'Gas Reserves (Bcf)', width: 450 },
  { field: 'weaRecoveryFactor', headerName: 'WEA Recovery Factor (%)', width: 450 },
  { field: 'typeCurveEur', headerName: 'Projected Well EUR (MMboe)', width: 450 },
  { field: 'firstProductionDate', headerName: 'Production Start', width: 450 },
  { field: 'peakProductionDate', headerName: 'Peak Production', width: 450 },
  { field: 'peakProductionBoed', headerName: 'Peak Production (boe/d)', width: 450 },
  {
    field: 'lastTwelveMonthsAverageProdBoed',
    headerName: 'Last 12 Months Average Production (boe/d)',
    width: 450,
  },
  { field: 'npv', headerName: 'NPV10 (US$MM)', width: 200 },
  { field: 'npvBoe', headerName: 'NPV/boe (US$)', width: 200 },
  { field: 'remainingCapex', headerName: 'Remaining Capex + Abex (US$MM)', width: 450 },
  { field: 'operator', headerName: 'Operator', width: 300 },
  { field: 'company1Name', headerName: 'Company #1 Name', width: 300 },
  { field: 'company1Stake', headerName: 'Company #1 Stake', width: 200 },
  { field: 'company2Name', headerName: 'Company #2 Name', width: 300 },
  { field: 'company2Stake', headerName: 'Company #2 Stake', width: 200 },
  { field: 'company3Name', headerName: 'Company #3 Name', width: 300 },
  { field: 'company3Stake', headerName: 'Company #3 Stake', width: 200 },
  { field: 'company4Name', headerName: 'Company #4 Name', width: 300 },
  { field: 'company4Stake', headerName: 'Company #4 Stake', width: 200 },
  { field: 'company5Name', headerName: 'Company #5 Name', width: 300 },
  { field: 'company5Stake', headerName: 'Company #5 Stake', width: 200 },
];

export const COUNTRY_LEVEL_ASSETS_ROWS = (assets) =>
  assets?.map((asset) => {
    let participations = asset?.participations || [];
    participations = [...participations].sort(
      (a, b) =>
        new Date(b.effectiveDate) - new Date(a.effectiveDate) ||
        b.isCompanyOperator - a.isCompanyOperator ||
        b.percentStake - a.percentStake ||
        a.company?.name?.localeCompare(b.company?.name),
    );
    participations = participations.filter(
      (p) => p.effectiveDate === participations[0].effectiveDate,
    );
    return {
      id: asset.id,
      countryName: asset?.country?.displayName,
      assetName: asset?.displayName,
      basinName: asset?.basin?.name,
      licensingRound: asset?.licensingRound,
      hydrocarbonType: asset?.formattedHydrocarbonType,
      api: asset?.api,
      shoreStatus: asset?.shoreStatus?.name,
      waterDepth: asset?.formattedWaterDepthM,
      primaryResourceType: asset?.resourceType?.displayName,
      primaryFormationType: asset?.geologies?.find((g) => g.primaryFormation === true)?.formation
        ?.name,
      reservoirDepth: asset?.formattedReservoirDepthM,
      acreage: asset?.formattedAcreage,
      licenseAwardDate: asset?.licenseAwardDate,
      licenseExpiryDate: asset?.licenseExpirationDate,
      xFactor: asset?.formattedXFactor,
      discoveryDate: asset?.discoveryDate,
      totalReservesMmboe: asset?.reserve?.totalReservesMmboe,
      remainingReservesMmboe: asset?.reserve?.totalRemainingReservesMmboe,
      pctRemainingReserves: asset?.reserve?.pctRemainingReserves,
      originalOilInPlaceMmbbl: asset?.originalOilInPlaceMmbbl, // TODO: add column to assets table for Brazil
      totalReservesLiquidMmbbl: asset?.reserve?.totalReservesLiquidMmbbl,
      accumulatedLiquidsMmbbl: asset?.reserve?.accumulatedLiquidsMmbbl,
      remainingLiquidReservesMmbbl: asset?.reserve?.totalRemainingReservesLiquidsMmbbl,
      originalGasInPlaceBcf: asset?.originalGasInPlaceBcf, // TODO: add column to assets table for Brazil
      totalGasReservesBcf: asset?.reserve?.totalReservesGasBcf,
      accumulatedGasBcf: asset?.reserve?.accumulatedGasBcf,
      remainingGasReservesBcf: asset?.reserve?.totalRemainingReservesGasBcf,
      weaRecoveryFactor: asset?.weaRecoveryFactor,
      typeCurveEur: asset?.typeCurveEur,
      firstProductionDate: asset?.firstProductionDate,
      peakProductionDate: asset?.peakProductionDate,
      peakProductionBoed: asset?.formattedPeakProductionBoed,
      lastTwelveMonthsAverageProdBoed: asset?.lastTwelveMonthsAverageProdBoed,
      npv: asset?.formattedNpv,
      npvBoe: asset?.formattedNpvBoe,
      remainingCapex: asset?.remainingCapex,
      operator: participations?.find((p) => p.isCompanyOperator)?.company?.name,
      company1Name: participations[0]?.company?.name,
      company1Stake: participations[0]?.formattedPercentStake,
      company2Name: participations[1]?.company?.name,
      company2Stake: participations[1]?.formattedPercentStake,
      company3Name: participations[2]?.company?.name,
      company3Stake: participations[2]?.formattedPercentStake,
      company4Name: participations[3]?.company?.name,
      company4Stake: participations[3]?.formattedPercentStake,
      company5Name: participations[4]?.company?.name,
      company5Stake: participations[4]?.formattedPercentStake,
    };
  });
