import { MAP_SOURCE_IDS } from '../Utils/constants';
import React, { useCallback, useEffect, useState } from 'react';

import IconButton from '@mui/material/IconButton';
import TimelineIcon from '@mui/icons-material/Timeline';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import { rulerEvent } from '../Utils/utils';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { DISTANCE_IMPERIAL, DISTANCE_METRIC } from '../../../utils/distanceUnits';
import { useRulerButtonStyles } from './RulerButton.style';
import { geojson, linestring } from '../map.constants';

export const RulerButton = ({ map, rulerActive = false, setRulerActive, mapLoaded }) => {
  const classes = useRulerButtonStyles();

  // prepend the ruler control to the mapbox controls if ruler is enabled
  const setRuler = useCallback(
    (node) => {
      if (mapLoaded && node) {
        // mapbox control group (bottom-right nav controls) container
        const groupContainer = document.getElementsByClassName('mapboxgl-ctrl-group')[0];
        groupContainer.prepend(node);
      }
    },
    [mapLoaded],
  );

  useEffect(() => {
    if (map) {
      map.toggleMapRuler(rulerActive);
    }
  }, [rulerActive]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const openMenu = useCallback((event) => setAnchorEl(event.currentTarget), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);

  const onRulerClick = (isImperial) => {
    map.onClickRulerCallback = rulerEvent(
      map,
      geojson,
      linestring,
      isImperial ? DISTANCE_IMPERIAL : DISTANCE_METRIC,
    );
    setRulerActive(true);
    closeMenu();
  };

  const onRulerClose = () => {
    geojson.features = [];
    linestring.geometry.coordinates = [];
    map.getSource(MAP_SOURCE_IDS.geojson).setData(geojson);
    setRulerActive(false);
  };

  const onRulerButtonClick = (e) => (rulerActive ? onRulerClose() : openMenu(e));

  return (
    <div ref={setRuler}>
      <Tooltip title="Ruler" aria-label="ruler tooltip">
        <IconButton
          id="ruler-button"
          aria-label="ruler"
          aria-controls={open ? 'ruler-menu' : undefined}
          aria-haspopup={rulerActive ? 'false' : 'true'}
          aria-expanded={open ? 'true' : undefined}
          onClick={onRulerButtonClick}
          edge="start"
          className={clsx(classes.rulerAction, {
            [classes.rulerActionSelected]: rulerActive,
          })}
          size="large"
        >
          <TimelineIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="ruler-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': 'ruler-button',
        }}
      >
        <MenuItem onClick={() => onRulerClick(true)}>Imperial</MenuItem>
        <MenuItem onClick={() => onRulerClick(false)}>Metric</MenuItem>
      </Menu>
    </div>
  );
};
