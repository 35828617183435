import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid2';
import React from 'react';
import StarIcon from '@mui/icons-material/Star';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { formatDateShort } from '../../../utils/helpers';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  assetListItemContainer: {
    border: 'solid 1px #f2f5f7',
    padding: '25px 0 25px 35px',
    cursor: 'pointer',
  },
  assetListItemInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  assetListItemChip: {
    ...theme.palette.accent,
  },
  assetListItemChipContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  assetListItemChipDataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'lightgray',
    borderRadius: '4px',
    margin: '3px',
    flexDirection: 'column',
  },
  assetListItemAccent: {
    ...theme.palette.secondaryAccentText,
  },
  favorite: {
    color: 'gold',
  },
}));

export default ({ asset, setAsset, isSelected, isWatchList, sortType }) => {
  const classes = useStyles();

  const selectAsset = () => {
    setAsset(asset);
  };

  const renderAssetData = () => {
    if (sortType.filter === 'name') {
      if (asset.mAndAOpportunity) {
        return (
          <Grid className={classes.assetListItemChipContainer} container size={3}>
            <Chip className={classes.assetListItemChip} label="M&A" />
          </Grid>
        );
      }
      return null;
    } else {
      let value, label, unit;
      switch (sortType.filter) {
        case 'npv':
          label = 'NPV';
          unit = '(US$MM)';
          value = asset.formattedNpv;
          break;
        case 'remaining_reserves':
          label = 'RES.';
          unit = '(MMboe)';
          value = asset.formattedRemainingReserves;
          break;
      }
      return (
        <Grid className={classes.assetListItemChipContainer} container size={3}>
          {asset.mAndAOpportunity ? (
            <Grid className={classes.assetListItemChipContainer} size={12}>
              <Chip className={classes.assetListItemChip} label="M&A" />
            </Grid>
          ) : null}
          {value ? (
            <Grid className={classes.assetListItemChipDataContainer} container size={12}>
              <Typography className={classes.assetListItemAccent} variant="body1">
                {label}
              </Typography>
              <Typography className={classes.assetListItemAccent} variant="body1">
                {unit}
              </Typography>
              <Typography color="secondary" variant="body1">
                {value}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      );
    }
  };

  return (
    <Grid
      className={classes.assetListItemContainer}
      style={isSelected ? { backgroundColor: '#ecf0f5' } : null}
      container
      onClick={selectAsset}
    >
      <Grid className={classes.assetListItemInfo} size={7}>
        <Typography className={classes.assetListItemAccent} variant="caption">
          {asset.country.displayName}
        </Typography>
        <Typography color="secondary" variant="subtitle1">
          {asset.displayName}
        </Typography>
        <Typography className={classes.assetListItemAccent} variant="overline">
          UPDATED: {asset.reportUpdatedDate ? formatDateShort(asset.reportUpdatedDate) : '-'}
        </Typography>
      </Grid>
      <Grid className={classes.assetListItemChipContainer} size={2}>
        {isWatchList ? (
          <Tooltip title={<Typography variant="overline">Asset on Watch List</Typography>}>
            <StarIcon className={classes.favorite} />
          </Tooltip>
        ) : null}
      </Grid>
      {renderAssetData()}
    </Grid>
  );
};
