import { gql } from '@apollo/client';

export const REGIONS_QUERY = gql`
  {
    regions {
      id
      name
      displayName
      coord
      zoom
    }
  }
`;

export const COUNTRIES_QUERY = gql`
  {
    countries {
      id
      displayName
      name
      isoCode
    }
  }
`;
