import {
  ASSETS_BY_NAME_WITH_FILTER_QUERY,
  ASSETS_FILTER,
  BASINS_WITH_FILTER_QUERY,
  CCUS_COUNTRIES_FILTER_QUERY,
  CCUS_PROJECT_QUERY,
  COMPANIES_WITH_FILTER_QUERY,
  COUNTRIES_WITH_FILTER_QUERY,
  DIM_COMPANIES_WITH_FILTER_QUERY,
  DIM_FACILITIES_QUERY,
  DIM_INDUSTRY_QUERY,
  FACILITIES_QUERY,
  GHG_FILTERS_QUERY,
  SHORE_STATUS_QUERY,
  STORAGE_SITE_QUERY,
} from './operations';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { GHG_CONTEXT } from '../GHG/constants';
import OilRigIcon from '../Icons/OilRigIcon';
import { PORTFOLIO_CONTEXT } from '../PortfolioAnalysis/constants';
import React from 'react';
import { getFeatureByContext } from './utils';

export const FilterKeys = {
  assets: 'assets',
  assetUnits: 'assetUnits',
  basins: 'basins',
  captureCost: 'captureCost',
  ccusDevelopmentStatuses: 'ccusDevelopmentStatuses',
  ccusProjects: 'ccusProjects',
  ccusProjectScales: 'ccusProjectScales',
  ccusProjectTypes: 'ccusProjectTypes',
  ccusSeparationTypes: 'ccusSeparationTypes',
  ccusStorages: 'ccusStorages',
  cO2Concentration: 'cO2Concentration',
  companies: 'companies',
  companiesBuyer: 'companiesBuyer',
  companiesSeller: 'companiesSeller',
  companyTargets: 'companyTargets',
  countries: 'countries',
  developmentStatuses: 'developmentStatuses',
  developmentTypes: 'developmentTypes',
  eventType: 'eventType',
  facilities: 'facilities',
  facilityTotalCO2Emissions: 'facilityTotalCO2Emissions',
  formations: 'formations',
  hasAbatement: 'hasAbatement',
  hydrocarbons: 'hydrocarbons',
  industries: 'industries',
  includeHistoricalParticipants: 'includeHistoricalParticipants',
  npvMax: 'npvMax',
  npvMin: 'npvMin',
  operator: 'operator',
  opportunities: 'opportunities',
  productionMax: 'productionMax',
  productionMin: 'productionMin',
  reservesMax: 'reservesMax',
  reservesMin: 'reservesMin',
  depthMax: 'depthMax',
  depthMin: 'depthMin',
  resourceThemes: 'resourceThemes',
  shoreStatuses: 'shoreStatuses',
  storageBlockStatuses: 'storageBlockStatuses',
  storageCost: 'storageCost',
  storageShoreStatuses: 'storageShoreStatuses',
  storageSites: 'storageSites',
  storageTypes: 'storageTypes',
  totalAbatementCost: 'totalAbatementCost',
  transportationCost: 'transportationCost',
  hasMaTransactions: 'hasMaTransactions',
};

export const FilterLabels = {
  assets: 'Asset/Block',
  assetUnits: 'Asset Units',
  basins: 'Basin',
  block: 'Block',
  captureCost: 'Capture Cost US$/t',
  ccusDevelopmentStatuses: 'Development Status',
  ccusProjects: 'CCUS Project',
  ccusProjectTypes: 'Project Type',
  ccusProjectScales: 'Project Scale',
  ccusSeparationTypes: 'Separation Type',
  cO2Concentration: 'CO2 Concentration (Units)',
  companies: 'Company',
  companiesSeller: 'Seller',
  companiesBuyer: 'Buyer',
  companyTargets: 'Company Targets',
  countries: 'Country / Area',
  developmentStatuses: 'Development Status',
  developmentTypes: 'Development Type',
  dimCompanies: 'Company',
  eventType: 'Event Type',
  facilities: 'Facility',
  facilityTotalCO2Emissions: 'Facility Total CO2 Emissions (MMTPA)',
  formations: 'Formation',
  hasAbatement: 'Has Abatement Model',
  hydrocarbons: 'Hydrocarbon',
  industries: 'Industry',
  includeHistoricalParticipants: 'Historical Participants',
  npvMax: 'NPV Max',
  npvMin: 'NPV Min',
  operator: 'Operator Only',
  opportunities: 'M&A Opportunities',
  productionMax: 'Production Max',
  productionMin: 'Production Min',
  reservesMax: 'Reserves Max',
  reservesMin: 'Reserves Min',
  depthMax: 'Reservoir Depth Max',
  depthMin: 'Reservoir Depth Min',
  resourceThemes: 'Resource Theme',
  shoreStatuses: 'Shore Status',
  storageBlockStatuses: 'Block Status',
  storageCost: 'Storage Cost US$/t',
  storageShoreStatuses: 'Shore Status',
  storageSites: 'Storage Site',
  storageTypes: 'Storage Type',
  totalAbatementCost: 'Total Abatement Cost US$/t',
  transportationCost: 'Transportation Cost US$/t',
};

// ghg_*filters* are temporary until lower 48 is released
export const GHG_ASSETS = {
  name: FilterLabels.assets,
  key: FilterKeys.assets,
  query: GHG_FILTERS_QUERY,
  menuItems: null,
  menuItemsCallback: null,
  optionKey: 'menuName',
  runQueryAfterOnChange: true,
  queryVariable: 'search',
  multiple: true,
  note: {
    hideCallback: (res) => res?.ghgFilter?.totalCount <= 100,
    label:
      '*Only the top 100 assets will be shown, you can access more assets by typing in the name.',
  },
  responseCallback: (res) => res?.assets || [],
  propVariables: ({
    basins = [],
    companies = [],
    countries = [],
    includeHistoricalParticipants,
    operator,
  }) => ({
    basins,
    companies,
    countries,
    includeHistoricalParticipants,
    operator,
    filter: 'assets',
  }),
};
export const GHG_ASSET_UNITS = {
  name: FilterLabels.assetUnits,
  key: FilterKeys.assetUnits,
  query: GHG_FILTERS_QUERY,
  menuItems: null,
  menuItemsCallback: null,
  optionKey: 'optionDisplayName',
  runQueryOnChange: false,
  queryVariable: null,
  multiple: true,
  disabled: (a) => a.length === 0,
  responseCallback: (res) => res?.assetUnits || [],
  propVariables: ({ assets = [] }) => ({
    assets,
    filter: 'assetUnits',
  }),
};
export const GHG_BASINS = {
  name: FilterLabels.basins,
  key: FilterKeys.basins,
  query: GHG_FILTERS_QUERY,
  menuItems: null,
  menuItemsCallback: null,
  optionKey: 'name',
  runQueryAfterOnChange: false,
  queryVariable: null,
  multiple: true,
  responseCallback: (res) => res?.basins || [],
  propVariables: ({ assets = [], companies = [], countries = [], operator }) => ({
    assets,
    companies,
    countries,
    operator,
    filter: 'basins',
  }),
};
export const GHG_COMPANIES = {
  name: FilterLabels.companies,
  key: FilterKeys.companies,
  query: GHG_FILTERS_QUERY,
  menuItems: null,
  menuItemsCallback: null,
  optionKey: 'name',
  runQueryAfterOnChange: false,
  queryVariable: null,
  multiple: true,
  control: {
    controlItems: [
      {
        key: FilterKeys.operator,
        name: 'Operator',
        label: FilterLabels.operator,
        option: {
          name: 'Operator',
        },
      },
      {
        key: FilterKeys.includeHistoricalParticipants,
        name: 'Historical Participants',
        label: FilterLabels.includeHistoricalParticipants,
        option: {
          name: 'Historical Participants',
        },
      },
    ],
    getControlValuesFromFilters: (filters) => ({
      controlValues: {
        [FilterKeys.operator]: filters[FilterKeys.operator],
        [FilterKeys.includeHistoricalParticipants]:
          filters[FilterKeys.includeHistoricalParticipants],
      },
    }),
  },
  responseCallback: (res) => res?.companies || [],
  propVariables: ({
    assets = [],
    basins = [],
    countries = [],
    includeHistoricalParticipants,
    operator,
  }) => ({
    assets,
    basins,
    countries,
    includeHistoricalParticipants,
    operator,
    filter: 'companies',
  }),
};

// MULTI-SELECT TYPES
export const ASSETS = {
  name: FilterLabels.assets,
  key: FilterKeys.assets,
  query: ASSETS_BY_NAME_WITH_FILTER_QUERY,
  menuItems: null,
  menuItemsCallback: null,
  optionKey: 'menuName',
  runQueryAfterOnChange: true,
  queryVariable: 'search',
  multiple: true,
  note: {
    hideCallback: (res) => res?.assetsByNameWithFilter?.totalCount <= 100,
    label:
      '*Only the top 100 assets will be shown, you can access more assets by typing in the name.',
  },
  responseCallback: (res) => res?.assets || [],
};

export const ASSET_UNITS = {
  name: FilterLabels.assetUnits,
  key: FilterKeys.assetUnits,
  query: ASSETS_FILTER,
  menuItems: null,
  menuItemsCallback: (assets) => {
    const items = [];
    assets.forEach((a) => {
      if (a.participations.length === 0) return;
      const currDate = a.participations[0].effectiveDate;
      const parts = a.participations
        .filter((p) => p.effectiveDate === currDate)
        .sort(
          (a, b) =>
            b.percentStake - a.percentStake || a.company?.name?.localeCompare(b.company?.name),
        );
      parts.forEach((p) => {
        if (p.company) {
          items.push({
            id: items.length + 1,
            optionDisplayName: `${a.displayName}-${p.company.name} (${p.formattedPercentStake})`,
            percentStake: p.percentStake,
            displayName: a.displayName,
            name: `${a.displayName}-${p.company.name}`,
            companyName: p.company.name,
            legacyId: a.legacyId,
            isoCode: a.country.isoCode,
          });
        }
      });
    });
    return { items };
  },
  optionKey: 'optionDisplayName',
  runQueryOnChange: false,
  queryVariable: null,
  multiple: true,
  disabled: (a) => a.length === 0,
};

export const BASINS = {
  name: FilterLabels.basins,
  key: FilterKeys.basins,
  query: BASINS_WITH_FILTER_QUERY,
  menuItems: null,
  menuItemsCallback: null,
  optionKey: 'name',
  runQueryAfterOnChange: false,
  queryVariable: null,
  multiple: true,
};

export const BLOCK = {
  name: FilterLabels.block,
  key: FilterKeys.countries,
  query: COUNTRIES_WITH_FILTER_QUERY,
  menuItems: null,
  menuItemsCallback: null,
  optionKey: 'displayName',
  runQueryAfterOnChange: false,
  queryVariable: null,
  multiple: true,
};

export const COMPANIES = {
  name: FilterLabels.companies,
  key: FilterKeys.companies,
  query: COMPANIES_WITH_FILTER_QUERY,
  menuItems: null,
  menuItemsCallback: null,
  optionKey: 'name',
  runQueryAfterOnChange: false,
  queryVariable: null,
  multiple: true,
  control: {
    controlItems: [
      {
        key: FilterKeys.operator,
        name: 'Operator',
        label: FilterLabels.operator,
        option: {
          name: 'Operator',
        },
      },
      {
        key: FilterKeys.includeHistoricalParticipants,
        name: 'Historical Participants',
        label: FilterLabels.includeHistoricalParticipants,
        option: {
          name: 'Historical Participants',
        },
      },
    ],
    getControlValuesFromFilters: (filters) => ({
      controlValues: {
        [FilterKeys.operator]: filters[FilterKeys.operator],
        [FilterKeys.includeHistoricalParticipants]:
          filters[FilterKeys.includeHistoricalParticipants],
      },
    }),
  },
};

export const DIM_COMPANIES = {
  name: FilterLabels.companies,
  key: FilterKeys.companies,
  query: DIM_COMPANIES_WITH_FILTER_QUERY,
  menuItems: null,
  menuItemsCallback: null,
  optionKey: 'companyName',
  chipOptionKey: 'companyName',
  runQueryAfterOnChange: true,
  queryVariable: 'search',
  multiple: true,
  note: {
    hideCallback: (res) => res?.dimCompaniesWithFilter?.totalCount <= 100,
    label:
      '*Only the top 100 companies will be shown, you can access more companies by typing in the name.',
  },
  responseCallback: (res) => res?.companies || [],
  propVariables: ({ countries, ccusProjects, facilities, industries, storageSites }) => ({
    filters: {
      countries,
      ccusProjects,
      facilities,
      industries,
      storageSites,
    },
  }),
};

export const COUNTRIES = {
  name: FilterLabels.countries,
  key: FilterKeys.countries,
  query: COUNTRIES_WITH_FILTER_QUERY,
  mapQueryData: (data) => data?.countriesWithFilter,
  groupOptionKey: 'region',
  groupByName: 'Region',
  menuItems: null,
  menuItemsCallback: null,
  optionKey: 'displayName',
  runQueryAfterOnChange: false,
  queryVariable: null,
  multiple: true,
  excelLike: true,
  propVariables: (
    {
      assets = [],
      companies = [],
      basins = [],
      operator,
      hasAssets = true,
      hasBlocks = false,
      hasLeases = false,
      hasGhgEmissions = false,
      sortBy = 'displayName',
    },
    context,
  ) => ({
    assets,
    companies: companies?.map((a) => a.name),
    basins: basins?.map((a) => a.name),
    operator,
    hasAssets: hasAssets && context !== GHG_CONTEXT,
    hasBlocks: hasBlocks || context === PORTFOLIO_CONTEXT,
    hasLeases,
    hasGhgEmissions: hasGhgEmissions || context === GHG_CONTEXT,
    sortBy,
    feature: getFeatureByContext(context),
  }),
};

export const CCUS_COUNTRIES = {
  name: FilterLabels.countries,
  key: FilterKeys.countries,
  query: CCUS_COUNTRIES_FILTER_QUERY,
  mapQueryData: (data) => data?.ccusCountries,
  groupOptionKey: 'region',
  groupByName: 'Region',
  menuItems: null,
  menuItemsCallback: null,
  optionKey: 'displayName',
  runQueryAfterOnChange: false,
  queryVariable: null,
  multiple: true,
  excelLike: true,
  propVariables: ({ ccusProjects, companies, facilities, industries, storageSites }) => ({
    filters: {
      ccusProjects,
      companies,
      facilities,
      industries,
      storageSites,
    },
  }),
};

export const DIM_FACILITIES = {
  name: FilterLabels.facilities,
  key: FilterKeys.facilities,
  query: DIM_FACILITIES_QUERY,
  menuItems: null,
  menuItemsCallback: null,
  optionKey: 'facilityName',
  chipOptionKey: 'facilityName',
  runQueryAfterOnChange: true,
  queryVariable: 'search',
  multiple: true,
  note: {
    hideCallback: (res) => res?.dimFacilities?.totalCount <= 100,
    label:
      '*Only the top 100 facilities will be shown, you can access more facilities by typing in the name.',
  },
  responseCallback: (res) => res?.facilities || [],
  propVariables: ({ countries, companies, hasAbatement, industries }) => ({
    filters: {
      countries,
      companies,
      hasAbatement,
      industries,
    },
  }),
  control: {
    controlItems: [
      {
        key: FilterKeys.hasAbatement,
        name: FilterLabels.hasAbatement,
        label: FilterLabels.hasAbatement,
        option: {
          name: FilterLabels.hasAbatement,
        },
      },
    ],
    getControlValuesFromFilters: (filters) => ({
      controlValues: {
        [FilterKeys.hasAbatement]: filters[FilterKeys.hasAbatement],
      },
    }),
  },
};

export const EVENT_TYPE = {
  name: FilterLabels.eventType,
  key: FilterKeys.countries,
  query: COUNTRIES_WITH_FILTER_QUERY,
  menuItems: null,
  menuItemsCallback: null,
  optionKey: 'displayName',
  runQueryAfterOnChange: false,
  queryVariable: null,
  multiple: true,
};

export const FACILITIES = {
  name: FilterLabels.facilities,
  key: FilterKeys.facilities,
  query: FACILITIES_QUERY,
  menuItems: null,
  menuItemsCallback: null,
  optionKey: 'name',
  runQueryAfterOnChange: true,
  queryVariable: 'search',
  multiple: true,
  note: {
    hideCallback: (res) => res?.facilities?.totalCount <= 100,
    label:
      '*Only the top 100 facilities will be shown, you can access more facilities by typing in the name.',
  },
  responseCallback: (res) => res?.facilities || [],
};

export const HYDROCARBONS = {
  name: FilterLabels.hydrocarbons,
  key: FilterKeys.hydrocarbons,
  query: null,
  menuItems: [
    { id: 1, oil: 'extra_heavy', type: 'oil', name: 'Extra Heavy Oil (<10° API)' },
    { id: 2, oil: 'heavy', type: 'oil', name: 'Heavy Oil (10° - 22° API)' },
    { id: 3, oil: 'medium', type: 'oil', name: 'Medium Oil (22° - 31° API)' },
    { id: 4, oil: 'light', type: 'oil', name: 'Light Oil (>31° API)' },
    { id: 5, oil: null, type: 'gas', name: 'Gas' },
  ],
  optionKey: 'name',
  runQueryOnChange: false,
  queryVariable: null,
  multiple: true,
};

export const INDUSTRIES = {
  name: FilterLabels.industries,
  key: FilterKeys.industries,
  query: DIM_INDUSTRY_QUERY,
  menuItems: null,
  menuItemsCallback: null,
  optionKey: 'displayName',
  runQueryAfterOnChange: false,
  queryVariable: null,
  multiple: true,
  propVariables: ({ countries, companies, facilities, ccusProjects }) => ({
    filters: {
      countries,
      companies,
      facilities,
      ccusProjects,
    },
  }),
};

export const SHORE_STATUSES = {
  name: FilterLabels.shoreStatuses,
  key: FilterKeys.shoreStatuses,
  query: SHORE_STATUS_QUERY,
  menuItems: null,
  optionKey: 'name',
  runQueryAfterOnChange: false,
  queryVariable: null,
  multiple: true,
  hideItem: (item) => item.name.toLowerCase() === 'pre-salt',
};

export const STORAGE_SITES = {
  name: FilterLabels.storageSites,
  key: FilterKeys.storageSites,
  query: STORAGE_SITE_QUERY,
  menuItems: null,
  menuItemsCallback: null,
  optionKey: 'storageSiteName',
  chipOptionKey: 'storageSiteName',
  runQueryAfterOnChange: false,
  queryVariable: null,
  multiple: true,
  responseCallback: (res) => res?.storageSites || [],
  propVariables: ({ countries, companies }) => ({
    filters: {
      countries,
      companies,
    },
  }),
};

export const CCUS_PROJECTS = {
  name: FilterLabels.ccusProjects,
  key: FilterKeys.ccusProjects,
  query: CCUS_PROJECT_QUERY,
  menuItems: null,
  menuItemsCallback: (projects) => {
    const groupedOptions = {};
    const items = [];
    projects.forEach((p) => {
      const { hubName } = p;
      if (hubName) {
        if (groupedOptions[hubName]) {
          groupedOptions[hubName].group.push(p);
        } else {
          const hubOption = { ...p, projectName: `${hubName} (Hub)` };
          groupedOptions[hubName] = { group: [p], parent: hubOption };
          items.push(hubOption);
        }
      }
      items.push(p);
    });
    return { items, groupedOptions };
  },
  optionKey: 'projectName',
  chipOptionKey: 'projectName',
  groupOptionKey: 'hubName',
  runQueryAfterOnChange: false,
  queryVariable: null,
  multiple: true,
  responseCallback: (res) => res?.ccusProjects || [],
  propVariables: ({ countries, companies, industries }) => ({
    filters: {
      countries,
      companies,
      industries,
    },
  }),
};

export const defaultBasicFilterTypes = [COUNTRIES, ASSETS, ASSET_UNITS, COMPANIES];
export const defaultGhgBasicFilterTypes = [COUNTRIES, GHG_ASSETS, GHG_ASSET_UNITS, GHG_COMPANIES];

const defaultAdvancedFilterTypes = [BASINS];
const defaultGhgAdvancedFilterTypes = [GHG_BASINS];

export const defaultFilterTypes = [...defaultBasicFilterTypes, ...defaultAdvancedFilterTypes];
export const defaultGhgFilterTypes = [
  ...defaultGhgBasicFilterTypes,
  ...defaultGhgAdvancedFilterTypes,
];

// CHECK LIST TYPES
// CCUS TYPES - Will reorder these at a later time
export const COMPANY_TARGETS_CHECK_LIST = {
  name: FilterLabels.companyTargets,
  key: FilterKeys.companyTargets,
  list: 'companyTargets',
  getParent: (target) => {
    const { name } = target;
    switch (name) {
      case 'Net Zero Scope 1 & 2 and Undisclosed':
      case 'Net Zero Scope 3':
        return 'Net Zero Targets';
      case 'GHG Reduction Targets Scope 1 & 2 and Undisclosed':
      case 'GHG Reduction Targets Scope 3':
        return 'GHG Reduction Targets';
      default:
        return null;
    }
  },
  parentItemsToInclude: ['Net Zero Targets', 'GHG Reduction Targets'],
};
export const CCUS_PROJECT_SCALES_CHECK_LIST = {
  name: FilterLabels.ccusProjectScales,
  key: FilterKeys.ccusProjectScales,
  list: 'ccusProjectScales',
  nameKey: 'projectScale',
};
export const CCUS_PROJECT_TYPES_CHECK_LIST = {
  name: FilterLabels.ccusProjectTypes,
  key: FilterKeys.ccusProjectTypes,
  list: 'ccusProjectTypes',
  nameKey: 'projectType',
};
export const CCUS_SEPARATION_TYPES_CHECK_LIST = {
  name: FilterLabels.ccusSeparationTypes,
  key: FilterKeys.ccusSeparationTypes,
  list: 'ccusSeparationTypes',
  nameKey: 'separationType',
};
export const CCUS_DEVELOPMENT_STATUSES_CHECK_LIST = {
  name: FilterLabels.ccusDevelopmentStatuses,
  key: FilterKeys.ccusDevelopmentStatuses,
  list: 'ccusDevelopmentStatusesTypes',
  nameKey: 'developmentStatus',
};
export const CCUS_PROJECTS_CHECK_LIST_GROUP = {
  name: 'CCUS Projects',
  group: [
    CCUS_PROJECT_TYPES_CHECK_LIST,
    CCUS_PROJECT_SCALES_CHECK_LIST,
    CCUS_SEPARATION_TYPES_CHECK_LIST,
    CCUS_DEVELOPMENT_STATUSES_CHECK_LIST,
  ],
  xs: 6,
  width: '50%',
};
export const STORAGE_BLOCK_STATUSES_CHECK_LIST = {
  name: FilterLabels.storageBlockStatuses,
  key: FilterKeys.storageBlockStatuses,
  list: 'storageBlockStatuses',
  nameKey: 'blockStatus',
};
export const STORAGE_SHORE_STATUSES_CHECK_LIST = {
  name: FilterLabels.storageShoreStatuses,
  key: FilterKeys.storageShoreStatuses,
  list: 'storageShoreStatuses',
  nameKey: 'shoreStatus',
};
export const STORAGE_TYPES_CHECK_LIST = {
  name: FilterLabels.storageTypes,
  key: FilterKeys.storageTypes,
  list: 'storageTypes',
  nameKey: 'storageType',
};
export const CCUS_STORAGE_GROUP = {
  name: 'Storage',
  group: [
    STORAGE_SHORE_STATUSES_CHECK_LIST,
    STORAGE_TYPES_CHECK_LIST,
    STORAGE_BLOCK_STATUSES_CHECK_LIST,
  ],
  xs: 12,
  width: '20%',
};
// END CCUS TYPES

export const DEVELOPMENT_STATUSES_CHECK_LIST = {
  name: FilterLabels.developmentStatuses,
  key: FilterKeys.developmentStatuses,
  list: 'developmentStatuses',
  customItems: {
    1: {
      name: 'Pre-sanction Development',
      isParent: true,
    },
  },
  getParent: (item) => (item.name.includes('Pre-sanction') ? 'Pre-sanction Development' : null),
};

export const DEVELOPMENT_TYPES_CHECK_LIST = {
  name: FilterLabels.developmentTypes,
  key: FilterKeys.developmentTypes,
  list: 'developmentTypes',
};

export const RESOURCE_THEMES_CHECK_LIST = {
  name: FilterLabels.resourceThemes,
  key: FilterKeys.resourceThemes,
  list: 'resourceThemes',
};

export const SHORE_STATUSES_CHECK_LIST = {
  name: FilterLabels.shoreStatuses,
  key: FilterKeys.shoreStatuses,
  list: 'shoreStatuses',
  customItems: {
    1: {
      name: 'Offshore',
      isParent: true,
    },
  },
  combinedItems: {
    Deepwater: ['Pre-salt'],
  },
  getParent: (item) => (item.name !== 'Onshore' ? 'Offshore' : null),
};

export const defaultCheckListFilterTypes = [
  RESOURCE_THEMES_CHECK_LIST,
  SHORE_STATUSES_CHECK_LIST,
  DEVELOPMENT_STATUSES_CHECK_LIST,
];

// MIN MAX TYPES
export const NPV_MIN_MAX = {
  label: 'NPV10 (US$MM)',
  icon: <AttachMoneyIcon color="secondary" />,
  isMinMax: true,
  min: {
    inputLabel: FilterLabels.npvMin,
    key: FilterKeys.npvMin,
  },
  max: {
    inputLabel: FilterLabels.npvMax,
    key: FilterKeys.npvMax,
  },
};

export const PRODUCTION_MIN_MAX = {
  label: 'ESTIMATED RECENT PRODUCTION (boe/d)',
  icon: <OilRigIcon color="secondary" />,
  isMinMax: true,
  min: {
    inputLabel: FilterLabels.productionMin,
    key: FilterKeys.productionMin,
  },
  max: {
    inputLabel: FilterLabels.productionMax,
    key: FilterKeys.productionMax,
  },
};

export const RESERVES_MIN_MAX = {
  label: 'RESERVES (MMboe)',
  icon: <OilRigIcon color="secondary" />,
  isMinMax: true,
  min: {
    inputLabel: FilterLabels.reservesMin,
    key: FilterKeys.reservesMin,
  },
  max: {
    inputLabel: FilterLabels.reservesMax,
    key: FilterKeys.reservesMax,
  },
};

export const RESERVOIR_MIN_MAX = {
  label: 'RESERVOIR DEPTH (m)',
  icon: <OilRigIcon color="secondary" />,
  isMinMax: true,
  min: {
    inputLabel: FilterLabels.depthMin,
    key: FilterKeys.depthMin,
  },
  max: {
    inputLabel: FilterLabels.depthMax,
    key: FilterKeys.depthMax,
  },
};

export const defaultMinMaxFilterTypes = [NPV_MIN_MAX, RESERVES_MIN_MAX, PRODUCTION_MIN_MAX];

// CHECK BOX TYPES
export const OPPORTUNITIES = {
  label: FilterLabels.opportunities,
  key: FilterKeys.opportunities,
};

// M&A opportunities disabled until completion of this card
// https://welligence.atlassian.net/browse/WWP-375
export const defaultCheckBoxFilterTypes = [
  /*OPPORTUNITIES*/
];

// SLIDER TYPES
export const FilterMaxKeys = {
  [FilterKeys.captureCost]: 'maxCaptureCost',
  [FilterKeys.cO2Concentration]: 'maxCo2Concentration',
  [FilterKeys.facilityTotalCO2Emissions]: 'maxCo2Emission',
  [FilterKeys.storageCost]: 'maxStorageCost',
  [FilterKeys.totalAbatementCost]: 'maxAbatementCost',
  [FilterKeys.transportationCost]: 'maxTransportationCost',
};
export const CAPTURE_COST_SLIDER = {
  label: FilterLabels.captureCost,
  key: FilterKeys.captureCost,
  marks: [
    {
      value: 0,
      label: '0',
    },
    {
      value: 100,
      label: '100',
    },
  ],
  maxKey: FilterMaxKeys.captureCost,
};

export const CO2_CONCENTRATION_SLIDER = {
  label: FilterLabels.cO2Concentration,
  key: FilterKeys.cO2Concentration,
  marks: [
    {
      value: 0,
      label: '0',
    },
    {
      value: 100,
      label: '100',
    },
  ],
  maxKey: FilterMaxKeys.cO2Concentration,
};

export const FACILITY_TOTAL_CO2_EMISSIONS_SLIDER = {
  label: FilterLabels.facilityTotalCO2Emissions,
  key: FilterKeys.facilityTotalCO2Emissions,
  marks: [
    {
      value: 0,
      label: '0',
    },
    {
      value: 100,
      label: '100',
    },
  ],
  maxKey: FilterMaxKeys.facilityTotalCO2Emissions,
};

export const STORAGE_COST_SLIDER = {
  label: FilterLabels.storageCost,
  key: FilterKeys.storageCost,
  marks: [
    {
      value: 0,
      label: '0',
    },
    {
      value: 100,
      label: '100',
    },
  ],
  maxKey: FilterMaxKeys.storageCost,
};

export const TOTAL_ABATEMENT_COST_SLIDER = {
  label: FilterLabels.totalAbatementCost,
  key: FilterKeys.totalAbatementCost,
  marks: [
    {
      value: 0,
      label: '0',
    },
    {
      value: 100,
      label: '100',
    },
  ],
  maxKey: FilterMaxKeys.totalAbatementCost,
};

export const TRANSPORTATION_COST_SLIDER = {
  label: FilterLabels.transportationCost,
  key: FilterKeys.transportationCost,
  marks: [
    {
      value: 0,
      label: '0',
    },
    {
      value: 100,
      label: '100',
    },
  ],
  maxKey: FilterMaxKeys.transportationCost,
};

export const defaultSliderFilterTypes = [
  FACILITY_TOTAL_CO2_EMISSIONS_SLIDER,
  // TODO: concentration cost will be added later on
  // https://trello.com/c/aFwacEZi/1874-ccus-co2-concentration-slider
  // CO2_CONCENTRATION_SLIDER,
  TOTAL_ABATEMENT_COST_SLIDER,
  CAPTURE_COST_SLIDER,
  TRANSPORTATION_COST_SLIDER,
  STORAGE_COST_SLIDER,
];
