import { AutoComplete } from '../../AutoComplete';
import Grid from '@mui/material/Grid2';
import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  countryOverviewSelectInput: {
    backgroundColor: '#fafafa',
    boxSizing: 'border-box',
    paddingLeft: 0,
    display: 'flex',
  },
  countryOverviewChips: {
    margin: '0',
  },
  select: {
    padding: '5px',

    '& .MuiFilledInput-root': {
      padding: '8px 0',
      paddingRight: '0 !important',
    },

    '& .MuiAutocomplete-endAdornment': {
      top: '40%',
    },
  },
  text: {
    boxShadow: 'none',
    borderRadius: 0,
  },
}));

export default ({ filter, propVariables, setFilter, value, xs }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.select}
      size={xs}
      sx={{
        justifyContent: xs === 7 ? 'flex-end' : 'flex-start',
      }}
    >
      <div style={{ width: '300px' }}>
        <AutoComplete
          getData={filter.query}
          menuItems={filter.menuItems}
          onChange={(value) => setFilter(value, filter.name)}
          value={value}
          label={filter.label || filter.name}
          optionKey={filter.optionKey}
          propVariables={propVariables}
          runQueryAfterOnChange={filter.runQueryAfterOnChange}
          queryVariable={filter.queryVariable}
          multiple={filter.multiple}
          styles={{
            text: classes.text,
            input: clsx({
              [classes.countryOverviewSelectInput]: true,
            }),
            root: classes.countryOverviewChips,
          }}
          disableLimitTags
        />
      </div>
    </Grid>
  );
};
