import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  graphWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '1rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    height: '100%',

    '& > .js-plotly-plot > .plotly > .svg-container > svg': {
      overflow: 'unset',
    },
  },
  boxes: {
    display: 'flex',
    boxSizing: 'border-box',
    gap: '1rem',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: '1',
    textAlign: 'center',
    backgroundColor: theme.palette.baseColors.cloudGray.c100,
    padding: '.5rem 1rem',
    color: theme.palette.baseColors.deepSeaNavy.c75,
    boxShadow:
      '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
  },
  boxName: {
    fontSize: theme.fontSizes.smallest,
  },
  boxValue: {
    fontSize: theme.fontSizes.biggest,
  },
}));
