import { formatDateShort, titleizeAlt } from '../../../utils/helpers';

export const DISPLAY_NAME_FIELD_SUMMARY_ITEM = {
  label: 'Field',
  accessor: 'displayName',
};

export const HYDROCARBON_TYPE_FIELD_SUMMARY_ITEM = {
  label: () => 'Hydrocarbon Type',
  accessor: (f) => titleizeAlt(f?.hydrocarbonType, '/'),
};

export const PRODUCTION_START_DATE_FIELD_SUMMARY_ITEM = {
  label: () => 'Production Start Date',
  accessor: (f) => formatDateShort(f?.firstProductionDate),
};

export const PRODUCTION_CESSATION_DATE_FIELD_SUMMARY_ITEM = {
  label: () => 'Production Cessation Date',
  accessor: (f) => formatDateShort(f?.productionCessationDate),
};

export const PROJECT_SANCTION_DATE_FIELD_SUMMARY_ITEM = {
  label: () => 'Project Sanction Date',
  accessor: (f) => formatDateShort(f?.projectSanctionDate),
};
