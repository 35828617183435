import { gql } from '@apollo/client';
import { COUNTRY_LEVEL_ASSETS } from '../constants';

const COUNTRY_LEVEL_ASSETS_QUERY = gql`
  query dataDownloadsMetaData($downloadType: String!, $countryIsoCodes: [String!]) {
    dataDownloadsMetaData(downloadType: $downloadType, countryIsoCodes: $countryIsoCodes) {
      totalCount
    }
  }
`;

export const DATA_DOWNLOADS_META_DATA_QUERY = (downloadType) => {
  switch (downloadType) {
    case COUNTRY_LEVEL_ASSETS:
      return COUNTRY_LEVEL_ASSETS_QUERY;
    default:
      return COUNTRY_LEVEL_ASSETS_QUERY;
  }
};
