import {
  DIAMETER_SUMMARY_ITEM,
  OPERATOR_SUMMARY_ITEM,
} from '../../../../../SummaryList/SummaryItems/base';
import {
  LENGTH_PIPELINE_SUMMARY_ITEM,
  NAME_PIPELINE_SUMMARY_ITEM,
  START_DATE_PIPELINE_SUMMARY_ITEM,
  STATUS_PIPELINE_SUMMARY_ITEM,
  TYPE_PIPELINE_SUMMARY_ITEM,
} from '../../../../../SummaryList/SummaryItems/pipeline';

export default [
  NAME_PIPELINE_SUMMARY_ITEM,
  TYPE_PIPELINE_SUMMARY_ITEM,
  OPERATOR_SUMMARY_ITEM,
  STATUS_PIPELINE_SUMMARY_ITEM,
  LENGTH_PIPELINE_SUMMARY_ITEM,
  DIAMETER_SUMMARY_ITEM,
  START_DATE_PIPELINE_SUMMARY_ITEM,
];
