import {
  ANALYTICS_EVENTS,
  FORGOT_PASSWORD_PATH,
  ROOT_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
} from '../../utils/constants';
import { Link, useHistory } from 'react-router-dom';
// This is needed to "remove" navigate on line 121 to prevent a console error
/* eslint no-unused-vars: 0 */
import React, { useContext, useState } from 'react';
import { isAuthenticated, persistUserCredentials } from '../../utils/auth';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import { LOGIN_MUTATION } from './operations';
import Logo from '../../../assets/images/FullLogo.svg';
import MuiLink from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { Redirect } from 'react-router-dom';
import SnackbarContext from '../SnackbarContext';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { joinGqlErrorMessage } from '../../utils/gqlErrors';
import makeStyles from '@mui/styles/makeStyles';
import { useCurrentUser } from '../CurrentUserContext';
import { useMutation } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  loginRoot: {
    ...theme.sizes.fullPageNoAppBar,
    ...theme.palette.primaryBackground,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginForm: {
    height: '500px',
    width: '500px',
    padding: '45px',
    boxSizing: 'border-box',
  },
  loginButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginButton: {
    width: '75%',
  },
  link: {
    cursor: 'pointer',
  },
}));

const Login = () => {
  if (isAuthenticated()) {
    return <Redirect to={ROOT_PATH} />;
  }
  const classes = useStyles();
  const history = useHistory();
  const { setSnack } = useContext(SnackbarContext);
  const { refreshProvider } = useCurrentUser();

  const [formState, setFormState] = useState({
    email: '',
    password: '',
  });

  const [userLogin] = useMutation(LOGIN_MUTATION, {
    variables: {
      email: formState.email,
      password: formState.password,
    },
    onCompleted: ({ userLogin }) => {
      if (userLogin.errors) {
        setSnack({
          severity: 'error',
          message: userLogin.errors,
          open: true,
        });
      } else {
        persistUserCredentials(
          userLogin.credentials.accessToken,
          userLogin.credentials.client,
          userLogin.credentials.expiry,
          userLogin.tokenType,
          userLogin.authenticatable.email,
          userLogin.authenticatable.id,
        );
        refreshProvider();
        const route =
          history.location.state && history.location.state.from.pathname !== SIGN_IN_PATH
            ? history.location.state.from.pathname
            : ROOT_PATH;
        gtag('event', ANALYTICS_EVENTS.login, { userId: userLogin.authenticatable.id });
        history.push(route);
      }
    },
    onError: ({ graphQLErrors }) => {
      setSnack({
        open: true,
        message: joinGqlErrorMessage(graphQLErrors),
        severity: 'error',
      });
    },
  });

  const requestAccess = () => {
    const el = document.createElement('input');
    el.value = 'info@welligence.com';
    el.id = 'url-input';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    el.remove();
    window.location.href = `mailto:info@welligence.com`;
    setSnack({
      open: true,
      severity: 'info',
      message: 'If email does not open, the contact has been copied to clipboard!',
    });
  };

  const CustomLink = (props) => {
    const { navigate, ...rest } = props;
    return <MuiLink {...rest} />;
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        userLogin();
      }}
      className={classes.loginRoot}
      noValidate
      autoComplete="off"
    >
      <Paper className={classes.loginForm}>
        <img id="logo" src={Logo} alt="Welligence-Logo" width="200px" height="75px" />
        <Typography variant="h4" style={{ margin: '30px 0 15px' }}>
          Sign In
        </Typography>
        <Grid
          container
          direction="column"
          align="center"
          spacing={3}
          sx={{
            justifyContent: 'space-around',
          }}
        >
          <Grid align="center" size={12}>
            <TextField
              value={formState.email}
              onChange={(e) => {
                setFormState({
                  ...formState,
                  email: e.target.value,
                });
              }}
              fullWidth
              id="user_email"
              name="user[email]"
              label="Email"
              className={classes.loginRow}
              variant="filled"
            />
          </Grid>
          <Grid align="center" size={12}>
            <TextField
              value={formState.password}
              onChange={(e) => {
                setFormState({
                  ...formState,
                  password: e.target.value,
                });
              }}
              fullWidth
              id="user_password"
              name="user[password]"
              label="Password"
              className={classes.loginRow}
              variant="filled"
              type="password"
            />
          </Grid>
          <Grid size={12}>
            <Button
              type="submit"
              className={classes.loginButton}
              variant="contained"
              color="secondary"
            >
              Login
            </Button>
          </Grid>
          <Grid size={12}>
            <Typography variant="caption">
              <Link component={CustomLink} color="secondary" to={FORGOT_PASSWORD_PATH}>
                Forgot Password?
              </Link>
              &nbsp; | &nbsp;
              <Link component={CustomLink} color="secondary" to={SIGN_UP_PATH}>
                User Registration
              </Link>
              &nbsp; | &nbsp;
              <MuiLink className={classes.link} onClick={requestAccess} color="secondary">
                Request Access Now
              </MuiLink>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};

export default Login;
