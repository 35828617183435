import { gql } from '@apollo/client';

const ASSET_QUERY = gql`
  query asset($legacyId: ID!, $countryIsoCode: String!, $feature: String) {
    asset(legacyId: $legacyId, countryIsoCode: $countryIsoCode, feature: $feature) {
      id
      legacyId
      displayName
      formattedAcreage
      country {
        id
        displayName
      }
      participations {
        id
        effectiveDate
        formattedPercentStake
        percentStake
        isCompanyOperator
        company {
          name
        }
      }
    }
  }
`;

const BLOCK_QUERY = gql`
  query block($legacyId: ID!, $countryIsoCode: String!, $feature: String) {
    block(legacyId: $legacyId, countryIsoCode: $countryIsoCode, feature: $feature) {
      id
      legacyId
      displayName
      formattedAcreage
      country {
        id
        displayName
      }
      participations {
        id
        effectiveDate
        formattedPercentStake
        percentStake
        isCompanyOperator
        company {
          name
        }
      }
    }
  }
`;

const CCUS_PROJECT_QUERY = gql`
  query ccusProject($id: ID!) {
    ccusProject(id: $id) {
      id
      announcedCapacity
      announcementYear
      countryName
      developmentLikelihood
      developmentStatus
      fidYear
      formattedPartners
      hubName
      industryType
      operationalYear
      projectDescription
      projectName
      projectScale
      projectType
      storagePartnership
      storageType
    }
  }
`;

const CO2_EMITTER_QUERY = gql`
  query co2Emitter($id: ID!, $includeDownload: Boolean!) {
    co2Emitter(id: $id, includeDownload: $includeDownload) {
      facility {
        id
        countryName
        facilityName
        industryType
        formattedCo2Emission
        formattedBiogenicCo2Emission
        formattedOwners
        formattedProcessEmission
        formattedStationaryEmission
      }
      hasDownload
    }
  }
`;

const STORAGE_SITE_QUERY = gql`
  query storageSite($id: ID!, $includeDownload: Boolean!) {
    storageSite(id: $id, includeDownload: $includeDownload) {
      storageSite {
        id
        blockStatus
        formattedAcreage
        formattedPartners
        shoreStatus
        storageSiteName
        storageType
      }
      hasDownload
    }
  }
`;

const FIELD_QUERY = gql`
  query field($legacyId: ID!, $countryIsoCode: String!, $feature: String) {
    field(legacyId: $legacyId, countryIsoCode: $countryIsoCode, feature: $feature) {
      id
      legacyId
      displayName
      hydrocarbonType
      country {
        id
        displayName
      }
      fieldStatus {
        name
      }
    }
  }
`;

const LEASE_QUERY = gql`
  query lease($legacyId: ID!, $countryIsoCode: String!, $feature: String) {
    lease(legacyId: $legacyId, countryIsoCode: $countryIsoCode, feature: $feature) {
      id
      legacyId
      currentParticipationDates
      displayName
      country {
        id
        displayName
      }
      groupedParticipations {
        A {
          id
          effectiveDate
          formattedPercentStake
          percentStake
          isCompanyOperator
          company {
            name
          }
        }
        B {
          id
          effectiveDate
          formattedPercentStake
          percentStake
          isCompanyOperator
          company {
            name
          }
        }
        C {
          id
          effectiveDate
          formattedPercentStake
          percentStake
          isCompanyOperator
          company {
            name
          }
        }
        D {
          id
          effectiveDate
          formattedPercentStake
          percentStake
          isCompanyOperator
          company {
            name
          }
        }
        E {
          id
          effectiveDate
          formattedPercentStake
          percentStake
          isCompanyOperator
          company {
            name
          }
        }
        O {
          id
          effectiveDate
          formattedPercentStake
          percentStake
          isCompanyOperator
          company {
            name
          }
        }
        S {
          id
          effectiveDate
          formattedPercentStake
          percentStake
          isCompanyOperator
          company {
            name
          }
        }
        noGroup {
          id
          effectiveDate
          formattedPercentStake
          percentStake
          isCompanyOperator
          company {
            name
          }
        }
      }
    }
  }
`;

const NOOP_QUERY = gql`
  query noop($id: ID!) {
    noop(id: $id) {
      message
    }
  }
`;

const PIPELINE_QUERY = gql`
  query pipeline($legacyId: ID!, $countryIsoCode: String!, $feature: String) {
    pipeline(legacyId: $legacyId, countryIsoCode: $countryIsoCode, feature: $feature) {
      id
      legacyId
      formattedDiameterInches
      formattedLengthKm
      name
      operator
      startDate
      product {
        name
      }
      status {
        name
      }
    }
  }
`;

const WELLS_QUERY = gql`
  query well($legacyId: ID!, $countryIsoCode: String!, $feature: String) {
    well(legacyId: $legacyId, countryIsoCode: $countryIsoCode, feature: $feature) {
      id
      legacyId
      displayName
      drillDays
      drillingStartDate
      drillingEndDate
      operator
      weaClassification
      wellResult
      wellStatus
      country {
        id
        displayName
      }
      wellDesign {
        name
      }
    }
  }
`;

export const getQuery = (entityType) => {
  switch (entityType) {
    case 'asset':
      return ASSET_QUERY;
    case 'block':
      return BLOCK_QUERY;
    case 'ccus_project':
      return CCUS_PROJECT_QUERY;
    case 'co2_emitter':
      return CO2_EMITTER_QUERY;
    case 'field':
      return FIELD_QUERY;
    case 'lease':
      return LEASE_QUERY;
    case 'pipeline':
      return PIPELINE_QUERY;
    case 'storage_site':
      return STORAGE_SITE_QUERY;
    case 'well':
      return WELLS_QUERY;
    default:
      return NOOP_QUERY;
  }
};
