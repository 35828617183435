import React, { useContext, useEffect, useRef, useState } from 'react';

import { GOOGLE_ANALYTICS_TAG } from '../../constants';
import GraphContainer from '../../../Graphs/graphContainer';
import Legend from '../../../Legend/legend';
import Plotly from '../../../Graphs/plotly';
import ProductionLineGraphContext from './ProductionLineGraphContext';
import makeStyles from '@mui/styles/makeStyles';
import { useWidth } from '../../../../hooks/useWidth';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
  },
  legendRoot: {
    flexDirection: 'column',
    backgroundColor: '#ecf0f5',
    height: '100%',
    width: '15%',
    padding: 0,
  },
  legendTitle: {
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'center',
    paddingLeft: '5px',
    border: 'solid rgba(0, 0, 0, 0.12) thin',
    backgroundColor: 'white',
    width: '100%',
    height: '36px',
    [theme.breakpoints.down('lg')]: {
      height: '24px',
    },
  },
  legendItem: {
    padding: '5px',
  },
}));

const ProductionLineGraph = ({
  open,
  setOpen,
  setClose,
  showLegend,
  range,
  appBarId,
  linkToPage,
  darkTheme,
}) => {
  const width = useWidth();
  const classes = useStyles();
  const [legendHeight, setLegendHeight] = useState('100px');
  const [showPlotlyLegend, setShowPlotlyLegend] = useState(true);
  const graphContainerRef = useRef(null);

  const {
    title,
    setOpenControlsModal,
    trace,
    loading,
    stacked,
    setStacked,
    downloadCallback,
    onGraphUpdate,
    onInitialized,
    onAfterPlot,
    onLegendDoubleClick,
    legendItems,
    onRelayout,
    maxY,
    handleTraceToggle,
  } = useContext(ProductionLineGraphContext);

  useEffect(() => {
    if (showLegend) {
      switch (width) {
        case 'md':
        case 'sm':
        case 'xs':
          setLegendHeight(`${graphContainerRef.current.offsetHeight - 24}px`);
          break;
        default:
          setLegendHeight(`${graphContainerRef.current.offsetHeight - 36}px`);
      }
    }
  }, [width]);

  return (
    <div ref={graphContainerRef} className={classes.root}>
      {showLegend ? (
        <Legend
          vertical
          clickCallback={handleTraceToggle}
          height={legendHeight}
          items={legendItems}
          title="PRODUCTION"
          styles={classes}
        />
      ) : null}
      <GraphContainer
        open={open}
        setClose={setClose}
        setOpen={setOpen}
        setOpenControlsModal={setOpenControlsModal}
        showLineToggle
        showPlotlyLegendToggle={!showLegend}
        showPlotlyLegend={showPlotlyLegend}
        setShowPlotlyLegend={setShowPlotlyLegend}
        stacked={stacked}
        setStacked={setStacked}
        title={title}
        downloadCallback={downloadCallback}
        loading={loading}
        appBarId={appBarId}
        monthly
        linkToPage={linkToPage}
        darkTheme={darkTheme}
        googleAnalyticsTag={GOOGLE_ANALYTICS_TAG}
      >
        <Plotly
          data={trace}
          layout={{
            margin: { l: 50, r: 30, b: 30, t: 30 },
            xaxis: {
              range: range.x,
              tickformat: 'd',
            },
            yaxis: {
              range: range.y,
              rangemode: 'nonnegative',
            },
            // This legend refers to plotly legend.
            // Hide when we show our custom legend.
            showlegend: !showLegend && showPlotlyLegend,
            legend: {
              x: 1,
              xanchor: 'right',
              y: 1,
              bgcolor: 'rgba(236, 240, 245, 0.8)',
            },
          }}
          maxY={maxY}
          loading={loading}
          onRelayout={onRelayout}
          onInitialized={onInitialized}
          onAfterPlot={onAfterPlot}
          onLegendDoubleClick={onLegendDoubleClick}
          onUpdate={showLegend ? onGraphUpdate : () => {}}
          darkTheme={darkTheme}
        />
      </GraphContainer>
    </div>
  );
};

export default ProductionLineGraph;
