import { Button } from '@mui/material';
import React from 'react';
import WeaAssumptions from '../WeaAssumptions';
import makeStyles from '@mui/styles/makeStyles';
import { requestTrainingMailTo } from '../../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '70%',
    height: '100%',
    position: 'relative',
    margin: '1rem 10rem',
    marginBottom: '4rem',
  },
  button: {
    ...theme.palette.button,
    padding: '1rem 1.5rem !important',
  },
  requestTraining: {
    padding: '1rem 10rem',
    backgroundColor: theme.palette.baseColors.skyBlue.c25,
  },
  link: { color: '#0101d6' },
  underlined: {
    textDecoration: 'underline',
  },
}));

export default () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.requestTraining}>
        <h2>Request Platform Overview Session</h2>
        <p>
          Our onboarding/platform overview sessions are designed to assist you to quickly navigate
          and extract data specific to your individual requirements.
        </p>
        <Button className={classes.button} href={requestTrainingMailTo} variant="primary">
          Request training
        </Button>
        <p>We look forward to speaking with you soon.</p>
      </div>
      <div className={classes.root}>
        <div id="landing-page-documentation">
          <WeaAssumptions />
          <h1>How we define reserves and resources</h1>
          <h2>Overview</h2>
          <p>
            This document explains how we define reserves and resources at Welligence and how that
            definition compares to recognized third-party guidelines. We currently have two
            definitions.
          </p>
          <h3>Reserves</h3>
          <p>
            “At Welligence, we model the volume of oil and gas that we estimate can be extracted and
            sold under our base-case commercial assumptions. We consider this broadly equivalent to
            a 2P reserves figure, but we may also treat contingent resources as commercial e.g., a
            promising new discovery. Note that it is the liquids and gas available for sale to the
            market that we classify as reserves – volumes such as gas used in-field as fuel are
            excluded.”
          </p>
          <h3>Sub-commercial Resources</h3>
          <p>
            “These are the volumes of oil and gas that we believe are potentially recoverable from a
            discovered accumulation but are not currently considered commercial due to one or more
            reasons. For example, a stranded gas field with no market, or a new discovery that needs
            appraisal. A producing field can also hold sub-commercial resource – this could be a
            deep gas interval, or an area with poor reservoir quality.”
          </p>
          <h2>Petroleum Resources Management System – definitions</h2>
          <p>
            The Petroleum Resources Management System (PRMS) is the guidelines collated by several
            professional institutions, including the SPE and AAPG. We use these definitions as the
            basis for our reserve and resource classifications.
          </p>
          <h3>STOIIP – Stock Tank Oil Initially In Place</h3>
          <p>
            The volume of oil estimated to be contained within known accumulations before the start
            of production.
          </p>
          <ul>
            <li>
              Also referred to as Stock Tank Oil Originally in Place (STOOIP) and Oil Initially In
              Place (OIIP)
            </li>
            <li>The gas equivalent measure is Gas Initially In Place (GIIP) </li>
          </ul>
          <h3>Reserves</h3>
          <p>
            The volume of oil/gas estimated to be commercially recoverable from known accumulations
            under a defined set of base-case conditions. • We only include sales quantities as
            reserves (see next page)
          </p>
          <h3>Contingent Resources</h3>
          <p>
            The volume of oil/gas estimated to be potentially recoverable from known accumulations
            but not currently considered commercial due to one or more contingencies.
          </p>

          <h2>Why do we only classify sales volumes as reserves?</h2>
          <p>
            We count the oil and gas available for sale to the market as reserves, excluding all
            other hydrocarbons produced e.g., flaring, in-field fuel use, etc.
          </p>

          <ul>
            <span className={classes.underlined}>The following volumes are excluded</span>
            <li>Gas flared</li>
            <li>Gas vented</li>
            <li>Gas re-injected for disposal/pressure support</li>
            <li>Oil & gas used in-field for fuel</li>
            <li>Impurities and other components</li>
          </ul>

          <h3>Why do we exclude these volumes?</h3>
          <p>
            Our reserves must have a direct monetary value i.e., generate revenue. Some items like
            gas flared/vented or injected for disposal reasons, while being operational necessities,
            clearly provide no direct revenue. Other items do bring value to operations, but this is
            captured indirectly through non-revenue mechanisms.
          </p>
          <ul>
            <li>
              Oil & gas used in-field for fuel: The value is reflected in lower operating costs as
              gas/diesel does not need to be purchased
            </li>
            <li>
              Gas re-injected for pressure support: The value is reflected in higher oil recovery
            </li>
          </ul>

          <h2>The Welligence reserve categories – PDP vs. UD</h2>
          <p>
            For any commercial asset on our platform, our reserves can be split into two categories
            – Proved Developed Producing (PDP) and Undeveloped (UD).
          </p>

          <p>
            <span className={classes.underlined}>Proved Developed Producing</span>
            <br />
            This is production from wells already drilled and online. With the wells and associated
            facilities already in place, no further investment is required.
          </p>
          <ul>
            <li>
              For those countries with well-level data, our algorithms decline out production from
              each well
            </li>
            <li>
              The approach we take for other countries depends on the data available, from applying
              a decline curve algorithm at the field level to implementing a manual forecast (e.g. a
              gas field that produces a fixed volume dictated by a sales contract)
            </li>
          </ul>

          <span>
            Without any capex requirement, this production is typically low cost and remains online
            in a low oil price scenario.
          </span>

          <br />
          <br />
          <span className={classes.underlined}>Undeveloped</span>
          <p>
            This is production from new wells that we expect to be drilled at the asset. Capex is
            required, from drilling to (possibly) installing infrastructure like facilities,
            pipelines, etc.
          </p>
          <ul>
            <li>We use a production curve to forecast production from each new well</li>
            <li>
              For those countries with well-level data, our algorithm calculates a production curve
            </li>
            <li>
              For the others, we select production curves based on analogues, primary research,
              operator guidance, etc.
            </li>
          </ul>
          <span>
            This is higher cost production and is at risk in a low oil price scenario as companies
            defer investment.{' '}
          </span>
        </div>
      </div>
    </>
  );
};
