import { DEFAULT_TRACE_LIMIT, SORT_DIRECTIONS, TOP_SORT_DIRECTION } from '../constants';
import React, { useState } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid2';
import InputItem from '../../InputList/inputItem';
import MenuButton from '../../MenuButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  inputItemRoot: {
    '& > .MuiOutlinedInput-root': {
      fontSize: '0.7rem',
      width: '55px',
    },
  },
}));

export default ({
  aggregate,
  aggregateIsDisabled,
  defaultSortDirection = TOP_SORT_DIRECTION,
  handleSetAggregate,
  selectedAggregate,
  styles,
}) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(aggregate.defaultLimit || DEFAULT_TRACE_LIMIT);
  const [sortDirection, setSortDirection] = useState(defaultSortDirection);
  const isDisabled = aggregateIsDisabled(aggregate);

  return (
    <RadioGroup
      value={selectedAggregate}
      name="graph-aggregate-radio-buttons-group"
      onChange={() => handleSetAggregate(aggregate.name, limit, sortDirection)}
    >
      <Grid
        className={styles.radioRow}
        container
        direction="row"
        sx={{
          alignItems: 'center',
        }}
      >
        <Typography
          className={clsx({
            [styles.radioRowLabel]: true,
            [styles.disabled]: isDisabled,
          })}
        >
          {aggregate.name}
        </Typography>
        <Grid>
          <FormControlLabel
            checked={aggregate.name === selectedAggregate}
            style={{ margin: 0 }}
            control={<Radio />}
            disabled={isDisabled}
            label={
              <Grid
                container
                direction="row"
                style={{ flexWrap: 'nowrap' }}
                sx={{
                  alignItems: 'center',
                }}
              >
                <MenuButton
                  context={aggregate}
                  items={SORT_DIRECTIONS}
                  isDisabled={isDisabled}
                  selectedItem={sortDirection}
                  onChange={(newSort) => {
                    let hasNewLimit = limit;
                    if (newSort.limit !== undefined) {
                      hasNewLimit = newSort.limit;
                      setLimit(hasNewLimit);
                    }
                    handleSetAggregate(aggregate.name, hasNewLimit, newSort);
                    setSortDirection(newSort);
                  }}
                  tooltip="Select whether you want the top or bottom x results."
                />
                <InputItem
                  styles={classes}
                  inputLabel="Limit"
                  value={limit}
                  onChange={(e) => {
                    // Only allow postive integers and null string
                    if (e.target.value.match(/^(\d|\|)*$/)) {
                      handleSetAggregate(aggregate.name, e.target.value, sortDirection);
                      setLimit(e.target.value);
                    }
                  }}
                  isDisabled={isDisabled || sortDirection.limit !== undefined} // All sort direction has a default limit of 0
                />
              </Grid>
            }
            labelPlacement="start"
          />
        </Grid>
      </Grid>
    </RadioGroup>
  );
};
