const getEmissionFilters = ({
  captureCost,
  companies,
  companyTargets,
  countries,
  facilities,
  facilityTotalCO2Emissions,
  hasAbatement,
  industries,
  storageCost,
  totalAbatementCost,
  transportationCost,
}) => ({
  captureCost,
  companies,
  companyTargets,
  countries,
  facilities,
  facilityTotalCO2Emissions,
  hasAbatement,
  industries,
  storageCost,
  totalAbatementCost,
  transportationCost,
});

const getMapFilters = ({ countries }) => ({ countries });

const getProjectFilters = ({
  ccusDevelopmentStatuses,
  ccusProjects,
  ccusProjectScales,
  ccusProjectTypes,
  companies,
  ccusSeparationTypes,
  companyTargets,
  countries,
  industries,
}) => ({
  ccusDevelopmentStatuses,
  ccusProjects,
  ccusProjectScales,
  ccusProjectTypes,
  companies,
  ccusSeparationTypes,
  companyTargets,
  countries,
  industries,
});

const getStorageFilters = ({
  companies,
  companyTargets,
  countries,
  storageBlockStatuses,
  storageShoreStatuses,
  storageSites,
  storageTypes,
}) => ({
  companies,
  companyTargets,
  countries,
  storageBlockStatuses,
  storageShoreStatuses,
  storageSites,
  storageTypes,
});

export const getFilters = (filters) => ({
  emissionFilters: getEmissionFilters(filters),
  mapFilters: getMapFilters(filters),
  projectFilters: getProjectFilters(filters),
  storageFilters: getStorageFilters(filters),
});
