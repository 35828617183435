import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid2';
import { IconButton } from '@mui/material';
import React from 'react';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useSidePageButtonStyles } from './SidePageButton.style';

export const SidePageButton = ({
  isOpen,
  showIcon,
  label,
  labelClosed,
  onClick,
  className,
  closeAble = false,
  onClose,
  boxy = false,
  left = false,
}) => {
  const classes = useSidePageButtonStyles();

  return (
    <Grid
      container
      className={clsx(classes.button, className, {
        [classes.buttonWithClose]: !!closeAble,
        [classes.boxy]: boxy,
        [classes.left]: left,
      })}
      onClick={onClick}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Typography className={classes.buttonLabel} variant="caption" noWrap>
        {showIcon ? isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon /> : null}
        {isOpen ? label : labelClosed || label}
      </Typography>
      {!!closeAble && (
        <IconButton
          className={classes.close}
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      )}
    </Grid>
  );
};
