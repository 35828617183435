import { gql } from '@apollo/client';

export const SECURE_SUBSCRIBER_DOWNLOAD_QUERY = gql`
  query secureSubscriberDownload($reportType: String!, $slug: String!) {
    secureSubscriberDownload(reportType: $reportType, slug: $slug) {
      url
      filename
      downloadMessage
    }
  }
`;
