import { gql } from '@apollo/client';

export const CURRENT_USER_QUERY = gql`
  {
    currentUserProfile {
      id
      admin
      company
      downloadMessage
      email
      features
      featuresEntitled
      firstName
      lastName
      mapTilesetSources
      mapCcusTilesetSources
      name
      seenUserWelcome
      subscribedCountries {
        displayName
        name
        isoCode
      }
      createdAt
      updatedAt
    }
  }
`;

export const CURRENT_USER_FRIENDS = gql`
  {
    currentUserFriends {
      id
      firstName
      lastName
      admin
      client {
        companyTitle
      }
      userFilters {
        id
      }
      userPortfolios {
        id
      }
      sharedUserFilters {
        id
      }
      sharedUserPortfolios {
        id
      }
    }
  }
`;
