export default (driver) => {
  driver.defineSteps([
    {
      element: '#appbar-menu-button',
      popover: {
        title: 'Menu Icon',
        description:
          'Click on this icon for access to your user settings, help page, or page tutorials on applicable pages.',
        position: 'left-top',
      },
    },
    {
      element: '#watchlist-accordion',
      popover: {
        title: 'Watch List',
        description:
          'Assets added to your Watchlist on the upstream assets page will appear here so you can stay on top of the latest updates.',
        position: 'left',
      },
    },
    {
      element: '#industry-tracker-accordion',
      popover: {
        title: 'Industry Trackers',
        description:
          'Download spreadsheets tracking M&A opportunities, offshore wells, and licensing rounds.',
        position: 'left',
      },
    },
    {
      element: '#landing-page-valuations-button',
      popover: {
        title: 'Upstream Assets',
        description:
          'Visit Valuations and Reports to filter and analyze key assets, download detailed models and raw data, and read the most recent reports.',
        position: 'left-bottom',
      },
    },
  ]);

  // Start the introduction
  driver.start(0);
};
