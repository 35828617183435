export const NAME_CCUS_PROJECT_SUMMARY_ITEM = {
  label: 'Project Name',
  accessor: 'projectName',
};

export const HUB_CCUS_PROJECT_SUMMARY_ITEM = {
  label: 'HUB Name',
  accessor: 'hubName',
};

export const SCALE_CCUS_PROJECT_SUMMARY_ITEM = {
  label: 'Project Scale',
  accessor: 'projectScale',
};

export const COUNTRY_CCUS_PROJECT_SUMMARY_ITEM = {
  label: 'Country',
  accessor: 'countryName',
};

export const TYPE_CCUS_PROJECT_SUMMARY_ITEM = {
  label: 'Project Type',
  accessor: 'projectType',
};

export const DEV_STATUS_CCUS_PROJECT_SUMMARY_ITEM = {
  label: 'Development Status',
  accessor: 'developmentStatus',
};

export const DESCRIPTION_CCUS_PROJECT_SUMMARY_ITEM = {
  label: 'Project Description',
  accessor: 'projectDescription',
};

export const ANNOUNCEMENT_YEAR_CCUS_PROJECT_SUMMARY_ITEM = {
  label: 'Announcement Year',
  accessor: 'announcementYear',
};

export const FID_YEAR_CCUS_PROJECT_SUMMARY_ITEM = {
  label: 'FID Year',
  accessor: 'fidYear',
};

export const OPERATIONAL_YEAR_CCUS_PROJECT_SUMMARY_ITEM = {
  label: 'Operational Year',
  accessor: 'operationalYear',
};

export const CAPACITY_CCUS_PROJECT_SUMMARY_ITEM = {
  label: 'Announced Capacity',
  unit: 'MMtpa',
  accessor: 'announcedCapacity',
};

export const DEV_LIKELIHOOD_CCUS_PROJECT_SUMMARY_ITEM = {
  label: 'Development Likelihood',
  accessor: 'developmentLikelihood',
};

export const STORAGE_PARTNERSHIP_CCUS_PROJECT_SUMMARY_ITEM = {
  label: 'Storage Partnership',
  accessor: 'storagePartnership',
};
