import {
  ACQUISITION_COST_AGGREGATE_COMPARE_TO_TITLE,
  RESERVES_AGGREGATE_BY_TITLE_OPTIONS,
  RESERVES_STACK_BY_TYPE_ENUM,
} from '../types';

import { titleize } from '../../../../../utils/helpers';
import { useGetMaReservesAggregates } from './useGetMaReservesAggregates';
import { useGetMaReservesAggregatesStacked } from './useGetMaReservesAggregatesStacked';
import { useMemo } from 'react';

const MAX_LEGEND_ITEMS = 5;

const getAltYAxisChartData = (data, aggregateBy) => {
  const hoverTitle = titleize(aggregateBy);
  const altYAxisTitle = ACQUISITION_COST_AGGREGATE_COMPARE_TO_TITLE;
  return [
    {
      x: data?.map((d) => d.group) || [],
      y: data?.map((d) => d.acquisitionCostSum) || [],
      name: altYAxisTitle,
      yaxis: 'y2',
      type: 'scatter',
      mode: 'markers',
      hovertemplate: `${hoverTitle}: %{x}<br>${altYAxisTitle}: %{y:,.2f}<extra></extra>`,
    },
  ];
};

const getFlatChartData = (data, aggregateBy, type) => {
  const title = RESERVES_AGGREGATE_BY_TITLE_OPTIONS[type];
  const hoverTitle = titleize(aggregateBy);
  return [
    {
      x: data?.map((d) => d.group) || [],
      y: data?.map((d) => d.reservesSum) || [],
      name: title,
      type: 'bar',
      hovertemplate: `${hoverTitle}: %{x}<br>${title}: %{y:,.2f}<extra></extra>`,
    },
    ...getAltYAxisChartData(data, aggregateBy),
  ];
};

const getStackedChartData = (data, stackedData, stacks, aggregateBy, stackBy, type) => {
  const yAxisTitle = RESERVES_AGGREGATE_BY_TITLE_OPTIONS[type];
  const aggregateByTitle = titleize(aggregateBy);
  const stackByTitle = titleize(stackBy);

  const chartX = [];
  const chartYs = new Map(stacks.map((stack) => [stack, []]));
  // fill in the X and and primary Y values for each stack
  stackedData.forEach((d) => {
    // get list of X-axis values (e.g. countries, years, etc.)
    chartX.push(d.group);
    // get list of Y-axis values for each stack (i.e., buyer, seller)
    // if stack value is not present in the data, we need to default to 0 to keep the chart consistent
    stacks.forEach((stack) => chartYs.get(stack).push(d.values[stack]?.reservesSum || 0));
  });

  return [...chartYs]
    .map(([stack, chartY], idx) => ({
      // same X-axis values for all stacks
      x: chartX,
      // one Y-axis value for stack
      y: chartY,
      name: stack,
      type: 'bar',
      hovertemplate: `${stackByTitle}: ${stack} <br>${aggregateByTitle}: %{x}<br>${yAxisTitle}: %{y:,.2f}<extra></extra>`,
      showlegend: idx < MAX_LEGEND_ITEMS,
    }))
    .concat(getAltYAxisChartData(data, aggregateBy));
};

export const useGetChartData = (aggregateBy, stackBy, type) => {
  const { data, loading, error } = useGetMaReservesAggregates(aggregateBy, type);

  // only query for stacked data if we are stacking by a valid field
  const skipStackedQuery = !Object.values(RESERVES_STACK_BY_TYPE_ENUM).includes(stackBy);
  const {
    data: stackedData,
    stacks,
    loading: stackedLoading,
    error: stackedError,
  } = useGetMaReservesAggregatesStacked(aggregateBy, stackBy, type, skipStackedQuery);

  const chartData = useMemo(() => {
    if (!data || !stackedData) return [];

    return skipStackedQuery
      ? getFlatChartData(data, aggregateBy, type)
      : getStackedChartData(data, stackedData, stacks, aggregateBy, stackBy, type);
  }, [stackBy, data, stackedData]);

  return { data: chartData, loading: loading || stackedLoading, error: error || stackedError };
};
