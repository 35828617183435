import React, { createContext, useState } from 'react';

import Button from '@mui/material/Button';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import makeStyles from '@mui/styles/makeStyles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const SnackbarContext = createContext();

export const SnackbarProvider = (props) => {
  const classes = useStyles();

  const [snack, setSnack] = useState({
    open: false,
    message: null,
    severity: null,
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnack({ ...snack, open: false });
  };

  const autoHide = snack.disableHide ? null : 6000;
  const origin = { vertical: 'bottom', horizontal: 'right' };
  const action = snack.action ? (
    <Button color="secondary" size="small" onClick={snack.action?.onClick}>
      {snack.action?.title}
    </Button>
  ) : undefined;
  return (
    <SnackbarContext.Provider value={{ setSnack }}>
      {props.children}
      <div className={classes.root}>
        <Snackbar
          open={snack.open}
          autoHideDuration={autoHide}
          onClose={handleClose}
          anchorOrigin={origin}
          action={action}
        >
          {/* Div is needed here: https://github.com/mui/material-ui/issues/28918#issuecomment-1106820571 */}
          <div>
            <Alert onClose={handleClose} severity={snack.severity}>
              {snack.message}
              {action}
            </Alert>
          </div>
        </Snackbar>
      </div>
    </SnackbarContext.Provider>
  );
};

export const SnackbarConsumer = SnackbarContext.Consumer;

export default SnackbarContext;
