import { gql } from '@apollo/client';

export const COUNTRIES_QUERY = gql`
  {
    countries {
      id
      displayName
      name
      isoCode
      geom
      zoom
      region {
        id
        name
        displayName
      }
    }
  }
`;

export const REGIONS_QUERY = gql`
  query regions($filters: JSON!) {
    regions(filters: $filters) {
      id
      name
      displayName
      countries {
        id
        displayName
        name
        isoCode
        geom
      }
    }
  }
`;
