import { formatDateShort, roundAndFormat } from '../../../utils/helpers';
import { oneMonthBeforeReportUpdate, outdatedValue } from './utils';

export const COMMERCIAL_ASSETS_COUNTRY_SUMMARY_ITEM = {
  label: 'Commercial Assets',
  accessor: 'countOfAssets',
};

export const ACTIVE_COMPANIES_COUNTRY_SUMMARY_ITEM = {
  label: 'Active Companies',
  accessor: 'countOfActiveCompanies',
};

export const AREA_LICENSED_COUNTRY_SUMMARY_ITEM = {
  label: 'Area Licensed',
  accessor: (countryStatistics) => {
    return countryStatistics && countryStatistics.formattedAcreage
      ? roundAndFormat(countryStatistics.formattedAcreage)
      : '0';
  },
};

export const LIQUID_PRODUCTION_COUNTRY_SUMMARY_ITEM = {
  label: 'Liquids Production',
  unit: (countryStatistics) => {
    const date =
      countryStatistics && countryStatistics.recentProductionDate
        ? outdatedValue(countryStatistics)
          ? formatDateShort(oneMonthBeforeReportUpdate(countryStatistics))
          : formatDateShort(countryStatistics.recentProductionDate)
        : '';
    return `(${date}) (bbl/d)`;
  },
  accessor: (countryStatistics) => {
    return countryStatistics && countryStatistics.recentLiquidsProductionBbld
      ? `${
          outdatedValue(countryStatistics)
            ? countryStatistics.formattedWeaProdLiquidsBbld
            : roundAndFormat(countryStatistics.recentLiquidsProductionBbld)
        } (*)`
      : '0';
  },
};

export const GAS_PRODUCTION_COUNTRY_SUMMARY_ITEM = {
  label: 'Gas Production',
  unit: (countryStatistics) => {
    const date =
      countryStatistics && countryStatistics.recentProductionDate
        ? outdatedValue(countryStatistics)
          ? formatDateShort(oneMonthBeforeReportUpdate(countryStatistics))
          : formatDateShort(countryStatistics.recentProductionDate)
        : '';
    return `(${date}) (MMcf/d)`;
  },
  accessor: (countryStatistics) => {
    return countryStatistics && countryStatistics.recentGasProductionMmcfd
      ? `${
          outdatedValue(countryStatistics)
            ? countryStatistics.formattedWeaProdGasMmcfd
            : roundAndFormat(countryStatistics.recentGasProductionMmcfd)
        } (*)`
      : '0';
  },
};

export const REMAINING_LIQUID_RESERVES_COUNTRY_SUMMARY_ITEM = {
  label: 'Liquids Reserves',
  unit: '(MMbbl)',
  accessor: (countryStatistics) => {
    return countryStatistics && countryStatistics.remainingReservesLiquidsMmbbl
      ? roundAndFormat(countryStatistics.remainingReservesLiquidsMmbbl)
      : '0';
  },
};

export const REMAINING_GAS_RESERVES_COUNTRY_SUMMARY_ITEM = {
  label: 'Gas Reserves',
  unit: '(Bcf)',
  accessor: (countryStatistics) => {
    return countryStatistics && countryStatistics.remainingReservesGasBcf
      ? roundAndFormat(countryStatistics.remainingReservesGasBcf)
      : '0';
  },
};

export const NPV_COUNTRY_SUMMARY_ITEM = {
  label: 'NPV of All Assets',
  unit: '(US$MM)',
  accessor: (countryStatistics) => {
    return countryStatistics && countryStatistics.formattedTotalValuationMmusd
      ? countryStatistics.formattedTotalValuationMmusd
      : '0';
  },
};

export const REMAINING_CAPEX_COUNTRY_SUMMARY_ITEM = {
  label: 'Remaining Capex',
  unit: '(US$MM)',
  accessor: (countryStatistics) => {
    return countryStatistics && countryStatistics.formattedTotalRemainingCapexMmusd
      ? countryStatistics.formattedTotalRemainingCapexMmusd
      : '0';
  },
};

export const FISCAL_REGIME_COUNTRY_SUMMARY_ITEM = {
  label: 'Fiscal Regimes',
  accessor: ({ fiscalRegimes }) => {
    // const fiscalRegimes = countryStatistics.fiscalRegimes;
    return fiscalRegimes && fiscalRegimes.length > 0
      ? fiscalRegimes.map((fiscalRegime) => fiscalRegime.name).join(', ')
      : 'N/A';
  },
};

export const NOTE_COUNTRY_SUMMARY_ITEM = {
  label:
    '(*) The production shown is actual if reported within the last 6 months; otherwise we show our most recent estimate.',
  accessor: () => ' ',
};
