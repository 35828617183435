import React, { useEffect, useMemo, useState } from 'react';
import {
  TRANSACTIONS_AGGREGATE_BY_TITLE,
  TRANSACTIONS_AGGREGATE_BY_TYPE_ENUM,
  TRANSACTIONS_AGGREGATE_COMPARE_TO_ENUM,
  TRANSACTIONS_AGGREGATE_COMPARE_TO_OPTIONS,
  TRANSACTIONS_AGGREGATE_COMPARE_TO_RESULT_KEYS_ENUM,
  TRANSACTIONS_AGGREGATE_COMPARE_TO_TITLES_ENUM,
  TRANSACTIONS_AGGREGATE_FIELDS_ENUM,
  TRANSACTIONS_AGGREGATE_GRAPH_BY_OPTIONS,
  TRANSACTIONS_STACK_BY_OPTIONS,
} from '../../types';

import { MaGraph } from '../../../MaGraph';
import { getGraphLayout } from '../../../../helpers';
import { titleize } from '../../../../../../utils/helpers';
import { useGetChartData } from './hooks/useGetChartData';

const SESSION_STORAGE_KEY = 'MaOverviewValuationsChart';
const getInitialChartSettings = () => {
  const sessionSettings = sessionStorage.getItem(SESSION_STORAGE_KEY);
  if (sessionSettings) {
    return JSON.parse(sessionSettings);
  }

  return {
    stackBy: 'NONE', // default value must be non-null otherwise options wont render
    aggregateBy: TRANSACTIONS_AGGREGATE_BY_TYPE_ENUM.COUNTRY,
    compareTo: TRANSACTIONS_AGGREGATE_COMPARE_TO_ENUM.RESERVES,
  };
};

export const ValuationsChart = () => {
  const {
    stackBy: defaultStackBy,
    aggregateBy: defaultAggregateBy,
    compareTo: defaultCompareTo,
  } = getInitialChartSettings();

  const [stackBy, setStackBy] = useState(defaultStackBy);
  const [aggregateBy, setAggregateBy] = useState(defaultAggregateBy);
  const [compareTo, setCompareTo] = useState(defaultCompareTo);
  const fields = useMemo(
    () => [
      TRANSACTIONS_AGGREGATE_COMPARE_TO_RESULT_KEYS_ENUM[compareTo],
      TRANSACTIONS_AGGREGATE_FIELDS_ENUM.VALUATIONS,
    ],
    [compareTo],
  );

  useEffect(() => {
    // Save the current state to sessionStorage
    sessionStorage.setItem(
      SESSION_STORAGE_KEY,
      JSON.stringify({ stackBy, aggregateBy, compareTo }),
    );
  }, [stackBy, aggregateBy, compareTo]);

  const xConfig = {
    value: aggregateBy,
    setValue: setAggregateBy,
    options: TRANSACTIONS_AGGREGATE_GRAPH_BY_OPTIONS,
  };
  const yConfig = {
    value: compareTo,
    setValue: setCompareTo,
    options: TRANSACTIONS_AGGREGATE_COMPARE_TO_OPTIONS,
  };

  const stackByConfig = {
    value: stackBy,
    setValue: setStackBy,
    options: TRANSACTIONS_STACK_BY_OPTIONS,
  };

  const yAxisTitle = TRANSACTIONS_AGGREGATE_BY_TITLE;
  const altYAxisTitle = TRANSACTIONS_AGGREGATE_COMPARE_TO_TITLES_ENUM[compareTo];
  const graphLayout = getGraphLayout({
    showLegend: true,
    yAxisTitle: yAxisTitle,
    altYAxisTitle: altYAxisTitle,
  });

  const { data, loading, error } = useGetChartData({
    aggregateBy,
    stackBy,
    compareTo,
    fields,
  });

  if (error) return null;

  return (
    <MaGraph
      data={data}
      title={`NPV10 Valuation by ${titleize(aggregateBy)}`}
      loading={loading}
      xConfig={xConfig}
      yConfig={yConfig}
      stackByConfig={stackByConfig}
      graphLayout={graphLayout}
      maxXAxisTicks={aggregateBy === TRANSACTIONS_AGGREGATE_BY_TYPE_ENUM.YEAR ? 0 : 50}
    />
  );
};
