export const valuationsDriver = (driver) => {
  driver.defineSteps([
    {
      element: '#valuations-asset-filters',
      popover: {
        title: 'Asset Filters',
        description:
          'Filter the returned assets based on country, company, basin, shore status, hydrocarbon, or more advanced filters.',
        position: 'bottom-right',
      },
    },
    {
      element: '#valuations-advanced-filters',
      popover: {
        title: 'Advanced Filters',
        description: 'Filter based on NPV, Production, Epoch, Lithology, and Reservoir Depth.',
        position: 'bottom',
      },
    },
    {
      element: '#valuations-watch-list-checkbox',
      popover: {
        title: 'Watch List',
        description: 'Select to restrict assets to your Watchlist.',
        position: 'left-bottom',
      },
    },
    {
      element: '#valuations-ma-checkbox',
      popover: {
        title: 'M&A Opportunities',
        description: 'Select to restrict assets to M&A opportunities.',
        position: 'left-bottom',
      },
    },
    {
      element: '#valuations-save-filter-button',
      popover: {
        title: 'Save Filter',
        description: 'Save the existing filters into a custom filter that can be easily reloaded.',
        position: 'left-bottom',
      },
    },
    {
      element: '#valuations-contact-analyst',
      popover: {
        title: 'Contact Analyst',
        description: 'Contact the analyst responsible for coverage of this asset.',
        position: 'left',
      },
    },
    {
      element: '#valuations-country-downloads',
      popover: {
        title: 'Country Downloads',
        description:
          'Country level downloads: Our flagship country overview report, M&A Opportunities spreadsheet, offshore well tracker, licensing tracker, and the underlying GIS data for this country in .gpkg format.',
        position: 'left',
      },
    },
    {
      element: '#valuations-explore-more',
      popover: {
        title: 'Explore More',
        description:
          'Explore detailed asset level trends in our technical analysis dashboard, or find this asset in our mapping tools.',
        position: 'left',
      },
    },
    {
      element: '#valuations-save-to-watch-list-checkbox',
      popover: {
        title: 'Save to Watchlist',
        description: 'Save the asset to your Watchlist.',
        position: 'left',
      },
    },
    {
      element: '#valuations-view-asset-page-button',
      popover: {
        title: 'View Asset Page',
        description: 'View an asset specific landing page, and read the detailed asset report.',
        position: 'left',
      },
    },
    {
      element: '#valuations-asset-valuation-model-button',
      popover: {
        title: 'View Asset Valuation Model',
        description: 'Download the detailed asset valuation model or high-level asset report.',
        position: 'left',
      },
    },
  ]);
  // Start the introduction
  driver.start(0);
};

export const assetPageDriver = (driver) => {
  driver.defineSteps([
    {
      element: '#asset-page-view-asset-report-button',
      popover: {
        title: 'Asset Report',
        description: 'Now read the asset report directly in the site without downloading it.',
        position: 'top',
      },
    },
    {
      element: '#asset-page-similar-assets',
      popover: {
        title: 'Similar Assets',
        description: 'Explore the assets our research team has determined are most similar.',
        position: 'left',
      },
    },
    {
      element: '#asset-page-country-news',
      popover: {
        title: 'Country News',
        description: "Read Welligence's perspective in the latest relevant news coverage.",
        position: 'left',
      },
    },
  ]);

  // Start the introduction
  driver.start(0);
};
