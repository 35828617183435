import { GHG_CONTEXT, GHG_GOOGLE_ANALYTICS_TAG } from './constants';
import { defaultGhgBasicFilterTypes, defaultGhgFilterTypes } from '../Filters/filterTypes';

import Charts from './Charts';
import Portfolio from '../Portfolio';
import React from 'react';

const GHG = () => (
  <Portfolio
    basicFilterTypes={defaultGhgBasicFilterTypes}
    defaultFilterTypes={defaultGhgFilterTypes}
    context={GHG_CONTEXT}
    googleAnalyticsTag={GHG_GOOGLE_ANALYTICS_TAG}
    GraphingComponent={Charts}
  />
);

export default GHG;
