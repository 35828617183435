import React, { useEffect, useState } from 'react';

import { EntityRow } from '../EntityRow';
import { GroupRow } from './components/GroupRow';
import clsx from 'clsx';
import { useStyles } from './Group.style';

export const Group = ({
  group,
  groupChecked,
  setGroupIsChosen,
  entitiesGrouped,
  setOptionIsChosen,
  openGroupWhileTyping,
  groupsOpenedByDefault = false,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(groupsOpenedByDefault);
  useEffect(() => {
    if (openGroupWhileTyping) {
      setIsOpen(true);
    }
  }, [openGroupWhileTyping]);
  return (
    <div>
      <div>
        <GroupRow
          displayName={group.displayName}
          groupChecked={groupChecked}
          setOptionIsChosen={(e) => setGroupIsChosen(group.id, e)}
          openCloseCb={() => setIsOpen(!isOpen)}
          isOpen={isOpen}
          isClickable={entitiesGrouped.length > 0}
        />
      </div>
      <div className={clsx(classes.subList, { [classes.subListOpened]: isOpen })}>
        {entitiesGrouped.map(({ name, displayName, isChosen, id }) => (
          <EntityRow
            displayName={displayName || name}
            isChosen={!!isChosen}
            key={id}
            setOptionIsChosen={(e) => setOptionIsChosen(id, e)}
          />
        ))}
      </div>
    </div>
  );
};
