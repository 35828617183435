import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../../../../utils/constants';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import Button from '@mui/material/Button';
import { CURRENT_USER_FRIENDS } from '../../../../CurrentUserContext/operations';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid2';
import InputLabel from '@mui/material/InputLabel';
import { LOAD_FILTER } from '../LoadFilter/LoadFilter.query';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { SHARE_FILTER } from './ShareFilter.query';
import Select from '@mui/material/Select';
import SnackbarContext from '../../../../SnackbarContext';
import { defaultDimensions } from '../../../../../utils/analytics';
import { joinGqlErrorMessage } from '../../../../../utils/gqlErrors';
import makeStyles from '@mui/styles/makeStyles';
import { useCurrentUser } from '../../../../CurrentUserContext';

const useStyles = makeStyles((theme) => ({
  modal: {
    minHeight: '300px',
    height: '75%',
    maxHeight: '515px',
    width: '500px',
    paddingBottom: '10px',
    boxSizing: 'border-box',
  },
  container: {
    ...theme.palette.altScrollbarBlue,
    width: '250px',
    padding: '10px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  margin: {
    margin: '10px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'column',
  },
  button: {
    ...theme.palette.button,
    paddingTop: '8px',
    paddingBottom: '8px',
    margin: '10px 0 20px',
  },
}));

export const ShareFilter = () => {
  const classes = useStyles();
  const { currentUser } = useCurrentUser();
  const { setSnack } = useContext(SnackbarContext);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [selectedFriends, setSelectedFriends] = useState([]);

  const {
    loading: filterLoading,
    data: filterData,
    refetch: filterRefetch,
  } = useQuery(LOAD_FILTER, {
    fetchPolicy: 'network-only',
  });
  const {
    loading: friendsLoading,
    data: friendsData,
    refetch: friendsRefetch,
  } = useQuery(CURRENT_USER_FRIENDS, {
    fetchPolicy: 'network-only',
  });

  const sortAndCreateFriendList = (friends) => {
    let menuItemProps = friends.map((user) => {
      const isShared = !!user.sharedUserFilters.find((p) => p.id === selectedFilter.id);
      const isOwner = !isShared && !!user.userFilters.find((p) => p.id === selectedFilter.id);
      const friendName = `${user.firstName} ${user.lastName}`;
      const key = `${user.id}-${friendName}`;
      const companyTitle = user.admin
        ? 'Welligence (Not a Client)'
        : user.client
          ? user.client.companyTitle
          : '';
      const sharedSuffix = isShared ? ' (shared)' : isOwner ? ' (owner)' : '';
      const listItemTextPrimary = `${
        currentUser.admin && companyTitle ? `${companyTitle}: ` : ''
      }${friendName}${sharedSuffix}`;
      return {
        isShared,
        isOwner,
        key,
        listItemTextPrimary,
        user,
      };
    });
    menuItemProps.sort((a, b) => {
      if (a.listItemTextPrimary < b.listItemTextPrimary) return -1;
      if (a.listItemTextPrimary > b.listItemTextPrimary) return 1;
      return 0;
    });

    return menuItemProps.map(({ isShared, isOwner, key, listItemTextPrimary, user }) => (
      <MenuItem key={key} value={user} disabled={!!isShared || !!isOwner}>
        <ListItemText primary={listItemTextPrimary} />
      </MenuItem>
    ));
  };

  const friends = useMemo(() => {
    return !friendsLoading && friendsData
      ? sortAndCreateFriendList(friendsData.currentUserFriends)
      : null;
  }, [friendsData, selectedFilter]);

  const [shareFilter] = useMutation(SHARE_FILTER, {
    variables: { userFilterId: selectedFilter.id, users: selectedFriends.map((f) => f.id) },
    onCompleted: () => {
      setSnack({
        open: true,
        severity: 'success',
        message: `Successfully shared filter!`,
      });
    },
    onError: ({ graphQLErrors }) => {
      setSnack({
        open: true,
        message: joinGqlErrorMessage(graphQLErrors),
        severity: 'error',
      });
    },
  });

  const shareFilters = () => {
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: 'FilterAnalysis',
      event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
      event_label: 'share_filter',
      ...defaultDimensions,
    });
    shareFilter();
  };

  const refetch = () => {
    filterRefetch();
    friendsRefetch();
  };

  useEffect(() => {
    refetch && refetch();
  }, []);

  const handleSelectedFilter = (e) => {
    setSelectedFilter(e.target.value);
    setSelectedFriends([]);
  };

  const handleSelectedFriends = (e) => {
    const { value } = e.target;
    if (value[value.length - 1] === 'all') {
      setSelectedFriends(
        selectedFriends.length === friends.length
          ? []
          : friends.filter((f) => !f.props.disabled).map((f) => f.props.value),
      );
    } else {
      setSelectedFriends(value);
    }
  };

  return (
    <div className={classes.content}>
      <Grid className={classes.container}>
        <FormControl variant="filled" fullWidth className={classes.margin}>
          <InputLabel>Filters</InputLabel>
          <Select disableUnderline value={selectedFilter} onChange={handleSelectedFilter}>
            {!filterLoading && filterData
              ? filterData.userFilters.filters
                  .map((filter) => (
                    <MenuItem key={`share-filter-${filter.name}`} value={filter}>
                      {filter.name}
                    </MenuItem>
                  ))
                  .concat(
                    filterData.userFilters.sharedFilters.map((filter) => (
                      <MenuItem key={`share-filter-shared-filter-${filter.name}`} value={filter}>
                        {filter.name} (shared with you)
                      </MenuItem>
                    )),
                  )
              : null}
          </Select>
        </FormControl>
        <FormControl variant="filled" fullWidth className={classes.margin}>
          <InputLabel>Users</InputLabel>
          <Select
            disabled={!selectedFilter.filter}
            disableUnderline
            multiple
            value={selectedFriends}
            onChange={handleSelectedFriends}
            renderValue={(selected) =>
              selected.map((s) => `${s.firstName} ${s.lastName}`).join(', ')
            }
          >
            <MenuItem value="all">Select All</MenuItem>
            {friends}
          </Select>
        </FormControl>
      </Grid>

      <div className={classes.buttonWrapper}>
        <Button
          disabled={!selectedFilter.filter || !selectedFriends.length}
          variant="contained"
          className={classes.button}
          onClick={shareFilters}
        >
          Share Filter
        </Button>
      </div>
    </div>
  );
};
