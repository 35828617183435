import Panel from './Panel';
import createPanels, {
  createAssetPanels,
  createCountryDownloadsPanels,
  createSimilarAssetsPanels,
} from './createPanels';
import LeadPanel from './LeadAnalyst/leadPanel';

export default Panel;
export {
  createPanels,
  createAssetPanels,
  createCountryDownloadsPanels,
  createSimilarAssetsPanels,
  LeadPanel,
};
