import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '5px',
    ...theme.palette.text,
    wordWrap: 'normal',
    whiteSpace: 'pre-line',
  },
  label: {
    color: '#507180',
  },
}));

/**
 * Component to render a label and multiple values with actions:
 * @param {Object} props
 * @param {String} props.label Label to be displayed
 * @param {Array} props.valuesAndActions Array of objects with the following structure:
 * {
 *  value: String,
 *  action?: Function
 * }
 * @returns {React.Component}
 */
export default ({ label, valuesAndActions }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.root} variant="body1" component="p">
      <Typography className={classes.label} variant="body1" component="span">
        {label}
      </Typography>
      {valuesAndActions.map(({ value, action }, i) => (
        <span key={`${label}-${i}`}>
          {i > 0 && ', '}
          &nbsp;
          {action ? (
            <a href="#" onClick={action}>
              {value}
            </a>
          ) : (
            value
          )}
        </span>
      ))}
    </Typography>
  );
};
