// ruler objects
// GeoJSON object to hold our measurement features
export const geojson = {
  type: 'FeatureCollection',
  features: [],
};
// Used to draw a line between points
export const linestring = {
  type: 'Feature',
  geometry: {
    type: 'LineString',
    coordinates: [],
  },
};
