import makeStyles from '@mui/styles/makeStyles';

export const useButtonListStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    color: theme.palette.baseColors.deepSeaNavy.c100,
    borderBottom: `1px solid ${theme.palette.baseColors.deepSeaNavy.c100}`,
    fontSize: theme.fontSizes.smaller,
  },
  selectedLink: {
    ...theme.palette.accentText,
    ...theme.menuButtonFontStyles,
  },
}));
