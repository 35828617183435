import { getCurrentPath } from '../../../utils/constants';

// get map layer local storage key
export const getMapLayerSessionStorageKey = () => {
  const currentPath = getCurrentPath(window.location.pathname);
  return `${currentPath}_mapLayer`;
};

// get user preference map layer
export const getUserPreferenceMapLayer = (defaultLayer, multiple) => {
  let mapLayer = sessionStorage.getItem(getMapLayerSessionStorageKey());
  let defaultValue = '';
  /* Initially as is on the Country Overviews page, the user preferred 
    map layer (from session storage) is a single layer type string. 
    On the CCUS page, a user now has the ability to toggle on and off 
    multiple map layers stored in a JSON. Maybe we'd like to update the 
    country overviews to follow this format as well even though it is 
    limited to a single layer.*/
  if (multiple) {
    mapLayer = JSON.parse(mapLayer);
    defaultValue = [];
  }
  return mapLayer || defaultLayer || defaultValue;
};

// set user preference map Layer
export const setUserPreferenceMapLayer = (newLayer) => {
  sessionStorage.setItem(getMapLayerSessionStorageKey(), newLayer);
};
