/* eslint no-useless-escape: 0 */
/* eslint no-use-before-define: 0 */
import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import makeStyles from '@mui/styles/makeStyles';
import { SECURE_REPORT_DOWNLOAD_QUERY } from '../../../Valuations/AssetList/operations';
import { REPORT_TYPES } from '../../constants';

const useStyles = makeStyles(() => ({
  assetSummaryOverview: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

export default ({ asset, setReportsLoaded, setSnack }) => {
  const classes = useStyles();
  const [renderedAssetId, setRenderedAssetId] = useState(null);
  const [renderedReport, setReport] = useState(null);
  const [removedFacilityProductions, setRemovedFacilityProductions] = useState(false);

  const [getSecureDownload, { loading: download_html_loading }] = useLazyQuery(
    SECURE_REPORT_DOWNLOAD_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const { url, downloadMessage } = data.secureReportDownload;
        if (url) {
          setSnack({
            open: true,
            message: downloadMessage,
            severity: 'success',
          });

          fetch(url)
            .then((res) => {
              if (res.ok) {
                return res.text();
              } else {
                throw new Error('Not 2xx response');
              }
            })
            .then((html) => {
              // Client-side cleaning. Temporary?
              // Remove the "triangle" and "footer" image elements
              let processedHtml = html.replace(/^<img.*(triangle|footer|pdf-logo).*\" \/>$/gm, '');
              processedHtml = processedHtml.replace(/^<div class=\'page-number.*\/div>$/gm, '');
              // auto-scale .container width
              processedHtml = processedHtml.replace(
                /\.container{padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto}/gm,
                '.container{padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto;width:100%}',
              );
              // #content
              processedHtml = processedHtml.replace(
                /#content{width:1280px !important;height:720px !important;/gm,
                '#content{',
              );
              // remove @media (min-width)
              processedHtml = processedHtml.replace(
                /@media \(min-width: 768px\){\.container{width:750px}}@media \(min-width: 992px\){\.container{width:970px}}@media \(min-width: 1200px\){\.container{width:1170px}}/gm,
                '',
              );
              // let map images auto-scale
              processedHtml = processedHtml.replace(
                /\.map-image{width:590px;height:519px;/gm,
                '.map-image{',
              );
              // pad "start-cover" page
              processedHtml = processedHtml.replace(
                /\.start-cover{text-align:right;padding-top:350px;padding-left:200px;padding-right:210px;/gm,
                '.start-cover{text-align:right;',
              );
              processedHtml = processedHtml.replace(
                /\.sold-asset{margin-left:763px;margin-right:210px}/gm,
                '.sold-asset{text-align:right;}',
              );
              // let "end" page auto-scale width
              processedHtml = processedHtml.replace(
                /\.end{text-align:right;width:1280px !important;/gm,
                '.end{text-align:right;',
              );
              // remove font-size rule from base html
              processedHtml = processedHtml.replace(/html{font-size:10px;/gm, 'html{');

              const el = document.createElement('html');
              el.innerHTML = processedHtml;
              const body = el.getElementsByTagName('body')[0];
              Object.values(body.getElementsByClassName('start')).forEach((start) => {
                start.remove();
              });
              Object.values(body.getElementsByClassName('disclaimer')).forEach((disclaimer) => {
                disclaimer.remove();
              });
              // Yes, source has a typo in this class name
              Object.values(body.getElementsByClassName('faclitity-productions')).forEach(
                (facilityProduction) => {
                  facilityProduction.remove();
                },
              );
              // In the event the class name is "corrected"
              Object.values(body.getElementsByClassName('facility-productions')).forEach(
                (facilityProduction) => {
                  facilityProduction.remove();
                },
              );
              Object.values(body.getElementsByClassName('end')).forEach((end) => {
                end.remove();
              });
              processedHtml = el.outerHTML;
              setReport(processedHtml);
              setRenderedAssetId(asset.legacyId);
              setRemovedFacilityProductions(false);
            })
            .catch(() =>
              setReport(
                'This report could not be rendered. Please try downloading the Asset Report to view.',
              ),
            );
        } else {
          setReport('This report is not available.');
        }
      },
      onError: (error) => {
        setSnack({
          open: true,
          message: error.message,
          severity: 'error',
        });
      },
    },
  );

  const getReportUrl = () => {
    getSecureDownload({
      variables: {
        assetLegacyId: asset.legacyId,
        isoCode: asset.country.isoCode,
        reportType: REPORT_TYPES.ENHANCED_HTML_REPORT,
      },
    });
  };

  useEffect(() => {
    if (asset !== null && asset.legacyId !== renderedAssetId) {
      getReportUrl();
    }
  }, [asset]);

  useEffect(() => {
    let start = document.getElementById('start');
    if (start) start.remove();
    let disclaimer = document.getElementById('disclaimer');
    if (disclaimer) disclaimer.remove();
    let facilityProduction = document.getElementById('faclitity-productions');
    while (facilityProduction) {
      facilityProduction.remove();
      facilityProduction = document.getElementById('faclitity-productions');
    }
    facilityProduction = document.getElementById('facility-productions');
    while (facilityProduction) {
      facilityProduction.remove();
      facilityProduction = document.getElementById('facility-productions');
    }
    let end = document.getElementById('end');
    if (end) end.remove();
    setRemovedFacilityProductions(true);
  }, [removedFacilityProductions]);

  const assetOverviewReportHtml = () => {
    if (!download_html_loading && renderedReport && asset && asset.legacyId === renderedAssetId) {
      setReportsLoaded(true);
      return { __html: renderedReport };
    } else {
      return null;
    }
  };

  return (
    <div className={classes.assetSummaryOverview}>
      <div dangerouslySetInnerHTML={assetOverviewReportHtml()} />
    </div>
  );
};
