import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(() => ({
  label: {
    justifyContent: 'flex-end',
    marginLeft: 0,
  },
}));

export default ({ item, onClick, value }) => {
  const classes = useStyles();

  return (
    <FormControl component="fieldset">
      <RadioGroup
        value={value}
        onClick={() => {
          onClick(!value, item.key);
        }}
      >
        <FormControlLabel
          className={classes.label}
          labelPlacement="start"
          checked={value}
          value={true}
          control={<Checkbox />}
          label={<Typography variant="overline">{item.label}</Typography>}
        />
      </RadioGroup>
    </FormControl>
  );
};
