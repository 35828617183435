import { formatDateShort } from '../../../utils/helpers';

export const NAME_LEASE_SUMMARY_ITEM = {
  label: 'Lease',
  accessor: 'displayName',
};

export const COUNTRY_LEASE_SUMMARY_ITEM = {
  label: 'Country',
  accessor: 'US Gulf of Mexico',
};

export const PARTICIPATION_LEASE_SUMMARY_ITEM = {
  label: 'Participation',
  accessor: (l) => {
    if (!(l.groupedParticipations && l.currentParticipationDates)) return '';
    const { groupedParticipations } = l;
    const groups = Object.keys(l.currentParticipationDates).sort();
    if (groups.length === 0) return '';
    const participants = [];

    groups.forEach((group) => {
      const currDate = l.currentParticipationDates[group];
      const parts = groupedParticipations[group]
        .filter((p) => p.effectiveDate === currDate)
        .sort(
          (a, b) =>
            b.percentStake - a.percentStake || a.company?.name?.localeCompare(b.company?.name),
        );

      const participantGroup = [];
      parts.forEach((p) => {
        if (p.company) {
          const { name } = p.company;
          const percent = `(${p.formattedPercentStake})`;
          p.isCompanyOperator
            ? participantGroup.unshift(`${name} (Operator) ${percent}`)
            : participantGroup.push(`${name} ${percent}`);
        }
      });
      if (group === 'noGroup') {
        participants.push(`\n${participantGroup.join(', ')}`);
      } else {
        participants.push(`\nGroup ${group}: ${participantGroup.join(', ')}`);
      }
    });
    return participants.join('');
  },
};

export const ABANDONMENT_LIABILITY_LEASE_SUMMARY_ITEM = {
  label: 'Abandonment Liability',
  unit: '(US$MM)',
  accessor: 'formattedLiabilityMmusd',
};

export const BID_AMOUNT_LEASE_SUMMARY_ITEM = {
  label: 'Bid Amount',
  unit: '(US$MM)',
  accessor: 'formattedBidAmountMmusd',
};

export const EFFECTIVE_DATE_LEASE_SUMMARY_ITEM = {
  label: 'Lease Effective Date',
  accessor: (l) => formatDateShort(l?.effectiveDate),
};

export const PRIMARY_TERM_LEASE_SUMMARY_ITEM = {
  label: 'Primary Term',
  unit: '(years)',
  accessor: 'primaryTermYears',
};

export const EXPIRATION_DATE_LEASE_SUMMARY_ITEM = {
  label: 'Lease Expiration Date',
  accessor: (l) => formatDateShort(l?.expirationDate),
};

export const STATUS_LEASE_SUMMARY_ITEM = {
  label: 'Lease Status',
  accessor: (l) => l?.leaseStatus?.name,
};

export const LIQUIDS_PRODUCTION_LEASE_SUMMARY_ITEM = {
  label: 'Most Recent Liquids Production',
  unit: (l) => {
    const date = l.recentProductionDate ? formatDateShort(l.recentProductionDate) : '-';
    return `(${date}) (bbl/d)`;
  },
  accessor: (l) =>
    l.formattedRecentProductionLiquidsBbld ? l.formattedRecentProductionLiquidsBbld : '',
};

export const GAS_PRODUCTION_LEASE_SUMMARY_ITEM = {
  label: 'Most Recent Gas Production',
  unit: (l) => {
    const date = l.recentProductionDate ? formatDateShort(l.recentProductionDate) : '-';
    return `(${date}) (MMcf/d)`;
  },
  accessor: (l) => (l.formattedRecentProductionGasMmcfd ? l.formattedRecentProductionGasMmcfd : ''),
};
