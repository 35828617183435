import CheckList from '../CheckList';
import Grid from '@mui/material/Grid2';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  checkListItem: {
    height: '100%',
    width: '30%',
    padding: '5px',
  },
  checkListRoot: {
    height: 'calc(100% - 30.25px)',
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100% - 27.6px)',
    },
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 24.94px)',
    },
  },
  subHeader: {
    color: theme.palette.baseColors.fireOrange.c100,
  },
}));

export default ({ checkListItems, filter, filters, setFilter }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.checkListItem} container direction="column" size={12}>
      <Tooltip title={filter.name}>
        <Typography variant="overline" noWrap>
          {filter.name}
        </Typography>
      </Tooltip>
      <CheckList
        callback={(value) => setFilter(value, filter.key)}
        checked={filters[filter.key]}
        includeAll
        selectAllOnNoneChecked
        list={checkListItems[filter.list]}
        nameKey={filter.nameKey}
        styles={classes}
        customItems={filter.customItems}
        getParent={filter.getParent}
        parentItemsToInclude={filter.parentItemsToInclude}
        combinedItems={filter.combinedItems}
      />
    </Grid>
  );
};
