import { gql } from '@apollo/client';

export const WATCH_LIST_QUERY = gql`
  query WatchList {
    watchList {
      id
      displayName
      legacyId
      name
      reportUpdatedDate
      country {
        displayName
        name
        isoCode
      }
    }
  }
`;

export const ADD_TO_WATCH_LIST_MUTATION = gql`
  mutation AddToWatchList($assetLegacyId: ID!, $assetIsoCode: String!) {
    addToWatchList(assetLegacyId: $assetLegacyId, assetIsoCode: $assetIsoCode) {
      watchList {
        assetLegacyId
        assetIsoCode
      }
    }
  }
`;

export const REMOVE_FROM_WATCH_LIST_MUTATION = gql`
  mutation RemoveFromWatchList($assetLegacyId: ID!, $assetIsoCode: String!) {
    removeFromWatchList(assetLegacyId: $assetLegacyId, assetIsoCode: $assetIsoCode) {
      watchList {
        assetLegacyId
        assetIsoCode
      }
    }
  }
`;
