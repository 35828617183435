import React, { useState, createContext } from 'react';
import GraphControls from '../../../Graphs/GraphControls';
import { CCUS_GOOGLE_ANALYTICS_TAG } from '../../constants';
import {
  INDUSTRY_TYPE,
  TOTAL_CO2_EMISSIONS_SIZE_TYPE,
  mapChartTypes,
  mapSizeTypes,
} from '../utils';
import { layerProps } from './constants';
import { getUserPreferenceMapLayer } from '../../../Map/Settings/utils';

const MapContext = createContext();

export const MapProvider = ({ children }) => {
  const [graphType, setGraphType] = useState(INDUSTRY_TYPE);
  const [openControlsModal, setOpenControlsModal] = useState(false);
  const [sizeType, setSizeType] = useState(TOTAL_CO2_EMISSIONS_SIZE_TYPE.value);
  const [mapLayers, setMapLayers] = useState(
    getUserPreferenceMapLayer(layerProps.defaultLayer, layerProps.multiple),
  );

  return (
    <MapContext.Provider
      value={{
        graphType,
        mapLayers,
        setMapLayers,
        setOpenControlsModal,
        sizeType,
        title: 'CCUS Map',
      }}
    >
      {children}
      <GraphControls
        googleAnalyticsTag={CCUS_GOOGLE_ANALYTICS_TAG}
        graphType={graphType}
        open={openControlsModal}
        setGraphType={setGraphType}
        setOpen={setOpenControlsModal}
        setSizeType={setSizeType}
        sizeType={sizeType}
        sizeTypes={mapSizeTypes}
        types={mapChartTypes}
      />
    </MapContext.Provider>
  );
};

export const MapConsumer = MapContext.Consumer;

export default MapContext;
