import React from 'react';
import Graph from './graph';
import { BAR_CHART_CONTEXT } from './constants';

// barColor - color of bars on chart
// layout - any additional layout properties or overwrite default

export default ({ data, title, xlabel, ylabel, layout, loading, onUpdate, onRelayout, maxY }) => {
  return (
    <Graph
      data={data}
      layout={{
        annotations: [
          {
            xref: 'paper',
            yref: 'paper',
            x: 1,
            xanchor: 'right',
            y: 1,
            yanchor: 'bottom',
            text: 'Source: Welligence Energy Analytics',
            showarrow: false,
            font: {
              color: 'lightgrey',
              size: 10,
            },
          },
        ],
        ...layout,
        xaxis: {
          tickangle: '45',
          ...layout.xaxis,
        },
      }}
      config={{ modeBarButtonsToRemove: ['pan2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d'] }}
      title={title}
      xlabel={xlabel}
      ylabel={ylabel}
      loading={loading}
      onRelayout={onRelayout}
      onUpdate={onUpdate}
      maxY={maxY}
      context={BAR_CHART_CONTEXT}
    />
  );
};
