import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '../../RadioGroup';
import { hydrocarbonTypes } from '../../PortfolioAnalysis/PortfolioGraphing/utils';

const useStyles = makeStyles((theme) => ({
  radioGroupRoot: {
    padding: 0,
  },
  radioGroup: {
    justifyContent: 'space-between',
  },
  label: {
    ...theme.palette.alternateText,
    fontWeight: 'bold',
  },
  radioItemLabel: {
    marginLeft: '-10px',
    '& > .MuiFormControlLabel-label': {
      fontWeight: 'bold',
    },
  },
  radioItemControl: {
    color: theme.palette.baseColors.skyBlue.c75,
    '&.Mui-checked': {
      color: theme.palette.baseColors.skyBlue.c75,
    },
  },
}));

export default ({ handleSetState, hydrocarbonType, selectedCheckboxAggregates, value }) => {
  const classes = useStyles();

  return (
    <>
      {hydrocarbonType && (
        <FormControl style={{ width: '100%' }}>
          <RadioGroup
            itemIsDisabledParams={selectedCheckboxAggregates}
            handleToggle={(e) => handleSetState(e.target.value, 'hydrocarbonType')}
            items={hydrocarbonTypes}
            label="Hydrocarbon"
            styles={classes}
            value={value}
          />
        </FormControl>
      )}
    </>
  );
};
