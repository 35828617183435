import { formatDateShort, roundAndFormat } from '../../../utils/helpers';

export const NAME_EA_WELL_SUMMARY_ITEM = {
  label: 'Well Name',
  accessor: 'name',
};

export const WELL_TYPE_EA_WELL_SUMMARY_ITEM = {
  label: 'Type',
  accessor: 'formattedWellType',
};

export const PROJECT_NAME_EA_WELL_SUMMARY_ITEM = {
  label: 'Prospect Name',
  accessor: (w) => {
    // Don't show prospect name for appraisal wells:
    if (w.wellType === 'appraisal') {
      return '';
    }
    return w.prospect;
  },
};

export const FIELD_NAME_EA_WELL_SUMMARY_ITEM = {
  label: 'Field',
  accessor: (w) => {
    // Don't show prospect name for exploration wells:
    if (w.wellType === 'exploration') {
      return '';
    }
    return w.fieldName;
  },
};

export const BLOCK_NAME_EA_WELL_SUMMARY_ITEM = {
  label: 'Block',
  accessor: 'blockName',
};

export const HYDROCARBON_TARGET_EA_WELL_SUMMARY_ITEM = {
  label: 'Hydrocarbon Target',
  accessor: 'formattedHydrocarbonTarget',
};

export const PROSPECT_SIZE_EA_WELL_SUMMARY_ITEM = {
  label: 'Prospect Size',
  accessor: 'formattedProspectSize',
};

export const IMPACT_EA_WELL_SUMMARY_ITEM = {
  label: 'Impact',
  accessor: 'formattedImpact',
};

export const GEOLOGICAL_TARGET_EA_WELL_SUMMARY_ITEM = {
  label: 'Geological Target',
  accessor: 'geologicalTarget',
};

export const HOST_FACILITY_EA_WELL_SUMMARY_ITEM = {
  label: 'Potential Host Facility',
  accessor: 'hostFacilityName',
};

export const COMMITMENT_EA_WELL_SUMMARY_ITEM = {
  label: 'Commitment',
  accessor: (w) => (w.isCommitment ? 'Yes' : 'No'),
};

export const COORDINATES_EA_WELL_SUMMARY_ITEM = {
  label: 'Coordinates',
  accessor: (w) => {
    const { estimatedCoordinates } = w;
    if (estimatedCoordinates === null) return null;
    return estimatedCoordinates ? 'Estimated Location' : 'Reported location';
  },
};

export const DRILL_START_EA_WELL_SUMMARY_ITEM = {
  label: 'Drill Start',
  accessor: (w) => formatDateShort(w?.drillStart),
};

export const EXPECTED_SPUD_EA_WELL_SUMMARY_ITEM = {
  label: 'Expected Spud',
  // Hide if drill start is populated:
  accessor: (w) => !w?.drillStart && w?.expectedSpudQuarter,
};

export const ESTIMATED_COST_EA_WELL_SUMMARY_ITEM = {
  label: 'Estimated Cost',
  unit: '(US$MM)',
  accessor: (w) => w?.formattedEstimatedCost,
};

export const TARGET_DEPTH_EA_WELL_SUMMARY_ITEM = {
  label: 'Target Depth',
  unit: '(m)',
  accessor: (w) => roundAndFormat(w?.targetDepthM, 2),
};

export const WATER_DEPTH_EA_WELL_SUMMARY_ITEM = {
  label: 'Water Depth',
  unit: '(m)',
  accessor: (w) => roundAndFormat(w?.waterDepthM, 2),
};

export const RIG_NAME_EA_WELL_SUMMARY_ITEM = {
  label: 'Rig Name',
  accessor: 'rigName',
};
