import { useMemo, useState } from 'react';
import { useDefaultFilterOptions } from '../../helpers/useDefaultFilterOptions';
import { useDataOnUpdate } from '../../helpers/useDataOnUpdate';
import { getChart1Data } from './helpers/getChart1Data';
import { getCalculatePageContentDataCb } from './helpers/getCalculatePageContentDataCb';
import { calculateMedian } from './helpers/calculateMedian';
import { COST_GRAPH_TYPES } from '../../helpers/countGraphConstatns';
import { getCostCountChartData } from '../../helpers/getCostCountChartData';
import { SECONDARY_Y_AXIS_TYPES } from '../../helpers/secondaryYAxisConstatns';
import {
  SESSION_STORAGE_KEY_MA_OV_CH1_STACK,
  SESSION_STORAGE_KEY_MA_OV_CH1_SY,
  SESSION_STORAGE_KEY_MA_OV_CH1_X,
  SESSION_STORAGE_KEY_MA_OV_CH2_X,
} from '../../../../utils/constants';
import { STACK_BY_TYPES } from '../../helpers/stackByConstatnts';
import { useMaFilterContext } from '../../helpers/MaFilterProvider';

const getAllYears = (from, to) => {
  const years = [];
  let currentYear = from;
  while (currentYear <= to) {
    years.push(currentYear);
    currentYear += 1;
  }
  return years;
};

export const useOverviewController = (data) => {
  const pageContentData = {
    assetNamesByYear: {},
    assetNamesByX: {},
    sum: {},
    sumCompany: {},
    count: {},
    countByType: {},
    xAxisList: {},
    chart2XAxisList: {},
    considerationBox: [],
    dealIds: [],
    dealWorkingInterestReserves: {},
    dealWorkingInterestValuations: {},
    dealWorkingInterestProductions: {},
    graph1X: {},
    graph1Y: {},
    secondaryYChart1: {},
  };

  const [showPlotlyLegendChart1, setShowPlotlyLegendChart1] = useState(true);
  const [showPlotlyLegendChart2, setShowPlotlyLegendChart2] = useState(true);

  const [stackBy, setStackBy] = useState(
    sessionStorage.getItem(SESSION_STORAGE_KEY_MA_OV_CH1_STACK) || STACK_BY_TYPES.SELLER,
  );
  const [graphXType, setGraphXType] = useState(
    sessionStorage.getItem(SESSION_STORAGE_KEY_MA_OV_CH1_X) || COST_GRAPH_TYPES.YEAR,
  );
  const [graphYSecondaryType, setGraphYSecondaryType] = useState(
    sessionStorage.getItem(SESSION_STORAGE_KEY_MA_OV_CH1_SY) ||
      SECONDARY_Y_AXIS_TYPES.RESERVES_ACQUIRED,
  );

  const [graph2Type, setGraph2Type] = useState(
    sessionStorage.getItem(SESSION_STORAGE_KEY_MA_OV_CH2_X) || COST_GRAPH_TYPES.YEAR,
  );
  const { isLoading } = useDefaultFilterOptions(data);

  const updateStackBy = (value) => {
    setStackBy(value);
    sessionStorage.setItem(SESSION_STORAGE_KEY_MA_OV_CH1_STACK, value);
  };

  const updateGraphXType = (value) => {
    setGraphXType(value);
    sessionStorage.setItem(SESSION_STORAGE_KEY_MA_OV_CH1_X, value);
  };

  const updateGraphYSecondaryType = (value) => {
    setGraphYSecondaryType(value);
    sessionStorage.setItem(SESSION_STORAGE_KEY_MA_OV_CH1_SY, value);
  };

  const updateGraph2Type = (value) => {
    setGraph2Type(value);
    sessionStorage.setItem(SESSION_STORAGE_KEY_MA_OV_CH2_X, value);
  };

  const calculatePageContentData = getCalculatePageContentDataCb(
    graphXType,
    graph2Type,
    stackBy,
    graphYSecondaryType,
  );

  const { pageContentDataResult, filters } = useDataOnUpdate(
    data,
    pageContentData,
    calculatePageContentData,
    graphXType,
    graph2Type,
    stackBy,
    graphYSecondaryType,
  );

  //COUNT CHART SOURCE DATA AND AVAILABLE FILTER OPTIONS
  const overviewContentData = useMemo(() => {
    if (isLoading) return {};
    const {
      sum,
      count,
      assetNamesByYear,
      assetNamesByX,
      countByType,
      xAxisList,
      chart2XAxisList,
      considerationBox,
      dealWorkingInterestReserves,
      dealWorkingInterestProductions,
      dealWorkingInterestValuations,
      dealIds,
      sumCompany,
      graph1X,
      graph1Y,
      secondaryYChart1,
    } = pageContentDataResult;
    const { chosenYears } = useMaFilterContext();

    const allYearsForCharts = getAllYears(chosenYears.from, chosenYears.to);
    const allXAxisList = Object.keys(xAxisList).sort((a, b) => a - b);

    let assetNames = [];
    let totalCount = 0;

    for (let i = 0; i < allYearsForCharts.length; i++) {
      const year = allYearsForCharts[i];
      const assetName = Object.keys(assetNamesByYear[year] || {}).join(', ');
      const assetNameTrimmed =
        assetName.length > 30 ? assetName.substring(0, 30) + '..' : assetName;
      assetNames.push(assetNameTrimmed);
      totalCount += count[year];
    }
    const considerationMedian = calculateMedian(Object.values(considerationBox));

    const workingInterestReservesMedian = calculateMedian(
      Object.values(dealWorkingInterestReserves).filter((v) => !!v),
    );

    const dealWorkingInterestValuationsKeys = Object.keys(dealWorkingInterestValuations);

    const weaEstimatedReservesAcquisitionCosts = [];
    const weaEstimatedProductionAcquisitionCosts = [];
    for (let i = 0; i < dealWorkingInterestValuationsKeys.length; i++) {
      let dealId = dealWorkingInterestValuationsKeys[i];

      if (dealWorkingInterestReserves[dealId] > 0) {
        weaEstimatedReservesAcquisitionCosts.push(
          dealWorkingInterestValuations[dealId] / dealWorkingInterestReserves[dealId],
        );
      }

      if (dealWorkingInterestProductions[dealId] > 0) {
        weaEstimatedProductionAcquisitionCosts.push(
          (dealWorkingInterestValuations[dealId] * 1e6) / dealWorkingInterestProductions[dealId],
        );
      }
    }

    const weaEstimatedReservesAcquisitionCostsMedian = calculateMedian(
      weaEstimatedReservesAcquisitionCosts,
    );

    const weaEstimatedProductionAcquisitionCostsMedian = calculateMedian(
      weaEstimatedProductionAcquisitionCosts,
    );

    const dealCount = dealIds.length;

    // FIRST CHART START
    const {
      data: chart1Data,
      name: chart1Name,
      stackByWarning,
    } = getChart1Data(
      graphXType,
      allYearsForCharts,
      sum,
      assetNames,
      allXAxisList,
      assetNamesByX,
      sumCompany,
      graph1X,
      graph1Y,
      stackBy,
      secondaryYChart1,
      graphYSecondaryType,
    );

    // SECOND CHART START
    const chart2Data = getCostCountChartData(countByType, chart2XAxisList, graph2Type);

    return {
      chart1Data,
      chart1Name,
      totalCount,
      considerationMedian,
      workingInterestReservesMedian,
      dealCount,
      chart2Data,
      graphXType,
      graph2Type,
      weaEstimatedReservesAcquisitionCostsMedian,
      weaEstimatedProductionAcquisitionCostsMedian,
      stackByWarning,
    };
  }, [isLoading, pageContentDataResult, graphXType, graph2Type]);

  return {
    isLoading,
    filters,
    overviewContentData,
    showPlotlyLegendChart1,
    setShowPlotlyLegendChart1,
    showPlotlyLegendChart2,
    setShowPlotlyLegendChart2,
    stackBy,
    updateStackBy,
    graphYSecondaryType,
    updateGraphYSecondaryType,
    updateGraphXType,
    updateGraph2Type,
  };
};
