import { REGIONS_QUERY, COUNTRIES_QUERY } from './operations';

export const filterTypes = [
  {
    name: 'regions',
    label: 'REGION',
    query: REGIONS_QUERY,
    menuItems: null,
    optionKey: 'displayName',
    runQueryAfterOnChange: false,
    queryVariable: null,
    multiple: true,
  },
  {
    name: 'countries',
    label: 'COUNTRY / AREA',
    query: COUNTRIES_QUERY,
    menuItems: null,
    optionKey: 'displayName',
    runQueryAfterOnChange: false,
    queryVariable: null,
    multiple: true,
  },
];
