import { Box, Button } from '@mui/material';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TABLE_COLUMNS, TABLE_PAGE_SIZE_OPTIONS } from './types';

import { DataGrid } from '@mui/x-data-grid';
import SnackbarContext from '../../../SnackbarContext';
import { useDownloadMaTransactions } from './hooks/useDownloadMaTransactions';
import { useGetMaTransactions } from './hooks/useGetMaTransactions';
import { useStyles } from './styles';

const SESSION_STORAGE_KEY = 'MaTransactionTableSettings';
const getInitialTableSettings = () => {
  const sessionSettings = sessionStorage.getItem(SESSION_STORAGE_KEY);
  if (sessionSettings) {
    const { sortModel, paginationModel, columnVisibilityModel } = JSON.parse(sessionSettings);
    return { sortModel, paginationModel, columnVisibilityModel };
  }

  return {
    sortModel: [{ field: 'effectiveDate', sort: 'desc' }],
    paginationModel: { page: 0, pageSize: TABLE_PAGE_SIZE_OPTIONS[1] },
    columnVisibilityModel: {},
  };
};

export const TransactionTable = () => {
  const classes = useStyles();

  const { setSnack } = useContext(SnackbarContext);
  const [downloading, setDownloading] = useState(false);

  const {
    sortModel: defaultSortModel,
    paginationModel: defaultPaginationModel,
    columnVisibilityModel: defaultColumnVisibilityModel,
  } = getInitialTableSettings();

  const [sortModel, setSortModel] = useState(defaultSortModel);
  const [paginationModel, setPaginationModel] = useState(defaultPaginationModel);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(defaultColumnVisibilityModel);

  const { downloadMaTransactions } = useDownloadMaTransactions();
  const { data, totalCount, loading, error } = useGetMaTransactions(
    paginationModel?.page,
    paginationModel?.pageSize,
    sortModel[0]?.sort,
    sortModel[0]?.field,
  );

  // Memoize the rowCount to prevent the table from re-setting to 0 when the totalCount changes
  // Reference: https://mui.com/x/react-data-grid/pagination/
  const rowCountRef = useRef(totalCount || 0);
  const rowCount = useMemo(
    () => (rowCountRef.current = totalCount || rowCountRef.current),
    [totalCount],
  );

  useEffect(() => {
    // Save the current state to sessionStorage
    sessionStorage.setItem(
      SESSION_STORAGE_KEY,
      JSON.stringify({ sortModel, paginationModel, columnVisibilityModel }),
    );
  }, [paginationModel, sortModel, columnVisibilityModel]);

  const onDownload = useCallback(async () => {
    setDownloading(true);
    setSnack({
      open: true,
      message: 'Your download should begin shortly.',
      severity: 'success',
    });

    const { ok, errors } = await downloadMaTransactions();

    if (!ok) {
      setSnack({
        open: true,
        message: errors[0]?.message || "We're sorry, but something went wrong.",
        severity: 'error',
      });
    }
    setDownloading(false);
  }, [downloadMaTransactions, setDownloading, setSnack]);

  if (error) return null;

  return (
    <Box>
      <Button
        className={classes.download}
        variant="contained"
        onClick={onDownload}
        disabled={downloading}
      >
        Download Data
      </Button>
      <Box className={classes.container}>
        <Box className={classes.tableWrapper}>
          <DataGrid
            className={classes.table}
            rows={data}
            columns={TABLE_COLUMNS}
            rowCount={rowCount}
            loading={loading}
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={TABLE_PAGE_SIZE_OPTIONS}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={setColumnVisibilityModel}
            slotProps={{
              // Add comma separator to numbers in the pagination label
              pagination: {
                labelDisplayedRows: ({ from, to, count }) =>
                  `${from.toLocaleString()} - ${to.toLocaleString()} of ${count.toLocaleString()}`,
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
