export const getAllYears = (yearsForCharts) => {
  let allYearsForCharts = [];
  const yearsForChartSorted = Object.keys(yearsForCharts).sort((a, b) => a - b);
  if (yearsForChartSorted.length > 0) {
    const from = parseInt(yearsForChartSorted[0]);
    const to = parseInt(yearsForChartSorted[yearsForChartSorted.length - 1]);

    for (let i = 0; from + i <= to; i++) {
      allYearsForCharts.push(from + i);
    }
  }
  return allYearsForCharts;
};
