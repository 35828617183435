import { useLazyQuery } from '@apollo/client';
import { SECURE_REPORT_DOWNLOAD_QUERY } from '../../../../../../Valuations/AssetList/operations';
import { openDownloadUrl } from '../../../../../../Downloads/helpers';
import { SECURE_COUNTRY_DOWNLOAD_QUERY } from '../../../../../../Downloads/operations';
import { useContext } from 'react';
import SnackbarContext from '../../../../../../SnackbarContext';
import { CORE_DOWNLOAD_TYPE } from '../../../../../../CountryOverviews/constants';
import { REPORT_TYPES } from '../../../../../../Valuations/constants';

export const useResultCardController = (row) => {
  const { setSnack } = useContext(SnackbarContext);
  const [getReportDownload] = useLazyQuery(SECURE_REPORT_DOWNLOAD_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { url, filename, downloadMessage } = data.secureReportDownload;
      if (url && filename) {
        setSnack({
          open: true,
          message: `Your download should begin shortly.${downloadMessage}`,
          severity: 'success',
        });
        openDownloadUrl(url, filename);
      }
    },
    onError: (error) => {
      setSnack({
        severity: 'error',
        message: error.message,
        open: true,
      });
    },
  });

  const downloadAssetReport = () =>
    getReportDownload({
      variables: {
        assetLegacyId: row.linkId,
        isoCode: row.isoCode,
        reportType: REPORT_TYPES.ASSET_REPORT,
      },
    });

  const downloadAssetModel = () =>
    getReportDownload({
      variables: {
        assetLegacyId: row.linkId,
        isoCode: row.isoCode,
        reportType: REPORT_TYPES.VALUATIONS_MODEL,
      },
    });

  const [getCountryReport] = useLazyQuery(SECURE_COUNTRY_DOWNLOAD_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { url, filename, downloadMessage } = data.secureCountryDownload;
      if (url) {
        setSnack({
          open: true,
          message: `Your download should begin shortly.${downloadMessage}`,
          severity: 'success',
        });
        openDownloadUrl(url, filename);
      }
    },
    onError: (error) => {
      setSnack({
        severity: 'error',
        message: error.message,
        open: true,
      });
    },
  });

  const downloadCountriesReport = () =>
    getCountryReport({
      variables: { downloadType: CORE_DOWNLOAD_TYPE, countryIsoCode: row.isoCode },
    });

  let download;
  switch (row.action) {
    case 'download-asset-report':
      download = downloadAssetReport;
      break;
    case 'download-valuation-model':
      download = downloadAssetModel;
      break;
    case 'download-country-report':
      download = downloadCountriesReport;
      break;
  }

  return {
    download,
  };
};
