import {
  X_FACTOR_SUMMARY_ITEM,
  COUNTRY_SUMMARY_ITEM,
  BASIN_SUMMARY_ITEM,
  ACREAGE_SUMMARY_ITEM,
  WATER_DEPTH_M_SUMMARY_ITEM,
  AVERAGE_VERTICAL_WELL_DEPTH_SUMMARY_ITEM,
  SHORE_STATUS_SUMMARY_ITEM,
  PARTICIPATION_SUMMARY_ITEM,
  CONTRACT_START_SUMMARY_ITEM,
  CONTRACT_END_SUMMARY_ITEM,
  FISCAL_REGIME_SUMMARY_ITEM,
} from '../../../SummaryList/SummaryItems/base';
import {
  TRANSPORTATION_METHOD_ASSET_SUMMARY_ITEM,
  TRUCKING_DESTINATION_ASSET_SUMMARY_ITEM,
  PIPELINE_NETWORK_ASSET_SUMMARY_ITEM,
  PIPELINE_NETWORK_SEGMENT_ASSET_SUMMARY_ITEM,
  DISPLAY_NAME_ASSET_SUMMARY_ITEM,
  GEOLOGY_ASSET_SUMMARY_ITEM,
  HPHT_ASSET_SUMMARY_ITEM,
  API_ASSET_SUMMARY_ITEM,
  LICENSING_ROUND_ASSET_SUMMARY_ITEM,
  FIRST_PRODUCTION_ASSET_SUMMARY_ITEM,
  REMAINING_LIQUIDS_RESERVES_ASSET_SUMMARY_ITEM,
  REMAINING_GAS_RESERVES_ASSET_SUMMARY_ITEM,
  NPVS_ASSET_SUMMARY_ITEM,
  LIQUIDS_PRODUCTION_ASSET_SUMMARY_ITEM,
  GAS_PRODUCTION_ASSET_SUMMARY_ITEM,
  ACTIVE_WELLS_ASSET_SUMMARY_ITEM,
  TOTAL_DEVELOPMENT_WELLS_DRILLED_ASSET_SUMMARY_ITEM,
  NOTES_ASSET_SUMMARY_ITEM,
} from '../../../SummaryList/SummaryItems/asset';

export default (layer) => {
  const colombiaXFactor = [];
  let colombiaTransport = [];
  if (layer === 'COL') {
    colombiaXFactor.push(X_FACTOR_SUMMARY_ITEM);
    colombiaTransport.push(
      TRANSPORTATION_METHOD_ASSET_SUMMARY_ITEM,
      TRUCKING_DESTINATION_ASSET_SUMMARY_ITEM,
      PIPELINE_NETWORK_ASSET_SUMMARY_ITEM,
      PIPELINE_NETWORK_SEGMENT_ASSET_SUMMARY_ITEM,
    );
  }
  return [
    DISPLAY_NAME_ASSET_SUMMARY_ITEM,
    COUNTRY_SUMMARY_ITEM,
    BASIN_SUMMARY_ITEM,
    ACREAGE_SUMMARY_ITEM,
    WATER_DEPTH_M_SUMMARY_ITEM,
    AVERAGE_VERTICAL_WELL_DEPTH_SUMMARY_ITEM,
    SHORE_STATUS_SUMMARY_ITEM,
    GEOLOGY_ASSET_SUMMARY_ITEM,
    HPHT_ASSET_SUMMARY_ITEM,
    API_ASSET_SUMMARY_ITEM,
    PARTICIPATION_SUMMARY_ITEM,
    ...colombiaXFactor,
    FISCAL_REGIME_SUMMARY_ITEM,
    LICENSING_ROUND_ASSET_SUMMARY_ITEM,
    FIRST_PRODUCTION_ASSET_SUMMARY_ITEM,
    CONTRACT_START_SUMMARY_ITEM,
    CONTRACT_END_SUMMARY_ITEM,
    REMAINING_LIQUIDS_RESERVES_ASSET_SUMMARY_ITEM,
    REMAINING_GAS_RESERVES_ASSET_SUMMARY_ITEM,
    NPVS_ASSET_SUMMARY_ITEM,
    LIQUIDS_PRODUCTION_ASSET_SUMMARY_ITEM,
    GAS_PRODUCTION_ASSET_SUMMARY_ITEM,
    ACTIVE_WELLS_ASSET_SUMMARY_ITEM,
    TOTAL_DEVELOPMENT_WELLS_DRILLED_ASSET_SUMMARY_ITEM,
    NOTES_ASSET_SUMMARY_ITEM,
    ...colombiaTransport,
  ];
};
