import { gql } from '@apollo/client';

export const REQUEST_REPORT = gql`
  query consolidatedAssetCashflows(
    $companyIds: [[ID!]!]!
    $assetUnits: [[JSON!]!]!
    $assetIds: [[ID!]!]!
    $basePrices: JSON
    $discountRate: Int
    $writeFlag: Boolean!
    $baseYear: Int
    $costInflation: Float
    $emailReport: Boolean!
    $reportName: String
  ) {
    consolidatedAssetCashflows(
      companyIds: $companyIds
      assetUnits: $assetUnits
      assetIds: $assetIds
      basePrices: $basePrices
      discountRate: $discountRate
      writeFlag: $writeFlag
      baseYear: $baseYear
      costInflation: $costInflation
      emailReport: $emailReport
      reportName: $reportName
    ) {
      requestId
      presignedWebsocketUrl
    }
  }
`;

export const GET_USER_REPORTS = gql`
  {
    consolidatedAssetCashflowsUserReports {
      presignedUrl
      time
    }
  }
`;

export const GET_REQUEST_STATUS = gql`
  query consolidatedAssetCashflowsRequestStatus($requestId: ID!) {
    consolidatedAssetCashflowsRequestStatus(requestId: $requestId) {
      message
      presignedUrl
    }
  }
`;

export const CONSOLIDATED_ASSETS_QUERY = gql`
  query consolidatedAssets($portfolios: [JSON!], $limit: Int) {
    consolidatedAssets(portfolios: $portfolios, limit: $limit) {
      assetIds
      assetCountries
    }
  }
`;

export const GLOBAL_ASSUMPTIONS_QUERY = gql`
  {
    globalAssumptions {
      baseYear
      allModelsMatchBaseYear
      discountRate
      costInflation
      brentPriceBaseYear
      brentPriceBaseYearPlusOne
      brentPriceBaseYearPlusTwo
    }
  }
`;

export const SAVE_PRICE_DECK = gql`
  mutation CreateUserPriceDeck($priceDeck: JSON!, $name: String!, $notes: String!) {
    createUserPriceDeck(priceDeck: $priceDeck, name: $name, notes: $notes) {
      userPriceDeck {
        id
        priceDeck
        name
        notes
      }
    }
  }
`;

export const LOAD_PRICE_DECKS = gql`
  {
    loadPriceDecks {
      priceDecks {
        id
        name
        priceDeck
        notes
      }
    }
  }
`;
