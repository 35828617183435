import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    '&.MuiDataGrid-root .MuiDataGrid-columnHeaderDraggableContainer .MuiDataGrid-columnHeaderTitle':
      {
        textOverflow: 'clip',
        whiteSpace: 'break-spaces',
        lineHeight: 1,
      },
    '&.MuiDataGrid-root .MuiDataGrid-row:hover': {
      backgroundColor: theme.palette.baseColors.iceBlue.c50,
    },
    '&.MuiDataGrid-root .MuiDataGrid-row.Mui-selected': {
      backgroundColor: theme.palette.baseColors.skyBlue.c25,
    },
  },
}));
