import { COST_GRAPH_TYPES } from '../../../helpers/countGraphConstatns';
import { STACK_BY_TYPES } from '../../../helpers/stackByConstatnts';
import { getChartDataXY } from '../../../helpers/getChartDataXY';
import { getSecondaryYAxisChartData } from '../../../helpers/getSecondaryYAxisChartData';

const getChartDataXYExtended = (
  yAxisDataDoubleObject,
  xAxisArray,
  hoverTemplateCb,
  additionalDetails,
  allowStacking,
  chartTooltipUnit,
  assetNames,
) => {
  if (allowStacking) {
    return getChartDataXY(yAxisDataDoubleObject, xAxisArray, hoverTemplateCb, additionalDetails);
  } else {
    const result = {};
    xAxisArray.forEach((xAxisKey) => {
      result[xAxisKey] = 0;
    });

    Object.keys(yAxisDataDoubleObject).forEach((outerKeyStackedBy) => {
      xAxisArray.forEach((xAxisKey) => {
        result[xAxisKey] += yAxisDataDoubleObject[outerKeyStackedBy][xAxisKey] || 0;
      });
    });
    return [
      {
        x: xAxisArray,
        y: Object.values(result),
        customdata: assetNames,
        hovertemplate: `${chartTooltipUnit}: %{x}<br>Valuation (US$MM): %{y}<br>Assets Purchased:<br>%{customdata}<extra></extra>`,
        type: 'bar',
        name: 'Valuation (US$MM)',
      },
    ];
  }
};

export const getChart1Data = (
  graphXType,
  allYearsForCharts,
  sum,
  assetNames,
  allXAxisList,
  assetNamesByX,
  sumCompany,
  graph1X,
  graph1Y,
  stackBy,
  secondaryY,
  graphYSecondaryType,
) => {
  let unit = '';

  if (graphXType !== COST_GRAPH_TYPES.YEAR) {
    assetNames = [];

    allXAxisList.forEach((x) => {
      const assetName = Object.keys(assetNamesByX[x] || {}).join(', ');
      const assetNameTrimmed =
        assetName.length > 30 ? assetName.substring(0, 30) + '..' : assetName;
      assetNames.push(assetNameTrimmed);
    });
  }

  switch (graphXType) {
    case COST_GRAPH_TYPES.YEAR:
      unit = 'Year';
      break;
    case COST_GRAPH_TYPES.COUNTRY_NAME:
      unit = 'Country';
      break;
    case COST_GRAPH_TYPES.BUYER_NAME:
      unit = 'Buyer';
      break;
    case COST_GRAPH_TYPES.SELLER_NAME:
      unit = 'Seller';
      break;
  }
  let chartTooltipUnit;
  switch (graphXType) {
    case COST_GRAPH_TYPES.YEAR:
      chartTooltipUnit = 'Year';
      break;
    case COST_GRAPH_TYPES.COUNTRY_NAME:
      chartTooltipUnit = 'Country Name';
      break;
    case COST_GRAPH_TYPES.BUYER_NAME:
    case COST_GRAPH_TYPES.SELLER_NAME:
      chartTooltipUnit = 'Company Name';
      break;
  }

  const allowStacking = Object.keys(sum).length < 30;
  const xAxisList = graphXType === COST_GRAPH_TYPES.YEAR ? allYearsForCharts : allXAxisList;

  const secondaryYAxisData = getSecondaryYAxisChartData(
    xAxisList,
    secondaryY,
    graphYSecondaryType,
    chartTooltipUnit,
  );

  if (graphXType === COST_GRAPH_TYPES.BUYER_NAME || graphXType === COST_GRAPH_TYPES.SELLER_NAME) {
    const xAxisList = graph1X;

    const getV = (companyDeals) => {
      if (!companyDeals) return 0;
      let considerationCalculated = 0;
      Object.values(companyDeals).forEach((value) => {
        considerationCalculated += value;
      });
      return considerationCalculated;
    };

    const allChart2XAxisList = Object.keys(xAxisList).filter(
      (x) => xAxisList[x].value && getV(xAxisList[x].value) > 0,
    );

    const isTop20 = allChart2XAxisList.length < 30 && stackBy !== STACK_BY_TYPES.NONE;

    let chart2X;

    const buyers = [];
    const buyersByType = {};

    allChart2XAxisList.forEach((x) => {
      if (isTop20) {
        Object.keys(graph1Y).forEach((companyName) => {
          buyersByType[companyName] = [
            ...(buyersByType[companyName] || []),
            getV(graph1Y[companyName][x]),
          ];
        });
      } else {
        buyers.push(getV(xAxisList[x].value));
      }
    });

    chart2X = allChart2XAxisList;

    const data = isTop20
      ? Object.keys(buyersByType).map((companyName) => ({
          x: chart2X,
          y: buyersByType[companyName],
          type: 'bar',
          name: companyName,
          hovertemplate: `Company Name: ${companyName}<br>Valuation (US$MM): %{y}<extra></extra>`,
        }))
      : [
          {
            x: chart2X,
            y: buyers,
            type: 'bar',
            hovertemplate: 'Valuation (US$MM): %{y}<extra></extra>',
            name: 'Valuation (US$MM)',
          },
        ];

    return {
      data: [...data, secondaryYAxisData],
      name: 'Valuation ' + unit + ' (US$MM)',
    };
  }

  return {
    data: [
      ...getChartDataXYExtended(
        sum,
        xAxisList,
        (outerKeyStackedBy) =>
          `Company Name: ${outerKeyStackedBy}<br>Transacted Value (US$MM): %{y}<extra></extra>`,
        {
          type: 'bar',
        },
        allowStacking && stackBy !== STACK_BY_TYPES.NONE,
        chartTooltipUnit,
        assetNames,
      ),
      secondaryYAxisData,
    ],
    name: 'Welligence Deal NPV10 Valuation',
    stackByWarning: allowStacking
      ? null
      : "Too many bars present in the chart, please filter in more specific data set to enable 'Stack By' feature.",
  };
};
