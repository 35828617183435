import {
  BarGraphContextLeft,
  BarGraphContextRight,
  BarGraphProviderLeft,
  BarGraphProviderRight,
} from './BarGraphs';
import { LineGraphs, MonetaryLineGraphProvider, ProductionLineGraphProvider } from './LineGraphs';
import TreemapContext, { TreemapProvider } from './Treemap/TreemapContext';

import BarGraph from '../../Portfolio/Charts/BarGraph/barGraph';
import Grid from '@mui/material/Grid2';
import React from 'react';
import Treemap from '../../Portfolio/Charts/Treemap';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.palette.primaryBackground,
    height: '100%',
    width: '100%',
    padding: theme.spacing(2),
  },
}));

export default ({ portfolios, consolidateAssetsProps }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.root}
      spacing={1}
      columnsSpacing={{ xs: 2 }}
      sx={{
        justifyContent: 'space-evenly',
      }}
    >
      <Grid id="portfolio-analysis-treemap" size={3}>
        <TreemapProvider portfolios={portfolios}>
          <Treemap context={TreemapContext} />
        </TreemapProvider>
      </Grid>
      <Grid container spacing={2} size={9}>
        <ProductionLineGraphProvider portfolios={portfolios}>
          <MonetaryLineGraphProvider portfolios={portfolios}>
            <LineGraphs portfolios={portfolios} consolidateAssetsProps={consolidateAssetsProps} />
          </MonetaryLineGraphProvider>
        </ProductionLineGraphProvider>
        <Grid container columnSpacing={2} spacing={2} size={12}>
          <Grid id="portfolio-analysis-bar-graph" size={6}>
            <BarGraphProviderLeft portfolios={portfolios}>
              <BarGraph includeId context={BarGraphContextLeft} />
            </BarGraphProviderLeft>
          </Grid>
          <Grid size={6}>
            <BarGraphProviderRight portfolios={portfolios}>
              <BarGraph context={BarGraphContextRight} />
            </BarGraphProviderRight>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
