import React, { useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(() => ({
  menuButton: {
    borderRadius: '5px',
    border: 'solid thin darkgrey',
    display: 'flex',
    fontWeight: 'bold',
    justifyContent: 'center',
    padding: '5px',
    flex: '0 1 auto',
    fontSize: '0.7rem',
    cursor: 'pointer',
    margin: '0 5px',
    minWidth: '55px',
  },
}));

export default ({ context, items, isDisabled, selectedItem, onChange, tooltip }) => {
  const classes = useStyles();
  const [menuEl, setMenuEl] = useState(null);

  const handleMenuClick = (event) => {
    if (!isDisabled) {
      setMenuEl(event.currentTarget);
    }
  };

  const handleMenuClose = (item) => {
    if (item) {
      onChange(item);
    }
    setMenuEl(null);
  };

  return (
    <>
      <Tooltip title={tooltip} aria-label={tooltip}>
        <Typography variant="caption" className={classes.menuButton} onClick={handleMenuClick}>
          {selectedItem.label}
        </Typography>
      </Tooltip>
      <Menu
        disabled={isDisabled}
        anchorEl={menuEl}
        keepMounted
        open={Boolean(menuEl)}
        onClose={() => handleMenuClose()}
      >
        {items.map((item) => (
          <MenuItem
            key={`${context}-menu-item-${item.label}`}
            onClick={() => handleMenuClose(item)}
            selected={selectedItem.label === item.label}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
