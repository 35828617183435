export const SearchLabels = {
  asset: 'Go to Asset',
  block: 'Go to Block',
  country: 'Go to Country/Area',
  facility: 'Go to Facility',
  field: 'Go to Field',
  lngFacility: 'Go to LNG Facility',
  pipelineNetwork: 'Go to Pipeline Network',
  well: 'Go to Well',
};
