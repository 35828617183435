import { gql } from '@apollo/client';

export const LEAD_ANALYST_QUERY = gql`
  query leadAnalyst($countryIsoCode: String!) {
    leadAnalyst(countryIsoCode: $countryIsoCode) {
      name
      email
    }
  }
`;
