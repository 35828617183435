import { gql } from '@apollo/client';

export const SEND_PASSWORD_RESET = gql`
  mutation userSendPasswordResetWithToken($email: String!, $redirectUrl: String!) {
    userSendPasswordResetWithToken(email: $email, redirectUrl: $redirectUrl) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation userUpdatePasswordWithToken(
    $password: String!
    $passwordConfirmation: String!
    $resetPasswordToken: String!
  ) {
    userUpdatePasswordWithToken(
      resetPasswordToken: $resetPasswordToken
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      authenticatable {
        email
      }
    }
  }
`;
