import { gql } from '@apollo/client';

export const SEEN_NEW_FEATURE = gql`
  mutation seenNewFeature($feature: String!) {
    seenNewFeature(feature: $feature) {
      userWelcome {
        features
      }
    }
  }
`;
