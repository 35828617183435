import React, { useMemo, useState } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Grid from '@mui/material/Grid2';
import Layers from './layers';
import Legend from '../../Legend';
import MapStyle from './mapStyle';
import Slide from '@mui/material/Slide';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { getUserPreferenceMapLayer } from './utils';
import { layerProperties } from '../Utils/constants';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.palette.transparentBackground,
    position: 'fixed',
    width: '400px',
    right: 0,
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    backdropFilter: 'blur(20px)',
    minHeight: '150px',
    maxHeight: '300px',
  },
  closed: {
    position: 'fixed',
    right: 0,
    minHeight: '150px',
    maxHeight: '300px',
  },
  button: {
    ...theme.palette.transparentBackground,
    color: 'black',
    cursor: 'pointer',
    backgroundColor: 'rgba(205, 215, 222, .5)',
    '&:hover': {
      backgroundColor: 'rgba(205, 215, 222, .75)',
    },
    width: '40px',
    height: '100%',
    padding: '5px 10px',
    boxSizing: 'border-box',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    writingMode: 'vertical-lr',
    transform: 'scale(-1)',
  },
  settings: {
    width: '360px',
    height: '100%',
    overflow: 'auto',
    ...theme.palette.altScrollbar,
  },
  legendContainer: {
    right: '10px',
    flexWrap: 'wrap',
    width: '390px',
    height: 'auto',
  },
}));

export default ({
  addLayer,
  includeLayers,
  includeMapStyle,
  layerProps = {},
  legendContainerRef,
  mapStyle,
  removeLayer,
  styles = {},
  toggleLayer,
  toggleMapStyle,
  top = '64', // 64 is the height of the appbar
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [legend, setLegend] = useState(
    layerProperties[getUserPreferenceMapLayer()]?.legend ||
      (layerProps['defaultLayer'] ? layerProperties[layerProps['defaultLayer']]?.legend : {}),
  );

  // Calculate the height of the settings panel based on the props passed in
  const height = useMemo(() => {
    let percent = includeLayers ? 45 : 0;
    percent += includeMapStyle ? 30 : 0;
    return percent;
  }, [includeLayers, includeMapStyle]);

  // Inline styles for opening and closing the settings panel
  const { inlineHandleStyle, inlineRootStyle } = useMemo(() => {
    const { handleStyle, rootStyle } = styles;
    const defaultStyle = { top: `calc(15% + ${top}px)`, height: `calc(${height}% - ${top}px)` };
    const inlineHandleStyle = open ? handleStyle?.openStyle : handleStyle?.closedStyle;
    const inlineRootStyle = open ? rootStyle?.openStyle : rootStyle?.closedStyle;

    return {
      inlineHandleStyle: { ...defaultStyle, ...inlineHandleStyle },
      inlineRootStyle: { ...defaultStyle, ...inlineRootStyle },
    };
  }, [styles, top, height, open]);

  // Not sure why but after changing how the map fullscreen for CCUS is rendered, this component kept
  // re-rendering when hovering over for the tooltip. This useMemo prevents that.
  const SlideToggle = useMemo(
    () =>
      ({ style = {} }) => (
        <Tooltip title={open ? 'Hide Layers' : 'Show Layers'}>
          <Grid
            container
            className={clsx({ [classes.button]: true, [classes.closed]: !open })}
            onClick={() => (open ? setOpen(false) : setOpen(true))}
            style={style}
            sx={{
              justifyContent: 'center',
            }}
          >
            <Typography variant="caption" noWrap>
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              {open ? 'HIDE LAYERS' : 'SHOW LAYERS'}
            </Typography>
          </Grid>
        </Tooltip>
      ),
    [open, inlineHandleStyle],
  );

  return (
    <>
      {!open && <SlideToggle key="map-settings-toggle" style={inlineHandleStyle} />}
      <Slide direction="left" in={open} className={classes.root} style={inlineRootStyle}>
        <Grid container direction="row">
          {open && <SlideToggle key="map-settings-toggle" />}
          <Grid
            container
            className={classes.settings}
            sx={{
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
          >
            {includeMapStyle && (
              <MapStyle
                divider={includeLayers}
                mapStyle={mapStyle}
                toggleMapStyle={toggleMapStyle}
              />
            )}
            {includeLayers && (
              <Layers
                {...layerProps}
                addLayer={addLayer}
                removeLayer={removeLayer}
                setLegend={setLegend}
                toggleLayer={toggleLayer}
              />
            )}
          </Grid>
        </Grid>
      </Slide>
      {legend?.legendItems && (
        <Legend
          containerRef={legendContainerRef}
          enabled
          items={legend.legendItems}
          title={`Legend: ${legend.legendLabel}`}
          multiline
          styles={classes}
        />
      )}
    </>
  );
};
