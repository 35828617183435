import React, { createContext, useCallback, useContext, useMemo } from 'react';

import { GET_FEATURE_FLAGS_QUERY } from './operations';
import { useCurrentUser } from '../../components/CurrentUserContext';
import { useQuery } from '@apollo/client';

const FeatureFlagsContext = createContext();

const THIRTY_MINUTES = 1000 * 60 * 30;

const FEATURE_FLAGS = {
  IN_DEVELOPMENT: 'inDevelopment',
  IN_LOCAL_ENV: 'inLocalEnv',
  ENABLE_SENTRY: 'enableSentry',
  MA_V2: 'maV2',
  VIDEO_TUTORIALS: 'videoTutorials',
  SCREENSHOT_MODE: 'screenshotMode',
  DISABLE_CCF_LIMIT: 'disableCcfLimit',
};

const FeatureFlagsProvider = ({ children }) => {
  const { currentUser } = useCurrentUser();
  const { data } = useQuery(GET_FEATURE_FLAGS_QUERY, {
    skip: !Object.keys(currentUser).length,
    pollInterval: THIRTY_MINUTES,
  });
  const { featureFlags } = data || { featureFlags: null };

  const isFlagEnabled = useCallback((flagKey) => featureFlags?.[flagKey] ?? false, [featureFlags]);

  // Feature Flags
  const inDevelopment = useMemo(() => isFlagEnabled(FEATURE_FLAGS.IN_DEVELOPMENT, []));
  const inLocalEnv = useMemo(() => isFlagEnabled(FEATURE_FLAGS.IN_LOCAL_ENV, []));
  const enableSentry = useMemo(() => isFlagEnabled(FEATURE_FLAGS.ENABLE_SENTRY, []));
  const maV2 = useMemo(() => isFlagEnabled(FEATURE_FLAGS.MA_V2, []));
  const videoTutorials = useMemo(() => isFlagEnabled(FEATURE_FLAGS.VIDEO_TUTORIALS, []));
  const screenshotMode = useMemo(() => isFlagEnabled(FEATURE_FLAGS.SCREENSHOT_MODE, []));
  const disableCcfLimit = useMemo(() => isFlagEnabled(FEATURE_FLAGS.DISABLE_CCF_LIMIT, []));

  return (
    <FeatureFlagsContext.Provider
      value={{
        inDevelopment,
        inLocalEnv,
        enableSentry,
        maV2,
        videoTutorials,
        screenshotMode,
        disableCcfLimit,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

const useFeatureFlags = () => useContext(FeatureFlagsContext);

export { FeatureFlagsProvider, useFeatureFlags };
