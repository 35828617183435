export const getCostCountChartData = (countByType, chart2XAxisList, xAxisName) => {
  const allChart2XAxisList = Object.keys(chart2XAxisList).sort((a, b) => a - b);
  const countTypes = Object.keys(countByType);

  let chart2X;
  let chart2Y;

  const countsByType = {};
  allChart2XAxisList.forEach((x) => {
    countTypes.forEach((countType) => {
      countsByType[countType] = [
        ...(countsByType[countType] || []),
        countByType[countType][x] || 0,
      ];
    });
  });

  chart2Y = countsByType;
  chart2X = allChart2XAxisList;

  return Object.keys(countsByType).map((countType) => ({
    x: chart2X,
    y: chart2Y[countType],
    type: 'bar',
    name: countType,
    hovertemplate: `Asset Deal: ${countType} <br>${xAxisName}: %{x}<br>Count: %{y}<extra></extra>`,
  }));
};
