import {
  ACREAGE_SUMMARY_ITEM,
  FORMATTED_PARTNERS_SUMMARY_ITEM,
  STORAGE_TYPE_SUMMARY_ITEM,
} from '../../../../../SummaryList/SummaryItems/base';
import {
  NAME_STORAGE_SITE_SUMMARY_ITEM,
  SHORE_STATUS_STORAGE_SITE_SUMMARY_ITEM,
  STATUS_STORAGE_SITE_SUMMARY_ITEM,
} from '../../../../../SummaryList/SummaryItems/storageSite';

export default [
  NAME_STORAGE_SITE_SUMMARY_ITEM,
  ACREAGE_SUMMARY_ITEM,
  FORMATTED_PARTNERS_SUMMARY_ITEM,
  SHORE_STATUS_STORAGE_SITE_SUMMARY_ITEM,
  STATUS_STORAGE_SITE_SUMMARY_ITEM,
  STORAGE_TYPE_SUMMARY_ITEM,
];
