import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { LOAD_PORTFOLIO } from '../operations';
import { defaultDimensions } from '../../../../utils/analytics';
import Button from '@mui/material/Button';
import { getUserId } from '../../../../utils/auth';
import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '250px',
    margin: '10px',
  },
  button: {
    ...theme.palette.button,
    paddingTop: '8px',
    paddingBottom: '8px',
    margin: '10px 0 20px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'column',
  },
  textArea: {
    disableUnderline: true,
    paddingTop: '2.5rem',
  },
}));

export const LoadPortfolio = ({ context, setFilters: setPortfolios, handleClose }) => {
  const classes = useStyles();
  const [selectedPortfolio, setSelectedPortfolio] = useState('');

  const { loading, data, refetch } = useQuery(LOAD_PORTFOLIO, {
    variables: { context },
  });

  useEffect(() => {
    refetch && refetch();
  }, []);

  const loadPortfolios = () => {
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: 'PortfolioAnalysis',
      event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
      event_label: 'load_portfolio',
      userId: getUserId(),
      ...defaultDimensions,
    });
    setPortfolios(selectedPortfolio.portfolio);
    handleClose();
  };

  return (
    <div className={classes.content}>
      <FormControl className={classes.input} variant="filled">
        <InputLabel>Portfolios</InputLabel>
        <Select
          disableUnderline
          value={selectedPortfolio}
          onChange={(e) => setSelectedPortfolio(e.target.value)}
        >
          {!loading && data
            ? data.userPortfolios.portfolios
                .map((portfolio) => (
                  <MenuItem key={`load-portfolio-${portfolio.name}`} value={portfolio}>
                    {portfolio.name}
                  </MenuItem>
                ))
                .concat(
                  data.userPortfolios.sharedPortfolios.map((portfolio) => (
                    <MenuItem
                      key={`load-portfolio-shared-portfolio-${portfolio.name}`}
                      value={portfolio}
                    >
                      {portfolio.name} (shared with you)
                    </MenuItem>
                  )),
                )
            : null}
        </Select>
      </FormControl>
      <TextField
        className={classes.input}
        disabled
        label="Notes"
        variant="filled"
        multiline
        rows={6}
        defaultValue={selectedPortfolio ? selectedPortfolio.notes : ''}
        slotProps={{
          input: {
            className: classes.textArea,
          },
        }}
      />
      <div className={classes.buttonWrapper}>
        <Button
          disabled={!(selectedPortfolio.portfolio ? true : false)}
          variant="contained"
          className={classes.button}
          onClick={loadPortfolios}
        >
          Load Portfolio
        </Button>
      </div>
    </div>
  );
};
