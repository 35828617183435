import {
  COUNTRY_NAME_SUMMARY_ITEM,
  FORMATTED_PARTNERS_SUMMARY_ITEM,
  INDUSTRY_SUMMARY_ITEM,
  STORAGE_TYPE_SUMMARY_ITEM,
} from '../../../../../SummaryList/SummaryItems/base';
import {
  NAME_CCUS_PROJECT_SUMMARY_ITEM,
  HUB_CCUS_PROJECT_SUMMARY_ITEM,
  SCALE_CCUS_PROJECT_SUMMARY_ITEM,
  TYPE_CCUS_PROJECT_SUMMARY_ITEM,
  DEV_STATUS_CCUS_PROJECT_SUMMARY_ITEM,
  DESCRIPTION_CCUS_PROJECT_SUMMARY_ITEM,
  ANNOUNCEMENT_YEAR_CCUS_PROJECT_SUMMARY_ITEM,
  FID_YEAR_CCUS_PROJECT_SUMMARY_ITEM,
  OPERATIONAL_YEAR_CCUS_PROJECT_SUMMARY_ITEM,
  CAPACITY_CCUS_PROJECT_SUMMARY_ITEM,
  DEV_LIKELIHOOD_CCUS_PROJECT_SUMMARY_ITEM,
  STORAGE_PARTNERSHIP_CCUS_PROJECT_SUMMARY_ITEM,
} from '../../../../../SummaryList/SummaryItems/ccusProject';

export default [
  NAME_CCUS_PROJECT_SUMMARY_ITEM,
  HUB_CCUS_PROJECT_SUMMARY_ITEM,
  SCALE_CCUS_PROJECT_SUMMARY_ITEM,
  FORMATTED_PARTNERS_SUMMARY_ITEM,
  COUNTRY_NAME_SUMMARY_ITEM,
  TYPE_CCUS_PROJECT_SUMMARY_ITEM,
  INDUSTRY_SUMMARY_ITEM,
  DEV_STATUS_CCUS_PROJECT_SUMMARY_ITEM,
  DESCRIPTION_CCUS_PROJECT_SUMMARY_ITEM,
  ANNOUNCEMENT_YEAR_CCUS_PROJECT_SUMMARY_ITEM,
  FID_YEAR_CCUS_PROJECT_SUMMARY_ITEM,
  OPERATIONAL_YEAR_CCUS_PROJECT_SUMMARY_ITEM,
  CAPACITY_CCUS_PROJECT_SUMMARY_ITEM,
  DEV_LIKELIHOOD_CCUS_PROJECT_SUMMARY_ITEM,
  STORAGE_TYPE_SUMMARY_ITEM,
  STORAGE_PARTNERSHIP_CCUS_PROJECT_SUMMARY_ITEM,
];
