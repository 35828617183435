import { gql } from '@apollo/client';

export const USER_WELCOME_MUTATION = gql`
  mutation seenUserWelcome {
    seenUserWelcome {
      userWelcome {
        seen
      }
    }
  }
`;
