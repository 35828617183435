import {
  COUNTRY_OVERVIEWS_PATH,
  OIL_AND_GAS_MAP_PATH,
  VALUATIONS_PATH,
  PORTFOLIO_ANALYSIS_PATH,
  GLOBAL_SEARCH_ACTION_DETAIL,
  GLOBAL_SEARCH_URL_ACTION,
  GLOBAL_SEARCH_URL_LEGACY_ID,
  GLOBAL_SEARCH_URL_ISO_CODE,
  GLOBAL_SEARCH_URL_NAME,
  GLOBAL_SEARCH_URL_ENTITY,
  GLOBAL_SEARCH_URL_GEOM,
  GLOBAL_SEARCH_URL_COUNTRY_GEOM,
} from '../../utils/constants';

//TODO check and refactor this

// ???? generateFulltextResultLink should be used with screenPath from constant.js (eg. with OIL_AND_GAS_MAP_PATH)
const generateFulltextResultLink = (
  screenPath,
  action,
  { entity, isoCode, linkId, name, geom, countryGeom },
) => {
  const data = {};

  if (action) data[GLOBAL_SEARCH_URL_ACTION] = action;
  if (linkId) data[GLOBAL_SEARCH_URL_LEGACY_ID] = linkId;
  if (isoCode) data[GLOBAL_SEARCH_URL_ISO_CODE] = isoCode;
  if (name) data[GLOBAL_SEARCH_URL_NAME] = name;
  if (entity) data[GLOBAL_SEARCH_URL_ENTITY] = entity;

  // needed for the Oil and Gas
  if (geom) data[GLOBAL_SEARCH_URL_GEOM] = geom;
  if (countryGeom) data[GLOBAL_SEARCH_URL_COUNTRY_GEOM] = countryGeom;

  const searchParams = new URLSearchParams(data);
  return `${screenPath}?${searchParams.toString()}`;
};

const generateAssetValuationsLink = (screenPath, { isoCode, linkId }) => {
  // example: valuations/AGO/16007
  return `${screenPath}/${isoCode}/${linkId}`;
};

const actionToUrlPath = (action) => {
  switch (action) {
    case 'oil-and-gas': {
      return {
        displayName: 'Open Oil and Gas map',
        path: OIL_AND_GAS_MAP_PATH,
        searchAction: GLOBAL_SEARCH_ACTION_DETAIL,
      };
    }
    case 'oil-and-gas-lower': {
      return {
        displayName: 'Open Oil and Gas map',
        path: OIL_AND_GAS_MAP_PATH,
        searchAction: GLOBAL_SEARCH_ACTION_DETAIL,
      };
    }
    case 'oil-and-gas-networks': {
      return {
        displayName: 'Open Oil and Gas map',
        path: OIL_AND_GAS_MAP_PATH,
        searchAction: GLOBAL_SEARCH_ACTION_DETAIL,
      };
    }
    case 'country-overview': {
      return {
        displayName: 'Open Country overviews page',
        path: COUNTRY_OVERVIEWS_PATH,
        searchAction: GLOBAL_SEARCH_ACTION_DETAIL,
      };
    }
    case 'valuations': {
      return {
        displayName: 'Open Valuations page',
        path: VALUATIONS_PATH,
        searchAction: GLOBAL_SEARCH_ACTION_DETAIL,
      };
    }
    // Temporary case to by-pass weirdly
    // functioning valuations page
    case 'asset-valuations': {
      return {
        displayName: 'Open Valuations page',
        path: VALUATIONS_PATH,
        searchAction: GLOBAL_SEARCH_ACTION_DETAIL,
      };
    }
    case 'portfolio-analysis': {
      return {
        displayName: 'Open Portfolio analysis page',
        path: PORTFOLIO_ANALYSIS_PATH,
        searchAction: GLOBAL_SEARCH_ACTION_DETAIL,
      };
    }
  }
  return {};
};

export const generateFulltextLinkMenu = (resultRow) => {
  switch (resultRow.entity) {
    case 'ASSET':
      if (resultRow.action === 'asset-valuations') {
        const { displayName, path } = actionToUrlPath(resultRow.action);
        if (path) {
          return {
            displayName,
            link: generateAssetValuationsLink(path, resultRow),
          };
        }
        return null;
      }
    /* falls through */
    case 'BASIN':
    case 'BLOCK':
    case 'COUNTRY':
    case 'COMPANY':
    case 'FACILITY':
    case 'FIELD':
    case 'LNG_PROJECT':
    case 'PIPELINE_NETWORK':
    case 'WELL': {
      const { displayName, path, searchAction } = actionToUrlPath(resultRow.action);
      if (path) {
        return {
          displayName,
          link: generateFulltextResultLink(path, searchAction, resultRow),
        };
      }
      return null;
    }
    // Lease is a special case of Block
    case 'LEASE': {
      let adjustedRow = { ...resultRow };
      adjustedRow.entity = 'BLOCK';
      const { displayName, path, searchAction } = actionToUrlPath(adjustedRow.action);
      if (path) {
        return {
          displayName,
          link: generateFulltextResultLink(path, searchAction, adjustedRow),
        };
      }
      return null;
    }
  }

  return null;
};

export const getGoogleAnalyticsDimentions = (resultRow, clickCounter, reportClickCounter) => {
  const dimensions = {
    asset: 'NULL',
    country: 'NULL',
    company: 'NULL',
    action: resultRow.action,
    actionCount: clickCounter + reportClickCounter,
    reportClicks: reportClickCounter,
    siteClicks: clickCounter,
  };
  switch (resultRow.entity) {
    case 'ASSET':
    case 'BLOCK':
      dimensions['asset'] = resultRow.name;
      dimensions['countryIso'] = resultRow.isoCode;
      // TODO: Add a country name to the searchResults
      // dimensions['country'] = resultRow.country.name;
      // https://welligence.atlassian.net/browse/XWWP-395
      break;
    case 'COUNTRY':
      dimensions['countryIso'] = resultRow.isoCode;
      // TODO: Add a country name to the searchResults
      // dimensions['country'] = resultRow.country.name;
      // https://welligence.atlassian.net/browse/XWWP-395
      break;
    case 'COMPANY':
      dimensions['company'] = resultRow.name;
      break;
  }
  return dimensions;
};

export const getEntityNameByAction = (action, isPlural) => {
  switch (action) {
    case 'oil-and-gas':
      return isPlural ? 'Assets/Blocks on the map' : 'Asset/Block on the map';
    case 'oil-and-gas-lower':
      return isPlural ? 'Fields/Leases/Wells on the map' : 'Field/Lease/Well on the map';
    case 'oil-and-gas-networks':
      return isPlural ? 'Networks/Projects on the map' : 'Network/Project on the map';
    case 'country-overview':
      return 'on Country Overview page';
    case 'valuations':
      return 'on Valuations page';
    case 'asset-valuations':
      return 'on Valuations page';
    case 'portfolio-analysis':
      return 'on Portfolio Analysis page';
    case 'download-asset-report':
      return isPlural ? 'Asset reports' : 'Asset report';
    case 'download-valuation-model':
      return isPlural ? 'Valuation models' : 'Valuation model';
    case 'download-country-report':
      return isPlural ? 'Country reports' : 'Country report';
  }
  return '';
};

export const getMatchedValueFromAutocomplete = (option, searchedValue) => {
  switch (searchedValue.entity) {
    case 'COUNTRY':
      return option.isoCode === searchedValue.isoCode;
    case 'ASSET':
    case 'FIELD':
    case 'WELL':
      return (
        option.legacyId === searchedValue.legacyId &&
        option.country?.isoCode === searchedValue.isoCode
      );
    case 'BLOCK':
    case 'LEASE':
      if (searchedValue.isoCode === 'USA') {
        return option.name === searchedValue.name.split(' - ')[0];
      }
      return (
        option.legacyId === searchedValue.legacyId &&
        option.country?.isoCode === searchedValue.isoCode
      );
    case 'FACILITY':
    case 'LNG_PROJECT':
      return (
        option.name === searchedValue.name && option.country?.isoCode === searchedValue.isoCode
      );
    case 'PIPELINE_NETWORK':
      return (
        option.name === searchedValue.name &&
        option.countries?.find((country) => country.isoCode === searchedValue.isoCode)
      );
  }
};
