import { MenuButton, useMenuButtonStyles } from '../MenuButton';
import React, { useEffect, useRef, useState } from 'react';

import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grow from '@mui/material/Grow';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import clsx from 'clsx';
import { useDropDownStyles } from './DropDown.style';
import { useLocation } from 'react-router-dom';

export const DropDown = ({ link, forceNewTab, isUserMenu }) => {
  const classes = useDropDownStyles();
  const classesMenuButton = useMenuButtonStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const location = useLocation();
  const path = location.pathname;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const isCurrent = link.menuItems.find((item) => path.indexOf(item.link) !== -1) !== undefined;

  return (
    <>
      <Button
        ref={anchorRef}
        endIcon={
          !isUserMenu ? (
            <ExpandMoreIcon className={clsx(classes.expandIcon, { [classes.iconRotate]: open })} />
          ) : undefined
        }
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={clsx(
          isCurrent ? classes.selectedLink : classesMenuButton.link,
          classes.menuButton,
          {
            [classes.openedLink]: open,
            [classes.circle]: isUserMenu,
          },
        )}
      >
        {link.title}
      </Button>
      <Popper
        style={{ zIndex: open ? 1200 : -1 }} // zIndex of -1 so the mounted poppers don't block the welligence click functionality
        open={open}
        anchorEl={anchorRef.current}
        transition
        keepMounted // keep mounted so the shiny query doesn't cancel on popper close
        placement={isUserMenu ? 'bottom-end' : 'bottom-start'}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper
              className={clsx(classes.menu, { [classes.menuCurrent]: isCurrent && !isUserMenu })}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open}>
                  {link.menuItems.map((item) => {
                    if (item.type === 'jsx') {
                      return item.jsx;
                    }

                    return (
                      <MenuButton
                        key={item.title}
                        item={item}
                        forceNewTab={forceNewTab}
                        link={link}
                        onClick={() => setOpen(false)}
                        className={classes.button}
                      />
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
