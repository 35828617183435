import { GOOGLE_ANALYTICS_TAG, PORTFOLIO_CONTEXT } from './constants';

import Portfolio from '../Portfolio';
import PortfolioGraphing from './PortfolioGraphing';
import React from 'react';
import { portfolioAnalysisDriver } from './driver';

const PortfolioAnalysis = () => (
  <Portfolio
    context={PORTFOLIO_CONTEXT}
    googleAnalyticsTag={GOOGLE_ANALYTICS_TAG}
    GraphingComponent={PortfolioGraphing}
    walkthroughDriver={portfolioAnalysisDriver}
  />
);

export default PortfolioAnalysis;
