import { Box, Typography } from '@mui/material';

import React from 'react';
import { useStyles } from './styles';

export const EmptyState = ({ Icon, title, description }) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.root}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Icon className={classes.icon} />
      <Typography
        variant="h5"
        sx={{
          color: 'secondary',
          marginTop: 2,
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body"
        align="center"
        sx={{
          color: 'textSecondary',
          marginTop: 1,
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};
