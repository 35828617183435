import { FILTER_TYPES, GOOGLE_ANALYTICS_TAG } from './types';
import React, { useCallback, useEffect } from 'react';

import BasicFilters from '../../../Filters/basicFilters';
import { Box } from '@mui/material';
import { Skeleton } from '@mui/material';
import { Slider } from '../../../basic/Slider';
import { useFilters } from '../../FiltersContext';
import { useGetStartAndEndYear } from './hooks/useGetStartAndEndYear';
import { useMarks } from './hooks/useMarks';
import { useStyles } from './styles';

const SliderSkeleton = () => {
  const classes = useStyles();
  return (
    <div className={classes.slider}>
      <Box
        sx={{
          marginTop: 1,
        }}
      >
        <Skeleton variant="rectangular" width={200} height={12} />
      </Box>
      <Box
        sx={{
          marginTop: 1,
        }}
      >
        <Skeleton variant="rectangular" width="100%" height={10} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 1,
        }}
      >
        <Skeleton variant="rectangular" width={50} height={10} />
        <Skeleton variant="rectangular" width={50} height={10} />
      </Box>
    </div>
  );
};

export const Filters = () => {
  const classes = useStyles();
  const { filters, setFilters, setYearsRange, loading } = useFilters();
  const { data, loading: loadingData } = useGetStartAndEndYear();

  useEffect(() => {
    if (!loadingData && data?.startYear && data?.endYear) {
      setYearsRange([data.startYear, data.endYear]);

      // reset if the current date slider is outside the range of the data
      if (data?.startYear > filters.dateSlider[0] || data?.endYear < filters.dateSlider[1]) {
        setFilters((prevFilters) => ({
          ...prevFilters,
          dateSlider: [data.startYear, data.endYear],
        }));
      }
    }
  }, [data, loadingData]);

  const handleChangeValueSlider = useCallback(
    (_, newValue) => {
      setFilters({ ...filters, dateSlider: newValue });
    },
    [filters],
  );

  const marks = useMarks(data?.startYear, data?.endYear, loadingData);
  const isDisabled =
    loading ||
    !marks.length ||
    !data?.startYear ||
    !data?.endYear ||
    data?.startYear === data?.endYear ||
    // disable if the current date slider is outside the range of the data
    data?.startYear > filters.dateSlider[0] ||
    data?.endYear < filters.dateSlider[1];

  return (
    <>
      <div className={classes.sliderWrapper}>
        {/* Using a Skeleton when loading to force a re-render of
        the Slider component to avoid bug with overlapping dates */}
        {loading || loadingData ? (
          <SliderSkeleton />
        ) : (
          <Slider
            className={classes.slider}
            value={filters.dateSlider}
            onChange={handleChangeValueSlider}
            label="EFFECTIVE DATE (YEARS)"
            getAriaLabel={() => 'Effective Date (years)'}
            valueLabelDisplay={() => 'Effective Date (years)'}
            marks={marks}
            min={marks.length ? marks[0].value : undefined}
            step={1}
            max={marks.length ? marks[marks.length - 1].value : undefined}
            disabled={isDisabled}
          />
        )}
      </div>

      <BasicFilters
        applyFilters={setFilters}
        context="ma-filters"
        filters={filters}
        googleAnalyticsTag={GOOGLE_ANALYTICS_TAG}
        basicFilterTypes={FILTER_TYPES}
        filterItemClass={classes.filterItem}
      />
    </>
  );
};
