import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: theme.fonts.base,
    display: 'flex',
    ...theme.palette.primaryBackground,
    ...theme.sizes.scrollableFullPage,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: '50px auto',
    padding: '50px',
    width: '100%',
    maxWidth: '1600px',
  },
}));
