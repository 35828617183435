import React from 'react';
import { PageShell } from '../PageShell';
import { useTransactionAnalyticsController } from './TransactionAnalytics.controller';
import { COMP_GRAPH_TYPES, compOptions, compYOptions } from './TransactionAnalytics.constant';
import { costGraphTypesSimple } from '../../helpers/countGraphConstatns';
import { useTransactionAnalyticsStyles } from './TransactionAnalytics.style';
import {
  getGraphLayoutSettings,
  GRAPH_LAYOUT_CONFIG,
} from '../../helpers/GraphLayoutSettingConstant';
import { STACK_BY_TYPES, stackByOptionsSimple } from '../../helpers/stackByConstatnts';
import { ChartWithMetrics } from '../ChartWithMetrics/ChartWithMetrics';
import { secondaryYAxis } from '../../helpers/secondaryYAxisConstatns';
import { COST_GRAPH_TYPES } from '../../helpers/countGraphConstatns';

const FIRST_CHART_TITLE = 'Acquisition Cost';

export const TransactionAnalytics = ({ data }) => {
  const {
    filters,
    reservesRatioYears,
    reservesRatioData,
    barelRatioYears,
    barelRatioData,
    updateGraph3XType,
    graph3XType,
    graph3YType,
    updateGraph3YType,
    chart3Data,
    chart3Unit,
    chart3Name,
    chart4Data,
    graph4Type,
    updateGraph4Type,
    setShowPlotlyLegend,
    showPlotlyLegend,
    setShowPlotlyLegend4,
    showPlotlyLegend4,
    stackByWarning,
    updateStackBy,
    stackBy,
    graphYSecondaryType,
    updateGraphYSecondaryType,
    customDataChart1,
    customDataChart2,
  } = useTransactionAnalyticsController(data);
  const classes = useTransactionAnalyticsStyles();
  if (!filters) return null;

  const chart1Data = [
    {
      x: reservesRatioYears,
      y: reservesRatioData,
      mode: 'markers',
      type: 'scatter',
      customdata: customDataChart1,
      hovertemplate: `Year: %{x|%Y}<br>${FIRST_CHART_TITLE}: %{y}<br>Asset(s): %{customdata[0]}<br>Buyer: %{customdata[1]}<br>Seller: %{customdata[2]}<extra></extra>`,
      name: '',
    },
  ];

  const chart2Data = [
    {
      x: barelRatioYears,
      y: barelRatioData,
      mode: 'markers',
      type: 'scatter',
      customdata: customDataChart2,
      hovertemplate:
        'Year: %{x|%Y}<br>Price Per Flowing Barrel ($/boe): %{y}<br>Asset(s): %{customdata[0]}<br>Buyer: %{customdata[1]}<br>Seller: %{customdata[2]}<extra></extra>',
    },
  ];

  return (
    <PageShell filters={filters}>
      <div className={classes.graphWrapper}>
        <ChartWithMetrics
          data={chart1Data}
          graphLayoutSettings={getGraphLayoutSettings({
            xAxisType: 'year',
            yAxisTitle: 'US$/boe',
            marginBottom: 20,
          })}
          graphLayoutConfig={GRAPH_LAYOUT_CONFIG}
          title={FIRST_CHART_TITLE}
          enableChartSettingsButton={false}
        />
        <ChartWithMetrics
          data={chart2Data}
          graphLayoutSettings={getGraphLayoutSettings({
            xAxisType: 'year',
            yAxisTitle: 'US$/boe',
            marginBottom: 20,
          })}
          graphLayoutConfig={GRAPH_LAYOUT_CONFIG}
          title={'Price Per Flowing Barrel'}
          enableChartSettingsButton={false}
        />
        <ChartWithMetrics
          data={chart3Data}
          graphLayoutSettings={getGraphLayoutSettings({
            showlegend: showPlotlyLegend && !stackByWarning && stackBy !== STACK_BY_TYPES.NONE,
            yAxisTitle: graph3YType + ` (${chart3Unit})`,
            yAxisSecondaryTitle: graphYSecondaryType,
            xTickAngle:
              graph3XType === COMP_GRAPH_TYPES.SELLER_NAME ||
              graph3XType === COMP_GRAPH_TYPES.BUYER_NAME
                ? 45
                : 'auto',
          })}
          graphLayoutConfig={GRAPH_LAYOUT_CONFIG}
          title={chart3Name}
          showPlotlyLegendToggle={true}
          showPlotlyLegend={showPlotlyLegend}
          setShowPlotlyLegend={setShowPlotlyLegend}
          x={{ value: graph3XType, setValue: updateGraph3XType, options: compOptions }}
          y={{ value: graph3YType, setValue: updateGraph3YType, options: compYOptions }}
          ySecondary={{
            value: graphYSecondaryType,
            setValue: updateGraphYSecondaryType,
            options: secondaryYAxis,
          }}
          stackBy={{ value: stackBy, setValue: updateStackBy, disabledReason: stackByWarning }}
          getStackByOptions={(xValue) => {
            if (xValue === COMP_GRAPH_TYPES.BUYER_NAME || xValue === COMP_GRAPH_TYPES.SELLER_NAME) {
              return ['Enabled', STACK_BY_TYPES.NONE];
            } else {
              return stackByOptionsSimple;
            }
          }}
        />
        <ChartWithMetrics
          data={chart4Data}
          graphLayoutSettings={getGraphLayoutSettings({
            showlegend: showPlotlyLegend4,
            xTickAngle:
              graph4Type === COST_GRAPH_TYPES.SELLER_NAME ||
              graph4Type === COST_GRAPH_TYPES.BUYER_NAME
                ? 45
                : 'auto',
          })}
          graphLayoutConfig={GRAPH_LAYOUT_CONFIG}
          title={'Deal Count by Event Type'}
          showPlotlyLegendToggle={true}
          showPlotlyLegend={showPlotlyLegend4}
          setShowPlotlyLegend={setShowPlotlyLegend4}
          x={{ value: graph4Type, setValue: updateGraph4Type, options: costGraphTypesSimple }}
        />
      </div>
    </PageShell>
  );
};
