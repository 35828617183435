import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useMaFilterContext } from '../../helpers/MaFilterProvider';

export const usePageShellController = (filters) => {
  let location = useLocation();
  const {
    chosenYears,
    updateCountryList,
    updateTypeList,
    updateCompanySellerList,
    updateCompanyBuyerList,
    updateAssetList,
    updateChosenYears,
    resetFilters,
  } = useMaFilterContext();

  const {
    availableYearsForSlider,
    typesAvailableList,
    countryAvailableList,
    companySellerAvailableList,
    companyBuyerAvailableList,
    assetAvailableList,
  } = filters;

  const currentUrlNode = useMemo(() => location.pathname.split('/').slice(-1), [location.pathname]);

  const handleChangeValueSlider = (_, newValue) =>
    updateChosenYears({ from: newValue[0], to: newValue[1] });

  let marks = [];
  if (availableYearsForSlider) {
    const from = availableYearsForSlider[0];
    const to = availableYearsForSlider[availableYearsForSlider.length - 1];

    const middleMarks = [];
    if (to - from > 1) {
      const difference = to - from;
      const distance = Math.floor(difference / 3);
      middleMarks.push({
        value: from + distance,
        label: from + distance,
      });
      middleMarks.push({
        value: to - distance,
        label: to - distance,
      });
    } else if (to - from > 0) {
      middleMarks.push({
        value: to - 1,
        label: to - 1,
      });
    }

    marks = [
      {
        value: from,
        label: from,
      },
      ...middleMarks,
      {
        value: to,
        label: to,
      },
    ];
  }

  return {
    marks,
    handleChangeValueSlider,
    currentUrlNode,
    typesAvailableList,
    countryAvailableList,
    companySellerAvailableList,
    companyBuyerAvailableList,
    assetAvailableList,
    updateCountryList,
    valueSlider: chosenYears,
    updateTypeList,
    updateCompanySellerList,
    updateCompanyBuyerList,
    updateAssetList,
    resetFilters,
  };
};
