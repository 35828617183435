import { ORDER_BY_OPTIONS, ORDER_OPTIONS, PER_PAGE_OPTIONS } from './types';
import { createContext, useContext, useState } from 'react';

import React from 'react';

const VideoTutorialsContext = createContext();

const VideoTutorialsProvider = ({ children }) => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(PER_PAGE_OPTIONS[0]);
  const [order, setOrder] = useState(ORDER_OPTIONS[0].value);
  const [orderBy, setOrderBy] = useState(ORDER_BY_OPTIONS[0].value);

  return (
    <VideoTutorialsContext.Provider
      value={{
        selectedVideo,
        setSelectedVideo,
        search,
        setSearch,
        page,
        setPage,
        perPage,
        setPerPage,
        order,
        setOrder,
        orderBy,
        setOrderBy,
      }}
    >
      {children}
    </VideoTutorialsContext.Provider>
  );
};

const useVideoTutorials = () => useContext(VideoTutorialsContext);

export { VideoTutorialsProvider, useVideoTutorials };
