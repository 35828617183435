import {
  DIAMETER_SUMMARY_ITEM,
  OPERATOR_SUMMARY_ITEM,
} from '../../../SummaryList/SummaryItems/base';
import {
  NAME_PIPELINE_NETWORK_SUMMARY_ITEM,
  STATUS_PIPELINE_NETWORK_SUMMARY_ITEM,
  CAPACITY_PIPELINE_NETWORK_SUMMARY_ITEM,
  HYDROCARBON_PIPELINE_NETWORK_SUMMARY_ITEM,
  GRAPH_THROUGHPUT_PIPELINE_NETWORK_SUMMARY_ITEM,
} from '../../../SummaryList/SummaryItems/pipelineNetwork';

export default () => {
  return [
    NAME_PIPELINE_NETWORK_SUMMARY_ITEM,
    OPERATOR_SUMMARY_ITEM,
    STATUS_PIPELINE_NETWORK_SUMMARY_ITEM,
    DIAMETER_SUMMARY_ITEM,
    CAPACITY_PIPELINE_NETWORK_SUMMARY_ITEM,
    HYDROCARBON_PIPELINE_NETWORK_SUMMARY_ITEM,
    GRAPH_THROUGHPUT_PIPELINE_NETWORK_SUMMARY_ITEM,
  ];
};
