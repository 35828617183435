import { useDefaultFilterOptions } from '../../helpers/useDefaultFilterOptions';
import { useDataOnUpdate } from '../../helpers/useDataOnUpdate';
import { useState } from 'react';
import { COMP_GRAPH_TYPES, COMP_Y_GRAPH_TYPES } from './TransactionAnalytics.constant';
import { getCalculatePageContentDataCb } from './helpers/getCalculatePageContentDataCb';
import { getChart3Data } from './helpers/getChart3Data';
import { getCostCountChartData } from '../../helpers/getCostCountChartData';
import { COST_GRAPH_TYPES } from '../../helpers/countGraphConstatns';
import { STACK_BY_TYPES } from '../../helpers/stackByConstatnts';
import { SECONDARY_Y_AXIS_TYPES } from '../../helpers/secondaryYAxisConstatns';
import { sortBy } from '../../helpers/sortBy';
import {
  SESSION_STORAGE_KEY_MA_CA_CH3_STACK,
  SESSION_STORAGE_KEY_MA_CA_CH3_SY,
  SESSION_STORAGE_KEY_MA_CA_CH3_X,
  SESSION_STORAGE_KEY_MA_CA_CH3_Y,
  SESSION_STORAGE_KEY_MA_CA_CH4_X,
} from '../../../../utils/constants';

const CHART_CAP = 300000;

const concatNames = (dataPerYear, dealId, maxLength = 42) => {
  const assetName = Object.keys(dataPerYear[dealId] || {})
    .sort()
    .join(', ');
  return assetName.length > maxLength ? assetName.substring(0, maxLength) + '..' : assetName;
};

export const useTransactionAnalyticsController = (data) => {
  const [showPlotlyLegend, setShowPlotlyLegend] = useState(true);
  const [showPlotlyLegend4, setShowPlotlyLegend4] = useState(true);
  const [stackBy, setStackBy] = useState(
    sessionStorage.getItem(SESSION_STORAGE_KEY_MA_CA_CH3_STACK) || STACK_BY_TYPES.SELLER,
  );
  const [graph3XType, setGraph3XType] = useState(
    sessionStorage.getItem(SESSION_STORAGE_KEY_MA_CA_CH3_X) || COMP_GRAPH_TYPES.YEAR,
  );
  const [graph3YType, setGraph3YType] = useState(
    sessionStorage.getItem(SESSION_STORAGE_KEY_MA_CA_CH3_Y) || COMP_Y_GRAPH_TYPES.PRODUCTION,
  );
  const [graph4Type, setGraph4Type] = useState(
    sessionStorage.getItem(SESSION_STORAGE_KEY_MA_CA_CH4_X) || COST_GRAPH_TYPES.YEAR,
  );
  const [graphYSecondaryType, setGraphYSecondaryType] = useState(
    sessionStorage.getItem(SESSION_STORAGE_KEY_MA_CA_CH3_SY) ||
      SECONDARY_Y_AXIS_TYPES.RESERVES_ACQUIRED,
  );

  const updateStackBy = (value) => {
    setStackBy(value);
    sessionStorage.setItem(SESSION_STORAGE_KEY_MA_CA_CH3_STACK, value);
  };

  const updateGraph3XType = (value) => {
    setGraph3XType(value);
    sessionStorage.setItem(SESSION_STORAGE_KEY_MA_CA_CH3_X, value);
  };

  const updateGraph3YType = (value) => {
    setGraph3YType(value);
    sessionStorage.setItem(SESSION_STORAGE_KEY_MA_CA_CH3_Y, value);
  };

  const updateGraph4Type = (value) => {
    setGraph4Type(value);
    sessionStorage.setItem(SESSION_STORAGE_KEY_MA_CA_CH4_X, value);
  };

  const updateGraphYSecondaryType = (value) => {
    setGraphYSecondaryType(value);
    sessionStorage.setItem(SESSION_STORAGE_KEY_MA_CA_CH3_SY, value);
  };

  const { isLoading } = useDefaultFilterOptions(data);
  const pageContentInitData = {
    dealIds: [],
    reservesRatioTemp: {},
    barelRatioTemp: {},
    assetNamesPerDeal: {},
    workingInterest: {},
    assetNames: {},
    buyersPerDeal: {},
    sellersPerDeal: {},
    chart4XAxisList: {},
    countByType: {},
    graph3XAxisList: {},
    secondaryYChart3: {},
  };

  const calculatePageContentData = getCalculatePageContentDataCb(
    graph3XType,
    graph3YType,
    graph4Type,
    stackBy,
    graphYSecondaryType,
  );

  const { filters, pageContentDataResult } = useDataOnUpdate(
    data,
    pageContentInitData,
    calculatePageContentData,
    graph3XType,
    graph3YType,
    graph4Type,
    stackBy,
    graphYSecondaryType,
  );

  const {
    reservesRatioTemp,
    barelRatioTemp,
    assetNamesPerDeal,
    buyersPerDeal,
    sellersPerDeal,
    workingInterest,
    countByType,
    chart4XAxisList,
    graph3XAxisList,
    secondaryYChart3,
  } = pageContentDataResult || {
    reservesRatioTemp: {},
    barelRatioTemp: {},
    assetNamesPerDeal: {},
    buyersPerDeal: {},
    sellersPerDeal: {},
    workingInterest: {},
    countByType: {},
    chart4XAxisList: {},
    graph3XAxisList: {},
    secondaryYChart3: {},
  };

  const reservesRatioYears = [];
  const reservesRatioData = [];
  const customDataChart1 = [];
  const customDataChart2 = [];
  const reservesRatioTempSorted = Object.keys(reservesRatioTemp).sort((a, b) =>
    sortBy(reservesRatioTemp[a].date, reservesRatioTemp[b].date),
  );
  reservesRatioTempSorted.forEach((dealId) => {
    const reservesRationPerDeal = reservesRatioTemp[dealId];
    if (reservesRationPerDeal.valuation > 0) {
      const data = reservesRationPerDeal.valuation / reservesRationPerDeal.reserves;
      // chart it too zoomed out otherwise
      if (data < CHART_CAP) {
        reservesRatioYears.push(reservesRationPerDeal.date);
        reservesRatioData.push(data);

        const assetNameTrimmed = concatNames(assetNamesPerDeal, dealId);
        const buyerNameTrimmed = concatNames(buyersPerDeal, dealId);
        const sellerNameTrimmed = concatNames(sellersPerDeal, dealId);
        customDataChart1.push([assetNameTrimmed, buyerNameTrimmed, sellerNameTrimmed]);
      }
    }
  });

  const barelRatioYears = [];
  const barelRatioData = [];

  const barelRatioTempSorted = Object.keys(barelRatioTemp).sort((a, b) =>
    sortBy(barelRatioTemp[a].date, barelRatioTemp[b].date),
  );
  barelRatioTempSorted.forEach((dealId) => {
    const barelRatioPerDeal = barelRatioTemp[dealId];
    if (barelRatioPerDeal.production !== 0) {
      const data = (barelRatioPerDeal.valuation * 1e6) / barelRatioPerDeal.production;
      // chart it too zoomed out otherwise
      if (data < CHART_CAP) {
        barelRatioYears.push(barelRatioPerDeal.date);
        barelRatioData.push(data);

        const assetNameTrimmed = concatNames(assetNamesPerDeal, dealId);
        const buyerNameTrimmed = concatNames(buyersPerDeal, dealId);
        const sellerNameTrimmed = concatNames(sellersPerDeal, dealId);
        customDataChart2.push([assetNameTrimmed, buyerNameTrimmed, sellerNameTrimmed]);
      }
    }
  });

  const { chart3Data, chart3Unit, chart3Name, stackByWarning } = getChart3Data(
    graph3XType,
    graph3YType,
    workingInterest,
    graph3XAxisList,
    stackBy,
    secondaryYChart3,
    graphYSecondaryType,
  );

  const chart4Data = getCostCountChartData(countByType, chart4XAxisList, graph4Type);

  return {
    isLoading,
    filters,
    reservesRatioYears,
    reservesRatioData,
    barelRatioYears,
    barelRatioData,
    customDataChart1,
    customDataChart2,
    updateGraph3XType,
    graph3XType,
    graph3YType,
    updateGraph3YType,
    chart3Data,
    chart3Unit,
    chart3Name,
    graph4Type,
    updateGraph4Type,
    chart4Data,
    showPlotlyLegend,
    setShowPlotlyLegend,
    stackBy,
    updateStackBy,
    stackByWarning,
    graphYSecondaryType,
    updateGraphYSecondaryType,
    setShowPlotlyLegend4,
    showPlotlyLegend4,
  };
};
