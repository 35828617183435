import {
  COUNTRY_SUMMARY_ITEM,
  DESTINATION_SUMMARY_ITEM,
  DIAMETER_SUMMARY_ITEM,
  OPERATOR_SUMMARY_ITEM,
  ORIGIN_SUMMARY_ITEM,
} from '../../../SummaryList/SummaryItems/base';
import {
  COST_PIPELINE_SUMMARY_ITEM,
  GAS_CAPACITY_PIPELINE_SUMMARY_ITEM,
  LENGTH_PIPELINE_SUMMARY_ITEM,
  LIQUID_CAPACITY_PIPELINE_SUMMARY_ITEM,
  NAME_PIPELINE_SUMMARY_ITEM,
  NETWORK_PIPELINE_SUMMARY_ITEM,
  PARTICIPATION_PIPELINE_SUMMARY_ITEM,
  SEGMENT_PIPELINE_SUMMARY_ITEM,
  SPONSOR_PIPELINE_SUMMARY_ITEM,
  START_DATE_PIPELINE_SUMMARY_ITEM,
  STATUS_PIPELINE_SUMMARY_ITEM,
  TYPE_PIPELINE_SUMMARY_ITEM,
} from '../../../SummaryList/SummaryItems/pipeline';

export default (layer) => {
  if (layer === 'MEX') {
    return [
      NAME_PIPELINE_SUMMARY_ITEM,
      COUNTRY_SUMMARY_ITEM,
      TYPE_PIPELINE_SUMMARY_ITEM,
      OPERATOR_SUMMARY_ITEM,
      LENGTH_PIPELINE_SUMMARY_ITEM,
      STATUS_PIPELINE_SUMMARY_ITEM,
      DIAMETER_SUMMARY_ITEM,
      ORIGIN_SUMMARY_ITEM,
      DESTINATION_SUMMARY_ITEM,
      GAS_CAPACITY_PIPELINE_SUMMARY_ITEM,
      COST_PIPELINE_SUMMARY_ITEM,
      SPONSOR_PIPELINE_SUMMARY_ITEM,
      START_DATE_PIPELINE_SUMMARY_ITEM,
    ];
  } else if (layer === 'COL') {
    return [
      NAME_PIPELINE_SUMMARY_ITEM,
      TYPE_PIPELINE_SUMMARY_ITEM,
      NETWORK_PIPELINE_SUMMARY_ITEM,
      SEGMENT_PIPELINE_SUMMARY_ITEM,
      LENGTH_PIPELINE_SUMMARY_ITEM,
      DIAMETER_SUMMARY_ITEM,
      ORIGIN_SUMMARY_ITEM,
      DESTINATION_SUMMARY_ITEM,
      LIQUID_CAPACITY_PIPELINE_SUMMARY_ITEM,
    ];
  } else if (layer === 'PER') {
    return [
      NAME_PIPELINE_SUMMARY_ITEM,
      TYPE_PIPELINE_SUMMARY_ITEM,
      STATUS_PIPELINE_SUMMARY_ITEM,
      OPERATOR_SUMMARY_ITEM,
      PARTICIPATION_PIPELINE_SUMMARY_ITEM,
      LENGTH_PIPELINE_SUMMARY_ITEM,
      DIAMETER_SUMMARY_ITEM,
      GAS_CAPACITY_PIPELINE_SUMMARY_ITEM,
      LIQUID_CAPACITY_PIPELINE_SUMMARY_ITEM,
    ];
  }

  return [
    NAME_PIPELINE_SUMMARY_ITEM,
    TYPE_PIPELINE_SUMMARY_ITEM,
    STATUS_PIPELINE_SUMMARY_ITEM,
    DIAMETER_SUMMARY_ITEM,
    GAS_CAPACITY_PIPELINE_SUMMARY_ITEM,
    LIQUID_CAPACITY_PIPELINE_SUMMARY_ITEM,
    COST_PIPELINE_SUMMARY_ITEM,
    ORIGIN_SUMMARY_ITEM,
    DESTINATION_SUMMARY_ITEM,
    START_DATE_PIPELINE_SUMMARY_ITEM,
  ];
};
