import { useDefaultFilterOptions } from '../../helpers/useDefaultFilterOptions';
import { useDataOnUpdate } from '../../helpers/useDataOnUpdate';
import { useState } from 'react';
import { COMP_BENCH_GRAPH_1_TYPES, COMP_BENCH_GRAPH_2_TYPES } from './CompanyBenchMarking.constant';
import { getChartData } from './helpers/getChartData';
import { getCalculatePageContentDataCb } from './helpers/getCalculatePageContentDataCb';
import { STACK_BY_TYPES } from '../../helpers/stackByConstatnts';
import {
  SESSION_STORAGE_KEY_MA_CB_CH1_STACK,
  SESSION_STORAGE_KEY_MA_CB_CH1_X,
  SESSION_STORAGE_KEY_MA_CB_CH2_STACK,
  SESSION_STORAGE_KEY_MA_CB_CH2_X,
} from '../../../../utils/constants';

export const useCompanyBenchMarkingController = (data) => {
  const pageContentDataInitial = {
    buyerXAxisList: {},
    chart1Data: {},
    chart2Data: {},
    graph1XAxisList: {},
    graph2XAxisList: {},
    secondaryYChart1: {},
    secondaryYChart2: {},
  };

  const [stackBy1Chart, setStackBy1Chart] = useState(
    sessionStorage.getItem(SESSION_STORAGE_KEY_MA_CB_CH1_STACK) || STACK_BY_TYPES.NONE,
  );
  const [stackBy2Chart, setStackBy2Chart] = useState(
    sessionStorage.getItem(SESSION_STORAGE_KEY_MA_CB_CH2_STACK) || STACK_BY_TYPES.NONE,
  );
  const [showPlotlyLegendChart1, setShowPlotlyLegendChart1] = useState(true);
  const [showPlotlyLegendChart2, setShowPlotlyLegendChart2] = useState(true);

  const [graph1Type, setGraph1Type] = useState(
    sessionStorage.getItem(SESSION_STORAGE_KEY_MA_CB_CH1_X) || COMP_BENCH_GRAPH_1_TYPES.BUYER_NAME,
  );
  const [graph2Type, setGraph2Type] = useState(
    sessionStorage.getItem(SESSION_STORAGE_KEY_MA_CB_CH2_X) || COMP_BENCH_GRAPH_2_TYPES.SELLER_NAME,
  );
  const { isLoading } = useDefaultFilterOptions(data);

  const updateStackBy1Chart = (stackBy) => {
    setStackBy1Chart(stackBy);
    sessionStorage.setItem(SESSION_STORAGE_KEY_MA_CB_CH1_STACK, stackBy);
  };

  const updateStackBy2Chart = (stackBy) => {
    setStackBy2Chart(stackBy);
    sessionStorage.setItem(SESSION_STORAGE_KEY_MA_CB_CH2_STACK, stackBy);
  };

  const updateGraph1Type = (graphType) => {
    setGraph1Type(graphType);
    sessionStorage.setItem(SESSION_STORAGE_KEY_MA_CB_CH1_X, graphType);
  };

  const updateGraph2Type = (graphType) => {
    setGraph2Type(graphType);
    sessionStorage.setItem(SESSION_STORAGE_KEY_MA_CB_CH2_X, graphType);
  };

  const calculatePageDataCb = getCalculatePageContentDataCb(
    graph1Type,
    graph2Type,
    stackBy1Chart,
    stackBy2Chart,
  );

  const { pageContentDataResult, filters } = useDataOnUpdate(
    data,
    pageContentDataInitial,
    calculatePageDataCb,
    graph1Type,
    graph2Type,
    stackBy1Chart,
    stackBy2Chart,
  );

  const {
    chart1Data,
    chart2Data,
    graph1XAxisList,
    graph2XAxisList,
    secondaryYChart1,
    secondaryYChart2,
  } = pageContentDataResult || {
    chart1Data: {},
    chart2Data: {},
    graph1XAxisList: {},
    graph2XAxisList: {},
    secondaryYChart1: {},
    secondaryYChart2: {},
  };

  const {
    name: chart1Title,
    data: graph1Data,
    stackByWarning: stackByWarning1Chart,
  } = getChartData(graph1Type, graph1XAxisList, chart1Data, stackBy1Chart, secondaryYChart1);

  const {
    name: chart2Title,
    data: graph2Data,
    stackByWarning: stackByWarning2Chart,
  } = getChartData(graph2Type, graph2XAxisList, chart2Data, stackBy2Chart, secondaryYChart2);

  return {
    isLoading,
    filters,
    chart1Data: graph1Data,
    chart2Data: graph2Data,
    chart1Title,
    chart2Title,
    graph1Type,
    updateGraph1Type,
    graph2Type,
    updateGraph2Type,
    stackByWarning1Chart,
    updateStackBy1Chart,
    stackBy1Chart,
    stackByWarning2Chart,
    updateStackBy2Chart,
    stackBy2Chart,
    showPlotlyLegendChart1,
    setShowPlotlyLegendChart1,
    showPlotlyLegendChart2,
    setShowPlotlyLegendChart2,
  };
};
