import { useQuery } from '@apollo/client';
import { HISTORICAL_PARTICIPATIONS_QUERY } from '../operations';

export const useGetHistoricalParticipation = (displayedItem) => {
  const { data } = useQuery(HISTORICAL_PARTICIPATIONS_QUERY, {
    skip: !displayedItem,
    fetchPolicy: 'network-only',
    variables: {
      entityId: displayedItem?.id,
      entityType: displayedItem?.entityType,
      grouped: true,
      orderBy: 'effectiveDate',
      order: 'asc',
    },
  });

  return data?.participations?.participations || [];
};
