import { gql } from '@apollo/client';

export const MA_QUERY = gql`
  query mergesAcquisitions {
    mergesAcquisitions {
      id
      dealName
      considerationMmusd
      dealType
      effectiveDate
      comment
      paymentType
      dealStatus
      source
      developmentStatus
      announcedDate
      closeDate
      acreage
      enterpriseValue
      reserves1p
      reserves2p
      liquidesReserves1p
      liquidesReserves2p
      gasReserves1p
      gasReserves2p
      production
      productionLiquids
      productionGas
      reservesPrice
      priceFlowing
      priceAcre
      currentEmissionsIntensity
      maCompanyTransactions {
        id
        buyerCompanyId
        buyerCompanyName
        sellerCompanyId
        sellerCompanyName
        soldCompanyId
        soldCompanyName
        buyerMotivation
        sellerMotivation
        stakeSold
        maAssetTransactions {
          id
          countryId
          countryName
          regionName
          assetName
          assetStatus
          weaWorkingInterestReserves
          weaWorkingInterestProduction
          weaUsdFlowingBbl
          weaReservesAcquisitionCost
          weaWorkingInterestValuation
          shoreStatus
          buyerStakeBefore
          buyerStakeAfter
          sellerStakeBefore
          sellerStakeAfter
          workingInterest
          weaWorkingInterestLiquidsReserves
          weaWorkingInterestGasReserves
          weaWorkingInterestLiquidsProduction
          weaWorkingInterestGasProduction
          weaCurrentEmissionsIntensity
          weaRemainingEmissionsIntensity
          weaWorkingInterestRemainingEmissions
          weaWorkingInterestCurrentEmissions
        }
      }
    }
  }
`;
