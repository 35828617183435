import { gql } from '@apollo/client';

export const SAVE_PORTFOLIO = gql`
  mutation CreateUserPortfolio(
    $context: String!
    $name: String!
    $notes: String!
    $portfolio: JSON!
  ) {
    createUserPortfolio(context: $context, name: $name, notes: $notes, portfolio: $portfolio) {
      userPortfolio {
        id
        name
        notes
        portfolio
      }
    }
  }
`;

export const LOAD_PORTFOLIO = gql`
  query UserPortfolios($context: String!) {
    userPortfolios(context: $context) {
      portfolios {
        id
        name
        notes
        portfolio
      }
      sharedPortfolios {
        id
        name
        notes
        portfolio
      }
    }
  }
`;

export const SHARE_PORTFOLIO = gql`
  mutation shareUserPortfolio($userPortfolioId: ID!, $users: [ID!]!) {
    shareUserPortfolio(userPortfolioId: $userPortfolioId, users: $users) {
      success
      errors
    }
  }
`;
