import React, { createContext, useState } from 'react';

const MapSearchContext = createContext();

const defaultState = {
  country: null,
  assets: null,
  blocks: null,
  eaWells: null,
  fields: null,
  wells: null,
  facilities: null,
  lngProjects: null,
  pipelineNetworks: null,
};

export const MapSearchProvider = (props) => {
  const [state, setState] = useState(defaultState);

  const resetState = () => {
    setState(defaultState);
  };

  const setFilter = (key, value) => {
    setState({ ...defaultState, ['country']: state.country, [key]: value });
  };

  return (
    <MapSearchContext.Provider value={{ state, setFilter, resetState }}>
      {props.children}
    </MapSearchContext.Provider>
  );
};

export const MapSearchConsumer = MapSearchContext.Consumer;

export default MapSearchContext;
