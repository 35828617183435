import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import ErrorBoundary from '../../ErrorBoundary';
import IndustryTrackers from './IndustryTrackers';
import Support from '../../Support';
import WatchList from './WatchList';
// import RecentlyUpdated from './RecentlyUpdated';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '275px',
  },
  scroll: {
    ...theme.palette.scrollbar,
    width: '100%',
    overflow: 'auto',
    overflowX: 'hidden',
  },
}));

const SideBar = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.scroll}>
        <ErrorBoundary>
          <WatchList />
        </ErrorBoundary>
        <ErrorBoundary>
          <IndustryTrackers />
        </ErrorBoundary>
        {/* <RecentlyUpdated /> */}
      </div>
      <Support />
    </div>
  );
};

export default SideBar;
