import { COUNTRY_SUMMARY_ITEM } from '../../../../../SummaryList/SummaryItems/base';
import {
  DISPLAY_NAME_FIELD_SUMMARY_ITEM,
  HYDROCARBON_TYPE_FIELD_SUMMARY_ITEM,
} from '../../../../../SummaryList/SummaryItems/field';

export default [
  DISPLAY_NAME_FIELD_SUMMARY_ITEM,
  COUNTRY_SUMMARY_ITEM,
  HYDROCARBON_TYPE_FIELD_SUMMARY_ITEM,
];
