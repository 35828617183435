export const getChartDataXY = (
  yAxisDataDoubleObject,
  xAxisArray,
  hoverTemplateCb,
  additionalDetails,
) => {
  return Object.keys(yAxisDataDoubleObject).map((outerKeyStackedBy) => ({
    x: xAxisArray,
    y: xAxisArray.map((xAxisKey) => yAxisDataDoubleObject[outerKeyStackedBy][xAxisKey] || 0),
    name: outerKeyStackedBy,
    hovertemplate: hoverTemplateCb ? hoverTemplateCb(outerKeyStackedBy) : undefined,
    ...additionalDetails,
  }));
};
