import { DATA_DOWNLOADS_MENU_ITEMS } from '../constants';
import { REGIONS_QUERY, COUNTRIES_QUERY } from './operations';

export const filterTypes = [
  {
    name: 'downloadType',
    label: 'DOWNLOAD TYPE',
    query: null,
    menuItems: DATA_DOWNLOADS_MENU_ITEMS,
    optionKey: 'name',
    runQueryOnChange: false,
    queryVariable: null,
    multiple: false,
  },
  {
    name: 'regions',
    label: 'REGION',
    query: REGIONS_QUERY,
    menuItems: null,
    optionKey: 'displayName',
    runQueryAfterOnChange: false,
    queryVariable: null,
    multiple: true,
  },
  {
    name: 'countries',
    label: 'COUNTRY / AREA',
    query: COUNTRIES_QUERY,
    menuItems: null,
    optionKey: 'displayName',
    runQueryAfterOnChange: false,
    queryVariable: null,
    multiple: true,
  },
];
