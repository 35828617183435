import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { formatNumberFromString, removeDoubleNegative } from '../../../utils/helpers';

import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid2';
import InputItem from '../../InputList/inputItem';
import InputList from '../../InputList';
import Modal from '..';
import { PriceDecks } from '../ConsolidateAssets/PriceDecks';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import SnackbarContext from '../../SnackbarContext';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { requestMessage } from './helpers';

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '100%',
    margin: '1rem 0',
  },
  dividerWrapper: {
    position: 'relative',
    width: '100%',
  },
  radioGroup: {
    marginTop: '15px',
  },
  altButton: {
    ...theme.palette.altButton,
    marginRight: '10px',
    height: '100%',
  },
  buttonFill: {
    ...theme.palette.button,
  },
  button: {
    ...theme.palette.button,
    height: '100%',
    width: '128px',
  },
  buttonsContainer: {
    padding: '10px 0 10px 10px',
  },
  info: {
    position: 'relative',
    top: '5px',
    marginLeft: '5px',
  },
  inputHalfInflation: {
    width: '100%',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputHalf: {
    width: '50%',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputHalfAlternate: {
    width: '50%',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#f4f4f4f4',
  },
  inputHalfOutInflation: {
    display: 'flex',
    padding: '0 10px 10px',
    alignItems: 'center',
    flexDirection: 'column',
    width: '70%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem',
  },
  inputHalfOut: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    '& input': {
      width: '90%',
    },
    '& .MuiTextField-root': {
      width: '90%',
    },
  },
  inputHalfAlternateOut: {
    width: '80%',
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputContainer: {
    width: '95%',
  },
  title: {
    color: theme.palette.baseColors.deepSeaNavy.c100,
    fontSize: '1rem',
    fontWeight: 'bold',
    lineHeight: 1.5,
  },
  label: {
    ...theme.palette.alternateText,
    fontSize: '10px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1.5,
  },
  yearLabels: {
    color: theme.palette.baseColors.deepSeaNavy.c100,
    textAlign: 'center',
  },
  modal: {
    backgroundColor: 'white',
    height: 'auto',
    width: '75%',
    maxWidth: '800px',
  },
  disabledElement: {
    opacity: 0.8,
    pointerEvents: 'none',
  },
  spaceBetween: {
    flex: '0 1 auto',
    padding: '10px 20px',
    borderTop: 'solid rgba(0, 0, 0, 0.12) thin',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

// id - id attached to button for walkthrough
// styles - styles to override
// open - flag if modal is open
// setOpen - function to control state of modal
export default ({
  open,
  setOpen,
  assetIds,
  companyIds,
  assetUnits,
  discountRate,
  setDiscountRate,
  requestReport,
  setRequestingReport,
  baseYear,
  basePricesGlobalAssumptions,
  calculateBasePrices,
}) => {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);
  const webSocket = useRef(null);
  const [basePriceOil, setBasePriceOil] = useState('');
  const [y1PriceOil, setY1PriceOil] = useState('');
  const [y2PriceOil, setY2PriceOil] = useState('');
  const [y3PriceOil, setY3PriceOil] = useState('');
  const [y4PriceOil, setY4PriceOil] = useState('');
  const [escalatorOil, setEscalatorOil] = useState(2);
  const [basePriceGas, setBasePriceGas] = useState('');
  const [costInflation, setCostInflation] = useState(2);
  const [y1PriceGas, setY1PriceGas] = useState('');
  const [y2PriceGas, setY2PriceGas] = useState('');
  const [y3PriceGas, setY3PriceGas] = useState('');
  const [y4PriceGas, setY4PriceGas] = useState('');
  const [escalatorGas, setEscalatorGas] = useState(2);
  const [discountRateValue, setDiscountRateValue] = useState(discountRate);
  const [formMode, setFormMode] = useState('custom');
  const [emailReport, setEmailReport] = useState(true);
  const [reportName, setReportName] = useState('');

  const handleInput = (value, key) => {
    let val = formatNumberFromString(value);
    const valConverted = parseFloat(val);

    if (key === 'costInflation') {
      if (!isNaN(valConverted)) val = valConverted;
      if (valConverted > 20) val = 20.0;
      if (valConverted < -20) val = -20.0;

      setCostInflation(val);
    } else if (key === 'discountRate') {
      if (!isNaN(valConverted)) val = valConverted;
      if (valConverted > 100) val = 100.0;
      if (valConverted < 0) val = 0.0;

      setDiscountRateValue(val);
    } else if (['escalatorOil', 'escalatorGas'].includes(key)) {
      if (valConverted > 20) val = 20.0;
      if (valConverted < -20) val = -20.0;

      key.includes('Oil') ? setEscalatorOil(val) : setEscalatorGas(val);
    } else {
      if (valConverted > 500) val = 500.0;
      if (valConverted < 0) val = 0.0;

      switch (key) {
        case 'basePriceOil': {
          setBasePriceOil(val);
          break;
        }
        case 'basePriceGas': {
          setBasePriceGas(val);
          break;
        }
        case 'y1PriceOil': {
          setY1PriceOil(val);
          break;
        }
        case 'y1PriceGas': {
          setY1PriceGas(val);
          break;
        }
        case 'y2PriceOil': {
          setY2PriceOil(val);
          break;
        }
        case 'y2PriceGas': {
          setY2PriceGas(val);
          break;
        }
        case 'y3PriceOil': {
          setY3PriceOil(val);
          break;
        }
        case 'y3PriceGas': {
          setY3PriceGas(val);
          break;
        }
        case 'y4PriceOil': {
          setY4PriceOil(val);
          break;
        }
        case 'y4PriceGas': {
          setY4PriceGas(val);
          break;
        }
      }
    }
  };

  useEffect(() => {
    return () => {
      webSocket.current?.close();
    };
  }, []);

  // sync discount rate when it changes in the graph controls
  useEffect(() => {
    setDiscountRateValue(discountRate);
  }, [discountRate]);

  const validOilValues = () => {
    const validOilPrices =
      (!y1PriceOil && !y2PriceOil) || (y1PriceOil && !y2PriceOil) || (y1PriceOil && y2PriceOil);
    return basePriceOil && validOilPrices && escalatorOil;
  };

  const validGasValues = () => {
    const validGasPrices =
      (!y1PriceGas && !y2PriceGas) || (y1PriceGas && !y2PriceGas) || (y1PriceGas && y2PriceGas);
    return basePriceGas && validGasPrices && escalatorGas;
  };

  const validFieldValues = () => {
    return validOilValues() || validGasValues();
  };

  const fieldsValidation = () => {
    const missingFields = [];
    if (!basePriceOil) {
      missingFields.push(`Year ${baseYear} (Oil)`);
    }
    if (!escalatorOil) {
      missingFields.push('Escalator (Oil)');
    }
    if (!y1PriceOil && y2PriceOil) {
      missingFields.push(`Year ${baseYear + 1} (Oil)`);
    }
    if (!basePriceGas) {
      missingFields.push(`Year ${baseYear} (Gas)`);
    }
    if (!escalatorGas) {
      missingFields.push('Escalator (Gas)');
    }
    if (!y1PriceGas && y2PriceGas) {
      missingFields.push(`Year ${baseYear + 1} (Gas)`);
    }

    return {
      isValid: validFieldValues(),
      message: `Please fill in the following fields: (${missingFields.join(', ')})`,
    };
  };

  const clear = () => {
    setBasePriceOil('');
    setY1PriceOil('');
    setY2PriceOil('');
    setY3PriceOil('');
    setY4PriceOil('');
    setEscalatorOil(2);
    setBasePriceGas('');
    setY1PriceGas('');
    setY2PriceGas('');
    setY3PriceGas('');
    setY4PriceGas('');
    setEscalatorGas(2);
    setCostInflation(2);
    setDiscountRateValue(discountRate);
    setFormMode('custom');
  };

  const priceDeck = () => {
    return {
      basePriceOil: basePriceOil,
      y1PriceOil: y1PriceOil,
      y2PriceOil: y2PriceOil,
      y3PriceOil: y3PriceOil,
      y4PriceOil: y4PriceOil,
      escalatorOil: escalatorOil,
      basePriceGas: basePriceGas,
      y1PriceGas: y1PriceGas,
      y2PriceGas: y2PriceGas,
      y3PriceGas: y3PriceGas,
      y4PriceGas: y4PriceGas,
      escalatorGas: escalatorGas,
      costInflation: costInflation,
      discountRate: discountRateValue,
    };
  };

  const setPriceDecks = (priceDeck) => {
    setBasePriceOil(priceDeck.basePriceOil);
    setY1PriceOil(priceDeck.y1PriceOil);
    setY2PriceOil(priceDeck.y2PriceOil);
    setY3PriceOil(priceDeck.y3PriceOil);
    setY4PriceOil(priceDeck.y4PriceOil);
    setEscalatorOil(priceDeck.escalatorOil);
    setBasePriceGas(priceDeck.basePriceGas);
    setY1PriceGas(priceDeck.y1PriceGas);
    setY2PriceGas(priceDeck.y2PriceGas);
    setY3PriceGas(priceDeck.y3PriceGas);
    setY4PriceGas(priceDeck.y4PriceGas);
    setEscalatorGas(priceDeck.escalatorGas);
    setCostInflation(priceDeck.costInflation);
    setDiscountRateValue(priceDeck.discountRate);
  };

  const handleRequestReport = (baseRun = false) => {
    let variables = null;

    if (baseRun) {
      setOpen(false);
      setRequestingReport(true);

      setSnack({
        open: true,
        severity: 'info',
        message: requestMessage(emailReport),
        disableHide: true,
      });

      variables = {
        assetIds,
        companyIds,
        assetUnits,
        discountRate,
        writeFlag: false,
        baseYear,
        basePrices: basePricesGlobalAssumptions,
        emailReport,
        reportName,
      };
    } else {
      // apply the discount rate when report is generated
      setDiscountRate(discountRateValue);

      const validation = fieldsValidation();

      if (!validation.isValid) {
        setSnack({
          open: true,
          message: validation.message,
          severity: 'error',
          disableHide: true,
        });

        return;
      } else {
        setOpen(false);
        setRequestingReport(true);

        setSnack({
          open: true,
          severity: 'info',
          message: requestMessage(emailReport),
          disableHide: true,
        });

        const discountRate = discountRateValue;
        let basePrices;

        let oilPrices = [...basePricesGlobalAssumptions.oil];
        let gasPrices = calculateBasePrices(
          basePriceGas,
          y1PriceGas,
          y2PriceGas,
          y3PriceGas,
          y4PriceGas,
          escalatorGas,
        );

        if (basePriceOil) {
          oilPrices = calculateBasePrices(
            basePriceOil,
            y1PriceOil,
            y2PriceOil,
            y3PriceOil,
            y4PriceOil,
            escalatorOil,
          );
        }

        basePrices = {
          oil: oilPrices,
          gas: gasPrices,
          escalatorOil: escalatorOil,
          escalatorGas: escalatorGas,
        };

        variables = {
          assetIds,
          companyIds,
          assetUnits,
          discountRate,
          writeFlag: true,
          baseYear,
          basePrices,
          emailReport,
          reportName,
        };

        if (!isNaN(parseFloat(costInflation))) {
          variables['costInflation'] = costInflation;
        }
      }
    }

    requestReport({ variables: variables });
    clear();
  };

  const basePriceOilId = 'base-price-oil';
  const discountRateId = 'discount-rate';
  const reportNameId = 'report-name';

  const inputItemsOil = useMemo(
    () => [
      {
        id: basePriceOilId,
        key: 'basePriceOil',
        required: true,
      },
      {
        key: 'y1PriceOil',
        required: !!y2PriceOil,
      },
      {
        key: 'y2PriceOil',
        required: false,
      },
      {
        key: 'y3PriceOil',
        required: false,
      },
      {
        key: 'y4PriceOil',
        required: false,
      },
      {
        key: 'escalatorOil',
        required: true,
      },
    ],
    [basePriceOil, y1PriceOil, y2PriceOil, escalatorOil],
  );

  const inputItemsGas = useMemo(
    () => [
      {
        key: 'basePriceGas',
        required: true,
      },
      {
        key: 'y1PriceGas',
        required: !!y2PriceGas,
      },
      {
        key: 'y2PriceGas',
        required: false,
      },
      {
        key: 'y3PriceGas',
        required: false,
      },
      {
        key: 'y4PriceGas',
        required: false,
      },
      {
        key: 'escalatorGas',
        required: true,
      },
    ],
    [basePriceGas, y1PriceGas, y2PriceGas, escalatorGas],
  );

  const onButtonKeyDown = (e) => {
    // Only handle the tab key:
    if (e.key !== 'Tab') {
      return;
    }

    e.preventDefault();

    // Move focus to the next element:
    const universe = document.querySelectorAll('input, button, select, textarea, a[href]');
    const list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= '0';
    });
    let index = list.indexOf(e.target) + (e.shiftKey ? -1 : 1);

    // If we're at the end, go to the start:
    if (index >= list.length) {
      index = list.findIndex((item) => item.id === discountRateId);
    }
    list[index].focus();
  };

  const handleClose = () => {
    setOpen(false);
    clear();
  };

  const disableAll = (event) => {
    const containers = document.getElementsByClassName('disabledContent');
    const type = event ? event.target.value : 'base';

    for (let container of containers) {
      const elements = container.getElementsByTagName('*');

      if (type === 'base') {
        for (let i = 0; i < elements.length; i++) {
          elements[i].disabled = true;
          elements[i].style.opacity = '0.8';
          elements[i].style.pointerEvents = 'none';
        }
      } else {
        for (let i = 0; i < elements.length; i++) {
          elements[i].disabled = '';
          elements[i].style.opacity = '';
          elements[i].style.pointerEvents = '';
        }
      }
    }
  };

  const handleChange = (event) => {
    setFormMode(event.target.value);
    disableAll(event);
  };

  return (
    <>
      <Modal
        handleClose={handleClose}
        open={open}
        title={'PORTFOLIO CASH FLOWS'}
        titleAlignedWithClose={true}
        styles={classes}
      >
        <Grid container direction="column">
          <Divider />
          <Grid container direction="column">
            <div className={classes.content}>
              <FormControl fullWidth={true}>
                <FormLabel id="row-radio-buttons-group-label" disabled={true}>
                  Select desired cash flow consolidation method:
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className={classes.radioGroup}
                  defaultValue={'custom'}
                  onChange={handleChange}
                >
                  <FormControlLabel value="custom" control={<Radio />} label="Custom prices" />
                  <FormControlLabel value="base" control={<Radio />} label="Base prices" />
                </RadioGroup>
              </FormControl>
              <div className={classes.dividerWrapper}>
                <Divider className={classes.divider} />
              </div>

              <div className="disabledContent" style={{ marginBottom: '1rem' }}>
                <Grid className={classes.inputHalfOut}>
                  <Typography className={classes.label} variant="overline">
                    Discount rate (%)
                  </Typography>
                  <InputItem
                    id={discountRateId}
                    required={false}
                    onChange={(e) =>
                      handleInput(
                        e.target.value.match(/^(\d|\.|-)+$/)
                          ? removeDoubleNegative(e.target.value)
                          : '',
                        'discountRate',
                      )
                    }
                    value={discountRateValue}
                    alignRight={true}
                    tab
                    originCashflow
                  />
                  <Box
                    className={classes.label}
                    style={{ marginLeft: '1rem' }}
                    sx={{
                      gridColumn: 'span 2',
                    }}
                  >
                    <Typography variant="overline" className={classes.label}>
                      Cost Inflation/Deflation - from Base Year (%)
                    </Typography>
                  </Box>

                  <InputItem
                    required={false}
                    onChange={(e) =>
                      handleInput(
                        e.target.value.match(/^(\d|\.|-)+$/)
                          ? removeDoubleNegative(e.target.value)
                          : '',
                        'costInflation',
                      )
                    }
                    value={costInflation}
                    alignRight={true}
                    tab
                    originCashflow
                  />

                  <Box
                    sx={{
                      gridColumn: 'span 2',
                    }}
                  />
                </Grid>
              </div>

              <div className="disabledContent">
                <Grid className={classes.inputHalfOut}>
                  <Typography className={classes.yearLabels} />
                  <Typography className={classes.yearLabels}>{baseYear || 'Year 1'}</Typography>
                  <Typography className={classes.yearLabels}>
                    {baseYear ? baseYear + 1 : 'Year 2'}
                  </Typography>
                  <Typography className={classes.yearLabels}>
                    {baseYear ? baseYear + 2 : 'Year 3'}
                  </Typography>
                  <Typography className={classes.yearLabels}>
                    {baseYear ? baseYear + 3 : 'Year 4'}
                  </Typography>
                  <Typography className={classes.yearLabels}>
                    {baseYear ? baseYear + 4 : 'Year 5'}
                  </Typography>
                  <Typography className={classes.yearLabels}>Escalator %</Typography>
                  <Typography className={classes.label} variant="overline">
                    Brent Price (US$/bbl)
                  </Typography>
                  <InputList
                    values={{
                      basePriceOil,
                      y1PriceOil,
                      y2PriceOil,
                      y3PriceOil,
                      y4PriceOil,
                      escalatorOil,
                    }}
                    setInput={handleInput}
                    items={inputItemsOil}
                    tab={true}
                    showDivider={false}
                    alignRight={true}
                    originCashflow={true}
                  />
                  <Typography className={classes.label} variant="overline">
                    Gas Price (US$/Mcf)
                  </Typography>
                  <InputList
                    values={{
                      basePriceGas,
                      y1PriceGas,
                      y2PriceGas,
                      y3PriceGas,
                      y4PriceGas,
                      escalatorGas,
                    }}
                    setInput={handleInput}
                    items={inputItemsGas}
                    tab={true}
                    showDivider={false}
                    alignRight={true}
                    originCashflow={true}
                  />
                </Grid>
              </div>

              <div className={classes.dividerWrapper}>
                <Divider className={classes.divider} />
              </div>

              <Grid className={classes.inputHalfOut}>
                <Typography className={classes.label} variant="overline">
                  Customize File Name
                </Typography>
                <Box
                  sx={{
                    gridColumn: 'span 2',
                  }}
                >
                  <InputItem
                    id={reportNameId}
                    required={false}
                    onChange={(e) => setReportName(e.target.value)}
                    value={reportName}
                    alignRight={true}
                    isMinMax={true}
                    tab
                    originCashflow
                  />
                </Box>
                <Box
                  sx={{
                    gridColumn: 'span 4',
                  }}
                />
              </Grid>
            </div>
            <div className={classes.spaceBetween}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  required
                  control={
                    <Switch checked={emailReport} onChange={() => setEmailReport(!emailReport)} />
                  }
                  label="Email Report"
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  className={classes.buttonsContainer}
                  justify-content="space-end"
                  sx={{
                    display: 'flex',
                  }}
                >
                  <div className="disabledContent">
                    <PriceDecks
                      id={'price-decks'}
                      priceDeck={priceDeck()}
                      setPriceDecks={setPriceDecks}
                    />
                  </div>
                  <div className={'disabledContent'}>
                    <Button
                      variant="contained"
                      className={classes.altButton}
                      onClick={clear}
                      onKeyDown={onButtonKeyDown}
                    >
                      Clear
                    </Button>
                  </div>
                  <Tooltip
                    title={
                      'The consolidated cash flow is generated under the custom oil and gas price assumptions entered by the user'
                    }
                  >
                    <span>
                      <Button
                        onClick={() => handleRequestReport(formMode === 'base')}
                        className={classes.button}
                        variant="contained"
                        onKeyDown={onButtonKeyDown}
                      >
                        Run Report
                      </Button>
                    </span>
                  </Tooltip>
                </Box>
              </div>
            </div>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
