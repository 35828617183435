import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import React from 'react';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.palette.transparentBackgroundAccentButton,
    borderRadius: '5px',
    padding: '10px',
    margin: '5px',
    width: '100%',
    alignItems: 'start',
    textTransform: 'none',
  },
  header: {
    ...theme.palette.baseColors.iceBlue.c100,
    textAlign: 'start',
  },
  label: {
    textAlign: 'start',
  },
}));

export default ({ title, onClickCallback = () => {}, Icon = null, header = null }) => {
  const classes = useStyles();

  return (
    <Button className={classes.root} onClick={onClickCallback} variant="contained">
      <Grid container size={12} direction="column">
        {header && (
          <Typography variant="overline" className={classes.header}>
            {header}
          </Typography>
        )}
        <Typography variant="subtitle1" className={classes.label}>
          {title}
        </Typography>
      </Grid>
      {Icon && <Icon />}
    </Button>
  );
};
