import { useMemo } from 'react';

export const useMarks = (from, to, loading) => {
  const middleMarks = [];
  if (to - from > 1) {
    const difference = to - from;
    const distance = Math.floor(difference / 3);
    middleMarks.push({
      value: from + distance,
      label: from + distance,
    });
    middleMarks.push({
      value: to - distance,
      label: to - distance,
    });
  } else if (to - from > 0) {
    middleMarks.push({
      value: to - 1,
      label: to - 1,
    });
  }

  return useMemo(() => {
    if (loading || !from || !to) {
      return [];
    }

    return [
      {
        value: from,
        label: from,
      },
      ...middleMarks,
      {
        value: to,
        label: to,
      },
    ];
  }, [from, to, loading]);
};
