import { gql } from '@apollo/client';

export const UPDATE_ME_MUTATION = gql`
  mutation UpdateMe(
    $firstName: String!
    $lastName: String!
    $email: String!
    $newPassword: String
    $newPasswordConf: String
  ) {
    updateMe(
      firstName: $firstName
      lastName: $lastName
      email: $email
      newPassword: $newPassword
      newPasswordConf: $newPasswordConf
    ) {
      user {
        firstName
        lastName
        email
      }
    }
  }
`;

export const SEND_USER_RESET_TOKEN = gql`
  mutation UserSendPasswordResetWithToken($email: String!, $redirectUrl: String!) {
    userSendPasswordResetWithToken(email: $email, redirectUrl: $redirectUrl) {
      message
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UserUpdatePasswordWithToken(
    $resetPasswordToken: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    userUpdatePasswordWithToken(
      resetPasswordToken: $resetPasswordToken
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      authenticatable {
        email
      }
    }
  }
`;
