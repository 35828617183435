import { gql } from '@apollo/client';

export const SEARCH_RESULTS = gql`
  query fulltextSearch($query: String!, $filter: FulltextSearchFilterInput!) {
    fulltextSearch(query: $query, filter: $filter) {
      rank
      id
      linkId
      isoCode
      name
      entity
      description
      actions
      geom
      countryGeom
    }
  }
`;

export const SEARCH_REPORTS_RESULTS = gql`
  query fulltextReportsSearch($query: String!) {
    fulltextReportsSearch(query: $query) {
      id
      name
      slug
      actions
      rows
      imageUrl
      thumbnailUrl
      date
    }
  }
`;
