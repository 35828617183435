import { gql } from '@apollo/client';

export const MA_DEALS_QUERY = gql`
  query maDeals(
    $assets: [Int!]
    $countries: [String!]
    $buyers: [Int!]
    $sellers: [Int!]
    $eventTypes: [String!]
    $startYear: Int
    $endYear: Int
  ) {
    maDeals(
      assets: $assets
      countries: $countries
      buyers: $buyers
      sellers: $sellers
      eventTypes: $eventTypes
      startYear: $startYear
      endYear: $endYear
    ) {
      effectiveDate
      acquisitionCostSum
      priceFlowingBarrelSum

      maCompanyTransactions {
        buyerCompany {
          name
        }

        sellerCompany {
          name
        }

        soldCompany {
          name
        }

        maAssetTransactions {
          assetName
        }
      }
    }
  }
`;
