import GetAppIcon from '@mui/icons-material/GetApp';
import LaunchIcon from '@mui/icons-material/Launch';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { formatDate, getLatLongFromPoint } from '../../../utils/helpers';
import {
  getDeepDives,
  getNewsPosts,
  replaceSpecialCharacters,
  WORDPRESS_COUNTRY_IDS,
} from '../../WordPress';
// TODO: rethink how we do exceptions
import { hasMA, mapAndFieldException } from '../../Valuations/Sidebar/Panel/exceptions';
import { GA_PAGE } from '../constants';
import { defaultPortfolio } from '../../Portfolio';
import { defaultFilters } from '../../Valuations';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_EVENT_ACTIONS,
  OIL_AND_GAS_MAP_PATH,
  PORTFOLIO_ANALYSIS_PATH,
  VALUATIONS_PATH,
} from '../../../utils/constants';
import { getUserId } from '../../../utils/auth';

const analytics = {
  'COUNTRY OVERVIEW REPORT (CORE)': 'core_report',
  'MAP DATA': 'map_data',
  'POTENTIAL M&A OPPORTUNITIES': 'ma_opportunities_data',
  'EXPLORATION AND APPRAISAL WELL TRACKER': 'offshore_well_tracker_data',
  'LICENSING ROUND & DRO TRACKER': 'licensing_dro_data',
};

export const getNewPortfolio = (country) => {
  const newPortfolio = {
    ...defaultPortfolio,
    filters: {
      ...defaultPortfolio.filters,
    },
  };
  newPortfolio.filters.countries = [country];
  return newPortfolio;
};

export const setCountryForPortfolioAnalysis = (portfolio) => {
  sessionStorage.setItem('portfolio', JSON.stringify(portfolio));
};

const setCountryForValuations = (country) => {
  const newFilters = { ...defaultFilters };
  newFilters.COUNTRY = [country];
  sessionStorage.setItem('valuationFilters', JSON.stringify(newFilters));
};

export const createDefaultLinks = (country, getShinyUrl) => {
  if (country === null) return {};
  const explore = [];
  if (hasMA[country.isoCode]) {
    explore.push({
      title: 'M&A Evaluation',
      page: 'participation_changes',
      type: 'shiny',
      gA: () =>
        gtag('event', ANALYTICS_EVENTS.event, {
          event_category: GA_PAGE,
          event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
          event_label: 'ma_analytics',
          userId: getUserId(),
          asset: 'NULL',
          country: country.name,
          company: 'NULL',
        }),
    });
  }
  if (!mapAndFieldException[country.isoCode]) {
    explore.push(
      {
        title: 'Field Technical Analysis',
        page: 'fields',
        type: 'shiny',
        gA: () =>
          gtag('event', ANALYTICS_EVENTS.event, {
            event_category: GA_PAGE,
            event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
            event_label: 'field_analytics',
            userId: getUserId(),
            asset: 'NULL',
            country: country.name,
            company: 'NULL',
          }),
      },
      {
        title: 'Heat Maps',
        page: 'map',
        type: 'shiny',
        gA: () =>
          gtag('event', ANALYTICS_EVENTS.event, {
            event_category: GA_PAGE,
            event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
            event_label: 'heatmap_analytics',
            userId: getUserId(),
            asset: 'NULL',
            country: country.name,
            company: 'NULL',
          }),
      },
    );
  }
  explore.push({
    title: 'Oil and Gas Map',
    link: `${OIL_AND_GAS_MAP_PATH}/[${getLatLongFromPoint(country.geom)}]/${country.zoom}`,
    type: 'link',
    gA: () =>
      gtag('event', ANALYTICS_EVENTS.event, {
        event_category: GA_PAGE,
        event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
        event_label: 'oil_gas_map',
        userId: getUserId(),
        asset: 'NULL',
        country: country.name,
        company: 'NULL',
      }),
  });
  explore.push({
    title: 'Portfolio Analysis',
    link: `${PORTFOLIO_ANALYSIS_PATH}`,
    type: 'link',
    gA: () =>
      gtag('event', ANALYTICS_EVENTS.event, {
        event_category: GA_PAGE,
        event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
        event_label: 'portfolio_analysis',
        userId: getUserId(),
        asset: 'NULL',
        country: country.name,
        company: 'NULL',
      }),
    callback: () => {
      const portfolio = getNewPortfolio(country);
      setCountryForPortfolioAnalysis(portfolio);
    },
  });
  explore.push({
    title: 'Valuations and Reports',
    link: `${VALUATIONS_PATH}`,
    type: 'link',
    gA: () =>
      gtag('event', ANALYTICS_EVENTS.event, {
        event_category: GA_PAGE,
        event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
        event_label: 'valuations',
        userId: getUserId(),
        asset: 'NULL',
        country: country.name,
        company: 'NULL',
      }),
    callback: () => setCountryForValuations(country),
  });

  return {
    title: 'EXPLORE MORE',
    list: explore.map((e) => ({
      Icon: LaunchIcon,
      title: e.title,
      onClick:
        e.type === 'shiny'
          ? () => {
              e.gA();
              getShinyUrl({
                variables: { page: e.page, iso: country.isoCode },
              });
            }
          : () => {
              if (e.callback) {
                e.callback();
              }
              e.gA();
              window.location.href = e.link;
            },
    })),
  };
};

export const createCountryNewsLinks = (country) => {
  const countryWordPressId = WORDPRESS_COUNTRY_IDS[country.name.toLowerCase()];
  if (!countryWordPressId) return {};

  return getNewsPosts(countryWordPressId).then((news) => {
    return {
      title: 'COUNTRY NEWS',
      list: news.map((n) => ({
        Icon: ListAltIcon,
        onClick: () => {
          gtag('event', ANALYTICS_EVENTS.event, {
            event_category: GA_PAGE,
            event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
            event_label: 'country_news',
            userId: getUserId(),
            asset: 'NULL',
            country: country.name,
            company: 'NULL',
          }),
            window.open(n.link, '_blank');
        },
        header: formatDate(n.date),
        title: replaceSpecialCharacters(n.title.rendered),
      })),
    };
  });
};

export const createDeepDiveLinks = (country) => {
  const countryWordPressId = WORDPRESS_COUNTRY_IDS[country.name.toLowerCase()];
  if (!countryWordPressId) return {};

  return getDeepDives(countryWordPressId).then((news) => {
    return {
      title: 'DEEP DIVES',
      list: news.map((n) => ({
        Icon: ListAltIcon,
        onClick: () => {
          gtag('event', ANALYTICS_EVENTS.event, {
            event_category: GA_PAGE,
            event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
            event_label: 'deep_dives',
            userId: getUserId(),
            asset: 'NULL',
            country: country.name,
            company: 'NULL',
          }),
            window.open(n.link, '_blank');
        },
        header: formatDate(n.date),
        title: replaceSpecialCharacters(n.title.rendered),
      })),
    };
  });
};

export const createCountryDownloadLinks = (country, reports = [], getSecureCountryDownload) =>
  reports.length === 0
    ? {}
    : {
        title: `${country.name.toUpperCase()} DOWNLOADS`,
        list: reports.map((title) => ({
          Icon: GetAppIcon,
          title,
          onClick: () => {
            gtag('event', ANALYTICS_EVENTS.event, {
              event_category: GA_PAGE,
              event_action: ANALYTICS_EVENT_ACTIONS.download,
              event_label: analytics[title],
              userId: getUserId(),
              asset: 'NULL',
              country: country.name,
              company: 'NULL',
            }),
              getSecureCountryDownload({
                variables: { downloadType: title, countryIsoCode: country.isoCode },
              });
          },
        })),
      };
