import { useQuery } from '@apollo/client';
import { Button, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import ErrorBoundary from '../../ErrorBoundary';
import { downloadSummaries } from './constants';
import { downloadXlsx } from '../../Downloads/helpers';
import { DATA_DOWNLOADS_META_DATA_QUERY } from './operations';
import Loading from '../../Loading';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexDirection: 'column',
    // overflow: 'auto',
    // minHeight: '100%',
    width: '20%',
    marginLeft: 'auto',
    marginRight: 'auto',
    ...theme.palette.scrollbar,
  },
}));

const defaultMetaData = {
  totalCount: 0,
};

export default ({ filters }) => {
  const classes = useStyles();
  const [downloadMetaData, setDownloadMetaData] = useState(defaultMetaData);

  const downloadType = filters?.downloadType?.name;
  const countries = filters?.countries.map((country) => country.displayName);
  const countryIsoCodes = filters?.countries.map((country) => country.isoCode);

  const { data, loading } = useQuery(DATA_DOWNLOADS_META_DATA_QUERY(downloadType), {
    variables: {
      downloadType: downloadType,
      countryIsoCodes: countryIsoCodes,
    },
  });

  const downloadCallback = () => {
    const path = 'data-download/export-to-xlsx';
    const body = {
      downloadType,
      countryIsoCodes,
    };
    const downloadName = `${downloadSummaries[downloadType]?.filename(countries)}.xlsx`;

    downloadXlsx(path, body, downloadName);
  };

  useEffect(() => {
    if (!loading && data?.dataDownloadsMetaData) {
      setDownloadMetaData(data?.dataDownloadsMetaData);
    } else {
      setDownloadMetaData(defaultMetaData);
    }
  }, [downloadType, data]);

  return (
    <ErrorBoundary>
      <Grid className={classes.root} container spacing={2} direction="column">
        <Grid item>{downloadType}</Grid>
        <Grid item>{downloadSummaries[downloadType]?.text}</Grid>
        <Grid item>
          {loading ? (
            <Loading />
          ) : (
            <Button variant="contained" onClick={downloadCallback}>
              Download {downloadMetaData.totalCount} records
            </Button>
          )}
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};
