import {
  TRANSACTIONS_AGGREGATE_BY_TYPE_ENUM,
  TRANSACTIONS_AGGREGATE_FIELDS_OPTIONS,
} from '../types';

import { FilterKeys } from '../../../../Filters';
import { MA_TRANSACTIONS_AGGREGATES_QUERY } from '../operations';
import { useFilters } from '../../../FiltersContext';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

export const useGetMaTransactionsAggregates = (
  by = TRANSACTIONS_AGGREGATE_BY_TYPE_ENUM.COUNTRY,
  fields = TRANSACTIONS_AGGREGATE_FIELDS_OPTIONS,
) => {
  const { filters } = useFilters();

  const filterVariables = useMemo(() => {
    const [startYear, endYear] = filters?.dateSlider || [];

    return {
      assets: filters[FilterKeys.assets]?.map((a) => Number(a.entityId)) || [],
      countries: filters[FilterKeys.countries]?.map((c) => c.isoCode) || [],
      buyers: filters[FilterKeys.companiesBuyer]?.map((b) => Number(b.id)) || [],
      sellers: filters[FilterKeys.companiesSeller]?.map((s) => Number(s.id)) || [],
      eventTypes: filters[FilterKeys.eventType]?.map((e) => e.name) || [],
      startYear: startYear,
      endYear: endYear,
    };
  }, [filters]);

  const requestedFields = useMemo(() => {
    return TRANSACTIONS_AGGREGATE_FIELDS_OPTIONS.reduce((acc, field) => {
      acc[field] = fields?.includes(field);
      return acc;
    }, {});
  }, [fields]);

  const { data, loading, error } = useQuery(MA_TRANSACTIONS_AGGREGATES_QUERY, {
    variables: {
      by,
      ...filterVariables,
      ...requestedFields,
    },
  });

  return { data: data?.maTransactionsAggregates || [], loading, error };
};
