import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
  },
  description: {
    margin: '20px 0',
    padding: '20px',
    borderRadius: '15px',
    ...theme.palette.primaryBackground,
  },
}));
