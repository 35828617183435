export const GA_PAGE = 'WEB_SHINY_DOWNLOADS';
export const GA_DIMENSION = 'webShinyDownloads';

/*
Existing asset level downloads:

- Well Properties (Excel File)
- Well Production (CSV File)
- Total Production (Excel File)
- Well Schedule (Excel File)
- NPV10 (Excel File)
- Participation (Excel File)
- Cash Flow (Excel File)


Existing country level downloads:

- Asset Download (Excel File)
- Exploration Block Download (Excel File)
- Map Data Download (GIS data in ZIP file)
- Geology Download (Excel File)
- Production Download (Excel File)
- Cashflow Download (Excel)
*/
export const COUNTRY_LEVEL_ASSETS = 'Country Level - Assets';
export const COUNTRY_LEVEL_EXPLORATION_BLOCKS = 'Country Level - Exploration Blocks';
export const COUNTRY_LEVEL_MAP_DATA = 'Country Level - Map Data';
export const COUNTRY_LEVEL_GEOLOGY = 'Country Level - Geology';
export const COUNTRY_LEVEL_PRODUCTION = 'Country Level - Production';
export const COUNTRY_LEVEL_CASHFLOW = 'Country Level - Cashflow';
export const ASSET_LEVEL_WELL_PROPERTIES = 'Asset Level - Well Properties';
export const ASSET_LEVEL_WELL_PRODUCTION = 'Asset Level - Well Production';
export const ASSET_LEVEL_TOTAL_PRODUCTION = 'Asset Level - Total Production';
export const ASSET_LEVEL_WELL_SCHEDULE = 'Asset Level - Well Schedule';
export const ASSET_LEVEL_NPV = 'Asset Level - NPV10';
export const ASSET_LEVEL_PARTICIPATION = 'Asset Level - Participation';
export const ASSET_LEVEL_CASH_FLOW = 'Asset Level - Cash Flow';
export const DATA_DOWNLOADS_MENU_ITEMS = [
  { id: 1, name: COUNTRY_LEVEL_ASSETS },
  { id: 2, name: COUNTRY_LEVEL_EXPLORATION_BLOCKS },
  { id: 3, name: COUNTRY_LEVEL_MAP_DATA },
  { id: 4, name: COUNTRY_LEVEL_GEOLOGY },
  { id: 5, name: COUNTRY_LEVEL_PRODUCTION },
  { id: 6, name: COUNTRY_LEVEL_CASHFLOW },
  { id: 7, name: ASSET_LEVEL_WELL_PROPERTIES },
  { id: 8, name: ASSET_LEVEL_WELL_PRODUCTION },
  { id: 9, name: ASSET_LEVEL_TOTAL_PRODUCTION },
  { id: 10, name: ASSET_LEVEL_WELL_SCHEDULE },
  { id: 11, name: ASSET_LEVEL_NPV },
  { id: 12, name: ASSET_LEVEL_PARTICIPATION },
  { id: 13, name: ASSET_LEVEL_CASH_FLOW },
];
