import React from 'react';
import { PageShell } from '../PageShell';
import { useOverviewController } from './Overview.controller';
import { useStyles } from './Overview.style';
import { STACK_BY_TYPES, stackByOptionsSimple } from '../../helpers/stackByConstatnts';
import { COST_GRAPH_TYPES, costGraphTypesSimple } from '../../helpers/countGraphConstatns';
import {
  getGraphLayoutSettings,
  GRAPH_LAYOUT_CONFIG,
} from '../../helpers/GraphLayoutSettingConstant';
import { ChartWithMetrics } from '../ChartWithMetrics/ChartWithMetrics';
import { secondaryYAxis } from '../../helpers/secondaryYAxisConstatns';

export const Overview = ({ data }) => {
  const {
    filters,
    overviewContentData,
    showPlotlyLegendChart1,
    setShowPlotlyLegendChart1,
    showPlotlyLegendChart2,
    setShowPlotlyLegendChart2,
    stackBy,
    updateStackBy,
    graphYSecondaryType,
    updateGraphYSecondaryType,
    updateGraphXType,
    updateGraph2Type,
  } = useOverviewController(data);
  const {
    chart1Data,
    chart1Name,
    chart2Data,
    considerationMedian,
    workingInterestReservesMedian,
    dealCount,
    weaEstimatedReservesAcquisitionCostsMedian,
    weaEstimatedProductionAcquisitionCostsMedian,
    graphXType,
    graph2Type,
    stackByWarning,
  } = overviewContentData;
  const classes = useStyles();

  const boxes = [
    { value: dealCount, name: 'Number of Deals' },
    { value: Math.round(considerationMedian), name: 'Estimated Median Valuation (US$MM)' },
    {
      value: Math.round(workingInterestReservesMedian),
      name: 'Estimated Median Reserves (MMboe)',
    },
    {
      value: Math.round(weaEstimatedReservesAcquisitionCostsMedian),
      name: 'Estimated Median Reserves Acquisition Cost ($/boe)',
    },
    {
      value: Math.round(weaEstimatedProductionAcquisitionCostsMedian),
      name: 'Estimated Median Production Acquisition Cost ($/boe)',
    },
  ];

  if (!filters) return null;

  return (
    <PageShell filters={filters}>
      <div className={classes.boxes}>
        {boxes.map(({ name, value }) => (
          <div className={classes.box} key={name}>
            <div className={classes.boxValue}>
              {new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 }).format(value)}
            </div>
            <div className={classes.boxName}>{name}</div>
          </div>
        ))}
      </div>
      <div className={classes.graphWrapper}>
        {chart1Data && (
          <ChartWithMetrics
            data={chart1Data}
            graphLayoutSettings={getGraphLayoutSettings({
              showlegend:
                showPlotlyLegendChart1 && !stackByWarning && stackBy !== STACK_BY_TYPES.NONE,
              yAxisTitle: 'NPV10 Valuation (US$MM)',
              yAxisSecondaryTitle: graphYSecondaryType,
              xTickAngle:
                graphXType === COST_GRAPH_TYPES.SELLER_NAME ||
                graphXType === COST_GRAPH_TYPES.BUYER_NAME
                  ? 45
                  : 'auto',
            })}
            graphLayoutConfig={GRAPH_LAYOUT_CONFIG}
            title={chart1Name}
            showPlotlyLegendToggle={true}
            showPlotlyLegend={showPlotlyLegendChart1}
            setShowPlotlyLegend={setShowPlotlyLegendChart1}
            x={{ value: graphXType, setValue: updateGraphXType, options: costGraphTypesSimple }}
            ySecondary={{
              value: graphYSecondaryType,
              setValue: updateGraphYSecondaryType,
              options: secondaryYAxis,
            }}
            stackBy={{ value: stackBy, setValue: updateStackBy, disabledReason: stackByWarning }}
            getStackByOptions={(xValue) => {
              if (
                xValue === COST_GRAPH_TYPES.BUYER_NAME ||
                xValue === COST_GRAPH_TYPES.SELLER_NAME
              ) {
                return ['Enabled', STACK_BY_TYPES.NONE];
              } else {
                return stackByOptionsSimple;
              }
            }}
          />
        )}
        {chart2Data && (
          <ChartWithMetrics
            data={chart2Data}
            graphLayoutSettings={getGraphLayoutSettings({
              showlegend: showPlotlyLegendChart2,
              xTickAngle:
                graph2Type === COST_GRAPH_TYPES.SELLER_NAME ||
                graph2Type === COST_GRAPH_TYPES.BUYER_NAME
                  ? 45
                  : 'auto',
            })}
            graphLayoutConfig={GRAPH_LAYOUT_CONFIG}
            title={'Deal Count by Event Type'}
            showPlotlyLegendToggle={true}
            showPlotlyLegend={showPlotlyLegendChart2}
            setShowPlotlyLegend={setShowPlotlyLegendChart2}
            x={{ value: graph2Type, setValue: updateGraph2Type, options: costGraphTypesSimple }}
          />
        )}
      </div>
    </PageShell>
  );
};
