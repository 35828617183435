import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  search: {
    width: '100%',
    maxWidth: '1000px',
    marginBottom: '16px',

    '& .MuiOutlinedInput-root': {
      maxHeight: '50px',
      borderRadius: '8px',
      borderColor: theme.palette.baseColors.deepSeaNavy.c25,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.baseColors.deepSeaNavy.c25,
    },
  },
  select: {
    marginBottom: '16px',

    '& .MuiOutlinedInput-root': {
      boxShadow: 'none',
    },

    '& .MuiSelect-root': {
      maxHeight: '50px',
      width: '100%',
      maxWidth: '400px',
      minWidth: '200px',
      boxShadow: 'none',
      backgroundColor: theme.palette.baseColors.white.c100,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
      borderColor: theme.palette.baseColors.deepSeaNavy.c25,
    },
  },
}));
