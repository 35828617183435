import makeStyles from '@mui/styles/makeStyles';

export const legendInChartStyle = makeStyles(() => ({
  root: {
    padding: '8px 12px',
    backgroundColor: 'rgba(242, 245, 246, .7)',
    position: 'absolute',
    right: (props) => (props.offsetFromRight ? '65px' : 0),
    top: '31px',
    maxHeight: '87px',
    overflow: 'auto',
    zIndex: 1,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
  },
  rootRelative: {
    position: 'relative',
    right: 'unset',
    top: 'unset',
    maxHeight: '100%',
  },
  row: {
    gap: '.5rem',
    display: 'flex',
    alignItems: 'center',
    '&:hover': { cursor: 'pointer' },
  },
  text: {
    maxWidth: '100px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));
