import React, { useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import SortIcon from '@mui/icons-material/Sort';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    cursor: 'pointer',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px 0 5px',
  },
}));

// setSortType - callback for setting the sortType
// sortType - the currently selected sortType
// sortTypes - the list of available sort types
//  ex. [{ name: "Name", value: "name" }, { name: "NPV", value: "npv" }]
export default ({ setSortType = () => {}, sortType, sortTypes }) => {
  const classes = useStyles();
  const [menuEl, setMenuEl] = useState(null);

  const handleMenuClick = (event) => {
    setMenuEl(event.currentTarget);
  };

  const handleMenuClose = (value) => {
    if (value !== undefined) setSortType(value);
    setMenuEl(null);
  };

  return (
    <>
      <Tooltip title="Change Sort Type" aria-label="sort type">
        <div className={classes.root} onClick={handleMenuClick}>
          <Typography variant="body2" className={classes.text}>
            Sort By {sortType.name}
          </Typography>
          <SortIcon />
        </div>
      </Tooltip>
      <Popover
        anchorEl={menuEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(menuEl)}
        onClose={() => handleMenuClose()}
      >
        {sortTypes.map((s) => (
          <MenuItem key={`card-list-sort-item-${s.name}`} onClick={() => handleMenuClose(s)}>
            {s.name}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};
