import { FilterKeys } from '../../../../Filters';
import { INITIAL_YEARS } from '../../../FiltersContext';
import { MA_START_AND_END_YEAR_QUERY } from '../operations';
import { useFilters } from '../../../FiltersContext';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

export const useGetStartAndEndYear = () => {
  const { filters } = useFilters();

  const filterVariables = useMemo(() => {
    return {
      assets: filters[FilterKeys.assets]?.map((a) => Number(a.entityId)) || [],
      countries: filters[FilterKeys.countries]?.map((c) => c.isoCode) || [],
      buyers: filters[FilterKeys.companiesBuyer]?.map((b) => Number(b.id)) || [],
      sellers: filters[FilterKeys.companiesSeller]?.map((s) => Number(s.id)) || [],
      eventTypes: filters[FilterKeys.eventType]?.map((e) => e.name) || [],
    };
  }, [filters]);

  const { data, previousData, loading, error } = useQuery(MA_START_AND_END_YEAR_QUERY, {
    variables: filterVariables,
    fetchPolicy: 'cache-and-network',
  });

  const maTransactionsSummary = loading
    ? previousData?.maTransactionsSummary
    : data?.maTransactionsSummary;

  return {
    data: maTransactionsSummary || INITIAL_YEARS,
    loading,
    error,
  };
};
