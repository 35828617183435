import makeStyles from '@mui/styles/makeStyles';

export const useRulerButtonStyles = makeStyles((theme) => ({
  rulerAction: {
    color: theme.palette.baseColors.deepSeaNavy.c100,
    borderRadius: '0',
    marginLeft: '0',
    '&:hover': {
      filter: 'brightness(95%)',
    },
  },
  rulerActionSelected: {
    backgroundColor: `${theme.palette.baseColors.deepSeaNavy.c100} !important`,
    color: `${theme.palette.baseColors.white.c100} !important`,
    borderRadius: '0',
    marginLeft: '0',
  },
}));
