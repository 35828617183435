export const ASSET_LEVEL_WELL_PRODUCTION_COLUMNS = [
  { field: 'assetName', headerName: 'Asset', width: 300 },
  { field: 'basinName', headerName: 'Basin', width: 300 },
  { field: 'wellName', headerName: 'Well', width: 300 },
  { field: 'period', headerName: 'Date', width: 300 },
  { field: 'oil', headerName: 'Oil (bbl/d)', width: 300 },
  { field: 'liquids', headerName: 'Liquids (bbl/d)', width: 300 },
  { field: 'gas', headerName: 'Gas (MMcf/d)', width: 300 },
  { field: 'condensate', headerName: 'Condensate (bbl/d)', width: 300 },
  { field: 'water', headerName: 'Water (bbl/d)', width: 300 },
  { field: 'waterInjection', headerName: 'Water Injection (bbl/d)', width: 300 },
  { field: 'gasInjection', headerName: 'Gas Injection (MMcf/d)', width: 300 },
  { field: 'co2Injection', headerName: 'CO2 Injection (MMcf/d)', width: 300 },
  { field: 'otherInjection', headerName: 'Other Injection (bbl/d)', width: 300 },
  { field: 'waterInjection_disposal', headerName: 'Water Injection Disposal (bbl/d)', width: 300 },
  { field: 'steamInjection', headerName: 'Steam Injection (tons/d)', width: 300 },
  { field: 'polymerInjection', headerName: 'Polymer Injection (bbl/d)', width: 300 },
];
// TODO: parse received data into `DataGrid`'s expected "Row" data structure
export const ASSET_LEVEL_WELL_PRODUCTION_ROWS = () => [];
