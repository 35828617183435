import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useReportsStyles } from './Reports.style';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { formatDate } from '../../../../../utils/helpers';
import { HighlightedResultText } from '../ResultListGroups/components/ResultCard/HighlightedResultText';

export const Reports = ({ dataReports, loadingReports, onActionClick, inputText }) => {
  const classes = useReportsStyles();
  const dataReportsEmpty = dataReports.length === 0;
  if (loadingReports && !dataReportsEmpty) {
    return (
      <div className={classes.loading}>
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        Reports
      </Typography>
      {dataReportsEmpty ? (
        <Typography variant="body1" className={classes.noResults}>
          {inputText ? 'No results found.' : 'Type to get results...'}
        </Typography>
      ) : (
        dataReports.map(({ id, slug, name, imageUrl, thumbnailUrl, date, rows, actions }) => (
          <Link
            color="primary"
            underline="none"
            href={'/customer/proprietary-downloads/' + slug}
            className={classes.report}
            target="_blank"
            onClick={() => {
              onActionClick({
                download: false,
                row: {
                  action: actions.length > 0 ? actions[0] : '',
                },
                isReport: true,
              });
            }}
            key={id}
          >
            <div className={classes.top}>
              <div
                className={classes.image}
                style={{ backgroundImage: `url("${thumbnailUrl ? thumbnailUrl : imageUrl}")` }}
              />
              <div className={classes.longTextDiv}>
                <Typography variant="h6" className={classes.name}>
                  {name}
                </Typography>
                <Typography variant="body1" className={classes.date}>
                  {formatDate(date, false)}
                </Typography>
              </div>
            </div>
            <HighlightedResultText
              inputText={inputText}
              text={rows[0]}
              variant="body1"
              classes={classes.row}
            />
          </Link>
        ))
      )}
    </div>
  );
};
