import { useEffect, useState } from 'react';
import { useMaFilterContext } from './MaFilterProvider';
import {
  SESSIONS_STORAGE_KEY_MA_FILTERS_ASSETS,
  SESSIONS_STORAGE_KEY_MA_FILTERS_COMPANY_SELLERS,
  SESSIONS_STORAGE_KEY_MA_FILTERS_COMPANY_BUYERS,
  SESSIONS_STORAGE_KEY_MA_FILTERS_COUNTRIES,
  SESSIONS_STORAGE_KEY_MA_FILTERS_TYPES,
  SESSIONS_STORAGE_KEY_MA_FILTERS_YEARS,
} from '../Ma.constants';
import { getGroupForDealType } from './calculateData';

const isValidYearPreference = (variable) => {
  return (
    variable &&
    variable.from &&
    variable.to &&
    typeof variable.from === 'number' &&
    typeof variable.to === 'number'
  );
};

const getYearsSessionValue = () => {
  const sessionPreference = sessionStorage.getItem(SESSIONS_STORAGE_KEY_MA_FILTERS_YEARS);
  const sessionPreferenceParsed = sessionPreference ? JSON.parse(sessionPreference) : null;

  return isValidYearPreference(sessionPreferenceParsed) ? sessionPreferenceParsed : null;
};

const getIdListSessionValue = (key) => {
  const sessionPreference = sessionStorage.getItem(key);
  const sessionPreferenceParsed = sessionPreference ? JSON.parse(sessionPreference) : null;

  return Array.isArray(sessionPreferenceParsed) ? sessionPreferenceParsed : [];
};

export const useDefaultFilterOptions = (data) => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    setCountryList,
    setTypeList,
    setCompanySellerList,
    setCompanyBuyerList,
    setAssetList,
    setChosenYears,
  } = useMaFilterContext();

  useEffect(() => {
    const countryList = {};
    const typeList = {};
    const companySellerObject = {};
    const companyBuyerObject = {};
    const assetObject = {};

    const chosenYearSessionValue = getYearsSessionValue();

    const chosenCountryIdsListSessionValue = getIdListSessionValue(
      SESSIONS_STORAGE_KEY_MA_FILTERS_COUNTRIES,
    );
    const chosenTypeIdsListSessionValue = getIdListSessionValue(
      SESSIONS_STORAGE_KEY_MA_FILTERS_TYPES,
    );
    const chosenCompanySellerIdsListSessionValue = getIdListSessionValue(
      SESSIONS_STORAGE_KEY_MA_FILTERS_COMPANY_SELLERS,
    );
    const chosenCompanyBuyerIdsListSessionValue = getIdListSessionValue(
      SESSIONS_STORAGE_KEY_MA_FILTERS_COMPANY_BUYERS,
    );
    const chosenAssetIdsListSessionValue = getIdListSessionValue(
      SESSIONS_STORAGE_KEY_MA_FILTERS_ASSETS,
    );

    data.forEach((dealsByCountry) => {
      dealsByCountry.forEach((deal) => {
        //TYPES
        typeList[deal.dealType] = {
          id: deal.dealType,
          name: deal.dealType,
          isChosen: chosenTypeIdsListSessionValue.includes(deal.dealType),
          group: getGroupForDealType(deal.dealType),
        };

        deal.maCompanyTransactions.forEach((ct) => {
          //COMPANIES
          if (ct.buyerCompanyId) {
            companyBuyerObject[ct.buyerCompanyId] = {
              id: ct.buyerCompanyId,
              name: ct.buyerCompanyName,
              isChosen: chosenCompanyBuyerIdsListSessionValue.includes(ct.buyerCompanyId),
            };
          }
          if (ct.sellerCompanyId) {
            companySellerObject[ct.sellerCompanyId] = {
              id: ct.sellerCompanyId,
              name: ct.sellerCompanyName,
              isChosen: chosenCompanySellerIdsListSessionValue.includes(ct.sellerCompanyId),
            };
          }

          ct.maAssetTransactions.forEach((at) => {
            //ASSETS
            assetObject[at.assetName] = {
              id: at.assetName,
              name: at.assetName,
              isChosen: chosenAssetIdsListSessionValue.includes(at.assetName),
            };

            //COUNTRIES
            countryList[at.countryId] = {
              id: at.countryId,
              displayName: at.countryName,
              isChosen: chosenCountryIdsListSessionValue.includes(at.countryId),
              region: {
                id: at.regionName,
                displayName: at.regionName,
                isChosen: false,
              },
            };
          });
        });
      });
    });

    setCountryList(Object.values(countryList));
    setTypeList(Object.values(typeList));
    setCompanySellerList(Object.values(companySellerObject));
    setCompanyBuyerList(Object.values(companyBuyerObject));
    setAssetList(Object.values(assetObject));
    const currentYear = new Date().getFullYear();
    setChosenYears(
      chosenYearSessionValue ? chosenYearSessionValue : { from: currentYear - 10, to: currentYear },
    );

    setIsLoading(false);
  }, []);

  return { isLoading };
};
