import { gql } from '@apollo/client';

export const COUNTRIES_QUERY = gql`
  query countryStatistics($filters: JSON, $sortBy: String) {
    countryStatistics(filters: $filters, sortBy: $sortBy) {
      id
      customRegion
      displayName
      name
      isoCode
      geom
      zoom
      countryStatistics {
        countOfAssets
        countOfActiveCompanies
        formattedAcreage
        formattedTotalValuationMmusd
        formattedTotalRemainingCapexMmusd
        formattedTotalRemainingReserves
        formattedWeaProdGasMmcfd
        formattedWeaProdLiquidsBbld
        recentLiquidsProductionBbld
        recentGasProductionMmcfd
        recentProductionDate
        remainingReservesLiquidsMmbbl
        remainingReservesGasBcf
        reportUpdatedAt
        totalValuationMmusd
        totalRemainingCapexMmusd
        fiscalRegimes {
          name
        }
      }
    }
  }
`;
