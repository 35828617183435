import { getDisplayedValue, getLabelUnit } from './SummaryItems/utils';

import Grid from '@mui/material/Grid2';
import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { ParticipationCell } from './ParticipationCell';

const useStyles = makeStyles((theme) => ({
  dottedLabel: {
    ...theme.palette.baseColors.iceBlue.c100,
    alignSelf: 'center',
  },
  dottedSpace: {
    flex: '1 1 auto',
    borderBottom: 'dotted rgba(255, 255, 255, 0.1) 2px',
    height: '60%',
  },
  tableValue: {
    ...theme.palette.text,
    width: '100%',
    wordWrap: 'normal',
    whiteSpace: 'pre-line',
    verticalAlign: 'top',
    borderBottom: 'none',
    padding: '3px',
  },
  tableLabel: {
    minWidth: '130px',
    verticalAlign: 'top',
    borderBottom: 'none',
    padding: '3px',
    fontWeight: 400,
  },
  tableSpace: {
    padding: '3px',
    border: 'none',
  },
  root: {
    margin: '5px 0 5px',
    ...theme.palette.text,
    wordWrap: 'normal',
    whiteSpace: 'pre-line',
  },
  label: {
    color: '#507180',
  },
  participationTable: {
    padding: '.5rem',
  },
  participationRow: {
    '&:nth-child(odd)': {
      backgroundColor: 'white !important',
    },
    '&:nth-child(even)': {
      backgroundColor: theme.palette.baseColors.cloudGray.c100,
    },
    '&:nth-child(1)': {
      backgroundColor: `${theme.palette.baseColors.deepSeaNavy.c100}  !important`,
    },
  },
  participationHeaderCell: {
    color: 'white',
  },
  participationCell: {
    fontWeight: '400',
  },
  participationTableBlock: {
    overflow: 'hidden',
    transition: 'height .3s ease-in-out',
    padding: '1rem',
    paddingTop: '.2rem',
  },
}));

export default ({
  variant,
  Icon,
  label,
  item,
  value,
  unit,
  labelUnitSeparator = ' ',
  labelValueSeparator = ' ',
  tooltip,
  showParticipationHistory,
  setShowParticipationHistory,
}) => {
  const classes = useStyles();
  const labelUnit = getLabelUnit(label, unit, labelUnitSeparator);
  const displayedValue = getDisplayedValue(label, value, Icon);

  switch (variant) {
    case 'dotted':
      return (
        <Grid container size={12}>
          <Typography className={classes.dottedLabel} variant="body2">
            {labelUnit}
          </Typography>
          <div className={classes.dottedSpace} />
          <Typography variant="subtitle1">{value}</Typography>
        </Grid>
      );
    case 'table':
      return (
        <TableRow>
          {labelUnit && (
            <TableCell className={classes.tableLabel}>
              {labelUnit}
              {labelValueSeparator}
            </TableCell>
          )}
          <TableCell className={classes.tableSpace} />
          {label === 'Participation' ? (
            <ParticipationCell
              showParticipationHistory={showParticipationHistory}
              setShowParticipationHistory={setShowParticipationHistory}
              displayedValue={displayedValue}
              classTableCell={classes.tableValue}
              item={item}
            />
          ) : (
            <TableCell className={classes.tableValue}>{displayedValue}</TableCell>
          )}
        </TableRow>
      );
    default:
      return (
        // Tooltip is only displayed on hover if a tooltip is not empty
        <Tooltip title={tooltip || ''} placement="top-start">
          <Typography className={classes.root} variant="body1" component="p">
            {labelUnit && (
              <Typography className={classes.label} variant="body1" component="span">
                {labelUnit}
                {labelValueSeparator}
              </Typography>
            )}
            {displayedValue}
          </Typography>
        </Tooltip>
      );
  }
};
