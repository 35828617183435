import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Overview } from './components/Overview';
import {
  M_A_TRANSACTION_ANALYTICS_PATH,
  M_A_COMPANY_BENCH_MARKING_PATH,
  M_A_OVERVIEW_PATH,
  M_A_TRANSACTION_TABLE_PATH,
} from '../../utils/constants';
import { MaFilterProvider } from './helpers/MaFilterProvider';
import { TransactionTable } from './components/TransactionTable';
import { CompanyBenchMarking } from './components/CompanyBenchMarking';
import { TransactionAnalytics } from './components/TransactionAnalytics';
import { useQuery } from '@apollo/client';
import { MA_QUERY } from './operations';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  loading: {
    ...theme.palette.loading,
    backgroundColor: 'none',
  },
}));

export const MaPage = () => {
  const classes = useStyles();
  const { data, loading } = useQuery(MA_QUERY);

  if (loading)
    return (
      <div className={classes.loading}>
        <CircularProgress color="secondary" />
      </div>
    );

  const maData = [data.mergesAcquisitions];

  return (
    <MaFilterProvider>
      <Switch>
        <Route path={M_A_OVERVIEW_PATH}>
          <Overview data={maData} />
        </Route>
        <Route path={M_A_TRANSACTION_TABLE_PATH}>
          <TransactionTable data={maData} />
        </Route>
        <Route path={M_A_TRANSACTION_ANALYTICS_PATH}>
          <TransactionAnalytics data={maData} />
        </Route>
        <Route path={M_A_COMPANY_BENCH_MARKING_PATH}>
          <CompanyBenchMarking data={maData} />
        </Route>
        {/*
      <Route path={M_A_POTENTIAL_OPPORTUNITIES_PATH}>
        <PotentialOpportunities />
      </Route>*/}
      </Switch>
    </MaFilterProvider>
  );
};
