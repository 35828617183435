import asset from './asset';
import basin from './basin';
import block from './block';
import facility from './facility';
import formation from './formation';
import field from './field';
import lease from './lease';
import lngFacility from './lngFacility';
import pipeline from './pipeline';
import pipelineNetwork from './pipelineNetwork';
import pipelineSegment from './pipelineSegment';
import presaltPolygon from './presaltPolygon';
import restrictedArea from './restrictedArea';
import well from './well';
import eaWell from './eaWell';

export const getTemplate = (entityType, country, tab, changeQueriedItem) => {
  switch (entityType) {
    case 'asset':
      return asset(country);
    case 'basin':
      return basin;
    case 'block':
      return block(country);
    case 'ea_well':
      return eaWell();
    case 'lng_project':
      return lngFacility(changeQueriedItem);
    case 'facility':
      return facility(country);
    case 'field':
      return field(country);
    case 'formation':
      return formation;
    case 'lease':
      return lease(country);
    case 'pipeline':
      return pipeline(country);
    case 'pipeline_network':
      return pipelineNetwork();
    case 'pipeline_segment':
      return pipelineSegment;
    case 'presalt_polygon':
      return presaltPolygon;
    case 'restricted_area':
      return restrictedArea;
    case 'well':
      return well(country, tab);
    default:
      return;
  }
};
