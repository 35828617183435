import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '../../RadioGroup';
import { NO_VALUE } from '../constants';

const useStyles = makeStyles((theme) => ({
  radioGroupRoot: {
    padding: 0,
  },
  radioGroup: {
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  label: {
    ...theme.palette.alternateText,
    fontWeight: 'bold',
  },
  radioItemLabel: {
    padding: '4px 0',
    justifyContent: 'space-between',
    '& > .MuiFormControlLabel-label': {
      fontWeight: 'bold',
    },
  },
  radioItemControl: {
    color: theme.palette.baseColors.skyBlue.c75,
    '&.Mui-checked': {
      color: theme.palette.baseColors.skyBlue.c75,
    },
  },
}));

export default ({
  accessor,
  handleSetState,
  itemIsDisabledParams = {},
  label,
  type,
  types,
  value,
}) => {
  const classes = useStyles();

  const handleToggle = (e) =>
    e.target.value === value
      ? handleSetState(NO_VALUE, accessor)
      : handleSetState(e.target.value, accessor);

  return (
    <>
      {type && (
        <FormControl style={{ width: '100%' }}>
          <RadioGroup
            handleToggle={handleToggle}
            itemIsDisabledParams={itemIsDisabledParams}
            items={types}
            label={label}
            labelPlacement="start"
            styles={classes}
            value={value}
          />
        </FormControl>
      )}
    </>
  );
};
