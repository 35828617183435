import React from 'react';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  footer: {
    ...theme.palette.secondaryBackground,
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    maxHeight: '30px',
  },
  label: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '9px',
    },
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Typography className={classes.label} variant="overline">
        COPYRIGHT © {new Date().getFullYear()} WELLIGENCE ENERGY ANALYTICS. ALL RIGHTS RESERVED.
      </Typography>
    </div>
  );
};
