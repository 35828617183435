// IDs for driver.js
import {
  ADVANCED_FILTER_BUTTON_ID,
  OTHER_ACTIONS_BUTTON_ID,
  PAGE_ACTION_MAIN_ID,
  PRESET_BUTTON_ID,
} from '../PageActions';
import { BASIC_FILTERS_WRAPPER_ID } from '../BasicFiltersWrapper/BasicFiltersWrapper.constant';
export const CCUS_MAP_ID = 'ccus-map';
export const CCUS_EMISSIONS_BAR_GARPH_ID = 'ccus-emissions-bar-graph';
export const CCUS_COSTS_BAR_GARPH_ID = 'ccus-costs-bar-graph';
export const CCUS_PROJECT_BAR_GARPH_ID = 'ccus-project-bar-graph';
export const CCUS_STORAGE_BAR_GARPH_ID = 'ccus-storage-bar-graph';
export const CCUS_GRAPH_APP_BAR_ID = 'ccus-graph-app-bar';

export const ccusDriver = (driver) => {
  driver.defineSteps([
    {
      element: `#${BASIC_FILTERS_WRAPPER_ID}`,
      popover: {
        title: 'Basic Filters',
        description:
          'Filter the returned data based on region, country, facility, industry, and company. Select one or multiple choices from each drop down filter.',
        position: 'bottom-right',
      },
    },
    {
      element: `#${ADVANCED_FILTER_BUTTON_ID}`,
      popover: {
        title: 'All Filters',
        description:
          'Clicking this will open up the advanced filters options. There you will have access to all the filters including point source emissions, company targets, ccus project filters, and storage filters.',
        position: 'bottom-right',
      },
    },
    {
      element: `#${PRESET_BUTTON_ID}`,
      popover: {
        title: 'Save / Load / Share Filter',
        description:
          'Clicking this will open up the dialog where you can Save, Load and Share selected filters.',
        position: 'left-top',
      },
    },
    {
      element: `#${PAGE_ACTION_MAIN_ID}`,
      popover: {
        title: 'Download Raw Data',
        description:
          'This will download the raw data (pre-aggregated) returned by the filter set. Within this excel you may view all the data associated to facilities, projects, and storage sites.',
        position: 'left-top',
      },
    },
    {
      element: `#${OTHER_ACTIONS_BUTTON_ID}`,
      popover: {
        title: 'Download All Charts',
        description:
          'This will download and combine each chart download into a single excel workbook.',
        position: 'left-top',
      },
    },
    {
      element: `#${CCUS_MAP_ID}`,
      popover: {
        title: 'Map',
        description:
          'View the CCUS map which in addition to the CCUS facilities, projects, and storage sites also includes upstream assets, blocks, fields, and wells.',
        position: 'right',
      },
    },
    {
      element: `#${CCUS_EMISSIONS_BAR_GARPH_ID}`,
      popover: {
        title: 'Facilities Emissions Bar Graph',
        description:
          'View emissions.<br /><br /><b>Note -</b> Aggregating by "all" metrics can lead to a decrease in performance if the filter set returns too many rows.',
        position: 'bottom',
      },
    },
    {
      element: `#${CCUS_COSTS_BAR_GARPH_ID}`,
      popover: {
        title: 'Abatement Cost Bar Graph',
        description:
          'View abatement costs.<br /><br /><b>Note -</b> Aggregating by "all" metrics can lead to a decrease in performance if the filter set returns too many rows.',
        position: 'bottom',
      },
    },
    {
      element: `#${CCUS_PROJECT_BAR_GARPH_ID}`,
      popover: {
        title: 'CCUS Project Tracker',
        description:
          'View project tracker.<br /><br /><b>Note -</b> Aggregating by "all" metrics can lead to a decrease in performance if the filter set returns too many rows.',
        position: 'top',
      },
    },
    {
      element: `#${CCUS_STORAGE_BAR_GARPH_ID}`,
      popover: {
        title: 'Storage Bar Graph',
        description:
          'View storage.<br /><br /><b>Note -</b> Aggregating by "all" metrics can lead to a decrease in performance if the filter set returns too many rows.',
        position: 'top',
      },
    },
    {
      element: `#${CCUS_GRAPH_APP_BAR_ID}`,
      popover: {
        title: 'Graph App Bar',
        description:
          "Every graph's app bar contains useful tools and information. Every chart will have a button for a fullscreen mode, downloading data, and accessing more of the chart's settings via the hamburger icon.",
        position: 'bottom',
      },
    },
  ]);

  // Start the introduction
  driver.start(0);
};
