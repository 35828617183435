import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  screenshotMode: {
    ...theme.palette.icedBackground,
    ...theme.mapPanel,
    padding: '5px',
    width: '500px',
    right: 0,
    height: `calc(${theme.sizes.fullPage.height} - 55px)`,
    zIndex: 3,
    [theme.breakpoints.down('lg')]: {
      width: '325px',
    },
  },
  scroll: {
    height: '100%',
    overflowY: 'auto',
    ...theme.palette.scrollbar,
  },
  tab: {
    backgroundColor: 'rgba(0,0,0,.2)',
    border: 'solid thin rgba(0, 0, 0, .5)',
    color: 'rgba(0, 0, 0, .7)',
    minWidth: '50px',
    minHeight: '30px',
    '&.Mui-selected': {
      backgroundColor: 'rgba(236, 240, 245, 0)',
      color: '#2a2e49',
      borderBottom: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px',
    },
  },
  tabContainer: {
    margin: '0 5px',
    minHeight: '30px',
  },
  indicator: {
    display: 'none',
  },
  flexContainer: {
    height: '100%',
  },
  button: {
    ...theme.palette.button,
    margin: theme.spacing(1),
    justifyContent: 'space-evenly',
  },
  imagesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '320px',
  },
  screenshot: {
    height: '320px',
    width: '360px',
  },
  screenshotPreview: {
    width: 748,
    height: 666,
    border: '5px solid red',
    opacity: 1,
    position: 'fixed',
    top: -10,
    left: -(window.screen.width - 510),
    pointerEvents: 'none',
  },
  rootLabel: {
    margin: '5px 0 5px',
    ...theme.palette.text,
    wordWrap: 'normal',
    whiteSpace: 'pre-wrap',
  },
  label: {
    color: '#507180',
  },
}));
