import { gql } from '@apollo/client';

export const FACILITY_GRAPH_QUERY = gql`
  query graphFacilityProductions($id: ID!) {
    graphFacilityProductions(id: $id) {
      liquids
      gas
      liquidsLabels
      gasLabels
    }
  }
`;

export const PIPELINE_THROUGHPUT_GRAPH_QUERY = gql`
  query graphPipelineThroughputs($id: ID!) {
    graphPipelineThroughputs(id: $id) {
      labels
      throughputs
      ylabel
    }
  }
`;

export const PIPELINE_THROUGHPUT_ASSET_VIEW_GRAPH_QUERY = gql`
  query graphPipelineThroughputAssetView($id: ID!) {
    graphPipelineThroughputAssetView(id: $id) {
      labels
      throughputs
      ylabel
    }
  }
`;

export const PIPELINE_NETWORK_THROUGHPUT_GRAPH_QUERY = gql`
  query graphPipelineNetworkThroughputs($id: ID!) {
    graphPipelineNetworkThroughputs(id: $id) {
      labels
      throughputs
      ylabel
    }
  }
`;
