import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => {
  return {
    subList: {
      backgroundColor: 'rgb(205 215 222 / 25%)',
      height: 0,
      transition: 'ease-in-out height .5',
      overflow: 'hidden',
    },
    subListOpened: {
      height: 'auto',
    },
  };
});
