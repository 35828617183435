import { ApolloProvider } from '@apollo/client';
import { AppRoutes } from '../utils/routes';
import { CurrentUserProvider } from '../components/CurrentUserContext';
import { FeatureFlagsProvider } from '../contexts/FeatureFlagsContext';
import Footer from '../components/Footer';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from '../components/SnackbarContext';
import { WalkthroughProvider } from '../components/WalkthroughContext';
import { createClient } from '../utils/apollo';
import { render } from 'react-dom';
import theme from '../utils/theme';

const App = () => {
  return (
    <ApolloProvider client={createClient()}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Router>
            <SnackbarProvider>
              <CurrentUserProvider>
                <FeatureFlagsProvider>
                  <WalkthroughProvider>
                    <AppRoutes />
                  </WalkthroughProvider>
                </FeatureFlagsProvider>
              </CurrentUserProvider>
            </SnackbarProvider>
            <Footer />
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    </ApolloProvider>
  );
};

render(<App />, document.querySelector('#root'));
