import * as Sentry from '@sentry/react';

import {
  CCUS_PATH,
  COUNTRY_OVERVIEWS_PATH,
  CUSTOMER_DOWNLOADS_PATH,
  DATA_DOWNLOADS_PATH,
  DEFAULT_PATH,
  FORGOT_PASSWORD_PATH,
  GHG_PATH,
  M_A_BASIC_PATH_V2,
  M_A_COMPANY_BENCH_MARKING_PATH,
  M_A_OVERVIEW_PATH,
  M_A_TRANSACTION_ANALYTICS_PATH,
  M_A_TRANSACTION_TABLE_PATH,
  OIL_AND_GAS_MAP_PATH,
  OIL_AND_GAS_MAP_PATH_WITH_LATLONG,
  OIL_AND_GAS_MAP_PATH_WITH_LATLONG_AND_ZOOM,
  PORTFOLIO_ANALYSIS_PATH,
  RESERVES_DEFINITION_PATH,
  RESET_PASSWORD_PATH,
  ROOT_PATH,
  SETTINGS_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  VALUATIONS_ASSET_PATH,
  VALUATIONS_ASSET_REPORT_PATH,
  VALUATIONS_PATH,
  VIDEO_TUTORIALS_PATH,
  WEA_ASSUMPTIONS_PATH,
} from './constants';
import React, { useMemo } from 'react';
import { ResetPassword, SendResetLink } from '../components/ForgotPassword';
import { Route, Switch } from 'react-router-dom';

import { AppBar } from '../components/AppBar';
import AssetTemplate from '../components/Valuations/AssetTemplate';
import CCUS from '../components/CCUS';
import CountryOverviews from '../components/CountryOverviews';
import ErrorBoundary from '../components/ErrorBoundary';
import GHG from '../components/GHG';
import GuidanceDocumentation from '../components/GuidanceDocumentation';
import { Integrations } from '@sentry/tracing';
import LandingPage from '../components/LandingPage';
import LazySignUp from '../components/LazySignUp';
import Login from '../components/Login';
import { MaPage } from '../components/M&A';
import { MaPage as MaPageNew } from '../components/M&A_NEW';
import OilAndGasMap from '../components/OilAndGasMap';
import PortfolioAnalysis from '../components/PortfolioAnalysis';
import ProtectedRoute from '../components/ProtectedRoute';
import Settings from '../components/Settings';
import ShinyDownloads from '../components/ShinyDownloads';
import SubscriberDownload from '../components/SubscriberDownload';
import Valuations from '../components/Valuations';
import { VideoTutorials } from '../pages/VideoTutorials';
import { useFeatureFlags } from '../contexts/FeatureFlagsContext';

export const getTestId = (name) => `${name}Page`;

export const ROUTES = [
  {
    path: SIGN_IN_PATH,
    Page: Login,
  },
  {
    path: SIGN_UP_PATH,
    Page: LazySignUp,
  },
  {
    path: FORGOT_PASSWORD_PATH,
    Page: SendResetLink,
  },
  {
    path: RESET_PASSWORD_PATH,
    Page: ResetPassword,
  },
];

export const useProtectedRoutes = () => {
  const { inDevelopment, inLocalEnv, maV2, videoTutorials } = useFeatureFlags();

  return useMemo(
    () => [
      // all protected routes paths and components
      {
        path: ROOT_PATH,
        Page: LandingPage,
      },
      {
        path: CCUS_PATH,
        Page: CCUS,
        forceNewTab: true,
      },
      {
        path: GHG_PATH,
        Page: GHG,
        forceNewTab: true,
      },
      {
        path: PORTFOLIO_ANALYSIS_PATH,
        Page: PortfolioAnalysis,
        forceNewTab: true,
      },
      {
        path: OIL_AND_GAS_MAP_PATH,
        Page: OilAndGasMap,
        forceNewTab: true,
      },
      {
        path: OIL_AND_GAS_MAP_PATH_WITH_LATLONG,
        Page: OilAndGasMap,
      },
      {
        path: OIL_AND_GAS_MAP_PATH_WITH_LATLONG_AND_ZOOM,
        Page: OilAndGasMap,
      },
      {
        path: VALUATIONS_PATH,
        Page: Valuations,
        forceNewTab: true,
      },
      {
        path: VALUATIONS_ASSET_PATH,
        Page: AssetTemplate,
      },
      {
        path: VALUATIONS_ASSET_REPORT_PATH,
        Page: AssetTemplate,
      },
      {
        path: COUNTRY_OVERVIEWS_PATH,
        Page: CountryOverviews,
      },
      ...(inLocalEnv
        ? [
            {
              path: DATA_DOWNLOADS_PATH,
              Page: ShinyDownloads,
            },
          ]
        : []),
      {
        path: M_A_OVERVIEW_PATH,
        Page: MaPage,
      },
      {
        path: M_A_TRANSACTION_TABLE_PATH,
        Page: MaPage,
      },
      {
        path: M_A_TRANSACTION_ANALYTICS_PATH,
        Page: MaPage,
      },
      {
        path: M_A_COMPANY_BENCH_MARKING_PATH,
        Page: MaPage,
      },
      ...(maV2
        ? [
            {
              path: M_A_BASIC_PATH_V2,
              Page: MaPageNew,
            },
          ]
        : []),
      {
        path: CUSTOMER_DOWNLOADS_PATH,
        Page: SubscriberDownload,
      },
      {
        path: RESERVES_DEFINITION_PATH,
        Page: GuidanceDocumentation,
      },
      {
        path: WEA_ASSUMPTIONS_PATH,
        Page: GuidanceDocumentation,
      },
      {
        path: SETTINGS_PATH,
        Page: Settings,
      },
      ...(videoTutorials
        ? [
            {
              path: VIDEO_TUTORIALS_PATH,
              Page: VideoTutorials,
            },
          ]
        : []),
      {
        path: DEFAULT_PATH,
        Page: LandingPage,
      },
    ],
    [inDevelopment, inLocalEnv, maV2],
  );
};

export const AppRoutes = () => {
  const { enableSentry } = useFeatureFlags();
  const protectedRoutes = useProtectedRoutes();

  if (enableSentry) {
    Sentry.init({
      dsn: 'https://bebe9ddb38fc4e56971bd65f3b76f5b3@o1101134.ingest.sentry.io/6126882',
      environment: process.env.APP_ENV,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }

  return (
    <Switch>
      {ROUTES.map(({ path, Page }) => {
        return (
          <Route
            exact
            key={path}
            path={path}
            component={() => (
              <div data-testid={getTestId(Page.name)}>
                <Page />
              </div>
            )}
          />
        );
      })}
      {protectedRoutes.map(({ path, Page, forceNewTab }) => {
        return (
          <ProtectedRoute exact key={path} path={path}>
            <ErrorBoundary>
              <AppBar forceNewTab={forceNewTab} />
              <div data-testid={getTestId(Page.name)}>
                <Page />
              </div>
            </ErrorBoundary>
          </ProtectedRoute>
        );
      })}
    </Switch>
  );
};
