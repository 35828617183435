import { SECONDARY_Y_AXIS_TYPES } from './secondaryYAxisConstatns';

export const getSecondaryYAxisChartData = (
  xAxisList,
  secondaryY,
  graphYSecondaryType,
  chartTooltipUnitX,
) => {
  const xAxisListFiltered = xAxisList.filter((selector) => secondaryY[selector]);
  const secondaryArray = xAxisList.map((selector) => secondaryY[selector]);

  return {
    x: xAxisListFiltered,
    y:
      graphYSecondaryType === SECONDARY_Y_AXIS_TYPES.RESERVES_ACQUIRED
        ? secondaryArray.map((v) => (v ? Math.round(v) : 0))
        : secondaryArray.map((v) => {
            if (v) {
              const value = v.valuation / v.divideByReserves;
              return value >= 0 ? Math.round(value) : 0;
            }
            return 0;
          }),
    name: graphYSecondaryType,
    hovertemplate: `${chartTooltipUnitX}: %{x}<br>${graphYSecondaryType}: %{y}<extra></extra>`,
    yaxis: 'y2',
    type: 'scatter',
    mode: 'markers',
  };
};
