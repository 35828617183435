import { MAP_LAYER_TYPES } from '../Map/Utils/constants';

// layers to activate on map
export const LAYERS = [
  MAP_LAYER_TYPES.assets,
  MAP_LAYER_TYPES.basins,
  MAP_LAYER_TYPES.blocks,
  MAP_LAYER_TYPES.eaWells,
  MAP_LAYER_TYPES.facilities,
  MAP_LAYER_TYPES.fields,
  MAP_LAYER_TYPES.formations,
  MAP_LAYER_TYPES.leases,
  MAP_LAYER_TYPES.lng,
  MAP_LAYER_TYPES.pipelines,
  MAP_LAYER_TYPES.pipelineNetworks,
  MAP_LAYER_TYPES.pipelineSegments,
  MAP_LAYER_TYPES.presaltPolygons,
  MAP_LAYER_TYPES.screening,
  MAP_LAYER_TYPES.wells,
];
