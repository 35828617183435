import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => {
  return {
    wrapperRegionRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: '0.5rem',
      fontSize: theme.fontSizes.base,
    },
    checkAndName: {
      display: 'flex',
      alignItems: 'center',
    },
    labelRegion: {
      fontWeight: 'bold',
    },
    wrapperCountry: {
      marginLeft: '1.5rem',
    },
    wrapperRegion: {
      cursor: 'pointer',
    },
    subListOpened: {
      transform: 'rotate(180deg)',
    },
    chosen: {
      backgroundColor: theme.palette.baseColors.deepSeaNavy.c50,
      color: 'white',
      borderRadius: '25px',
      width: '20px',
      height: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: theme.fonts.base,
      fontSize: theme.fontSizes.smallest,
      marginLeft: '1rem',
    },
  };
});
