import { gql } from '@apollo/client';

export const GET_VIDEO_TUTORIALS_QUERY = gql`
  query videos(
    $search: String
    $contextPage: [String!]
    $after: Int
    $page: Int
    $perPage: Int
    $order: String
    $orderBy: String
  ) {
    videos(
      search: $search
      contextPage: $contextPage
      after: $after
      page: $page
      perPage: $perPage
      order: $order
      orderBy: $orderBy
    ) {
      videos {
        id
        title
        description
        contextPage
        contextOrder
        url
        thumbnailUrl
      }
      totalCount
    }
  }
`;
