import { COST_GRAPH_TYPES } from './countGraphConstatns';

export const getCostGraphSelector = (
  year,
  graph4Type,
  assetTransaction,
  companyTransaction,
  dealNotCounted,
) => {
  let selector = year;
  switch (graph4Type) {
    case COST_GRAPH_TYPES.YEAR:
      selector = year;
      break;
    case COST_GRAPH_TYPES.COUNTRY_NAME:
      if (!dealNotCounted) return null;
      selector = assetTransaction.countryName;
      break;
    case COST_GRAPH_TYPES.BUYER_NAME:
      selector = companyTransaction.buyerCompanyName;
      break;
    case COST_GRAPH_TYPES.SELLER_NAME:
      selector = companyTransaction.sellerCompanyName;
      break;
  }
  return selector;
};
