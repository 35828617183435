import React, { useEffect, useRef, useState } from 'react';

import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid2';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { useWidth } from '../../hooks/useWidth';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.palette.transparentBackground,
    ...theme.sizes.fullPage,
    display: 'flex',
    position: 'absolute',
    flexDirection: 'column',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.2)',
  },
  close: {
    ...theme.palette.actionButton,
    width: 'fit-content',
    paddingTop: '10px',
  },
  headerContainer: {
    padding: '0 20px',
    justifyContent: 'space-between',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '5px',
    maxWidth: '75%',
  },
  subHeader: {
    ...theme.palette.baseColors.iceBlue.c100,
    alignSelf: 'center',
    whiteSpace: 'pre-line',
    lineHeight: '15px',
    position: 'relative',
    top: '5px',
  },
  headerItem: {
    display: 'flex',
    flexDirection: 'row',
    overflowWrap: 'anywhere',
  },
  divider: {
    ...theme.palette.transparentBackgroundAccent,
    height: '2px',
    width: 'calc(100% - 20px)',
  },
  content: {
    padding: '10px 15px',
  },
}));

// header - title for overlay
// headerAdornment - component rendered next to the headers
// inlineStyles - inlineStyles to override
// isOpen - boolean if slide is open
// onClose - callback for when the slide closes
// startAdornment - component rendered before the close
// subHeader - subtitle for overlay
// styles - styles to override
const InfoOverlay = ({
  children,
  header,
  headerAdornment = null,
  inlineStyles = {},
  isOpen,
  onClose,
  startAdornment = null,
  subHeader,
  styles = {},
}) => {
  const width = useWidth();
  const classes = useStyles();
  const headerRef = useRef();
  const [contentHeight, setContentHeight] = useState('auto');

  useEffect(() => {
    if (headerRef?.current) {
      setContentHeight(headerRef.current.offsetHeight);
    }
  }, [width, header, subHeader, headerAdornment]);

  return (
    <Slide
      direction="up"
      in={isOpen}
      mountOnEnter
      unmountOnExit
      className={clsx({ [classes.root]: true, [styles.infoOverlayRoot]: !!styles.infoOverlayRoot })}
    >
      <Grid
        container
        style={{ overflow: 'hidden', flexDirection: 'row', ...inlineStyles }}
        size={12}
        sx={{
          alignItems: 'center',
        }}
      >
        {startAdornment}
        <Grid
          container
          direction="row"
          size={12}
          className={clsx({
            [styles.infoOverlayContent]: !!styles.infoOverlayContent,
          })}
        >
          <Grid
            ref={headerRef}
            container
            direction="column"
            size={12}
            sx={{
              alignItems: 'center',
            }}
          >
            <Grid
              container
              direction="row"
              className={classes.close}
              onClick={onClose}
              size={12}
              sx={{
                justifyContent: 'center',
              }}
            >
              <ExpandMoreIcon
                className={clsx({
                  [styles.infoOverlayClose]: !!styles.infoOverlayClose,
                })}
              />
              <Typography
                variant="caption"
                className={clsx({
                  [styles.infoOverlayClose]: !!styles.infoOverlayClose,
                })}
              >
                CLOSE TAB
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              className={classes.headerContainer}
              size={12}
              sx={{
                justifyContent: 'space-between',
              }}
            >
              <Grid
                className={classes.headerItem}
                style={{ padding: '5px' }}
                size={{
                  xs: 6,
                  lg: 5,
                }}
              >
                <Typography variant="h2" className={classes.header}>
                  {header}
                </Typography>
                <Typography variant="overline" className={classes.subHeader}>
                  {subHeader}
                </Typography>
              </Grid>
              {headerAdornment}
            </Grid>
            <Divider className={classes.divider} />
          </Grid>
          <Grid
            container
            direction="row"
            className={classes.content}
            style={{ height: `calc(100% - ${contentHeight}px)` }}
            size={12}
          >
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
};

export default InfoOverlay;
