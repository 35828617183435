import {
  INDUSTRY_TRACKER_TYPES,
  SECURE_REGION_DOWNLOAD_QUERY,
} from '../../../Downloads/operations';
import React, { useCallback, useContext } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import Divider from '@mui/material/Divider';
import IndustryTracker from './IndustryTracker';
import OilRigIcon from '../../../Icons/OilRigIcon';
import SnackbarContext from '../../../SnackbarContext';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useFeatureFlags } from '../../../../contexts/FeatureFlagsContext';

const useStyles = makeStyles((theme) => ({
  header: {
    ...theme.palette.secondaryBackground,
    alignItems: 'center',
    display: 'flex',
    padding: '5px 16px',
  },
  title: {
    paddingLeft: '10px',
  },
}));

export default () => {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);
  const { inDevelopment } = useFeatureFlags();

  const downloadQueryOptions = useCallback(
    (dataKey) => ({
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const { url, downloadMessage } = data[dataKey];
        if (url) {
          setSnack({
            open: true,
            message: `Your download should begin shortly.${downloadMessage}`,
            severity: 'success',
          });
          window.location.href = url;
        }
      },
      onError: (error) => {
        setSnack({
          severity: 'error',
          message: error.message,
          open: true,
        });
      },
    }),
    [setSnack],
  );

  const [getSecureRegionDownload] = useLazyQuery(
    SECURE_REGION_DOWNLOAD_QUERY,
    downloadQueryOptions('secureRegionDownload'),
  );

  const { data: industryTrackerDownloadTypesData } = useQuery(INDUSTRY_TRACKER_TYPES, {
    fetchPolicy: 'cache-and-network',
  });
  const industryTrackerDownloadTypes =
    industryTrackerDownloadTypesData?.industryTrackerDownloadTypes;

  const getDownloadLink = (downloadType, region, subType) => {
    getSecureRegionDownload({
      variables: { downloadType, region, subType },
    });
  };

  return (
    <>
      <div id="industry-tracker-accordion" className={classes.header}>
        <OilRigIcon sx={{ fontSize: '24px' }} />
        <Typography className={classes.title} variant="h6">
          Industry Trackers
        </Typography>
      </div>
      <Divider />
      {industryTrackerDownloadTypes
        ?.filter(({ type }) => inDevelopment || type !== 'LNG FACILITIES')
        ?.map((industryTrackerDownloadType) => (
          <IndustryTracker
            key={industryTrackerDownloadType.name}
            industryTrackerDownloadType={industryTrackerDownloadType}
            getDownloadLink={getDownloadLink}
          />
        ))}
    </>
  );
};
