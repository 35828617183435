export const COMP_BENCH_GRAPH_1_TYPES = {
  BUYER_NAME: 'Buyer Name',
  BUYER_YEAR: 'Buyer Year',
};

export const compBenchGraph1Types = Object.values(COMP_BENCH_GRAPH_1_TYPES).map((name) => name);

export const COMP_BENCH_GRAPH_2_TYPES = {
  SELLER_NAME: 'Seller Name',
  SELLER_YEAR: 'Seller Year',
};

export const compBenchGraph2Types = Object.values(COMP_BENCH_GRAPH_2_TYPES).map((name) => name);
