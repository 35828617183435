import CardListItem from './cardListItem';
import ErrorBoundary from '../ErrorBoundary';
import Loading from '../Loading';
import React from 'react';
import Sort from './sort';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    height: '100%',
  },
  text: {
    padding: '0 5px',
  },
  list: {
    ...theme.palette.scrollbar,
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    overflow: 'auto',
  },
}));

// getDateText, onCardClick, dateLabel, dateFormat - prop for cardListItem
// rootInlineCss - set inline css for root if needed
// items - array of items to render to cards
// loading - boolean if list is loading
// selectedItem - the selected item
// styles - css override
// title - title of list
// setSortType, sortType, sortTypes - props for sort component
export default ({
  dateLabel,
  dateFormat,
  getDateText,
  items = [],
  loading,
  onCardClick,
  selectedItem,
  setSortType,
  sortType,
  sortTypes,
  styles = {},
  title,
}) => {
  const classes = useStyles();

  return (
    <ErrorBoundary>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Typography variant="overline" className={classes.text}>
            {title} LIST: {items.length}
          </Typography>
          {sortType && sortTypes && (
            <Sort
              setSortType={setSortType}
              sortType={sortType}
              sortTypes={sortTypes}
              styles={classes}
            />
          )}
          <div className={clsx({ [classes.list]: true, [styles.verticalCardList]: !!styles.list })}>
            {items.map((item, idx) => (
              <CardListItem
                key={`${title}-card-list-item-${item.displayName}-${idx}`}
                item={item}
                dateLabel={dateLabel}
                dateFormat={dateFormat}
                getDateText={getDateText}
                onCardClick={onCardClick}
                sortType={sortType}
                selected={selectedItem?.displayName === item.displayName}
              />
            ))}
          </div>
        </>
      )}
    </ErrorBoundary>
  );
};
