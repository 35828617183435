import {
  ASSETS_SUMMARY_ITEM,
  ASSOCIATED_BLOCKS_SUMMARY_ITEM,
  BASIN_SUMMARY_ITEM,
  COUNTRY_SUMMARY_ITEM,
  DEVELOPMENT_STATUS_SUMMARY_ITEM,
  DEVELOPMENT_TYPE_SUMMARY_ITEM,
  DISCOVERY_DATE_SUMMARY_ITEM,
  PARTICIPATION_SUMMARY_ITEM,
  RESOURCE_THEME_SUMMARY_ITEM,
  SHORE_STATUS_SUMMARY_ITEM,
  WATER_DEPTH_M_SUMMARY_ITEM,
} from '../../../SummaryList/SummaryItems/base';
import {
  DISPLAY_NAME_FIELD_SUMMARY_ITEM,
  HYDROCARBON_TYPE_FIELD_SUMMARY_ITEM,
  PRODUCTION_START_DATE_FIELD_SUMMARY_ITEM,
  PRODUCTION_CESSATION_DATE_FIELD_SUMMARY_ITEM,
  PROJECT_SANCTION_DATE_FIELD_SUMMARY_ITEM,
} from '../../../SummaryList/SummaryItems/field';

export default () => [
  DISPLAY_NAME_FIELD_SUMMARY_ITEM,
  COUNTRY_SUMMARY_ITEM,
  HYDROCARBON_TYPE_FIELD_SUMMARY_ITEM,
  BASIN_SUMMARY_ITEM,
  ASSETS_SUMMARY_ITEM,
  ASSOCIATED_BLOCKS_SUMMARY_ITEM,
  DEVELOPMENT_STATUS_SUMMARY_ITEM,
  PARTICIPATION_SUMMARY_ITEM,
  SHORE_STATUS_SUMMARY_ITEM,
  WATER_DEPTH_M_SUMMARY_ITEM,
  DEVELOPMENT_TYPE_SUMMARY_ITEM,
  RESOURCE_THEME_SUMMARY_ITEM,
  DISCOVERY_DATE_SUMMARY_ITEM,
  PROJECT_SANCTION_DATE_FIELD_SUMMARY_ITEM,
  PRODUCTION_START_DATE_FIELD_SUMMARY_ITEM,
  PRODUCTION_CESSATION_DATE_FIELD_SUMMARY_ITEM,
];
