import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { AutoComplete } from '../../AutoComplete';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.palette.mapInfoSection,
  },
  label: {
    ...theme.palette.secondaryAccentText,
    margin: '-3px',
  },
}));

export default ({ displayedItem, loading, data, onChange }) => {
  const classes = useStyles();
  const entity = data.asset || data.block;

  /**
   * This componenet should only be rendered when:
   * - there is an item object to be displayed
   * - data is not loading
   * - data exists
   * - entity is an `asset` or `block`
   * - count of dropdown options includes more than the item to be displayed
   * - the item to be displayed is in the list of dropdown options
   */
  if (!displayedItem || loading || !data || !entity) {
    return null;
  }
  const { relatedExplorations } = entity;
  const menuItems = relatedExplorations.assets.concat(relatedExplorations.blocks);
  const relatedExploration = menuItems.find(
    (relatedExploration) => relatedExploration.name === displayedItem.name,
  );
  if (!(menuItems.length > 1) || !relatedExploration) {
    return null;
  }

  return (
    <div className={classes.root}>
      <AutoComplete
        getData={null}
        menuItems={menuItems}
        onChange={onChange}
        value={relatedExploration}
        label="Related Exploration"
        optionKey="displayName"
        runQueryAfterOnChange={false}
        queryVariable={null}
        multiple={false}
      />
    </div>
  );
};
