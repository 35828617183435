import React, { useEffect } from 'react';
import { Box, ClickAwayListener } from '@mui/material';
import { useSearchModalStyles } from './SearchModal.style';
import { useSearchModalController } from './SearchModal.controller';
import { ResultListGroups } from './components/ResultListGroups';
import CircularProgress from '@mui/material/CircularProgress';
import { Reports } from './components/Reports';
import Typography from '@mui/material/Typography';
import { GLOBAL_SEARCH_EVENTS, GLOBAL_SEARCH_GOOGLE_ANALYTICS_TAG } from '../constants';
import { getUserId } from '../../../utils/auth';
import { defaultDimensions } from '../../../utils/analytics';

export const SearchModal = ({ inputText, inputTextLast, closeModal, setHistory }) => {
  const inputTextLocal = inputText || inputTextLast;
  const { data, loading, dataReports, loadingReports, onActionClick } = useSearchModalController({
    inputText: inputTextLocal,
    setHistory,
  });

  useEffect(() => {
    const keyPress = (e) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    };

    gtag('event', GLOBAL_SEARCH_EVENTS.event, {
      event_category: GLOBAL_SEARCH_GOOGLE_ANALYTICS_TAG,
      event_action: GLOBAL_SEARCH_EVENTS.searchOpen,
      event_label: 'search_bar_open',
      userId: getUserId(),
      ...defaultDimensions,
    });

    window.addEventListener('keydown', keyPress);
    return () => {
      window.removeEventListener('keydown', keyPress);
    };
  }, []);

  const classes = useSearchModalStyles();

  const emptyData = !data?.fulltextSearch || data.fulltextSearch.length === 0;

  const showLastSearch = !inputText && !!inputTextLast && !emptyData;

  return (
    <>
      <div className={classes.overlay} />
      <ClickAwayListener onClickAway={closeModal}>
        <Box className={classes.root} data-testid="gs-modal">
          {showLastSearch && (
            <Typography className={classes.lastSearch} variant="body1" id="pumTst">
              Last search: {inputTextLast}
            </Typography>
          )}
          <Box
            className={classes.content}
            style={{ height: showLastSearch ? 'calc(100% - 36px)' : '100%' }}
          >
            <Box className={classes.results}>
              <Box
                sx={{
                  pl: '8px',
                }}
              >
                <Typography variant="h6">Site Results</Typography>
                {loading && emptyData ? (
                  <div className={classes.loading}>
                    <CircularProgress color="secondary" />
                  </div>
                ) : emptyData ? (
                  <Typography variant="body1">
                    {inputTextLocal ? 'No results found.' : 'Type to get results...'}
                  </Typography>
                ) : (
                  <>
                    {loading && (
                      <div className={classes.loading}>
                        <CircularProgress color="secondary" />
                      </div>
                    )}
                    <ResultListGroups rows={data.fulltextSearch} onActionClick={onActionClick} />
                  </>
                )}
              </Box>
            </Box>
            <Reports
              dataReports={dataReports}
              loadingReports={loadingReports}
              onActionClick={onActionClick}
              inputText={inputTextLocal}
            />
          </Box>
        </Box>
      </ClickAwayListener>
    </>
  );
};
