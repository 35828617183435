import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Legend from './legend';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  keepMounted: {
    ...theme.palette.keepMounted,
  },
  keepMountedEnabled: {
    ...theme.palette.keepMountedEnabled,
  },
  tabs: {
    minHeight: 'auto',
    '& > .MuiTabs-fixed': {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > .MuiTabs-flexContainer': {
        ...theme.palette.transparentBackground,
        width: 'fit-content',
      },
    },
  },
  tabLabel: {
    minHeight: 'auto',
    color: theme.palette.baseColors.deepSeaNavy.c100,
    cursor: 'pointer',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    backgroundColor: 'rgba(205, 215, 222, .75)',
    '&:hover': {
      backgroundColor: 'rgba(205, 215, 222, .95)',
    },
    '& > .MuiTab-wrapper': {
      fontSize: '.8rem',
      fontWeight: '400',
    },
  },
}));

export default ({ enabled, keepMounted, tabbedItems }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const { renderedItems, renderedTabs } = useMemo(() => {
    const renderedItems = [];
    const renderedTabs = [];
    tabbedItems.forEach((item, idx) => {
      renderedItems.push(
        <Legend
          key={`legend-tab-component-${item.label}-${idx}`}
          items={item.items}
          multiline={item.multiline}
          itemBorder={item.itemBorder}
        />,
      );
      renderedTabs.push(
        <Tab
          className={classes.tabLabel}
          key={`legend-tab-${item.label}-${idx}`}
          label={item.label}
        />,
      );
    });
    return { renderedItems, renderedTabs };
  }, [tabbedItems]);

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.keepMounted]: !!keepMounted,
        [classes.keepMountedEnabled]: !!keepMounted && !!enabled,
      })}
    >
      <Tabs className={classes.tabs} scrollButtons="auto" value={value} onChange={handleChange}>
        {renderedTabs}
      </Tabs>
      {renderedItems[value]}
    </div>
  );
};
