import {
  ACQUISITION_COST_AGGREGATE_COMPARE_TO_TITLE,
  RESERVES_AGGREGATES_TYPE_ENUM,
  RESERVES_AGGREGATE_BY_TITLE_OPTIONS,
  RESERVES_AGGREGATE_BY_TYPE_ENUM,
  RESERVES_STACK_BY_OPTIONS,
  TRANSACTIONS_AGGREGATE_GRAPH_BY_OPTIONS,
} from './types';
import React, { useEffect, useState } from 'react';

import { MaGraph } from '../MaGraph';
import { getGraphLayout } from '../../helpers';
import { titleize } from '../../../../utils/helpers';
import { useGetChartData } from './hooks/useGetChartData';
import { useStyles } from './styles';

const SESSION_STORAGE_KEY = 'MaCompanyAnalysisReservesChart{#type}';

const getInitialChartSettings = (type) => {
  const sessionSettings = sessionStorage.getItem(
    SESSION_STORAGE_KEY.replace('{#type}', titleize(type)),
  );
  if (sessionSettings) {
    return JSON.parse(sessionSettings);
  }

  return {
    stackBy: 'NONE', // default value must be non-null otherwise options wont render
    aggregateBy:
      type === RESERVES_AGGREGATES_TYPE_ENUM.BUYER
        ? RESERVES_AGGREGATE_BY_TYPE_ENUM.BUYER
        : RESERVES_AGGREGATE_BY_TYPE_ENUM.SELLER,
  };
};

const ReservesChart = ({ type }) => {
  const { stackBy: defaultStackBy, aggregateBy: defaultAggregateBy } =
    getInitialChartSettings(type);
  const [stackBy, setStackBy] = useState(defaultStackBy);
  const [aggregateBy, setAggregateBy] = useState(defaultAggregateBy);
  const { data, loading, error } = useGetChartData(aggregateBy, stackBy, type);

  useEffect(() => {
    // Save the current state to sessionStorage
    sessionStorage.setItem(
      SESSION_STORAGE_KEY.replace('{#type}', titleize(type)),
      JSON.stringify({ stackBy, aggregateBy }),
    );
  }, [stackBy, aggregateBy]);

  const xConfig = {
    value: aggregateBy,
    setValue: setAggregateBy,
    options: TRANSACTIONS_AGGREGATE_GRAPH_BY_OPTIONS[type],
  };

  const stackByConfig = {
    value: stackBy,
    setValue: setStackBy,
    options: RESERVES_STACK_BY_OPTIONS[type],
  };

  if (error) return null;

  const title = RESERVES_AGGREGATE_BY_TITLE_OPTIONS[type];
  const graphLayout = getGraphLayout({
    showLegend: true,
    yAxisTitle: title,
    altYAxisTitle: ACQUISITION_COST_AGGREGATE_COMPARE_TO_TITLE,
  });

  return (
    <MaGraph
      data={data}
      title={`${title} by ${titleize(aggregateBy)}`}
      loading={loading}
      xConfig={xConfig}
      stackByConfig={stackByConfig}
      graphLayout={graphLayout}
      maxXAxisTicks={aggregateBy === RESERVES_AGGREGATE_BY_TYPE_ENUM.YEAR ? 0 : 50}
    />
  );
};

export const CompanyAnalysis = () => {
  const classes = useStyles();

  return (
    <div className={classes.graphWrapper}>
      <ReservesChart type={RESERVES_AGGREGATES_TYPE_ENUM.BUYER} />
      <ReservesChart type={RESERVES_AGGREGATES_TYPE_ENUM.SELLER} />
    </div>
  );
};
