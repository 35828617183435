import {
  COUNTRY_LEVEL_ASSETS,
  COUNTRY_LEVEL_EXPLORATION_BLOCKS,
  COUNTRY_LEVEL_MAP_DATA,
  COUNTRY_LEVEL_GEOLOGY,
  COUNTRY_LEVEL_PRODUCTION,
  COUNTRY_LEVEL_CASHFLOW,
  ASSET_LEVEL_WELL_PROPERTIES,
  ASSET_LEVEL_WELL_PRODUCTION,
  ASSET_LEVEL_TOTAL_PRODUCTION,
  ASSET_LEVEL_WELL_SCHEDULE,
  ASSET_LEVEL_NPV,
  ASSET_LEVEL_PARTICIPATION,
  ASSET_LEVEL_CASH_FLOW,
} from '../constants';

/*
 */
export const downloadSummaries = {
  [COUNTRY_LEVEL_ASSETS]: {
    text: 'Operational and financial data for each commercial asset.',
    filename: (countries) => `Welligence Assets Download - ${countries.join(', ')}`,
  },
  [COUNTRY_LEVEL_EXPLORATION_BLOCKS]: {
    text: 'Operational data for each exploration block.',
    filename: (countries) => `Welligence Exploration Block Download - ${countries.join(', ')}`,
  },
  [COUNTRY_LEVEL_MAP_DATA]: {
    text: 'Download mapping files for fields, exploration blocks, wells, pipelines and facilities in addition to all relevant data.',
    documentation: {
      title: 'GeoPackage Documentation',
      // TODO: figure out how to provide documentation
    },
  },
  [COUNTRY_LEVEL_GEOLOGY]: {
    text: 'Geology data for each commercial asset.',
    filename: (countries) => `Welligence Geology Download - ${countries.join(', ')}`,
  },
  [COUNTRY_LEVEL_PRODUCTION]: {
    text: 'Production data for each commercial asset.',
    filename: (countries) => `Welligence Production Download - ${countries.join(', ')}`,
  },
  [COUNTRY_LEVEL_CASHFLOW]: {
    text: 'Cashflow data for each commercial asset.',
    filename: (countries) => `Welligence Cashflow Download - ${countries.join(', ')}`,
  },
  [ASSET_LEVEL_WELL_PROPERTIES]: {
    text: 'Properties for wells drilled in the selected assets.',
    filename: (assets) => `Welligence Well Properties - ${assets.join(', ')}`,
  },
  [ASSET_LEVEL_WELL_PRODUCTION]: {
    text: 'Well production data for wells in the selected assets.',
    filename: (assets) => `Welligence Well Production - ${assets.join(', ')}`,
  },
  [ASSET_LEVEL_TOTAL_PRODUCTION]: {
    text: 'Total production data for the selected assets.',
    filename: (assets) => `Welligence Total Production - ${assets.join(', ')}`,
  },
  [ASSET_LEVEL_WELL_SCHEDULE]: {
    text: 'Historical and future forecasted well schedule for the selected assets.',
    filename: (assets) => `Welligence Well Schedule - ${assets.join(', ')}`,
  },
  [ASSET_LEVEL_NPV]: {
    text: 'Net present value as calculated in the asset models for the selected assets.',
    filename: (assets) => `Welligence NPV - ${assets.join(', ')}`,
  },
  [ASSET_LEVEL_PARTICIPATION]: {
    text: 'Current Participation for the selected assets.',
    filename: (assets) => `Welligence Participation - ${assets.join(', ')}`,
  },
  [ASSET_LEVEL_CASH_FLOW]: {
    text: 'Cash flow data for the selected assets.',
    filename: (assets) => `Welligence Cash Flow - ${assets.join(', ')}`,
  },
};
