import React, { useMemo } from 'react';

import { MaGraph } from '../MaGraph';
import { getGraphLayout } from '../../helpers';
import { truncate } from '../../helpers';
import { useGetDeals } from './hooks/useGetDeals';
import { useStyles } from './styles';

const AcquisitionCostGraph = ({ data, customData, loading }) => {
  const chartTitle = 'Acquisition Cost (US$/boe)';
  const chartData = useMemo(
    () => [
      {
        x: data?.map((d) => d.effectiveDate) || [],
        y: data?.map((d) => d.acquisitionCostSum) || [],
        name: chartTitle,
        type: 'scatter',
        mode: 'markers',
        customdata: customData,
        hovertemplate: `Year: %{x|%Y}<br>${chartTitle}: %{y:,.2f}<br>Asset(s): %{customdata[0]}<br>Buyer: %{customdata[1]}<br>Seller: %{customdata[2]}<extra></extra>`,
      },
    ],
    [data],
  );

  const graphLayout = getGraphLayout({
    showLegend: true,
    yAxisTitle: chartTitle,
    xAxisType: 'date',
  });

  return (
    <MaGraph data={chartData} title={chartTitle} loading={loading} graphLayout={graphLayout} />
  );
};

const PriceFlowingBarrelGraph = ({ data, customData, loading }) => {
  const chartTitle = 'Price per Flowing Barrel (US$/boe)';

  const chartData = useMemo(
    () => [
      {
        x: data?.map((d) => d.effectiveDate) || [],
        y: data?.map((d) => d.priceFlowingBarrelSum) || [],
        name: chartTitle,
        type: 'scatter',
        mode: 'markers',
        customdata: customData,
        hovertemplate: `Year: %{x|%Y}<br>${chartTitle}: %{y:,.2f}<br>Asset(s): %{customdata[0]}<br>Buyer: %{customdata[1]}<br>Seller: %{customdata[2]}<extra></extra>`,
      },
    ],
    [data],
  );

  const graphLayout = getGraphLayout({
    showLegend: true,
    yAxisTitle: chartTitle,
    xAxisType: 'date',
  });

  return (
    <MaGraph data={chartData} title={chartTitle} loading={loading} graphLayout={graphLayout} />
  );
};

export const TransactionAnalytics = () => {
  const classes = useStyles();
  const { data, loading, error } = useGetDeals();

  const customData = useMemo(
    () =>
      data?.map((d) => {
        const { assetNames, buyerNames, sellerNames } = d?.maCompanyTransactions.reduce(
          (acc, c) => {
            return {
              assetNames: [...acc.assetNames, c?.maAssetTransactions?.map((a) => a?.assetName)],
              buyerNames: [...acc.buyerNames, c?.buyerCompany?.name],
              sellerNames: [...acc.sellerNames, c?.sellerCompany?.name],
            };
          },
          { assetNames: [], buyerNames: [], sellerNames: [] },
        );

        return [
          // remove duplicates and truncate at 100 chars
          truncate([...new Set(assetNames.flat())].join(', '), 100),
          truncate([...new Set(buyerNames)].join(', '), 100),
          truncate([...new Set(sellerNames)].join(', '), 100),
        ];
      }),
    [data],
  );

  if (error) return null;

  return (
    <div className={classes.graphWrapper}>
      <AcquisitionCostGraph data={data} customData={customData} loading={loading} />
      <PriceFlowingBarrelGraph data={data} customData={customData} loading={loading} />
    </div>
  );
};
