import { gql } from '@apollo/client';

export const GRAPH_CCUS_QUERY = gql`
  query graphCcus(
    $aggregate: String!
    $graphType: String!
    $filters: JSON
    $stackType: String
    $limit: Int
    $sortDirection: String
  ) {
    graphCcus(
      aggregate: $aggregate
      graphType: $graphType
      filters: $filters
      stackType: $stackType
      limit: $limit
      sortDirection: $sortDirection
    ) {
      analytics
      maxY
      metaData
    }
  }
`;

export const SECURE_CCUS_DOWNLOAD_QUERY = gql`
  query secureCcusDownload($id: ID!, $entityType: String!) {
    secureCcusDownload(id: $id, entityType: $entityType) {
      url
      filename
      downloadMessage
    }
  }
`;
