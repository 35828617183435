export const COST_GRAPH_TYPES = {
  YEAR: 'Year',
  COUNTRY_NAME: 'Country Name',
  BUYER_NAME: 'Buyer Name',
  SELLER_NAME: 'Seller Name',
};

export const costGraphTypesSimple = Object.values(COST_GRAPH_TYPES).map((name) => name);

export const costGraphTypes = Object.values(COST_GRAPH_TYPES).map((name) => ({
  label: name,
  value: name,
}));
