import React, { useEffect, useRef, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import { IconButton } from '@mui/material';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import clsx from 'clsx';
import { useDropDownStyles } from './DropDown.style';

export const DropDown = ({ children, dropDownContent, iconButtonClass }) => {
  const classes = useDropDownStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={clsx(classes.menuButton, iconButtonClass)}
        size="large"
      >
        {children}
      </IconButton>
      <Popper
        style={{ zIndex: open ? 1200 : -1 }} // zIndex of -1 so the mounted poppers don't block the welligence click functionality
        open={open}
        anchorEl={anchorRef.current}
        transition
        keepMounted // keep mounted so the shiny query doesn't cancel on popper close
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>{dropDownContent}</ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
