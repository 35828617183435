import React from 'react';
import { formatDateShort, roundAndFormat } from '../../../utils/helpers';
import { FacilityGraphs } from '../../OilAndGasMap/Info/graphs';

export const PARTICIPATION_FACILITY_SUMMARY_ITEM = {
  label: 'Participants',
  accessor: (f) => f?.company?.name || f?.commonFacilityProperties?.operator,
};

export const CAPACITY_FACILITY_SUMMARY_ITEM = {
  label: 'Capacity',
  accessor: 'formattedGasCapacity',
};

// TODO: Figure out what this is. Is this still needed?
export const GAS_SUPPLY_FACILITY_SUMMARY_ITEM = {
  label: 'Gas Supply',
  accessor: '',
};

export const CRUDE_STORAGE_FACILITY_SUMMARY_ITEM = {
  label: 'Crude Storage',
  unit: '(bbl)',
  accessor: 'crudeStorageBbl',
};

export const LIQUID_UTILIZATION_FACILITY_SUMMARY_ITEM = {
  label: 'Liquids Facility Utilization',
  accessor: (f) =>
    f?.liquidsThroughputRecentBbld && f?.oilCapacity
      ? `${roundAndFormat((f?.liquidsThroughputRecentBbld / f?.oilCapacity) * 100)}%`
      : null,
};

export const GAS_UTILIZATION_FACILITY_SUMMARY_ITEM = {
  label: 'Gas Facility Utilization',
  accessor: (f) =>
    f?.gasThroughputRecentMmcfd && f?.gasCapacity
      ? `${roundAndFormat((f?.gasThroughputRecentMmcfd / f?.gasCapacity) * 100)}%`
      : null,
};

export const FACILITY_NAME_FACILITY_SUMMARY_ITEM = {
  label: 'Name',
  accessor: 'name',
};

export const TYPE_FACILITY_SUMMARY_ITEM = {
  label: 'Type',
  accessor: (f) => f?.alternativeTypeName || f?.typeName,
};

export const OPERATOR_FACILITY_SUMMARY_ITEM = {
  label: 'Operator',
  accessor: (f) => f?.commonFacilityProperties?.operator,
};

export const STATUS_FACILITY_SUMMARY_ITEM = {
  label: 'Status',
  accessor: 'status',
};

export const SHORE_STATUS_FACILITY_SUMMARY_ITEM = {
  label: 'Shore Status',
  accessor: 'shoreStatus',
};

export const CONTRACT_TYPE_FACILITY_SUMMARY_ITEM = {
  label: 'Contract Type',
  accessor: 'contractType',
};

// TODO: Figure out what this is. Is this still needed?
export const FIELD_FACILITY_SUMMARY_ITEM = {
  label: 'Field',
  accessor: '',
};

export const INSTALL_DATE_FACILITY_SUMMARY_ITEM = {
  label: 'Install Date',
  accessor: (f) => formatDateShort(f?.installDate),
};

export const NAMEPLATE_CAPACITY_FACILITY_SUMMARY_ITEM = {
  label: 'Nameplate Capacity',
  unit: '(MW)',
  accessor: 'formattedPowerPlantCapacityMw',
};

export const OIL_PROCESSING_CAPACITY_FACILITY_SUMMARY_ITEM = {
  label: 'Oil Processing Capacity',
  unit: '(bbl/d)',
  accessor: 'formattedOilCapacity',
};

export const OIL_THROUGHPUT_FACILITY_SUMMARY_ITEM = {
  label: (f) =>
    f?.throughputRecentDate
      ? `Most Recent (${formatDateShort(f?.throughputRecentDate)}) Oil Throughput`
      : null,
  unit: '(bbl/d)',
  accessor: 'formattedOilThroughputRecentBbld',
};

export const WATER_INJECTION_CAPACITY_FACILITY_SUMMARY_ITEM = {
  label: 'Water Injection Capacity',
  unit: '(bbl/d)',
  accessor: (f) => roundAndFormat(f?.waterInjectionCapacity),
};

export const WATER_PROCESSING_CAPACITY_FACILITY_SUMMARY_ITEM = {
  label: 'Water Processing Capacity',
  unit: '(bbl/d)',
  accessor: 'formattedWaterInjectionCapacity',
};

export const WATER_THROUGHPUT_FACILITY_SUMMARY_ITEM = {
  label: (f) =>
    f?.throughputRecentDate
      ? `Most Recent (${formatDateShort(f?.throughputRecentDate)}) Water Throughput`
      : null,
  unit: '(bbl/d)',
  accessor: 'formattedWaterThroughputRecentBbld',
};

export const LIQUID_THROUGHPUT_FACILITY_SUMMARY_ITEM = {
  label: (f) =>
    f?.throughputRecentDate
      ? `Most Recent (${formatDateShort(f?.throughputRecentDate)}) Liquids Throughput`
      : null,
  unit: '(bbl/d)',
  accessor: 'formattedLiquidsThroughputRecentBbld',
};

export const GAS_CAPACITY_FACILITY_SUMMARY_ITEM = {
  label: 'Gas Processing Capacity',
  unit: '(MMcf/d)',
  accessor: 'formattedGasCapacity',
};

export const GAS_THROUGHPUT_FACILITY_SUMMARY_ITEM = {
  label: (f) =>
    f?.throughputRecentDate
      ? `Most Recent (${formatDateShort(f?.throughputRecentDate)}) Gas Throughput`
      : '',
  unit: '(MMcf/d)',
  accessor: 'formattedGasThroughputRecentMmcfd',
};

export const START_UP_FACILITY_SUMMARY_ITEM = {
  label: 'Start-up',
  accessor: (f) => formatDateShort(f?.startUp),
};

export const GRAPH_PRODUCTION_FACILITY_SUMMARY_ITEM = {
  type: 'graph',
  accessor: (f) => (f.id ? <FacilityGraphs facilityId={f.id} /> : null),
};
