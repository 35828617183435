export const getGraphLayoutSettings = ({
  showlegend,
  yAxisTitle,
  yAxisSecondaryTitle,
  xAxisType = 'category',
  marginBottom = 60,
  xTickAngle = 'auto',
}) => ({
  hoverlabel: { align: 'left' },
  annotations: [
    {
      xref: 'paper',
      yref: 'paper',
      x: 1,
      xanchor: 'right',
      y: 1,
      yanchor: 'bottom',
      text: 'Source: Welligence Energy Analytics',
      showarrow: false,
      font: {
        color: 'lightgrey',
        size: 10,
      },
    },
  ],
  xaxis: {
    type: xAxisType,
    tickangle: xTickAngle,
  },
  barmode: 'stack',
  margin: { l: yAxisTitle ? 65 : 50, r: yAxisSecondaryTitle ? 65 : 15, b: marginBottom, t: 30 },
  legend: {
    x: 1,
    xanchor: 'right',
    y: 1,
    bgcolor: 'rgba(236, 240, 245, 0.8)',
  },
  showlegend,
  ...(yAxisTitle && { yaxis: { title: { text: yAxisTitle }, standoff: 10, tickmode: 'sync' } }),
  ...(yAxisSecondaryTitle && {
    yaxis2: {
      title: {
        text: yAxisSecondaryTitle,
        standoff: 10,
      },
      overlaying: 'y',
      side: 'right',
      tickformat: ',d',
      rangemode: 'tozero',
    },
  }),
});

export const getSecondaryYAxisConfig = (title) => ({
  title: {
    text: title,
    standoff: 10,
  },
  overlaying: 'y',
  side: 'right',
  tickformat: ',d',
  rangemode: 'tozero',
});

export const GRAPH_LAYOUT_CONFIG = {
  modeBarButtonsToRemove: ['pan2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d', 'lasso2d', 'select2d'],
};
