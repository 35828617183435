import { gql } from '@apollo/client';

export const GET_AVAILABLE_DOWNLOADS_QUERY = gql`
  query availableCountryDownloads($countryIsoCode: String!) {
    availableCountryDownloads(countryIsoCode: $countryIsoCode) {
      titles
    }
  }
`;

export const SECURE_COUNTRY_DOWNLOAD_QUERY = gql`
  query secureCountryDownload($downloadType: String!, $countryIsoCode: String!) {
    secureCountryDownload(downloadType: $downloadType, countryIsoCode: $countryIsoCode) {
      url
      filename
      downloadMessage
    }
  }
`;

export const SECURE_REGION_DOWNLOAD_QUERY = gql`
  query secureRegionDownload($downloadType: String!, $region: String, $subType: String) {
    secureRegionDownload(downloadType: $downloadType, region: $region, subType: $subType) {
      url
      downloadMessage
    }
  }
`;

export const INDUSTRY_TRACKER_TYPES = gql`
  query industryTrackerDownloadTypes {
    industryTrackerDownloadTypes {
      name
      type
      regions {
        displayName
        region
        type
      }
    }
  }
`;
