import React from 'react';
import { PageShell } from '../PageShell';
import { useCompanyBenchMarkingController } from './CompanyBenchMarking.controller';
import {
  COMP_BENCH_GRAPH_1_TYPES,
  COMP_BENCH_GRAPH_2_TYPES,
  compBenchGraph1Types,
  compBenchGraph2Types,
} from './CompanyBenchMarking.constant';
import {
  getGraphLayoutSettings,
  GRAPH_LAYOUT_CONFIG,
} from '../../helpers/GraphLayoutSettingConstant';
import { STACK_BY_TYPES, stackByOptionsSimple } from '../../helpers/stackByConstatnts';
import { ChartWithMetrics } from '../ChartWithMetrics/ChartWithMetrics';
import { useStyles } from '../Overview/Overview.style';
import { SECONDARY_Y_AXIS_TYPES } from '../../helpers/secondaryYAxisConstatns';

export const CompanyBenchMarking = ({ data }) => {
  const {
    filters,
    chart1Data,
    chart2Data,
    chart1Title,
    chart2Title,
    graph1Type,
    updateGraph1Type,
    graph2Type,
    updateGraph2Type,
    stackByWarning1Chart,
    updateStackBy1Chart,
    stackBy1Chart,
    stackByWarning2Chart,
    updateStackBy2Chart,
    stackBy2Chart,
    showPlotlyLegendChart1,
    setShowPlotlyLegendChart1,
    showPlotlyLegendChart2,
    setShowPlotlyLegendChart2,
  } = useCompanyBenchMarkingController(data);
  const classes = useStyles();
  if (!filters) return null;

  return (
    <PageShell filters={filters}>
      <div style={{ marginBottom: '2rem' }} className={classes.graphWrapper}>
        <ChartWithMetrics
          data={chart1Data}
          graphLayoutSettings={getGraphLayoutSettings({
            showlegend: showPlotlyLegendChart1,
            yAxisTitle: chart1Title,
            yAxisSecondaryTitle: SECONDARY_Y_AXIS_TYPES.PRICE_PAID,
            xTickAngle: 45,
          })}
          graphLayoutConfig={GRAPH_LAYOUT_CONFIG}
          title={chart1Title}
          showPlotlyLegendToggle={true}
          showPlotlyLegend={showPlotlyLegendChart1}
          setShowPlotlyLegend={setShowPlotlyLegendChart1}
          x={{ value: graph1Type, setValue: updateGraph1Type, options: compBenchGraph1Types }}
          stackBy={{
            value: stackBy1Chart,
            setValue: updateStackBy1Chart,
            disabledReason: stackByWarning1Chart,
          }}
          getStackByOptions={(xValue) =>
            xValue === COMP_BENCH_GRAPH_1_TYPES.BUYER_NAME
              ? ['Enabled', STACK_BY_TYPES.NONE]
              : stackByOptionsSimple
          }
        />
        <ChartWithMetrics
          data={chart2Data}
          graphLayoutSettings={getGraphLayoutSettings({
            showlegend: showPlotlyLegendChart2,
            yAxisTitle: chart2Title,
            yAxisSecondaryTitle: SECONDARY_Y_AXIS_TYPES.PRICE_PAID,
            xTickAngle: 45,
          })}
          graphLayoutConfig={GRAPH_LAYOUT_CONFIG}
          title={chart2Title}
          showPlotlyLegendToggle={true}
          showPlotlyLegend={showPlotlyLegendChart2}
          setShowPlotlyLegend={setShowPlotlyLegendChart2}
          x={{ value: graph2Type, setValue: updateGraph2Type, options: compBenchGraph2Types }}
          stackBy={{
            value: stackBy2Chart,
            setValue: updateStackBy2Chart,
            disabledReason: stackByWarning2Chart,
          }}
          getStackByOptions={(xValue) =>
            xValue === COMP_BENCH_GRAPH_2_TYPES.SELLER_NAME
              ? ['Enabled', STACK_BY_TYPES.NONE]
              : stackByOptionsSimple
          }
        />
      </div>
    </PageShell>
  );
};
