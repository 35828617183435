import { RESERVES_DEFINITION_PATH, WEA_ASSUMPTIONS_PATH } from '../../utils/constants';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';

import React from 'react';
import ReservesDefinition from './components/ReservesDefinition';
import WeaAssumptions from './components/WeaAssumptions';

const GuidanceDocumentation = () => {
  return (
    <Switch>
      <Route path={RESERVES_DEFINITION_PATH}>
        <ReservesDefinition />
      </Route>
      <Route path={WEA_ASSUMPTIONS_PATH}>
        <WeaAssumptions />
      </Route>
      {/* TODO #1059 waiting for Research team to provide this content */}
      {/* <Route path={PRICE_ASSUMPTIONS_PATH}>
        <PriceAssumptions />
      </Route> */}
    </Switch>
  );
};

export default GuidanceDocumentation;
