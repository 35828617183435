import {
  COUNTRY_LEVEL_ASSETS,
  ASSET_LEVEL_WELL_PROPERTIES,
  ASSET_LEVEL_WELL_PRODUCTION,
} from '../../constants';
import {
  ASSET_LEVEL_WELL_PRODUCTION_COLUMNS,
  ASSET_LEVEL_WELL_PRODUCTION_ROWS,
} from './assetLevelWellProduction';
import {
  ASSET_LEVEL_WELL_PROPERTIES_COLUMNS,
  ASSET_LEVEL_WELL_PROPERTIES_ROWS,
} from './assetLevelWellProperties';
import { COUNTRY_LEVEL_ASSETS_COLUMNS, COUNTRY_LEVEL_ASSETS_ROWS } from './countryLevelAssets';

export default (downloadType, data) => {
  switch (downloadType) {
    case COUNTRY_LEVEL_ASSETS:
      return {
        columns: COUNTRY_LEVEL_ASSETS_COLUMNS,
        rows: COUNTRY_LEVEL_ASSETS_ROWS(data?.assets || []),
      };
    case ASSET_LEVEL_WELL_PROPERTIES:
      return {
        columns: ASSET_LEVEL_WELL_PROPERTIES_COLUMNS,
        rows: ASSET_LEVEL_WELL_PROPERTIES_ROWS(data?.wells || []),
      };
    case ASSET_LEVEL_WELL_PRODUCTION:
      return {
        columns: ASSET_LEVEL_WELL_PRODUCTION_COLUMNS,
        rows: ASSET_LEVEL_WELL_PRODUCTION_ROWS(data?.wells || []),
      };
    default:
      return { columns: [], rows: [] };
  }
};
