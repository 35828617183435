import { GET_VIDEO_TUTORIALS_QUERY } from '../operations';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

export const useGetVideos = ({ search, contextPage, after, page, perPage, order, orderBy }) => {
  const filters = useMemo(
    () => ({
      search,
      contextPage,
      after,
      page: page + 1,
      perPage,
      order,
      ...(orderBy ? { orderBy } : {}),
    }),
    [search, contextPage, after, page, perPage, order, orderBy],
  );

  const { data, loading, error } = useQuery(GET_VIDEO_TUTORIALS_QUERY, {
    variables: filters,
  });

  return {
    videos: data?.videos?.videos || [],
    totalCount: data?.videos?.totalCount || 0,
    loading,
    error,
  };
};
