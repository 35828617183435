import React from 'react';
import BasicFilters from './basicFilters';
import { useFilterSetsContext } from '../FilterBarWrapper';

// width - provided by MUI withWidth (used for styling)
const PortfolioList = ({
  applyFilters,
  basicFilterTypes,
  context,
  filterItemClass,
  googleAnalyticsTag,
  portfolios,
}) => {
  const { currentFilterSetIndex } = useFilterSetsContext();

  return (
    <>
      <BasicFilters
        applyFilters={(filters) => applyFilters(filters, currentFilterSetIndex)}
        basicFilterTypes={basicFilterTypes}
        context={context}
        filterItemClass={filterItemClass}
        filters={portfolios[currentFilterSetIndex].filters}
        googleAnalyticsTag={googleAnalyticsTag}
      />
    </>
  );
};

export default PortfolioList;
