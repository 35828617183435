import React, { createContext, useContext, useEffect, useState } from 'react';

import { FilterKeys } from '../Filters';

const FiltersContext = createContext();

export const INITIAL_YEARS = [1901, new Date().getFullYear()];
const INITIAL_VALUES = {
  [FilterKeys.assets]: [],
  [FilterKeys.countries]: [],
  [FilterKeys.companiesBuyer]: [],
  [FilterKeys.companiesSeller]: [],
  [FilterKeys.eventType]: [],
  [FilterKeys.hasMaTransactions]: true,
  dateSlider: INITIAL_YEARS,
};

const getInitialFilters = () => {
  const sessionFilters = sessionStorage.getItem('MA');
  if (sessionFilters) {
    return JSON.parse(sessionFilters);
  }
  return INITIAL_VALUES;
};

const FiltersProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [yearsRange, setYearsRange] = useState(INITIAL_YEARS);
  const [filters, setFilters] = useState(getInitialFilters());

  useEffect(() => {
    sessionStorage.setItem('MA', JSON.stringify(filters));
  }, [filters]);

  const resetFilters = () => {
    setLoading(true);
    setFilters({
      ...INITIAL_VALUES,
      dateSlider: yearsRange,
    });
  };

  useEffect(() => {
    // Had to create a loading state to force the dateSlider component to re-render
    // when resetting the filters, otherwise the component was showing the old values,
    // even though the filters state was updated.
    // I think this is an issue with the library.
    // TODO: in the future, after updating MUI, we need to revisit this to see if it can be removed.
    setLoading(false);
  }, [filters]);

  return (
    <FiltersContext.Provider
      value={{ filters, setFilters, resetFilters, yearsRange, setYearsRange, loading }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

const useFilters = () => useContext(FiltersContext);

export { FiltersProvider, useFilters };
