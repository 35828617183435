import { roundAndFormat } from '../../../utils/helpers';

export const STATUS_BLOCK_SUMMARY_ITEM = {
  label: 'Status',
  accessor: (b) => b?.blockStatus?.name,
};

export const LICENSING_ROUND_BLOCK_SUMMARY_ITEM = {
  label: 'Round',
  accessor: (b) => b?.licensingRound?.name,
};

export const GOVERNMENT_PROFIT_BLOCK_SUMMARY_ITEM = {
  label: 'Government Profit Share/Royalty Bid',
  accessor: (b) => {
    if (b?.formattedGovernmentProfitSharePct) {
      return b?.formattedGovernmentProfitSharePct;
    } else if (b?.formattedRoyaltyRatePct) {
      return roundAndFormat(b?.formattedRoyaltyRatePct);
    }
    return null;
  },
};

export const SIGNATURE_BONUS_BLOCK_SUMMARY_ITEM = {
  label: 'Signature Bonus',
  unit: '(US$MM)',
  accessor: (b) => b?.formattedSignatureBonus,
};

export const WELL_COMMITMENT_BLOCK_SUMMARY_ITEM = {
  label: 'Well Commitment',
  accessor: 'wellCommitmentCount',
};

export const WELLS_DRILLED_BLOCK_SUMMARY_ITEM = {
  label: 'Committed Wells Drilled',
  accessor: 'commitedWellsDrilled',
};
