import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client';
import {
  getAccessToken,
  getClient,
  getCsrfToken,
  getTokenExpiry,
  getTokenType,
  getUid,
} from './auth';

import { clearUserCredentials } from './auth';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: '/graphql',
});

const authLink = setContext((_, { headers }) => {
  const accessToken = getAccessToken();
  const client = getClient();
  const csrfToken = getCsrfToken();
  const tokenExpiry = getTokenExpiry();
  const tokenType = getTokenType();
  const uid = getUid();

  return {
    headers: {
      ...headers,
      'X-CSRF-Token': csrfToken,
      'access-token': accessToken ? accessToken : '',
      client: client,
      expiry: tokenExpiry,
      'token-type': tokenType,
      uid: uid,
    },
  };
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ path, extensions }) => {
      if (extensions) {
        switch (extensions['code']) {
          case 'AUTHENTICATION_ERROR':
            clearUserCredentials();
            if (path[0] !== 'currentUserProfile') {
              window.alert('Your session has EXPIRED! You will be redirected to login.');
              window.location.href = window.location.origin;
            }
            break;
        }
      }
    });
  }
});

const createCache = () => {
  const cache = new InMemoryCache();

  if (process.env.NODE_ENV === 'development') {
    window.cacheContainer = cache;
  }

  return cache;
};

export const createClient = () => {
  const client = new ApolloClient({
    link: ApolloLink.from([authLink, errorLink, httpLink]),
    cache: createCache(),
  });

  return client;
};
