import { gql } from '@apollo/client';

export const GRAPH_ANALYTICS = gql`
  query graphAnalytics(
    $aggregate: String!
    $graphType: String!
    $hydrocarbonType: String
    $emissionsSource: [JSON!]
    $discountRate: Float
    $portfolios: [JSON!]
    $stacked: Boolean
    $limit: Int
    $sortDirection: String
  ) {
    graphAnalytics(
      aggregate: $aggregate
      graphType: $graphType
      hydrocarbonType: $hydrocarbonType
      emissionsSource: $emissionsSource
      discountRate: $discountRate
      portfolios: $portfolios
      stacked: $stacked
      limit: $limit
      sortDirection: $sortDirection
    ) {
      analytics
      maxY
    }
  }
`;

export const GRAPH_ASSETS_QUERY_NEW = gql`
  query graphAssetsNew(
    $aggregates: [String!]!
    $chartType: String!
    $graphType: String!
    $emissionsSource: [JSON!]
    $portfolios: [JSON!]
    $limit: Int
    $sortDirection: String
  ) {
    graphAssetsNew(
      aggregates: $aggregates
      chartType: $chartType
      graphType: $graphType
      emissionsSource: $emissionsSource
      portfolios: $portfolios
      limit: $limit
      sortDirection: $sortDirection
    ) {
      analytics
      maxY
    }
  }
`;
