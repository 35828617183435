import { Box } from '@mui/material';
import { EmptyState } from '../../../../components/EmptyState';
import React from 'react';
import { VideoGalleryControls } from '../VideoGalleryControls';
import { VideoGalleryGrid } from '../VideoGalleryGrid';
import { VideoGalleryGridSkeleton } from '../VideoGalleryGrid';
import { VideoLibrary } from '@mui/icons-material';
import { useStyles } from './styles';

export const VideoGallery = ({ videos, totalCount, loading }) => {
  const classes = useStyles();

  return (
    <>
      <VideoGalleryControls />
      <Box
        className={classes.gallery}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {loading && <VideoGalleryGridSkeleton />}
        {!loading && videos?.length === 0 && (
          <EmptyState
            Icon={VideoLibrary}
            title="No videos found"
            description="Try searching for something else"
          />
        )}

        {!loading && videos?.length > 0 && <VideoGalleryGrid {...{ videos, totalCount }} />}
      </Box>
    </>
  );
};
