import {
  ASSOCIATED_ASSETS_SUMMARY_ITEM,
  COUNTRY_SUMMARY_ITEM,
  WATER_DEPTH_M_SUMMARY_ITEM,
} from '../../../SummaryList/SummaryItems/base';
import { NAME_BASIN_SUMMARY_ITEM } from '../../../SummaryList/SummaryItems/basin';
import { COMPANY_SUMMARY_ITEM } from '../../../SummaryList/SummaryItems/company';
import {
  PARTICIPATION_FACILITY_SUMMARY_ITEM,
  CAPACITY_FACILITY_SUMMARY_ITEM,
  GAS_SUPPLY_FACILITY_SUMMARY_ITEM,
  CRUDE_STORAGE_FACILITY_SUMMARY_ITEM,
  LIQUID_UTILIZATION_FACILITY_SUMMARY_ITEM,
  GAS_UTILIZATION_FACILITY_SUMMARY_ITEM,
  FACILITY_NAME_FACILITY_SUMMARY_ITEM,
  TYPE_FACILITY_SUMMARY_ITEM,
  OPERATOR_FACILITY_SUMMARY_ITEM,
  STATUS_FACILITY_SUMMARY_ITEM,
  SHORE_STATUS_FACILITY_SUMMARY_ITEM,
  CONTRACT_TYPE_FACILITY_SUMMARY_ITEM,
  FIELD_FACILITY_SUMMARY_ITEM,
  INSTALL_DATE_FACILITY_SUMMARY_ITEM,
  NAMEPLATE_CAPACITY_FACILITY_SUMMARY_ITEM,
  OIL_PROCESSING_CAPACITY_FACILITY_SUMMARY_ITEM,
  OIL_THROUGHPUT_FACILITY_SUMMARY_ITEM,
  WATER_INJECTION_CAPACITY_FACILITY_SUMMARY_ITEM,
  WATER_PROCESSING_CAPACITY_FACILITY_SUMMARY_ITEM,
  WATER_THROUGHPUT_FACILITY_SUMMARY_ITEM,
  LIQUID_THROUGHPUT_FACILITY_SUMMARY_ITEM,
  GAS_CAPACITY_FACILITY_SUMMARY_ITEM,
  GAS_THROUGHPUT_FACILITY_SUMMARY_ITEM,
  START_UP_FACILITY_SUMMARY_ITEM,
  GRAPH_PRODUCTION_FACILITY_SUMMARY_ITEM,
} from '../../../SummaryList/SummaryItems/facility';
import asset from './asset';

export default (layer, getEntity) => {
  let peru = [];
  let guyana = [];
  let facilityUtilization = [];
  if (layer === 'PER') {
    peru = [
      PARTICIPATION_FACILITY_SUMMARY_ITEM,
      CAPACITY_FACILITY_SUMMARY_ITEM,
      GAS_SUPPLY_FACILITY_SUMMARY_ITEM,
    ];
  } else if (layer === 'GUY') {
    guyana = [CRUDE_STORAGE_FACILITY_SUMMARY_ITEM];
  }
  if (layer !== 'BRA') {
    facilityUtilization = [
      LIQUID_UTILIZATION_FACILITY_SUMMARY_ITEM,
      GAS_UTILIZATION_FACILITY_SUMMARY_ITEM,
    ];
  }
  return [
    {
      label: 'Facility',
      type: 'group',
      template: [
        FACILITY_NAME_FACILITY_SUMMARY_ITEM,
        COUNTRY_SUMMARY_ITEM,
        ...peru,
        TYPE_FACILITY_SUMMARY_ITEM,
        OPERATOR_FACILITY_SUMMARY_ITEM,
        STATUS_FACILITY_SUMMARY_ITEM,
        SHORE_STATUS_FACILITY_SUMMARY_ITEM,
        CONTRACT_TYPE_FACILITY_SUMMARY_ITEM,
        FIELD_FACILITY_SUMMARY_ITEM,
        WATER_DEPTH_M_SUMMARY_ITEM,
        ASSOCIATED_ASSETS_SUMMARY_ITEM,
        INSTALL_DATE_FACILITY_SUMMARY_ITEM,
        ...guyana,
        NAMEPLATE_CAPACITY_FACILITY_SUMMARY_ITEM,
        OIL_PROCESSING_CAPACITY_FACILITY_SUMMARY_ITEM,
        OIL_THROUGHPUT_FACILITY_SUMMARY_ITEM,
        WATER_INJECTION_CAPACITY_FACILITY_SUMMARY_ITEM,
        WATER_PROCESSING_CAPACITY_FACILITY_SUMMARY_ITEM,
        WATER_THROUGHPUT_FACILITY_SUMMARY_ITEM,
        LIQUID_THROUGHPUT_FACILITY_SUMMARY_ITEM,
        GAS_CAPACITY_FACILITY_SUMMARY_ITEM,
        GAS_THROUGHPUT_FACILITY_SUMMARY_ITEM,
        ...facilityUtilization,
        START_UP_FACILITY_SUMMARY_ITEM,
        GRAPH_PRODUCTION_FACILITY_SUMMARY_ITEM,
      ],
      getEntity,
    },
    {
      label: 'Assets',
      type: 'multiGroup',
      accessor: (f) => f.assets,
      template: asset(layer),
    },
    {
      label: 'Company',
      type: 'group',
      template: [COMPANY_SUMMARY_ITEM],
      getEntity: (f) => f.company,
    },
    {
      label: 'Basin',
      type: 'group',
      template: [NAME_BASIN_SUMMARY_ITEM],
      getEntity: (f) => f.basin,
    },
  ];
};
