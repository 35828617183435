export const STACK_BY_TYPES = {
  BUYER: 'Buyer',
  SELLER: 'Seller',
  NONE: 'None',
};

export const stackByOptionsSimple = Object.values(STACK_BY_TYPES).map((name) => name);

export const stackByOptions = Object.values(STACK_BY_TYPES).map((name) => ({
  label: name,
  value: name,
}));

export const stackByOptionsSeller = [STACK_BY_TYPES.SELLER, STACK_BY_TYPES.NONE];

export const stackByOptionsBuyer = [STACK_BY_TYPES.BUYER, STACK_BY_TYPES.NONE, STACK_BY_TYPES.NONE];
