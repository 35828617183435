import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  tabs: {
    fontWeight: '600',
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    margin: '1rem 0',
  },
  root: {
    fontFamily: theme.fonts.base,
    display: 'flex',
    ...theme.sizes.fullPage,
  },
  content: {
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'column',
  },
  button: {
    ...theme.palette.button,
    height: '36px',
    width: 'fit-content',
    minWidth: 'none',
  },
  altButton: {
    ...theme.palette.altButton,
    height: '36px',
    width: 'fit-content',
    minWidth: 'none',
  },
  chips: {
    display: 'flex',
    gap: '.5rem',
    borderBottom: 'solid rgba(0, 0, 0, 0.12) thin',
    paddingRight: '13px',
    padding: '0.5rem 0',
  },
  chipItemContainer: {
    minHeight: '40px',
  },
  consolidateAssetsButton: {
    margin: '13px 2px',
    height: '36px',
    width: 'fit-content',
    minWidth: 'none',
  },
  buttonSection: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    columnGap: '0.5rem',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '180px',
      minWidth: '58px',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      paddingBottom: '.5rem',
      rowGap: '1rem',
      minWidth: 'unset',
    },
  },
  filterSection: { display: 'flex', flexGrow: '1', flexWrap: 'wrap', alignItems: 'center' },
  filterItem: {
    flexBasis: '16%',
    [theme.breakpoints.down('lg')]: {
      flexBasis: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
    },
  },
  sliderWrapper: {
    paddingRight: '1rem',
    paddingLeft: '1.5rem',
    width: '15rem',
  },
  slider: {
    flexBasis: '16%',
    [theme.breakpoints.down('md')]: {
      flexBasis: '50%',
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    borderBottom: `1px solid ${theme.palette.baseColors.fireOrange.c100}`,
    padding: '6px',
    '&:hover': {
      color: theme.palette.baseColors.fireOrange.c100,
    },
  },
  currentLink: {
    color: theme.palette.baseColors.fireOrange.c100,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingLeft: '1.5rem',
    overflow: 'auto',
  },
}));
