import Button from './button';
import Grid from '@mui/material/Grid2';
import React from 'react';
import Typography from '@mui/material/Typography';

export default ({ list = [], title }) => {
  const ButtonListItemsContainer = () =>
    list.map((button, idx) => {
      return (
        <Button
          key={`button-list-button-${title}-${button.title}-${idx}`}
          header={button.header}
          Icon={button.Icon}
          onClickCallback={button.onClick}
          title={button.title}
        />
      );
    });

  return (
    <Grid container size={12}>
      <Typography variant="overline">{title}</Typography>
      <Grid container size={12} style={{ padding: '5px 0' }}>
        <ButtonListItemsContainer />
      </Grid>
    </Grid>
  );
};
