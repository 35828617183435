import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles(() => ({
  input: {
    width: '70%',
    margin: '5px',
  },
  minMax: {
    width: '35%',
    margin: '5px',
  },
}));

// inputLabel - label for the input (active when NOT a minMax group)
// isMinMax - flag for rendering a min/max input group (default: false)
// onChange - onChange function for the input item (active when NOT a minMax group)
// onKeyDown - onKeyDown function for the input item
// required - flag if the input item is a required field
// value - the value of the input item (active when NOT a minMax group)
// tab - flag for tabbing through the input items
// id - id of the input item
export default ({
  inputLabel,
  isMinMax = false,
  onChange = () => {},
  required = false,
  value,
  tab = false,
  id,
  styles = {},
  isDisabled,
  alignRight = false,
  originCashflow = false,
}) => {
  const classes = useStyles();

  const findTabStop = (el, getPrevious) => {
    const universe = document.querySelectorAll('input, button, select, textarea, a[href]');
    const list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= '0';
    });
    const index = list.indexOf(el);

    // if shift key is pressed down get the previous index
    const step = originCashflow ? 0 : getPrevious ? -1 : 1;
    let nextIndex = index + step;
    return list[nextIndex];
  };

  const onKeyDown = (current) => {
    if (!tab || current.key !== 'Tab') {
      return;
    }
    const nextEl = findTabStop(current.target, current.shiftKey);
    nextEl?.focus();
  };

  return (
    <TextField
      id={id}
      className={clsx({
        [classes.input]: !isMinMax,
        [classes.minMax]: isMinMax,
        [styles.inputItemRoot]: !!styles.inputItemRoot,
      })}
      variant="outlined"
      label={inputLabel}
      size="small"
      onChange={onChange}
      onKeyDown={onKeyDown}
      required={required}
      value={value}
      disabled={isDisabled}
      slotProps={{
        htmlInput: {
          style: {
            textAlign: alignRight ? 'right' : 'left',
          },
        },

        inputLabel: {
          shrink: true,
        },
      }}
    />
  );
};
