import React, { useState } from 'react';
import { STACK_BY_TYPES, stackByOptionsSimple } from '../../helpers/stackByConstatnts';

import { COST_GRAPH_TYPES } from '../../helpers/countGraphConstatns';
import Grid from '@mui/material/Grid2';
import { MaChartGraphNew } from '../MaChartGraphNew/MaChartGraphNew';
import { MetricNew } from '../ChartControls/MetricsNew';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const getClasses = makeStyles(() => ({
  warning: {
    lineHeight: 1.5,
  },
}));

export const ChartWithMetrics = ({
  x,
  y,
  ySecondary,
  stackBy,
  data,
  graphLayoutSettings,
  graphLayoutConfig,
  title,
  enableChartSettingsButton,
  setShowPlotlyLegend,
  showPlotlyLegend,
  showPlotlyLegendToggle,
  getStackByOptions = (xValue) => {
    if (xValue === COST_GRAPH_TYPES.BUYER_NAME || xValue === COST_GRAPH_TYPES.SELLER_NAME) {
      return ['Enabled', STACK_BY_TYPES.NONE];
    } else {
      return stackByOptionsSimple;
    }
  },
  defaultStackByValue = STACK_BY_TYPES.NONE,
}) => {
  const [openControlsModal, setOpenControlsModal] = useState(false);
  const [xValue, setXValue] = useState(x?.value);
  const [yValue, setYValue] = useState(y?.value);
  const [ySecondaryValue, setYSecondaryValue] = useState(ySecondary?.value);
  const [stackByValue, setStackBy] = useState(stackBy?.value);
  const classes = getClasses();

  const updateXValue = (value) => {
    setXValue(value);
    setStackBy(defaultStackByValue);
  };

  const updateYValue = (value) => {
    setYValue(value);
    setStackBy(defaultStackByValue);
  };

  const submitCb = () => {
    x && x.setValue(xValue);
    y && y.setValue(yValue);
    ySecondary && ySecondary.setValue(ySecondaryValue);
    stackBy && stackBy.setValue(stackByValue);
    setOpenControlsModal(false);
  };

  return (
    <MaChartGraphNew
      content={
        x ? (
          <>
            <Grid style={{ padding: '10px 20px' }} container size={6}>
              <MetricNew options={x.options} setValue={updateXValue} value={xValue} />
              {y && <MetricNew options={y.options} setValue={updateYValue} value={yValue} />}
            </Grid>
            <Grid style={{ padding: '10px 20px' }} container size={6}>
              <div style={{ width: '100%' }}>
                {!!stackBy?.disabledReason && (
                  <Typography variant="overline" sx={classes.warning}>
                    {stackBy.disabledReason}
                  </Typography>
                )}
                {!!stackBy && (
                  <MetricNew
                    title="Stack By"
                    options={getStackByOptions(xValue)}
                    setValue={setStackBy}
                    value={stackByValue}
                    isDisabled={!!stackBy?.disabledReason}
                    keyValue={xValue}
                  />
                )}
                {ySecondary && (
                  <MetricNew
                    title="Secondary Y Axis"
                    options={ySecondary.options}
                    setValue={setYSecondaryValue}
                    value={ySecondaryValue}
                  />
                )}
              </div>
            </Grid>
          </>
        ) : null
      }
      submitCb={submitCb}
      setOpenControlsModal={setOpenControlsModal}
      openControlsModal={openControlsModal}
      data={data}
      graphLayoutSettings={graphLayoutSettings}
      graphLayoutConfig={graphLayoutConfig}
      title={title}
      enableChartSettingsButton={enableChartSettingsButton}
      setShowPlotlyLegend={setShowPlotlyLegend}
      showPlotlyLegend={showPlotlyLegend}
      showPlotlyLegendToggle={showPlotlyLegendToggle}
    />
  );
};
