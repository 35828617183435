import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  label: {
    ...theme.palette.alternateText,
    fontWeight: 'bold',
  },
  slider: {
    color: theme.palette.baseColors.skyBlue.c100,
  },
}));

export default ({
  disabled = false,
  marks = [
    { value: 0, label: '0' },
    { value: 100, label: '100' },
  ],
  max = 100,
  onChange,
  value,
  title,
  tooltip,
}) => {
  const classes = useStyles();

  return (
    <>
      {tooltip ? (
        <Tooltip title={tooltip}>
          <Typography className={classes.label} variant="overline">
            {title}
          </Typography>
        </Tooltip>
      ) : (
        <Typography className={classes.label} variant="overline">
          {title}
        </Typography>
      )}
      <Slider
        className={classes.slider}
        value={value}
        onChange={onChange}
        valueLabelDisplay="auto"
        disabled={disabled}
        marks={marks}
        max={max}
      />
    </>
  );
};
