export const fillSimpleData = (data, selector, value, allowZero) => {
  if (!value && (!allowZero || !!data[selector])) return;
  if (typeof value === 'object' && value !== null) {
    const cumulative = data[selector] || {};
    Object.keys(value).forEach((key) => {
      cumulative[key] = (cumulative[key] || 0) + value[key];
    });
    data[selector] = cumulative;
  } else {
    data[selector] = (data[selector] || 0) + (value || 0);
  }
};
