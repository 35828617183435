import { COMP_GRAPH_TYPES, COMP_Y_GRAPH_TYPES } from '../TransactionAnalytics.constant';
import { getCostGraphSelector } from '../../../helpers/getCostGraphSelector';
import { STACK_BY_TYPES } from '../../../helpers/stackByConstatnts';
import { fillStackedData } from '../../../helpers/fillStackedData';
import { fillSimpleData } from '../../../helpers/fillSimpleData';
import { COST_GRAPH_TYPES } from '../../../helpers/countGraphConstatns';
import { getDataForPricePaidCalculation } from '../../../helpers/getDataForPricePaidCalculation';

export const getCalculatePageContentDataCb =
  (graph3XType, graph3YType, graph4Type, stackBy, graphYSecondaryType) =>
  (pageContentData, year, deal, companyTransaction, assetTransaction) => {
    const dealNotCounted = !pageContentData.dealIds.includes(deal.id);
    if (dealNotCounted) {
      pageContentData.dealIds.push(deal.id);
    }

    pageContentData.assetNames[assetTransaction.assetName] = true;
    const {
      weaWorkingInterestProduction,
      weaWorkingInterestValuation,
      weaWorkingInterestReserves,
    } = assetTransaction;
    if (weaWorkingInterestValuation > 0 && weaWorkingInterestReserves > 0) {
      pageContentData.reservesRatioTemp[deal.id] = {
        date: deal.effectiveDate,
        valuation:
          (pageContentData.reservesRatioTemp[deal.id]?.valuation || 0) +
          (weaWorkingInterestValuation || 0),
        reserves:
          (pageContentData.reservesRatioTemp[deal.id]?.reserves || 0) +
          (weaWorkingInterestReserves || 0),
      };
    }
    if (weaWorkingInterestProduction > 0 && weaWorkingInterestValuation > 0) {
      pageContentData.barelRatioTemp[deal.id] = {
        date: deal.effectiveDate,
        production:
          (pageContentData.barelRatioTemp[deal.id]?.production || 0) + weaWorkingInterestProduction,
        valuation:
          (pageContentData.barelRatioTemp[deal.id]?.valuation || 0) + weaWorkingInterestValuation,
      };
    }

    //GRAPH 3

    const axisXAggregator =
      graph3XType === COMP_GRAPH_TYPES.YEAR
        ? year
        : graph3XType === COMP_GRAPH_TYPES.BUYER_NAME
        ? companyTransaction.buyerCompanyName
        : graph3XType === COMP_GRAPH_TYPES.SELLER_NAME
        ? companyTransaction.sellerCompanyName
        : assetTransaction.assetName;

    if (axisXAggregator) {
      const axisYValue =
        graph3YType === COMP_Y_GRAPH_TYPES.VALUATION
          ? assetTransaction.weaWorkingInterestValuation
          : graph3YType === COMP_Y_GRAPH_TYPES.RESERVE
          ? assetTransaction.weaWorkingInterestReserves
          : assetTransaction.weaWorkingInterestProduction;

      const secondaryYData = getDataForPricePaidCalculation(assetTransaction, graphYSecondaryType);

      let selectorOuter;
      let selectorInner;
      if (
        graph3XType === COMP_GRAPH_TYPES.BUYER_NAME ||
        graph3XType === COMP_GRAPH_TYPES.BUYER_NAME
      ) {
        selectorOuter =
          graph3XType === COMP_GRAPH_TYPES.BUYER_NAME
            ? companyTransaction.sellerCompanyName
            : companyTransaction.buyerCompanyName;
        selectorInner =
          graph3XType === COMP_GRAPH_TYPES.BUYER_NAME
            ? companyTransaction.buyerCompanyName
            : companyTransaction.sellerCompanyName;
      } else {
        selectorOuter =
          stackBy === STACK_BY_TYPES.SELLER
            ? companyTransaction.sellerCompanyName
            : companyTransaction.buyerCompanyName;
        selectorInner = axisXAggregator;
      }

      fillSimpleData(pageContentData.secondaryYChart3, selectorInner, secondaryYData, true);

      if (axisYValue > 0) {
        fillStackedData(pageContentData.workingInterest, selectorOuter, selectorInner, axisYValue);

        pageContentData.graph3XAxisList[selectorInner] = {
          name: selectorInner,
          value: (pageContentData.graph3XAxisList[selectorInner]?.value || 0) + axisYValue,
        };
      }
    }

    pageContentData.assetNamesPerDeal[deal.id] = {
      ...(pageContentData.assetNamesPerDeal[deal.id] || {}),
      [assetTransaction.assetName]: true,
    };

    pageContentData.buyersPerDeal[deal.id] = {
      ...(pageContentData.buyersPerDeal[deal.id] || {}),
      [companyTransaction.buyerCompanyName]: true,
    };

    pageContentData.sellersPerDeal[deal.id] = {
      ...(pageContentData.sellersPerDeal[deal.id] || {}),
      [companyTransaction.sellerCompanyName]: true,
    };

    //GRAPH 4

    const selectorInner = getCostGraphSelector(
      year,
      graph4Type,
      assetTransaction,
      companyTransaction,
      dealNotCounted,
    );

    if (selectorInner && (graph4Type !== COST_GRAPH_TYPES.YEAR || dealNotCounted)) {
      pageContentData.countByType[deal.dealType] = {
        ...(pageContentData.countByType[deal.dealType] || {}),
        [selectorInner]:
          ((pageContentData.countByType[deal.dealType] || {})[selectorInner] || 0) + 1,
      };
      pageContentData.chart4XAxisList[selectorInner] = true;
    }
  };
