import { Button, MenuItem } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import { DATA_DOWNLOADS_PATH } from '../../../utils/constants';
import { OpenInNewOutlined } from '@mui/icons-material';
import React from 'react';
import { ShinyLink } from '../../Shiny';
import clsx from 'clsx';
import { useCurrentUser } from '../../CurrentUserContext';
import { useMemo } from 'react';
import { useMenuButtonStyles } from './MenuButton.style';

export const MenuButton = ({ link, item, onClick, forceNewTab, className }) => {
  const classes = useMenuButtonStyles();
  const location = useLocation();
  const path = location.pathname;

  const handleMenuItemClick = (event) => {
    event.target.children[0]?.click();
  };

  const target = useMemo(() => {
    if (forceNewTab) {
      return '_blank';
    }
    return link.target;
  }, [forceNewTab, link.target]);

  const { isUserEntitled } = useCurrentUser();

  const isSelectedLink = path.indexOf(item.link) !== -1;

  return (
    <MenuItem
      className={clsx(classes.menuItem, {
        [classes.inactiveMenuItem]: !isUserEntitled(item.link),
      })}
      key={item.title}
      onClick={handleMenuItemClick}
    >
      {item.type === 'shiny' ? (
        <ShinyLink
          link={item}
          styles={{ link: classes.link }}
          component="link"
          disabled={!isUserEntitled(DATA_DOWNLOADS_PATH)}
          onClickCallback={onClick}
          className={className}
          endAdornment={<OpenInNewOutlined className={classes.iconExternal} />}
        />
      ) : item.type === 'button' ? (
        <Button
          color="inherit"
          className={clsx(classes.link, classes.button)}
          underline="none"
          onClick={() => {
            item.onClick();
            onClick();
          }}
        >
          {item.title}
        </Button>
      ) : (
        <Link
          onClick={onClick}
          to={isUserEntitled(item.link) ? `${item.link}` : '#'}
          target={target}
          className={clsx(classes.link, className, {
            [classes.inactiveLink]: !isUserEntitled(item.link),
            [classes.selectedLink]: isSelectedLink,
          })}
        >
          {item.title}
        </Link>
      )}
    </MenuItem>
  );
};
