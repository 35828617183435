import { CONSOLIDATED_ASSETS_QUERY, GET_REQUEST_STATUS, REQUEST_REPORT } from './operations';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import SnackbarContext from '../../SnackbarContext';
import { enabledCountries } from './constants';
import { openDownloadUrl } from '../../Downloads/helpers';
import { openWebSocketConnection } from './websocket';
import { useCurrentUser } from '../../CurrentUserContext';
import { useFeatureFlags } from '../../../contexts/FeatureFlagsContext/FeatureFlagsContext';

export const useConsolidatedAssetsController = ({ portfolios }) => {
  const { setSnack } = useContext(SnackbarContext);
  const [requestingReport, setRequestingReport] = useState(false);
  const [restrictedIsoCodes, setRestrictedIsoCodes] = useState([]);
  const [restricted, setRestricted] = useState(true);
  const [assetCountries, setAssetCountries] = useState([]);
  const [assetsLoading, setAssetsLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(null);
  const [assetIds, setAssetIds] = useState([]);
  const { disableCcfLimit } = useFeatureFlags();

  const webSocket = useRef(null);
  const userReportsPollingTimer = useRef(null);
  const { currentUser } = useCurrentUser();
  const maxNumberOfAssets = 350;

  // TODO: remove this after testing
  const onlyPolling = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('onlyPolling') === 'true';
  }, []);

  const showErrorSnack = useMemo(
    () => (error) => {
      setSnack({
        open: true,
        message: `Something went wrong: ${error}`,
        severity: 'error',
      });
    },
    [setSnack],
  );

  const handleReportDownload = (reportUrl) =>
    reportUrl
      ? openDownloadUrl(reportUrl, 'Consolidated Report')
      : showErrorSnack('Something went wrong.');

  const showSuccessSnack = (reportUrl) => {
    setSnack({
      open: true,
      severity: 'success',
      message: `Report is ready. Open by clicking: `,
      action: {
        title: 'Combined Cash Flows Report',
        onClick: () => handleReportDownload(reportUrl),
      },
      disableHide: true,
    });
  };

  const {
    data: requestStatusResponseData,
    startPolling: pollRequestStatus,
    stopPolling: stopPollingRequestStatus,
  } = useQuery(GET_REQUEST_STATUS, {
    variables: { requestId: currentRequest?.id },
  });

  const { data: consolidatedAssetsData, loading: consolidatedLoading } = useQuery(
    CONSOLIDATED_ASSETS_QUERY,
    {
      variables: { portfolios: portfolios },
    },
  );

  useEffect(() => {
    if (consolidatedAssetsData && consolidatedAssetsData.consolidatedAssets) {
      const assetIds = consolidatedAssetsData?.consolidatedAssets.assetIds;
      const assetCountries = consolidatedAssetsData?.consolidatedAssets.assetCountries;
      const environment = process.env.APP_ENV;
      const enabledIsoCodes = Object.keys(enabledCountries);
      let restrictedIsoCodes = [];
      let alert = false;

      if (assetCountries) {
        restrictedIsoCodes = Object.keys(assetCountries).filter(
          (value) => !enabledIsoCodes.includes(value),
        );
      }

      if (assetIds) {
        const length = assetIds.reduce(
          (accumulator, subAssetIds) => accumulator + subAssetIds.length,
          0,
        );

        alert = length > maxNumberOfAssets && !disableCcfLimit;
      }

      const restricted =
        assetIds.length === 0 || (environment !== 'stg' && restrictedIsoCodes.length > 0);

      setAssetIds(assetIds);
      setAssetCountries(assetCountries);
      setAlert(alert);
      setRestrictedIsoCodes(restrictedIsoCodes);
      setRestricted(restricted);
    }
  }, [consolidatedAssetsData]);

  useEffect(() => {
    setAssetsLoading(consolidatedLoading);
  }, [consolidatedLoading]);

  useEffect(() => {
    return () => {
      webSocket.current?.close();
    };
  }, []);

  useEffect(() => {
    const requestStatus = requestStatusResponseData?.consolidatedAssetCashflowsRequestStatus;
    const { presignedUrl, message } = requestStatus || {};

    if (presignedUrl) {
      // If the report url is present, the request is complete so we can stop polling
      // and show the success snack with the download link:
      stopPollingRequestStatus();
      setRequestingReport(false);
      clearTimeout(userReportsPollingTimer.current);
      showSuccessSnack(requestStatus.presignedUrl);
    } else if (message && message !== currentRequest?.message) {
      // If there's a new message, show it in the snack:
      setSnack({
        open: true,
        message,
        severity: 'info',
      });
    }

    setCurrentRequest((currentRequest) => ({
      ...currentRequest,
      ...requestStatus,
    }));
  }, [requestStatusResponseData]);

  const [requestReport] = useLazyQuery(REQUEST_REPORT, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { presignedWebsocketUrl, requestId } = data?.consolidatedAssetCashflows || {};
      setCurrentRequest({ id: requestId });

      webSocket.current = openWebSocketConnection(
        onlyPolling ? null : presignedWebsocketUrl,
        requestId,
        (message, reportUrl, error, assetName = null) => {
          if (reportUrl || error) {
            // Either we have a report url or an error, so we can close the websocket connection:
            webSocket.current = null;
            const notAdminMessage =
              'Cash flow consolidation is taking longer than usual. Please check your inbox in the next few minutes for an email with the report attached.';

            if (error) {
              // Handle the error:
              if (error.message === 'Timeout') {
                setRequestingReport(false);
                setSnack({
                  open: true,
                  severity: 'info',
                  message: notAdminMessage,
                  disableHide: true,
                });
              } else if (assetName) {
                const errorMessage = currentUser.admin ? error.message : notAdminMessage;

                setRequestingReport(false);
                showErrorSnack(errorMessage);
              } else {
                pollRequestStatus(5000);

                userReportsPollingTimer.current = setTimeout(
                  () => {
                    if (requestingReport) {
                      setRequestingReport(false);
                      stopPollingRequestStatus();
                      showErrorSnack('Timeout.');
                    }
                  },
                  1000 * 60 * 10,
                ); // 10 minutes
              }
            } else {
              // Handle the report url:
              setRequestingReport(false);
              showSuccessSnack(reportUrl);
            }
          } else {
            // If we don't have a report url or an error, show the progress message:
            setSnack({
              open: true,
              severity: 'info',
              message,
              disableHide: true,
            });
          }
        },
      );
    },
    onError: () => {
      showErrorSnack('Something went wrong.');
      setRequestingReport(false);
    },
  });

  const tooltipMessage = useMemo(() => {
    let msg = 'Calculate Portfolio Cash Flow';

    if (requestingReport) {
      msg = 'Report request in progress...';
    } else if (assetsLoading) {
      msg = 'Assets loading...';
    } else if (alert) {
      msg =
        'Your search has returned more than 350 assets. Please narrow the scope of your search and re-try this request.';
    } else if (restricted) {
      msg = 'The current filtering returns no assets that can go through cash flow consolidation.';

      if (assetIds.length > 0) {
        const restrictedNames = restrictedIsoCodes.map((iso) => assetCountries[iso]);

        msg = `We are currently unable to consolidate cash flow data for the following countries. Please exclude them from your search: ${restrictedNames.join(
          ', ',
        )}`;
      }
    }

    return msg;
  }, [alert, assetsLoading, requestingReport]);

  const disabled = useMemo(() => {
    return restricted || alert || assetsLoading || requestingReport;
  }, [restricted, alert, assetsLoading, requestingReport]);

  return {
    tooltipMessage,
    disabled,
    setRequestingReport,
    requestReport,
    assetIds,
  };
};
