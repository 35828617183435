import { formatDateShort } from '../../../utils/helpers';

export const NAME_PIPELINE_SUMMARY_ITEM = {
  label: 'Name',
  accessor: 'name',
};

export const TYPE_PIPELINE_SUMMARY_ITEM = {
  label: 'Type',
  accessor: (p) => p?.product?.name,
};

export const LENGTH_PIPELINE_SUMMARY_ITEM = {
  label: 'Length',
  unit: '(km)',
  accessor: 'formattedLengthKm',
};

export const STATUS_PIPELINE_SUMMARY_ITEM = {
  label: 'Status',
  accessor: (p) => p?.status?.name,
};

export const GAS_CAPACITY_PIPELINE_SUMMARY_ITEM = {
  label: 'Capacity',
  unit: '(MMcf/d)',
  accessor: 'formattedGasCapacityMmcfd',
};

export const COST_PIPELINE_SUMMARY_ITEM = {
  label: 'Cost',
  unit: '(US$MM)',
  accessor: 'formattedCost',
};

export const SPONSOR_PIPELINE_SUMMARY_ITEM = {
  label: 'Sponsor',
  accessor: 'sponsor',
};

export const START_DATE_PIPELINE_SUMMARY_ITEM = {
  label: 'Start Date',
  accessor: (p) => formatDateShort(p?.startDate),
};

export const NETWORK_PIPELINE_SUMMARY_ITEM = {
  label: 'Pipeline Network',
  accessor: 'network',
};

export const SEGMENT_PIPELINE_SUMMARY_ITEM = {
  label: 'Pipeline Network Segment',
  accessor: 'segment',
};

export const PARTICIPATION_PIPELINE_SUMMARY_ITEM = {
  label: 'Participation',
  accessor: 'participation',
};

export const LIQUID_CAPACITY_PIPELINE_SUMMARY_ITEM = {
  label: () => 'Capacity',
  unit: '(bbl/d)',
  accessor: 'formattedLiquidsCapacityBbld',
};
