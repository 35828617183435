const TICK_LABEL_LIMIT = 8;

export const getTickText = (trace) => {
  if (trace[0]?.text) {
    const ticktextObj = {
      ticktext: [],
    };
    trace.forEach((stackedTrace) => {
      const xLabels = stackedTrace.text;
      xLabels.forEach((t) => {
        // check if label is already in ticktextObj
        if (!ticktextObj[t]) {
          ticktextObj[t] = true;
          // add the label depending on the number of labels and the length of the label
          if (t.length > TICK_LABEL_LIMIT) {
            ticktextObj.ticktext.push(`${t.slice(0, 7)}...`);
          } else {
            ticktextObj.ticktext.push(t);
          }
        }
      });
    });

    return ticktextObj.ticktext;
  } else {
    return trace.map((t) => {
      if (t.x && t.x[0]) {
        // This formats our x axis labels in case the strings are too long
        if (typeof t.x[0] === 'string') {
          if (t.x[0].length > TICK_LABEL_LIMIT) {
            return `${t.x[0].slice(0, 7)}...`;
          } else {
            return t.x[0];
          }
        } else {
          // In specific charts we return a number
          // We want to catch the error in those cases and return the number
          return t.x[0];
        }
      }
    });
  }
};

export const getTickVals = (trace) => {
  if (trace[0]?.text) {
    const tickvalObj = {
      tickval: [],
    };
    trace.forEach((stackedTrace) => {
      const xLabels = stackedTrace.text;
      xLabels.forEach((t) => {
        // check if label is already in tickvalObj
        if (!tickvalObj[t]) {
          tickvalObj[t] = true;
          tickvalObj.tickval.push(t);
        }
      });
    });

    return tickvalObj.tickval;
  } else {
    return trace.map((t) => {
      if (t.x && t.x[0]) {
        return t.x[0];
      }
    });
  }
};
