import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import ChipList from '../../ChipList';
import { FilterLabels } from '../../Filters';
import { UserFilters } from '../../Modals/UserFilters';
import { VALUATIONS_CONTEXT, VALUATIONS_GOOGLE_ANALYTICS_TAG } from '../constants';

const useStyles = makeStyles((theme) => ({
  button: {
    ...theme.palette.altButton,
  },
  buttonWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem',
  },
  valuationsChipContainer: {
    flexBasis: 'auto',
    padding: '5px 12px',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
  },
  chipItemContainer: {
    minHeight: '40px',
    maxHeight: '67px',
    overflowY: 'auto',
  },
}));

export default ({ filters, setFilters, clearFilters }) => {
  const classes = useStyles();

  const deleteFilter = (filter) => {
    const newFilters = { ...filters };
    const { key, value } = filter;
    newFilters[key] =
      typeof newFilters[key] === 'object'
        ? newFilters[key].filter((val) =>
            val?.menuName && value?.menuName
              ? val?.menuName !== value?.menuName
              : val.name !== value.name,
          )
        : '';
    setFilters(newFilters);
  };

  const chips = Object.keys(filters)
    .filter((filter) => filters[filter].length > 0)
    .reduce(
      (acc, filter) =>
        typeof filters[filter] === 'object'
          ? filters[filter]
              .map((value) => ({
                key: filter,
                parent: FilterLabels[filter],
                value,
              }))
              .concat(acc)
          : [
              ...acc,
              {
                key: filter,
                parent: FilterLabels[filter],
                value: { name: filters[filter] },
              },
            ],
      [],
    );

  return (
    <div className={classes.valuationsChipContainer}>
      <div>
        <ChipList
          title="CURRENT FILTERS"
          chips={chips}
          deleteChip={deleteFilter}
          styles={classes}
        />
      </div>
      <div>
        <div className={classes.buttonWrapper}>
          <Button variant="contained" className={classes.button} onClick={clearFilters}>
            Clear
          </Button>
          <UserFilters
            id="valuation-filters"
            filters={filters}
            setFilters={setFilters}
            context={VALUATIONS_CONTEXT}
            googleAnalyticsTag={VALUATIONS_GOOGLE_ANALYTICS_TAG}
          />
        </div>
      </div>
    </div>
  );
};
