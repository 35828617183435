/* eslint-disable */
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ChipItem from './chipItem';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    flex: '1',
    padding: '5px',
    ...theme.palette.altScrollbarBlue,
  },
  chipRoot: {
    margin: '5px',
  },
  chipBase: {
    height: 'auto',
  },
  chipLabel: {
    whiteSpace: 'normal',
  },
}));

export default ({ chips, deleteChip }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {chips.map((chip, idx) => (
        <ChipItem
          key={`chip-list-${chip.parent}-${chip.value.name}-${idx}`}
          chip={chip}
          onChipClick={() => {}}
          deleteChip={deleteChip}
          styles={classes}
        />
      ))}
    </div>
  );
};
