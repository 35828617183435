import {
  COMP_BENCH_GRAPH_1_TYPES,
  COMP_BENCH_GRAPH_2_TYPES,
} from '../CompanyBenchMarking.constant';
import { STACK_BY_TYPES } from '../../../helpers/stackByConstatnts';
import { getChartDataXY } from '../../../helpers/getChartDataXY';
import { getSecondaryYAxisChartData } from '../../../helpers/getSecondaryYAxisChartData';
import { SECONDARY_Y_AXIS_TYPES } from '../../../helpers/secondaryYAxisConstatns';

export const getChartData = (graphType, graphXAxisList, chartData, stackBy, secondaryY) => {
  const xAxisList = graphXAxisList;

  const isOrderedByYear =
    graphType === COMP_BENCH_GRAPH_1_TYPES.BUYER_YEAR ||
    graphType === COMP_BENCH_GRAPH_2_TYPES.SELLER_YEAR;

  const allChart2XAxisListFiltered = Object.keys(xAxisList).filter((x) => xAxisList[x].value);
  const allChart2XAxisList = isOrderedByYear
    ? allChart2XAxisListFiltered
    : allChart2XAxisListFiltered.sort((a, b) => {
        const aValue = xAxisList[a].value;
        const bValue = xAxisList[b].value;
        return bValue - aValue;
      });
  const allowStacking = allChart2XAxisList.length < 30;
  const isTop20 = allowStacking && stackBy !== STACK_BY_TYPES.NONE;

  const secondaryYAxisData = getSecondaryYAxisChartData(
    allChart2XAxisList,
    secondaryY,
    SECONDARY_Y_AXIS_TYPES.PRICE_PAID,
    isOrderedByYear ? 'Year' : 'Company Name',
  );

  let chart2X;

  const buyers = [];
  const buyersByType = {};

  allChart2XAxisList.forEach((x) => {
    if (isTop20) {
      Object.keys(chartData).forEach((companyName) => {
        buyersByType[companyName] = [
          ...(buyersByType[companyName] || []),
          chartData[companyName][x],
        ];
      });
    } else {
      buyers.push(xAxisList[x].value);
    }
  });

  chart2X = allChart2XAxisList;

  let chartName;

  switch (graphType) {
    case COMP_BENCH_GRAPH_1_TYPES.BUYER_NAME:
      chartName = `Buyer Reserves Acquired (MMboe)`;
      break;
    case COMP_BENCH_GRAPH_2_TYPES.SELLER_NAME:
      chartName = `Seller Reserves Divested (MMboe)`;
      break;
    case COMP_BENCH_GRAPH_1_TYPES.BUYER_YEAR:
      chartName = `Buyer Reserves Acquired (MMboe) by Year`;
      break;
    case COMP_BENCH_GRAPH_2_TYPES.SELLER_YEAR:
      chartName = `Seller Reserves Divested (MMboe) by Year`;
      break;
  }

  return {
    data: isTop20
      ? [
          ...getChartDataXY(
            chartData,
            allChart2XAxisList,
            (outerKey) => `Company Name: ${outerKey}<br>Reserves (MMboe): %{y}<extra></extra>`,
            { type: 'bar' },
          ),
          secondaryYAxisData,
        ]
      : [
          {
            x: chart2X,
            y: buyers,
            type: 'bar',
            name: chartName,
            hovertemplate: 'Reserves (MMboe): %{y}<extra></extra>',
          },
          secondaryYAxisData,
        ],
    name: chartName,
    stackByWarning: allowStacking
      ? null
      : "Too many bars present in the chart, please filter in more specific data set to enable 'Stack By' feature.",
  };
};
