import { gql } from '@apollo/client';

export const LOAD_FILTER = gql`
  query UserFilters($type: String!) {
    userFilters(type: $type) {
      id
      name
      filter
    }
  }
`;
