import React, { useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { INDUSTRY_TRACKER_IMAGES } from '../../constants';
import Tracker from './Tracker';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useFeatureFlags } from '../../../../contexts/FeatureFlagsContext';

const useStyles = makeStyles((theme) => ({
  details: {
    padding: 0,
  },
  layersAccordion: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    textTransform: 'uppercase',
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px',
    },
    fontWeight: 'bold',
  },
  summary: {
    '& > .MuiAccordionSummary-content': {
      alignItems: 'center',
    },
  },
}));

export default ({ industryTrackerDownloadType, getDownloadLink }) => {
  const classes = useStyles();
  const { inDevelopment } = useFeatureFlags();

  const [expanded, setExpanded] = useState(false);

  const { name, type, regions } = industryTrackerDownloadType;
  const image = INDUSTRY_TRACKER_IMAGES(inDevelopment)[type];
  return (
    <Accordion square={true} expanded={expanded} onChange={(e, exp) => setExpanded(exp)}>
      <AccordionSummary
        className={classes.summary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-layers"
      >
        <img src={image} width="20px" height="20px" />
        &nbsp;&nbsp;
        <Typography className={classes.label} variant="caption">
          {name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {regions.map(({ displayName, region, type: subType }) => (
          <Tracker
            key={displayName}
            title={displayName}
            onClick={() => getDownloadLink(type, region, subType)}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
