import React from 'react';
import Graph from './graph';
import { TREE_MAP_CONTEXT } from './constants';

// labels - list of all entities in tree
// parents - list of parents of entity corresponding to same idx in 'labels'
// values - list of values corresponding to each entity
// colors - list of colors corresponding to each entity
// layout - any additional layout properties or overwrite default

export default ({
  ids,
  labels,
  parents,
  values,
  colors,
  title,
  xlabel,
  ylabel,
  layout,
  customdata,
  hovertemplate,
  loading,
  onClick,
  level,
  texttemplate,
}) => {
  return (
    <Graph
      data={[
        {
          labels: labels,
          parents: parents,
          values: values,
          ids: ids,
          marker: {
            colors: colors ? colors : null,
          },
          type: 'treemap',
          branchvalues: 'total',
          customdata,
          hovertemplate,
          level,
          textinfo: 'label+text',
          texttemplate: texttemplate,
        },
      ]}
      config={{ displayModeBar: false }}
      layout={{
        title: {
          font: {
            family: "'Barlow', sans-serif",
            size: 12,
          },
          pad: {
            b: 2,
            l: 2,
          },
          y: 0,
          yanchor: 'bottom',
          x: 0,
          xaanchor: 'left',
        },
        ...layout,
      }}
      title={title}
      xlabel={xlabel}
      ylabel={ylabel}
      loading={loading}
      onClick={onClick}
      context={TREE_MAP_CONTEXT}
    />
  );
};
