import makeStyles from '@mui/styles/makeStyles';

export const useDropDownStyles = makeStyles((theme) => ({
  menu: {
    ...theme.appBarDropDown,
  },
  menuCurrent: {
    borderTop: `solid ${theme.palette.baseColors.fireOrange.c100} 3px`,
  },
  selectedLink: {
    ...theme.palette.accentText,
    ...theme.menuButtonFontStyles,
  },
  menuButton: {
    '&:hover': {
      backgroundColor: theme.palette.baseColors.iceBlue.c25,
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: '.5rem',
    },
  },
  openedLink: {
    backgroundColor: theme.palette.baseColors.iceBlue.c25,
  },
  circle: {
    backgroundColor: theme.palette.baseColors.deepSeaNavy.c100,
    color: theme.palette.baseColors.deepSeaNavy.c25,
    width: '40px',
    minWidth: 'unset',
    height: '40px',
    borderRadius: '50px',
    marginLeft: '0.5rem',
    '&:hover': {
      backgroundColor: theme.palette.baseColors.deepSeaNavy.c75,
    },
  },
}));
