export const PER_PAGE_OPTIONS = [10, 25, 50];
export const ORDER_BY_OPTIONS = [
  { value: 'createdAt', label: 'Date uploaded' },
  { value: 'title', label: 'Title' },
  { value: 'contextOrder', label: 'Order in page' },
];
export const ORDER_OPTIONS = [
  { value: 'asc', label: 'ASC' },
  { value: 'desc', label: 'DESC' },
];
