import makeStyles from '@mui/styles/makeStyles';

export const useReportsStyles = makeStyles((theme) => ({
  root: {
    flexBasis: 'calc(50% - 2rem)',
    maxHeight: '100%',
    overflowY: 'auto',
    margin: '0 1rem',
  },
  title: {
    marginBottom: '8px',
  },
  loading: {
    ...theme.palette.loading,
    backgroundColor: 'none',
  },
  top: {
    display: 'flex',
    gap: '1rem',
    marginBottom: '.5rem',
  },
  image: {
    borderRadius: '5px',
    width: '90px',
    height: '90px',
    flexShrink: 0,
    marginRight: '10px',
    backgroundColor: theme.palette.baseColors.deepSeaNavy.c10,
  },
  longTextDiv: {
    flexGrow: '1',
  },
  rows: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  report: {
    display: 'block',
    marginBottom: '1.5rem',
    color: theme.palette.text.primary,
  },
  name: {
    lineHeight: 1.25,
    fontSize: theme.fontSizes.base,
    marginBottom: '0.5rem',
  },
  date: {
    color: theme.palette.baseColors.deepSeaNavy.c75,
    fontSize: theme.fontSizes.smallest,
    fontWeight: 500,
  },
  row: {
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
}));
