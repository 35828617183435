import {
  TRANSACTIONS_AGGREGATE_BY_TYPE_ENUM,
  TRANSACTIONS_AGGREGATE_FIELDS_OPTIONS,
  TRANSACTIONS_STACK_BY_TYPE_ENUM,
} from '../types';

import { FilterKeys } from '../../../../Filters';
import { MA_TRANSACTIONS_AGGREGATES_STACKED_QUERY } from '../operations';
import { useFilters } from '../../../FiltersContext';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

export const useGetMaTransactionsAggregatesStacked = (
  by = TRANSACTIONS_AGGREGATE_BY_TYPE_ENUM.COUNTRY,
  stackBy = TRANSACTIONS_STACK_BY_TYPE_ENUM.BUYER,
  fields = TRANSACTIONS_AGGREGATE_FIELDS_OPTIONS,
  skip = true,
) => {
  const { filters } = useFilters();

  const filterVariables = useMemo(() => {
    const [startYear, endYear] = filters?.dateSlider || [];

    return {
      assets: filters[FilterKeys.assets]?.map((a) => Number(a.entityId)) || [],
      countries: filters[FilterKeys.countries]?.map((c) => c.isoCode) || [],
      buyers: filters[FilterKeys.companiesBuyer]?.map((b) => Number(b.id)) || [],
      sellers: filters[FilterKeys.companiesSeller]?.map((s) => Number(s.id)) || [],
      eventTypes: filters[FilterKeys.eventType]?.map((e) => e.name) || [],
      startYear: startYear,
      endYear: endYear,
    };
  }, [filters]);

  const { data, loading, error } = useQuery(MA_TRANSACTIONS_AGGREGATES_STACKED_QUERY, {
    variables: {
      by,
      stackBy,
      fields,
      ...filterVariables,
    },
    skip,
  });

  return {
    data: data?.maTransactionsAggregatesStacked?.data || [],
    stacks: data?.maTransactionsAggregatesStacked?.stacks || [],
    loading,
    error,
  };
};
