import { getCsrfToken } from '../../utils/auth';

export const openDownloadUrl = (url, filename) => {
  let anchor = document.createElement('a');

  anchor.href = url;
  anchor.download = filename;
  anchor.rel = 'noopener';

  anchor.click();
};

export const downloadXlsx = (
  path,
  body = {},
  downloadName = 'download.xlsx',
  errorHandler = () => {},
  successHandler = () => {},
) => {
  return fetch(`${window.location.origin}/${path}`, {
    method: 'POST',
    headers: {
      'X-CSRF-Token': getCsrfToken(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }).then(async (response) => {
    const responseClone = response.clone();
    if (response.ok) {
      successHandler();
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      openDownloadUrl(url, downloadName);
    } else {
      const json = await response.json();
      errorHandler(json?.errors || []);
    }

    // Return the original response for further processing
    return responseClone;
  });
};
