import { FilterKeys } from '../components/Filters';
import { PORTFOLIO_CONTEXT } from '../components/PortfolioAnalysis/constants';
import { VALUATIONS_CONTEXT } from '../components/Valuations/constants';
import {
  COUNTRY_OVERVIEWS_PATH,
  OIL_AND_GAS_MAP_PATH,
  PORTFOLIO_ANALYSIS_PATH,
  PROPRIETARY_DOWNLOADS_PATH,
  ROOT_PATH,
  SETTINGS_PATH,
  VALUATIONS_PATH,
} from './constants';

export const analytics = (filter) => {
  const gaObject = {};
  Object.keys(filter).forEach((key) => {
    switch (key) {
      case 'CURRENT PRODUCTION':
      case 'VALUATION':
      case 'RESERVES':
      case 'RESERVOIR DEPTH': {
        let min = filter[key].find((f) => f.type === 'min');
        let max = filter[key].find((f) => f.type === 'max');
        min = min === undefined ? '' : min.value;
        max = max === undefined ? '' : max.value;
        gaObject[key] = [min, max];
        break;
      }
      case FilterKeys.depthMin:
        if (gaObject['RESERVOIR DEPTH']) gaObject['RESERVOIR DEPTH'].unshift(filter[key]);
        else gaObject['RESERVOIR DEPTH'] = [];
        break;
      case FilterKeys.depthMax:
        if (gaObject['RESERVOIR DEPTH']) gaObject['RESERVOIR DEPTH'].push(filter[key]);
        else gaObject['RESERVOIR DEPTH'] = [];
        break;
      case FilterKeys.npvMin:
        if (gaObject['NPV (US$MM)']) gaObject['NPV (US$MM)'].unshift(filter[key]);
        else gaObject['NPV (US$MM)'] = [];
        break;
      case FilterKeys.npvMax:
        if (gaObject['NPV (US$MM)']) gaObject['NPV (US$MM)'].push(filter[key]);
        else gaObject['NPV (US$MM)'] = [];
        break;
      case FilterKeys.prodMin:
      case 'productionMin':
      case 'prodMin':
        if (gaObject.PRODUCTION) gaObject.PRODUCTION.unshift(filter[key]);
        else gaObject.PRODUCTION = [];
        break;
      case FilterKeys.prodMax:
      case 'productionMax':
      case 'prodMax':
        if (gaObject.PRODUCTION) gaObject.PRODUCTION.push(filter[key]);
        else gaObject.PRODUCTION = [];
        break;
      case FilterKeys.reservesMin:
        if (gaObject.RESERVES) gaObject.RESERVES.unshift(filter[key]);
        else gaObject.RESERVES = [];
        break;
      case FilterKeys.reservesMax:
        if (gaObject.RESERVES) gaObject.RESERVES.push(filter[key]);
        else gaObject.RESERVES = [];
        break;
      case FilterKeys.hasAbatement:
        gaObject[FilterKeys.hasAbatement] = filter[key];
        break;
      case 'OPERATOR':
      case FilterKeys.operator:
        gaObject.OPERATOR = filter[key];
        break;
      case 'OPPORTUNITIES':
      case FilterKeys.opportunities:
        gaObject.OPPORTUNITIES = filter[key];
        break;
      case 'includeHistoricalParticipants':
        gaObject.includeHistoricalParticipants = filter[key];
        break;
      default:
        gaObject[key] = filter[key].map((f) => f.name);
        break;
    }
  });
  return JSON.stringify(gaObject);
};

export const dimensions = (filter, context) => {
  const dimensions = { asset: 'NULL', country: 'NULL', company: 'NULL' };
  if (context === 'maps') {
    if (filter.asset && filter.asset.length !== 0) {
      dimensions.asset = filter.asset.map((f) => f.name).join(', ');
      dimensions.country = filter.asset.map((f) => f.country.name);
    }
    if (filter.country.length !== 0) {
      const countries = filter.country.map((f) => f.name);
      if (dimensions.country === 'NULL') {
        dimensions.country = countries;
      } else {
        dimensions.country = dimensions.country.concat(countries);
      }
    }
    if (filter.company.length !== 0) {
      dimensions.company = filter.company.map((f) => f.name).join(', ');
    }
  } else if ([VALUATIONS_CONTEXT, PORTFOLIO_CONTEXT].includes(context)) {
    // TODO: the naming/handling of filters should be standarized to the portfolio page
    if (filter[FilterKeys.assets].length !== 0) {
      dimensions.asset = filter.assets.map((f) => f.name).join(', ');
      dimensions.country = filter.assets.map((f) => f.country.name);
    }
    if (filter[FilterKeys.countries].length !== 0) {
      const countries = filter.countries.map((f) => f.name);
      if (dimensions.country === 'NULL') {
        dimensions.country = countries;
      } else {
        dimensions.country = dimensions.country.concat(countries);
      }
    }
    if (filter[FilterKeys.companies].length !== 0) {
      dimensions.company = filter.companies.map((f) => f.name).join(', ');
    }
  }
  // need to filter duplicate countries because of assets
  if (dimensions.country !== 'NULL') {
    dimensions.country = dimensions.country
      .filter((val, idx, self) => self.indexOf(val) === idx)
      .join(', ');
  }
  return dimensions;
};

export const defaultDimensions = {
  asset: 'NULL',
  country: 'NULL',
  company: 'NULL',
};

const validGALinks = [
  COUNTRY_OVERVIEWS_PATH,
  OIL_AND_GAS_MAP_PATH,
  ROOT_PATH,
  SETTINGS_PATH,
  VALUATIONS_PATH,
  PORTFOLIO_ANALYSIS_PATH,
];

export const isValidGALink = (path) => {
  if (path.startsWith(PROPRIETARY_DOWNLOADS_PATH)) {
    return true;
  }
  return !!validGALinks.find((link) => path.match(`^${link}`));
};
