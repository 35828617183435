import { useEffect, useState } from 'react';

import { getNotes } from '../../../../WordPress';

export const useGetNotes = () => {
  const [notes, setNotes] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        setError(null);
        setLoading(true);
        const notesData = await getNotes();
        setNotes(notesData);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotes();
  }, []);

  return { notes, error, loading };
};
