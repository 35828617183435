import React, { useCallback, createContext, useContext, useState } from 'react';

const FilterSetsContext = createContext({});

export const useFilterSetsContext = () => useContext(FilterSetsContext);

export const FilterSetsProvider = ({ children, filterSetsCount }) => {
  const [currentFilterSetIndex, setCurrentFilterSetIndex] = useState(0);

  const nextFilterSet = useCallback(() => setCurrentFilterSetIndex((v) => v + 1), []);
  const previousFilterSet = useCallback(() => setCurrentFilterSetIndex((v) => v - 1), []);
  return (
    <FilterSetsContext.Provider
      value={{
        currentFilterSetIndex,
        setCurrentFilterSetIndex,
        nextFilterSet,
        previousFilterSet,
        filterSetsCount: filterSetsCount || 1,
      }}
    >
      {children}
    </FilterSetsContext.Provider>
  );
};
