import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../../utils/constants';
import {
  ASSET_TEMPLATE_CONTEXT,
  ASSET_TEMPLATE_GOOGLE_ANALYTICS_TAG,
  VALUATIONS_CONTEXT,
  VALUATIONS_GOOGLE_ANALYTICS_TAG,
} from '../constants';
import {
  GET_AVAILABLE_DOWNLOADS_QUERY,
  SECURE_COUNTRY_DOWNLOAD_QUERY,
} from '../../Downloads/operations';
import Panel, {
  LeadPanel,
  createAssetPanels,
  createCountryDownloadsPanels,
  createPanels,
  createSimilarAssetsPanels,
} from './Panel';
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import { ASSETS_BY_SEARCH_KEY } from './Panel/operations';
import { CORE_DOWNLOAD_TYPE } from '../../CountryOverviews/constants';
import ErrorBoundary from '../../ErrorBoundary';
import { SHINY_URL } from '../../Shiny/operations';
import Typography from '@mui/material/Typography';
import analytics from './analytics';
import { getUserId } from '../../../utils/auth';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'auto',
    overflowX: 'hidden',
    ...theme.palette.scrollbar,
  },
  empty: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '10px',
    boxSizing: 'border-box',
    paddingTop: '82.4px',
    [theme.breakpoints.down('lg')]: {
      paddingTop: '71.2px',
    },
  },
}));

export default ({ asset, context, watchList, setSnack }) => {
  const classes = useStyles();
  const [panelViews, setPanelViews] = useState({});
  const { loading, data } = useQuery(GET_AVAILABLE_DOWNLOADS_QUERY, {
    variables: {
      countryIsoCode: asset ? asset.country.isoCode : '',
    },
  });

  const { data: similarAssetsData } = useQuery(ASSETS_BY_SEARCH_KEY, {
    variables: {
      search: Object.keys(asset?.similarAssets || {}),
    },
  });

  const [getSecureCountryDownload] = useLazyQuery(SECURE_COUNTRY_DOWNLOAD_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { url, downloadMessage } = data.secureCountryDownload;
      if (url) {
        setSnack({
          open: true,
          message: `Your download should begin shortly.${downloadMessage}`,
          severity: 'success',
        });
        window.location.href = url;
      }
    },
    onError: (error) => {
      setSnack({
        severity: 'error',
        message: error.message,
        open: true,
      });
    },
  });

  const [getShinyUrl, { data: shinyData }] = useLazyQuery(SHINY_URL, {
    onCompleted: () => {
      window.open(shinyData.shinyUrl.url, '_blank');
    },
    fetchPolicy: 'network-only',
  });

  const getDownloadLink = (title) => {
    if (asset === null) return null;
    getSecureCountryDownload({
      variables: { downloadType: title, countryIsoCode: asset.country.isoCode },
    });
  };

  useEffect(async () => {
    const defaultPanels = createPanels(asset, getShinyUrl);
    let titles = [];
    let onClicks = [];
    let countryDownloadPanels = null;

    if (!loading && data !== undefined) {
      ({ titles } = data.availableCountryDownloads);
      titles = titles.filter((title) => title !== CORE_DOWNLOAD_TYPE);
      onClicks = titles.map((title) => () => {
        const page =
          context === VALUATIONS_CONTEXT
            ? VALUATIONS_GOOGLE_ANALYTICS_TAG
            : ASSET_TEMPLATE_GOOGLE_ANALYTICS_TAG;
        gtag('event', ANALYTICS_EVENTS.event, {
          event_category: page,
          event_action: ANALYTICS_EVENT_ACTIONS.download,
          event_label: analytics[title],
          asset: 'NULL',
          country: asset.country.name,
          company: 'NULL',
          userId: getUserId(),
        });
        getDownloadLink(title);
      });
      countryDownloadPanels = createCountryDownloadsPanels(asset, titles, onClicks);
    }

    if (context === VALUATIONS_CONTEXT) {
      if (loading) {
        setPanelViews({ ...defaultPanels });
      } else {
        setPanelViews({ ...countryDownloadPanels, ...defaultPanels });
      }
    } else if (context === ASSET_TEMPLATE_CONTEXT) {
      let finalPanelViews = {};
      const assetsBySearchKey = similarAssetsData ? similarAssetsData.assetsBySearchKey : [];

      if (loading) {
        finalPanelViews = { ...defaultPanels };
      } else {
        finalPanelViews = {
          ...countryDownloadPanels,
          ...defaultPanels,
        };
      }

      const { similarAssets } = createSimilarAssetsPanels(asset, assetsBySearchKey);
      if (similarAssets) {
        finalPanelViews = {
          similarAssets,
          ...finalPanelViews,
        };
      }

      const { countryNews } = await createAssetPanels(asset);
      if (countryNews) {
        finalPanelViews = {
          ...finalPanelViews,
          countryNews,
        };
      }
      setPanelViews(finalPanelViews);
    }
  }, [context, asset, loading, data, similarAssetsData]);

  return (
    <div className={classes.root}>
      {asset === null && watchList ? (
        <Typography className={classes.empty}>No assets in your watch list!</Typography>
      ) : asset === null ? (
        <Typography className={classes.empty}>0 assets match your selected filters</Typography>
      ) : (
        <>
          <LeadPanel asset={asset} setSnack={setSnack} />
          <div id="valuations-side-panel">
            {Object.entries(panelViews).map(([panelItemKey, panelItem]) => (
              <ErrorBoundary key={panelItemKey}>
                <Panel asset={asset} panel={panelItem} context={context} />
              </ErrorBoundary>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
