export const discountRateDisabled = (graphType) =>
  !(graphType === 'Discounted Cash Flow' || graphType === 'Cumulative Discounted Cash Flow');

// returns shouldUpdate true if legend should update and the most updated legend
export const shouldUpdateLegend = (legendItems, graphDiv) => {
  if (graphDiv) {
    let shouldUpdate = false;
    const newLegend = graphDiv._fullData
      .slice()
      .reverse()
      .map((data, idx) => {
        const currentItem = legendItems[idx];
        const newItem = {
          color: data.line.color,
          title: data.text,
          visible: data.visible,
        };
        if (
          !currentItem ||
          !newItem ||
          currentItem.color !== newItem.color ||
          currentItem.title !== newItem.title ||
          currentItem.visible !== newItem.visible
        ) {
          shouldUpdate = true;
        }
        return newItem;
      });

    return { shouldUpdate, newLegend };
  }
};
