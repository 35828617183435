import AddIcon from '@mui/icons-material/Add';
import ChipItem from './chipItem';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
/* eslint-disable */
import React from 'react';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  add: {
    ...theme.palette.altButton,
    height: '28px',
    width: '28px',
  },
  chipLabel: {
    paddingRight: '25px',
  },
}));

// title for chip list
// chips is an array of set chips
/*
    [
        {
            parent: 'COUNTRY',
            value: {
                displayName: 'Angola',
                name: 'Angola',
                isoCode: 'AGO',
            },
            label: 'AGO',
            object: {}, // the actual origin object structure
            idx: 0,  // idx of position object is in chips array
        }
    ]
*/
// deleteChip - callback to delete a chip
// styles - classsnames to be passed to the container and title
// includeAddIcon - includes the add icon to allow for users another way of adding chips
// addCallback - dependent on includeAddIcon being true - the callback for when the addIcon is clicked
// onChipClick - callback for when a chip is clicked

export default ({ title, chips, deleteChip, styles, includeAddIcon, addCallback, onChipClick }) => {
  const classes = useStyles();

  return (
    <Grid
      className={styles ? styles.chipItemContainer : {}}
      container
      spacing={1}
      sx={{
        alignItems: 'center',
      }}
    >
      <Typography color="secondary" className={classes.chipLabel} variant="body1">
        {title}
      </Typography>
      {chips.map((chip, idx) => (
        <ChipItem
          key={`chip-list-${chip.parent}-${chip.value.name}-${idx}`}
          chip={chip}
          onChipClick={() => {
            onChipClick && onChipClick(chip.object, chip.idx);
          }}
          deleteChip={deleteChip}
        />
      ))}
      {includeAddIcon ? (
        <IconButton
          aria-label="add portfolio"
          onClick={() => addCallback()}
          className={classes.add}
          size="large"
        >
          {<AddIcon />}
        </IconButton>
      ) : null}
    </Grid>
  );
};
