import { gql } from '@apollo/client';

export const SHARE_FILTER = gql`
  mutation shareUserFilter($userFilterId: ID!, $users: [ID!]!) {
    shareUserFilter(userFilterId: $userFilterId, users: $users) {
      success
      errors
    }
  }
`;
