import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
  mutation UserLogin($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      authenticatable {
        email
        id
      }
      credentials {
        accessToken
        client
        expiry
        tokenType
        uid
      }
      errors
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation UserLogout {
    userLogout {
      authenticatable {
        email
      }
    }
  }
`;
