// This file is to eventually replace the current line graph using dygraphs

import React from 'react';
import Graph from './graph';
import { LINE_CHART_CONTEXT } from './constants';

// example data structure
/* 
[
    {
        x: [1, 2, 3],
        y: [2, 6, 3],
        type: 'scatter',
        mode: 'lines+markers',
    },
]
*/
// colors - array of line colors
// layout - any additional layout properties or overwrite default

export default ({
  data,
  colors,
  title,
  xlabel,
  ylabel,
  layout,
  loading,
  onUpdate,
  onRelayout,
  onInitialized,
  onAfterPlot,
  onLegendDoubleClick,
  maxY,
  darkTheme,
}) => {
  return (
    <Graph
      data={data}
      layout={{
        colorway: colors ? colors : null,
        annotations: [
          {
            xref: 'paper',
            yref: 'paper',
            x: 1,
            xanchor: 'right',
            y: 1,
            yanchor: 'bottom',
            text: 'Source: Welligence Energy Analytics',
            showarrow: false,
            font: {
              color: 'lightgrey',
              size: 10,
            },
          },
        ],
        ...layout,
      }}
      config={{
        modeBarButtonsToRemove: [
          'pan2d',
          'select2d',
          'lasso2d',
          'zoomIn2d',
          'zoomOut2d',
          'autoScale2d',
        ],
      }}
      title={title}
      xlabel={xlabel}
      ylabel={ylabel}
      loading={loading}
      onUpdate={onUpdate}
      onRelayout={onRelayout}
      onInitialized={onInitialized}
      onAfterPlot={onAfterPlot}
      onLegendDoubleClick={onLegendDoubleClick}
      maxY={maxY}
      darkTheme={darkTheme}
      context={LINE_CHART_CONTEXT}
    />
  );
};
