import React, { useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import BasicFilters from './basicFilters';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import ExpandedFilters from './expandedFilters';
import FilterAltIcon from '@mui/icons-material/FilterList';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import { defaultFilterTypes } from './filterTypes';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  accordion: {
    width: '100%',
    '&.MuiAccordion-root': {
      backgroundColor: 'transparent',
    },
    '&.MuiAccordion-root:before': {
      backgroundColor: 'transparent',
    },
  },
  alternateColor: {
    backgroundColor: '#F5F5F5',
  },
  button: {
    ...theme.palette.button,
    margin: '13px 2px',
    height: '36px',
    width: 'fit-content',
    minWidth: 'none',
  },
  deletePortfolio: {
    ...theme.palette.actionButton,
    color: 'grey',
    alignSelf: 'center',
  },
  details: {
    '&.MuiAccordionDetails-root': {
      padding: 0,
      backgroundColor: 'transparent',
    },
  },
  filterItem: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      flexBasis: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
    },
  },
}));

export default ({
  applyBasicFilters,
  basicFilterTypes = defaultFilterTypes,
  checkListItems,
  context,
  deletePortfolio,
  googleAnalyticsTag,
  idx,
  portfolio,
  removeable,
  inModal,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  return (
    <Grid
      className={clsx({ [classes.alternateColor]: idx % 2 === 1 })}
      container
      style={{ padding: '10px 20px', borderBottom: 'solid thin lightgrey' }}
      sx={{
        justifyContent: 'space-between',
      }}
    >
      <BasicFilters
        applyFilters={(filters) => applyBasicFilters(filters, idx)}
        basicFilterTypes={basicFilterTypes}
        context={context}
        filterItemClass={classes.filterItem}
        filters={portfolio.filters}
        googleAnalyticsTag={googleAnalyticsTag}
        inModal={inModal}
      />
      {removeable && (
        <Tooltip title="Remove portfolio">
          <CloseIcon className={classes.deletePortfolio} onClick={() => deletePortfolio(idx)} />
        </Tooltip>
      )}
      <Button
        startIcon={<FilterAltIcon />}
        id="portfolio-analysis-advanced-filters-button"
        className={classes.button}
        variant="contained"
        onClick={() => setExpanded(!expanded)}
      >
        All Filters
      </Button>
      <Accordion square className={classes.accordion} expanded={expanded}>
        <div />
        <AccordionDetails className={classes.details}>
          <ExpandedFilters
            filters={portfolio.filters}
            applyFilters={(filters) => applyBasicFilters(filters, idx)}
            checkListItems={checkListItems}
            context={context}
            googleAnalyticsTag={googleAnalyticsTag}
          />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
