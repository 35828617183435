import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../../utils/constants';
import {
  ASSETS,
  BASINS,
  COUNTRIES,
  FilterKeys,
  HYDROCARBONS,
  OPPORTUNITIES,
  SHORE_STATUSES,
} from '../../Filters/filterTypes';
import {
  COMPANY_FILTER_TYPE,
  VALUATIONS_CONTEXT,
  VALUATIONS_GOOGLE_ANALYTICS_TAG,
} from '../constants';

import { BasicFilters } from '../../Filters';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chips from './chips';
import Divider from '@mui/material/Divider';
import FilterAltIcon from '@mui/icons-material/FilterList';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid2';
import React from 'react';
import Typography from '@mui/material/Typography';
import { defaultDimensions } from '../../../utils/analytics';
import { getUserId } from '../../../utils/auth';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  filtersRoot: {
    flexShrink: 0,
  },
  formContainer: {
    padding: '10px',
  },
  valuationButton: {
    ...theme.palette.button,
    margin: '13px 2px',
    height: '36px',
    width: 'fit-content',
    minWidth: 'none',
  },
  valuationFormControl: {
    minWidth: 0,
    width: '140px',
    height: '36px',
  },
  valuationSelects: {
    margin: theme.spacing(1),
    minWidth: 0,
    width: '140px',
    height: '36px',
  },
  valuationSelectsInput: {
    minWidth: 0,
    width: '140px',
    height: '36px',
  },
  valuationSelectLineHeight: {
    lineheight: 0,
  },
  valuationRadioGroup: {
    margin: theme.spacing(1),
  },
  valuationFilterRow: {
    marginTop: '20px',
  },
  valuationFilterAdvancedRow: {
    marginTop: '20px',
    marginBottom: '10px',
  },
  checkboxWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    [theme.breakpoints.down('lg')]: {
      width: '155px',
    },
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  content: {
    display: 'flex',
    gap: '.5rem',
    marginRight: '13px',
    padding: '0.5rem 0',
  },
  partLeft: {
    display: 'flex',
  },
  partRight: {
    display: 'flex',
    flexShrink: 0,
    paddingRight: '8px',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
  buttonSection: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexShrink: '0',
    columnGap: '0.5rem',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      width: '155px',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      paddingBottom: '.5rem',
      rowGap: '1rem',
      minWidth: 'unset',
    },
  },
  filterSection: { display: 'flex', flexGrow: '1', flexWrap: 'wrap' },
  filterItem: {
    flexBasis: '16.66%',
    [theme.breakpoints.down('lg')]: {
      flexBasis: '33.33%',
    },
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
    },
  },
}));

export default ({
  filters,
  setFilters,
  clearFilters,
  setWatchList,
  watchList,
  openAdvancedFilters,
}) => {
  const classes = useStyles();

  const setFilter = (filter, name) => {
    const newFilters = {
      ...filters,
    };
    newFilters[name] = filter;
    setFilters(newFilters);
  };

  const handleAdvancedFilterClick = () => {
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: VALUATIONS_GOOGLE_ANALYTICS_TAG,
      event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
      event_label: 'advanced_filters',
      userId: getUserId(),
      ...defaultDimensions,
    });
    openAdvancedFilters();
  };

  return (
    <Grid className={classes.filtersRoot} container direction="column">
      <div className={classes.content}>
        <div className={classes.filterSection} id="valuations-asset-filters">
          <BasicFilters
            filters={filters}
            applyFilters={setFilters}
            basicFilterTypes={[
              ASSETS,
              COUNTRIES,
              COMPANY_FILTER_TYPE,
              BASINS,
              SHORE_STATUSES,
              HYDROCARBONS,
            ]}
            context={VALUATIONS_CONTEXT}
            googleAnalyticsTag={VALUATIONS_GOOGLE_ANALYTICS_TAG}
            filterItemClass={classes.filterItem}
          />
        </div>
        <div className={classes.buttonSection}>
          <Button
            startIcon={<FilterAltIcon />}
            id="valuations-advanced-filters"
            className={classes.valuationButton}
            variant="contained"
            color="secondary"
            onClick={handleAdvancedFilterClick}
          >
            All Filters
          </Button>
          <div className={classes.checkboxWrapper}>
            <div id="valuations-watch-list-checkbox">
              <FormControlLabel
                labelPlacement="start"
                checked={watchList}
                control={
                  <Checkbox
                    className={classes.checkbox}
                    onChange={({ target }) => {
                      if (target.checked)
                        gtag('event', ANALYTICS_EVENTS.event, {
                          event_category: VALUATIONS_GOOGLE_ANALYTICS_TAG,
                          event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
                          event_label: 'view_watchlist',
                          userId: getUserId(),
                          ...defaultDimensions,
                        });
                      setWatchList(target.checked);
                    }}
                  />
                }
                label={<Typography variant="overline">View Watch List</Typography>}
              />
            </div>
            <div id="valuations-ma-checkbox">
              <FormControlLabel
                labelPlacement="start"
                checked={filters[FilterKeys.opportunities]}
                control={
                  <Checkbox
                    className={classes.checkbox}
                    onChange={({ target }) => {
                      if (target.checked)
                        gtag('event', ANALYTICS_EVENTS.event, {
                          event_category: VALUATIONS_GOOGLE_ANALYTICS_TAG,
                          event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
                          event_label: 'view_ma_opportunities',
                          userId: getUserId(),
                          ...defaultDimensions,
                        });
                      setFilter(target.checked, FilterKeys.opportunities);
                    }}
                  />
                }
                label={<Typography variant="overline">{OPPORTUNITIES.label}</Typography>}
              />
            </div>
          </div>
        </div>
      </div>
      <Divider style={{ margin: '5px 0' }} />
      <Chips filters={filters} setFilters={setFilters} clearFilters={clearFilters} />
    </Grid>
  );
};
