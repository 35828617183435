import { FilterKeys } from '../../../../Filters';
import { MA_DEALS_QUERY } from '../operations';
import { useFilters } from '../../../FiltersContext';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

export const useGetDeals = () => {
  const { filters } = useFilters();

  const filterVariables = useMemo(() => {
    const [startYear, endYear] = filters?.dateSlider || [];

    return {
      assets: filters[FilterKeys.assets]?.map((a) => Number(a.entityId)) || [],
      countries: filters[FilterKeys.countries]?.map((c) => c.isoCode) || [],
      buyers: filters[FilterKeys.companiesBuyer]?.map((b) => Number(b.id)) || [],
      sellers: filters[FilterKeys.companiesSeller]?.map((s) => Number(s.id)) || [],
      eventTypes: filters[FilterKeys.eventType]?.map((e) => e.name) || [],
      startYear: startYear,
      endYear: endYear,
    };
  }, [filters]);

  const { data, loading, error } = useQuery(MA_DEALS_QUERY, {
    variables: filterVariables,
  });

  return {
    data: data?.maDeals || [],
    loading,
    error,
  };
};
