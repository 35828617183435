import MappingToolsImg from '../../../../assets/images/welligence-assets/images/mapping-tools.svg';
import AssetToolsImg from '../../../../assets/images/welligence-assets/images/upstream-asset-tools.svg';
import PortfolioAnalysisImg from '../../../../assets/images/welligence-assets/images/portfolio-analysis.svg';
import LicensingImg from '../../../../assets/images/welligence-assets/images/ma-licensing.svg';
import IntelligenceImg from '../../../../assets/images/welligence-assets/images/intelligence.svg';
import DataDownloadsImg from '../../../../assets/images/welligence-assets/images/data-downloads.svg';
import MappingTools from './MappingTools';
import AssetTools from './AssetTools';
import PortfolioAnalysis from './PortfolioAnalysis';
import Licensing from './Licensing';
import Intelligence from './Intelligence';
import DataDownloads from './DataDownloads';

export default [
  {
    label: 'Mapping Tools',
    img: MappingToolsImg,
    desc: 'Visualize data and analytics geographically on a comprehensive mapping platform and heat map.',
    component: MappingTools,
    released: true,
  },
  {
    label: 'Upstream Asset Tools',
    img: AssetToolsImg,
    desc: 'Country reports, asset reports, valuation models, and technical analysis of individual assets and wells.',
    component: AssetTools,
    released: true,
  },
  {
    label: 'Macro Analysis',
    img: PortfolioAnalysisImg,
    desc: 'View country-level upstream reports and analyze company positions through a variety of metrics and build hypothetical portfolios.',
    component: PortfolioAnalysis,
    released: true,
  },
  {
    label: 'M&A and Licensing',
    img: LicensingImg,
    desc: 'Complete history of asset-level upstream M&A, including licensing, exploration farm-ins/outs, asset deals, corporate M&A, and more.',
    component: Licensing,
    released: true,
  },
  {
    label: 'Intelligence',
    img: IntelligenceImg,
    desc: 'Written commentary on key issues, including M&A transactions, licensing, and other event-driven analysis.',
    component: Intelligence,
    released: true,
  },
  {
    label: 'Data Downloads',
    img: DataDownloadsImg,
    desc: 'Access to direct downloads for both commercial and technical data by asset and by country.',
    component: DataDownloads,
    released: true,
  },
];
