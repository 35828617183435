import { formatDateYear, roundAndFormat, snakeToTitle } from '../../../../utils/helpers';

// TODO: Create summary items objects for each of these in SummaryList/SummaryItems/lngFacility.js
export default (changeQueriedItem) => {
  const buildParticipations = (acessorKey) => ({
    type: 'func',
    label: () => 'Participation',
    accessor: (f) => {
      const { flexParticipations } = (acessorKey ? f?.[acessorKey] : f) || {};
      if (!flexParticipations?.length) return '';

      // Copy the array so we don't mutate the original:
      const flexParticipationsCopy = [...flexParticipations];

      return flexParticipationsCopy
        .sort((a, b) => {
          const aIsOperator = a.isCompanyOperator ? 1 : 0;
          const bIsOperator = b.isCompanyOperator ? 1 : 0;
          return (
            bIsOperator - aIsOperator ||
            b.percentStake - a.percentStake ||
            a.companyName.localeCompare(b.companyName)
          );
        })
        .map(({ companyName, formattedPercentStake, isCompanyOperator }) =>
          isCompanyOperator
            ? `${companyName} (Operator) (${formattedPercentStake})`
            : `${companyName} (${formattedPercentStake})`,
        )
        .join(', ');
    },
  });

  const lngProject = [
    { type: 'key', label: 'Name', accessor: 'name' },
    {
      type: 'func',
      label: () => 'Country',
      accessor: (f) => f.country.displayName,
    },
    {
      type: 'func',
      label: () => 'Type',
      accessor: (f) => {
        switch (f.lngType) {
          case 'lng_plant':
            return 'LNG Plant';
          case 'flng':
            return 'FLNG (Floating LNG)';
          case 'regas_terminal':
            return 'Regas Terminal';
          case 'fsru':
            return 'FSRU (Floating Storage and Regasification Unit)';
          default:
            return '';
        }
      },
    },
    {
      type: 'func',
      label: () => 'Status',
      accessor: (f) => snakeToTitle(f.lngStatus),
    },
    {
      type: 'func',
      label: () => 'Shore Status',
      accessor: (f) => snakeToTitle(f.lngShoreStatus),
    },
    {
      type: 'func',
      label: () => 'Water Depth (m)',
      accessor: (f) => (f.waterDepthM > 0 ? f.waterDepthM : ''),
    },
    {
      type: 'func',
      label: () => 'Start-up',
      accessor: (f) => formatDateYear(f.startDate) || '',
    },
    {
      type: 'func',
      label: () => 'Storage Capacity (m³)',
      accessor: (f) => f.formattedStorageCapacityM3,
    },
    {
      type: 'func',
      label: () => 'Number of Storage Tanks',
      accessor: (f) => f.numberOfStorageTanks,
    },
  ];

  const lngLiquefaction = [
    {
      type: 'func',
      label: () => 'Nominal Liquefaction Capacity (MMtpa)',
      accessor: (f) => roundAndFormat(f.lngLiquefaction?.capacityMmtpa, 2),
    },
    {
      type: 'multiGroup',
      accessor: (f) => f.lngLiquefaction?.lngTrains,
      template: [
        {
          type: 'func',
          label: (t) => `Train ${t.index + 1}`,
          accessor: () => ' ',
        },
        {
          type: 'key',
          label: 'Name',
          accessor: 'name',
        },
        {
          type: 'func',
          label: () => 'Status',
          accessor: (t) => snakeToTitle(t.status),
        },
        {
          type: 'func',
          label: () => 'Start-up',
          accessor: (t) => formatDateYear(t.startDate) || '',
        },
        {
          type: 'key',
          label: () => 'Cost (US$MM)',
          accessor: 'costMmusd',
        },
        {
          type: 'func',
          label: () => 'Nominal Liquefaction Capacity (MMtpa)',
          accessor: (t) => roundAndFormat(t.nominalLiquefactionCapacity, 2),
        },
        {
          type: 'selectable',
          label: () => 'Feedgas Supply Asset(s)',
          accessor: (t) => {
            // Map the asset names to their names and actions.
            // If the asset is present in the database, add an action to change the queried item.
            const { feedGasSupplyAssets, fetchedFeedGasSupplyAssets } = t;
            return feedGasSupplyAssets?.map((assetName) => {
              const asset = fetchedFeedGasSupplyAssets.find(
                (a) => a.name.toLowerCase() === assetName.toLowerCase(),
              );
              let action;
              if (asset) {
                // TODO: Create summary items objects for each of these in SummaryList/
                action = () => changeQueriedItem(asset);
              }
              return {
                value: assetName,
                action,
              };
            });
          },
        },
        {
          type: 'func',
          label: () => 'Liquefaction Technology',
          accessor: (t) => t.lngLiquefactionTechnology?.name || '',
        },
        buildParticipations(),
      ],
    },
  ];

  const lngRegas = [
    {
      type: 'func',
      label: () => 'Cost (US$MM)',
      accessor: (f) => f.lngRegas?.costMmusd,
    },
    {
      type: 'func',
      label: () => 'Nominal Send Out Capacity (MMtpa)',
      accessor: (f) => roundAndFormat(f.lngRegas?.nominalSendOutCapacity, 2, 2),
    },
    buildParticipations('lngRegas'),
  ];

  return [...lngProject, ...lngLiquefaction, ...lngRegas];
};
