import CheckList from '../CheckList';
import Grid from '@mui/material/Grid2';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  checkListGroup: {
    height: '100%',
    padding: '5px',
  },
  checkListRoot: {
    height: 'fit-content',
  },
  subHeader: {
    color: theme.palette.baseColors.fireOrange.c100,
  },
  scroll: {
    ...theme.palette.altScrollbarBlue,
    margin: 0,
    padding: '5px 10px 5px 0',
    overflow: 'auto',
    height: 'calc(100% - 30.25px)',
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100% - 27.6px)',
    },
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 24.94px)',
    },
  },
}));

export default ({ checkListItems, filter, filters, setFilter }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.checkListGroup} style={{ width: filter.width }}>
      <Typography variant="overline" noWrap>
        {filter.name}
      </Typography>
      <Grid className={classes.scroll} container direction="row">
        {filter.group.map((groupFilter) => (
          <Grid key={groupFilter.name} size={filter.xs}>
            <Tooltip title={groupFilter.name}>
              <Typography className={classes.subHeader} variant="body2" noWrap>
                {groupFilter.name}
              </Typography>
            </Tooltip>
            <CheckList
              callback={(value) => setFilter(value, groupFilter.key)}
              checked={filters[groupFilter.key]}
              selectAllOnNoneChecked
              list={checkListItems[groupFilter.list]}
              nameKey={groupFilter.nameKey}
              styles={classes}
              customItems={groupFilter.customItems}
              getParent={groupFilter.getParent}
              combinedItems={groupFilter.combinedItems}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
