import {
  FEATURE_NOT_AVAILABLE_TOOLTIP,
  M_A_OVERVIEW_PATH,
  M_A_OVERVIEW_PATH_V2,
} from '../../../../utils/constants';
import React, { useMemo } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Tooltip } from '@mui/material';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import makeStyles from '@mui/styles/makeStyles';
import { useCurrentUser } from '../../../CurrentUserContext';
import { useFeatureFlags } from '../../../../contexts/FeatureFlagsContext';

const useStyles = makeStyles((theme) => ({
  button: {
    ...theme.palette.button,
  },
}));

export default () => {
  const classes = useStyles();
  const { maV2 } = useFeatureFlags();
  const { isUserEntitled } = useCurrentUser();

  const maOverviewPath = useMemo(() => (maV2 ? M_A_OVERVIEW_PATH_V2 : M_A_OVERVIEW_PATH), [maV2]);

  return (
    <Grid container spacing={2} size={12}>
      <Grid size={2} />
      <Grid container spacing={2} size={8}>
        <Grid>
          <Tooltip
            title={
              isUserEntitled(maOverviewPath)
                ? 'Merger & Acquisitions Overview'
                : FEATURE_NOT_AVAILABLE_TOOLTIP
            }
          >
            <span>
              <Button
                component={Link}
                to={maOverviewPath}
                variant="contained"
                className={classes.button}
                underline="none"
                disabled={!isUserEntitled(maOverviewPath)}
              >
                <WorkOutlineIcon fontSize="small" />
                &nbsp;M&A Analytics
              </Button>
            </span>
          </Tooltip>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() =>
              window.open(
                'https://welligence.com/intelligence/?_post_content=ma&_category=deep-dives',
                '_blank',
              )
            }
          >
            <LibraryBooksIcon fontSize="small" />
            &nbsp;m&a reports
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
