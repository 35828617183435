import { SECONDARY_Y_AXIS_TYPES } from './secondaryYAxisConstatns';

export const getDataForPricePaidCalculation = (assetTransaction, graphYSecondaryType) => {
  if (graphYSecondaryType && graphYSecondaryType === SECONDARY_Y_AXIS_TYPES.RESERVES_ACQUIRED) {
    return assetTransaction.weaWorkingInterestReserves;
  }

  if (assetTransaction.weaWorkingInterestValuation && assetTransaction.weaWorkingInterestReserves) {
    return {
      valuation: assetTransaction.weaWorkingInterestValuation,
      divideByReserves: assetTransaction.weaWorkingInterestReserves,
    };
  }

  return null;
};
