import { STACK_BY_TYPES } from '../../../helpers/stackByConstatnts';
import {
  COMP_BENCH_GRAPH_1_TYPES,
  COMP_BENCH_GRAPH_2_TYPES,
} from '../CompanyBenchMarking.constant';
import { fillStackedData } from '../../../helpers/fillStackedData';
import { fillSimpleData } from '../../../helpers/fillSimpleData';
import { getDataForPricePaidCalculation } from '../../../helpers/getDataForPricePaidCalculation';

export const getCalculatePageContentDataCb =
  (graphXTypeValue, graph2TypeValue, stackBy1Chart, stackBy2Chart) =>
  (pageContentData, year, deal, companyTransaction, assetTransaction) => {
    if (
      companyTransaction.buyerCompanyName &&
      companyTransaction.buyerCompanyId &&
      companyTransaction.sellerCompanyName &&
      companyTransaction.sellerCompanyId
    ) {
      const secondaryYData1 = getDataForPricePaidCalculation(assetTransaction);

      //chart 1
      const selectorOuter1 =
        graphXTypeValue === COMP_BENCH_GRAPH_1_TYPES.BUYER_NAME
          ? companyTransaction.sellerCompanyName
          : stackBy1Chart === STACK_BY_TYPES.SELLER
          ? companyTransaction.sellerCompanyName
          : companyTransaction.buyerCompanyName;
      const selectorInner1 =
        graphXTypeValue === COMP_BENCH_GRAPH_1_TYPES.BUYER_NAME
          ? companyTransaction.buyerCompanyName
          : year;

      const axisYValue = assetTransaction.weaWorkingInterestReserves;

      if (axisYValue > 0) {
        fillStackedData(pageContentData.chart1Data, selectorOuter1, selectorInner1, axisYValue);

        pageContentData.graph1XAxisList[selectorInner1] = {
          name: selectorInner1,
          value: (pageContentData.graph1XAxisList[selectorInner1]?.value || 0) + axisYValue,
        };
      }
      if (secondaryYData1) {
        fillSimpleData(pageContentData.secondaryYChart1, selectorInner1, secondaryYData1);
      }

      //chart 2
      const secondaryYData2 =
        assetTransaction.weaWorkingInterestValuation && assetTransaction.weaWorkingInterestReserves
          ? {
              valuation: assetTransaction.weaWorkingInterestValuation,
              divideByReserves: assetTransaction.weaWorkingInterestReserves,
            }
          : null;
      const selectorOuter2 =
        graph2TypeValue === COMP_BENCH_GRAPH_2_TYPES.SELLER_NAME
          ? companyTransaction.buyerCompanyName
          : stackBy2Chart === STACK_BY_TYPES.SELLER
          ? companyTransaction.buyerCompanyName
          : companyTransaction.sellerCompanyName;
      const selectorInner2 =
        graph2TypeValue === COMP_BENCH_GRAPH_2_TYPES.SELLER_NAME
          ? companyTransaction.sellerCompanyName
          : year;

      if (axisYValue > 0) {
        fillStackedData(pageContentData.chart2Data, selectorOuter2, selectorInner2, axisYValue);
        pageContentData.graph2XAxisList[selectorInner2] = {
          name: selectorInner2,
          value: (pageContentData.graph2XAxisList[selectorInner2]?.value || 0) + axisYValue,
        };

        if (axisYValue > pageContentData.chart2Max) {
          pageContentData.chart2Max = axisYValue;
        }
      }
      if (secondaryYData2) {
        fillSimpleData(pageContentData.secondaryYChart2, selectorInner2, secondaryYData2);

        const secondaryValue = secondaryYData2.valuation / secondaryYData2.divideByReserves;
        if (secondaryValue > pageContentData.chart2Max) {
          pageContentData.chart2Max = secondaryValue;
        }
      }
    }
  };
