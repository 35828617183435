import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import {
  GLOBAL_SEARCH_URL_ACTION,
  GLOBAL_SEARCH_URL_LEGACY_ID,
  GLOBAL_SEARCH_URL_ISO_CODE,
  GLOBAL_SEARCH_URL_NAME,
  GLOBAL_SEARCH_URL_ENTITY,
  GLOBAL_SEARCH_URL_GEOM,
  GLOBAL_SEARCH_URL_COUNTRY_GEOM,
} from './constants';

export const getFulltextResultParametersFromSearch = (urlParams) => {
  return {
    action: urlParams.get(GLOBAL_SEARCH_URL_ACTION),
    isoCode: urlParams.get(GLOBAL_SEARCH_URL_ISO_CODE),
    legacyId: urlParams.get(GLOBAL_SEARCH_URL_LEGACY_ID),
    name: urlParams.get(GLOBAL_SEARCH_URL_NAME),
    entity: urlParams.get(GLOBAL_SEARCH_URL_ENTITY),
    geom: urlParams.get(GLOBAL_SEARCH_URL_GEOM),
    countryGeom: urlParams.get(GLOBAL_SEARCH_URL_COUNTRY_GEOM),
  };
};

export const getFulltextResultParameters = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return getFulltextResultParametersFromSearch(urlParams);
};

export const wipeFulltextResultParameters = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const globalSearchParamList = [
    GLOBAL_SEARCH_URL_ACTION,
    GLOBAL_SEARCH_URL_LEGACY_ID,
    GLOBAL_SEARCH_URL_ISO_CODE,
    GLOBAL_SEARCH_URL_NAME,
    GLOBAL_SEARCH_URL_ENTITY,
  ];

  let pushLocationSearch = false;
  globalSearchParamList.forEach((paramName) => {
    if (urlParams.get(paramName)) {
      urlParams.delete(paramName);
      pushLocationSearch = true;
    }
  });

  if (pushLocationSearch) {
    window.location.search = urlParams.toString();
  }
};

export const useGlobalSearchParameters = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};
