export const NAME_CO2_EMITTER_SUMMARY_ITEM = {
  label: 'Facility Name',
  accessor: 'facilityName',
};

export const OWNERS_CO2_EMITTER_SUMMARY_ITEM = {
  label: 'Facility Owners',
  accessor: 'formattedOwners',
};

export const EMISSIONS_CO2_EMITTER_SUMMARY_ITEM = {
  label: 'CO₂ Emissions',
  unit: '(MMtpa)',
  accessor: 'formattedCo2Emission',
};

export const STATIONARY_CO2_EMITTER_SUMMARY_ITEM = {
  label: 'Stationary Emissions',
  unit: '(MMtpa)',
  accessor: 'formattedStationaryEmission',
};

export const PROCESS_CO2_EMITTER_SUMMARY_ITEM = {
  label: 'Process Emissions',
  unit: '(MMtpa)',
  accessor: 'formattedProcessEmission',
};

export const BIOGENIC_CO2_EMITTER_SUMMARY_ITEM = {
  label: 'Biogenic Emissions',
  unit: '(MMtpa)',
  accessor: 'formattedBiogenicCo2Emission',
};
