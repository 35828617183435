import React, { useState } from 'react';
import { Box } from '@mui/material';
import { ResultCard } from './components/ResultCard';
import makeStyles from '@mui/styles/makeStyles';
import { getEntityNameByAction } from '../../../helper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: '8px', padding: '8px', paddingLeft: 0 },
  groupTitle: {
    fontSize: theme.fontSizes.base,
    fontWeight: 800,
    textDecoration: 'underline',
  },
  button: {
    color: theme.palette.baseColors.skyBlue.c100,
  },
}));

const getRows = (rows) => {
  if (rows.length < 6) {
    return {
      firstRows: rows,
      allRows: [],
    };
  }
  const tempRows = [...rows];
  const firstRows = tempRows.slice(0, 5);
  const allRows = tempRows;
  return {
    firstRows,
    allRows,
  };
};

export const ResultList = ({ action, rows, onActionClick }) => {
  const [showRemainingRows, setShowRemainingRows] = useState(false);
  const classes = useStyles();

  const { firstRows, allRows } = getRows(rows);

  return (
    <Box key={action} className={classes.root}>
      <Typography variant="h6" className={classes.groupTitle}>
        Found ({rows.length}) {getEntityNameByAction(action, rows.length > 1)}
      </Typography>
      {!showRemainingRows &&
        firstRows.map((row) => (
          <ResultCard key={`rc#${row.id}`} row={row} onActionClick={onActionClick} />
        ))}
      {allRows.length > 0 &&
        showRemainingRows &&
        allRows.map((row) => (
          <ResultCard key={`rce#${row.id}`} row={row} onActionClick={onActionClick} />
        ))}
      {allRows.length > 0 && (
        <Button
          className={classes.button}
          variant={'text'}
          size="small"
          onClick={() => setShowRemainingRows(!showRemainingRows)}
          startIcon={showRemainingRows ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        >
          {showRemainingRows ? 'Show less' : 'Show all'}
        </Button>
      )}
    </Box>
  );
};
