import {
  CCUS_PATH,
  PORTFOLIO_ANALYSIS_PATH,
  ROOT_PATH,
  VALUATIONS_PATH,
  getNewFeature,
} from '../../utils/constants';
import React, { createContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { SEEN_NEW_FEATURE } from './operations';
import { useCurrentUser } from '../CurrentUserContext';
import { useMutation } from '@apollo/client';

const WalkthroughContext = createContext();

const SESSION_STORAGE_KEY = 'hide-walkthrough';

const getPathWalthroughIndex = (path) => {
  if (path === ROOT_PATH) {
    return 0;
  }
  if (path.includes(VALUATIONS_PATH)) {
    return 1;
  }
  if (path.includes(PORTFOLIO_ANALYSIS_PATH)) {
    return 2;
  }
  if (path.includes(CCUS_PATH)) {
    return 3;
  }
  return -1;
};

const getFromSession = () => {
  const hideRaw = sessionStorage.getItem(SESSION_STORAGE_KEY);
  const hideParsed = hideRaw && JSON.parse(hideRaw);
  return Array.isArray(hideParsed) ? hideParsed : [];
};

const setToSession = (pathIndex) => {
  sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify([...getFromSession(), pathIndex]));
};

export const WalkthroughProvider = (props) => {
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const [walkthrough, setWalkthrough] = useState(false);
  const location = useLocation();
  const path = location.pathname;

  const setWalkthroughSession = (value) => {
    if (!value) {
      const pathIndex = getPathWalthroughIndex(path);
      if (pathIndex >= 0) {
        setToSession(pathIndex);
      }
    }
  };

  const setWalkthroughLocal = (value) => {
    setWalkthrough(value);
    setWalkthroughSession(value);
  };

  const hasWalkthrough = () => {
    const hide = getFromSession();
    const pathIndex = getPathWalthroughIndex(path);
    if (pathIndex >= 0) {
      return { available: true, sideButtonShown: !hide.includes(pathIndex) };
    }

    return { available: false, sideButtonShown: false };
  };

  const [seenNewFeature] = useMutation(SEEN_NEW_FEATURE);

  const setSeenNewFeature = (feature) => {
    seenNewFeature({ variables: { feature } });
  };

  const setNewFeatureWalkthrough = (path = location.pathname) => {
    const newFeature = getNewFeature(path);
    if (newFeature && currentUser.features && !currentUser.features[newFeature.key]) {
      setWalkthrough(true);
    }
  };

  useEffect(() => {
    // need to send if url typed directly (not on route change listener)
    // i.e. oil and gas map opens in new tab
    setNewFeatureWalkthrough();
    // listen for route changes
    return history.listen((location) => {
      setNewFeatureWalkthrough(location.pathname);
    });
  }, [history, currentUser]);

  return (
    <WalkthroughContext.Provider
      value={{
        hasWalkthrough,
        walkthrough,
        setWalkthrough: setWalkthroughLocal,
        setSeenNewFeature,
        setWalkthroughSession,
      }}
    >
      {props.children}
    </WalkthroughContext.Provider>
  );
};

export const WalkthroughConsumer = WalkthroughContext.Consumer;

export default WalkthroughContext;
