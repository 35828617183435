import React from 'react';

// reportUpdatedAt is used for country statistics
// reportUpdatedDate is used for assets
const reportUpdatedDate = ({ reportUpdatedAt, reportUpdatedDate }) => {
  const updateDate = reportUpdatedAt || reportUpdatedDate;
  return new Date(updateDate);
};

// entity can be asset or country statistics
const sixMonthsBeforeReportUpdate = (entity) =>
  reportUpdatedDate(entity).setMonth(reportUpdatedDate(entity).getMonth() - 6);

export const oneMonthBeforeReportUpdate = (entity) =>
  reportUpdatedDate(entity).setMonth(reportUpdatedDate(entity).getMonth() - 1);

const recentProductionDate = ({ currentDate, recentProductionDate }) => {
  const productionDate = currentDate || recentProductionDate;
  return new Date(productionDate);
};

export const outdatedValue = (entity) =>
  recentProductionDate(entity) < sixMonthsBeforeReportUpdate(entity);

export const getLabelUnit = (label, unit, separator = ' ') =>
  unit ? `${label}${separator}${unit}` : label;

export const getDisplayedValue = (label, value, Icon) =>
  value && typeof value === 'object'
    ? value.map((v) => (
        <span
          key={`summary-item-value-${v.text}`}
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          {v.text}
          <Icon enabled={v.needsIcon} />
        </span>
      ))
    : value;

export const getLabelUnitValueFromTemplate = (templateItem = {}, entityData = {}) => ({
  label:
    typeof templateItem.label === 'function' ? templateItem.label(entityData) : templateItem.label,
  unit: typeof templateItem.unit === 'function' ? templateItem.unit(entityData) : templateItem.unit,
  value:
    typeof templateItem.accessor === 'function'
      ? templateItem.accessor(entityData)
      : entityData[templateItem.accessor],
  tooltip: (typeof templateItem.onHover === 'function' && templateItem.onHover(entityData)) || null,
});
