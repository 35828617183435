export const openWebSocketConnection = (url, requestId, callback) => {
  if (!url || !requestId) {
    console.debug(`Missing url (${url}) or requestId (${requestId}))`);
    callback && callback(null, null, new Error('Connection failed'));
    return;
  }

  // Flag to prevent multiple calls to callback:
  let didFinish = false;
  // Timeout between messages:
  const timeoutSeconds = 120;

  const roomId = `${requestId}`; // Convert to string
  const socket = new WebSocket(url);
  let timeout;

  const handleMessage = (message, reportUrl, error, closeConnection, assetName = null) => {
    if (didFinish) return;

    clearTimeout(timeout);

    callback && callback(message, reportUrl, error, assetName);

    if (closeConnection) {
      didFinish = true;
      socket?.close();
    }
  };

  const startTimeout = () =>
    setTimeout(() => {
      handleMessage(null, null, new Error('Timeout'), true);
    }, timeoutSeconds * 1000);

  timeout = startTimeout();

  socket.onopen = () => {
    // Subscribe to the requestId:
    socket.send(
      JSON.stringify({
        roomId,
      }),
    );
  };

  socket.onmessage = (event) => {
    const data = JSON.parse(event.data || '{}');
    const {
      presigned_url: reportUrl,
      consolidated_cashflow_request_id: consolidatedCashflowRequestId,
      asset_name: assetName,
      error: errorFlag,
      message,
    } = data;

    let error = null;

    if (errorFlag || `${consolidatedCashflowRequestId}` !== roomId) {
      let errorDescription = 'Could not complete consolidation. ';

      if (assetName) {
        errorDescription += `Please review the following asset model: ${assetName}`;
      }

      error = new Error(errorDescription);
    }

    const isDone = !!reportUrl || !!error;
    handleMessage(message, reportUrl, error, isDone, assetName);

    if (!isDone) {
      // Restart the timeout:
      timeout = startTimeout();
    }
  };

  socket.onclose = () => {
    handleMessage(null, null, new Error('Connection closed'), false);
  };

  socket.onerror = (error) => {
    handleMessage(null, null, error, true);
  };

  return socket;
};
