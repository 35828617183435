import { Box, TextField } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import HistoryIcon from '@mui/icons-material/History';
import { HistoryPopover } from '../HistoryPopover';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useSearchFieldStyles } from './SearchField.style';

export const SearchField = ({
  inputText,
  setInputText,
  setIsOpenSearchTray,
  focusInput,
  openModal,
  showCloseIcon,
  history,
  onHistoryItemClick,
}) => {
  const classes = useSearchFieldStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'global-search-history-popper' : undefined;

  const onChange = (e) => {
    const lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const showHistoryIcon = history.length > 0;

  return (
    <Box className={classes.inputWrapper} onClick={(e) => e.stopPropagation()}>
      <TextField
        slotProps={{
          htmlInput: {
            style: {
              paddingLeft: '2rem',
              paddingRight: '2.5rem',
            },
          },
        }}
        onFocus={openModal}
        id="search-bar"
        value={inputText}
        onChange={onChange}
        onKeyDown={(e) => (e.key === 'Enter' ? onChange(e) : null)}
        variant="outlined"
        fullWidth
        label="Search"
        size="small"
        inputRef={focusInput ? (input) => input && input.focus() : undefined}
      />
      <SearchIcon className={classes.searchIcon} />
      {!!showHistoryIcon && !!showCloseIcon && (
        <>
          <HistoryIcon
            aria-describedby={id}
            className={classes.historyIcon}
            onClick={handleClick}
          />
          <HistoryPopover
            open={open}
            history={history}
            onHistoryItemClick={onHistoryItemClick}
            id={id}
            anchorEl={anchorEl}
            handleClose={() => setAnchorEl(null)}
          />
        </>
      )}
      {!!showCloseIcon && (
        <>
          <CloseIcon
            className={classes.closeIcon}
            onClick={() => {
              setIsOpenSearchTray(false);
            }}
          />
        </>
      )}
    </Box>
  );
};
