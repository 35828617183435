import {
  X_FACTOR_SUMMARY_ITEM,
  COUNTRY_SUMMARY_ITEM,
  BASIN_SUMMARY_ITEM,
  ACREAGE_SUMMARY_ITEM,
  WATER_DEPTH_M_SUMMARY_ITEM,
  AVERAGE_VERTICAL_WELL_DEPTH_SUMMARY_ITEM,
  SHORE_STATUS_SUMMARY_ITEM,
  PARTICIPATION_SUMMARY_ITEM,
  CONTRACT_START_SUMMARY_ITEM,
  CONTRACT_END_SUMMARY_ITEM,
  BLOCK_DISPLAY_NAME_SUMMARY_ITEM,
  FISCAL_REGIME_SUMMARY_ITEM,
} from '../../../SummaryList/SummaryItems/base';
import {
  STATUS_BLOCK_SUMMARY_ITEM,
  LICENSING_ROUND_BLOCK_SUMMARY_ITEM,
  GOVERNMENT_PROFIT_BLOCK_SUMMARY_ITEM,
  SIGNATURE_BONUS_BLOCK_SUMMARY_ITEM,
  WELL_COMMITMENT_BLOCK_SUMMARY_ITEM,
  // WELLS_DRILLED_BLOCK_SUMMARY_ITEM,
} from '../../../SummaryList/SummaryItems/block';

export default (layer) => {
  let colombiaXFactor = [];
  if (layer === 'COL') {
    colombiaXFactor = [X_FACTOR_SUMMARY_ITEM];
  }
  return [
    BLOCK_DISPLAY_NAME_SUMMARY_ITEM,
    COUNTRY_SUMMARY_ITEM,
    BASIN_SUMMARY_ITEM,
    ACREAGE_SUMMARY_ITEM,
    WATER_DEPTH_M_SUMMARY_ITEM,
    AVERAGE_VERTICAL_WELL_DEPTH_SUMMARY_ITEM,
    SHORE_STATUS_SUMMARY_ITEM,
    STATUS_BLOCK_SUMMARY_ITEM,
    PARTICIPATION_SUMMARY_ITEM,
    ...colombiaXFactor,
    FISCAL_REGIME_SUMMARY_ITEM,
    LICENSING_ROUND_BLOCK_SUMMARY_ITEM,
    GOVERNMENT_PROFIT_BLOCK_SUMMARY_ITEM,
    SIGNATURE_BONUS_BLOCK_SUMMARY_ITEM,
    CONTRACT_START_SUMMARY_ITEM,
    CONTRACT_END_SUMMARY_ITEM,
    WELL_COMMITMENT_BLOCK_SUMMARY_ITEM,
    // Temporary removal
    // WELLS_DRILLED_BLOCK_SUMMARY_ITEM,
  ];
};
