import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => {
  return {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '1.5rem',
    },
    checkbox: {
      padding: '5px',
    },
    label: {
      fontSize: theme.fontSizes.base,
      paddingLeft: '0.5rem',
    },
  };
});
