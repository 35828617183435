import React from 'react';
import { TableCell, TableRow, Box, Table } from '@mui/material';
import { useStyles } from './ParticipationHistoryTable.style';

const TABLE_HEADERS = ['Event', 'Date', 'Operator', 'Partners', 'Comments'];

const formatDate = (date) =>
  new Intl.DateTimeFormat('en-US', {
    month: 'short',
    year: 'numeric',
  })
    .format(new Date(date))
    .replace(/ /, '-');

const getParticipant = (name, percent) => {
  if (name && percent) return `${name} (${percent.toFixed(2)}%)`;
  if (name) return name;
  return '';
};

export const ParticipationHistoryTable = ({ historicalParticipation }) => {
  const classes = useStyles();
  return (
    <Box className={classes.participationTableBlock}>
      <Table className={classes.participationTable}>
        <TableRow className={classes.participationRow}>
          {TABLE_HEADERS.map((header) => (
            <TableCell className={classes.participationHeaderCell} key={header}>
              {header}
            </TableCell>
          ))}
        </TableRow>
        {historicalParticipation.map((row) => (
          <TableRow className={classes.participationRow} key={row.date}>
            <TableCell className={classes.participationCell}>{row.eventType}</TableCell>
            <TableCell className={classes.participationCell}>
              {formatDate(row.effectiveDate)}
            </TableCell>
            <TableCell className={classes.participationCell}>
              {getParticipant(row.companyOperatorName, row.companyOperatorPercentStake)}
            </TableCell>
            <TableCell className={classes.participationCell}>
              {row.partnerCompanies?.map((p) => getParticipant(p.name, p.percentStake))?.join(', ')}
            </TableCell>
            <TableCell className={classes.participationCell}>{row.comment}</TableCell>
          </TableRow>
        ))}
      </Table>
    </Box>
  );
};
