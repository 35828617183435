export const ASSET_LEVEL_WELL_PROPERTIES_COLUMNS = [
  { field: 'assetName', headerName: 'Asset', width: 300 },
  { field: 'blockName', headerName: 'Block', width: 300 },
  { field: 'wellName', headerName: 'Well', width: 300 },
  { field: 'basinName', headerName: 'Basin', width: 300 },
  { field: 'wellType', headerName: 'Well Type', width: 300 },
  { field: 'verticalDepthM', headerName: 'Vertical Depth (m)', width: 300 },
  { field: 'waterDepthM', headerName: 'Water Depth (m)', width: 300 },
  { field: 'eurOilMmbbl', headerName: 'EUR Oil (MMbbl)', width: 300 },
  { field: 'eurGasBcf', headerName: 'EUR Gas (Bcf)', width: 300 },
  { field: 'ioOilBbld', headerName: 'IP Oil (bbl/d)', width: 300 },
  { field: 'ipGasMmcfd', headerName: 'IP Gas (MMcf/d)', width: 300 },
  { field: 'recentProductionDate', headerName: 'Recent Production Date', width: 300 },
  {
    field: 'recentLiquidsProductionBbld',
    headerName: 'Recent Liquids Production (bbl/d)',
    width: 300,
  },
  { field: 'drillDays', headerName: 'Drill Days', width: 300 },
  { field: 'completionDays', headerName: 'Completion Days', width: 300 },
  { field: 'totalDCDays', headerName: 'Total D&C Days', width: 300 },
  { field: 'wellDesign', headerName: 'Well Design', width: 300 },
  { field: 'measuredDepthM', headerName: 'Measured Depth (m)', width: 300 },
];
// TODO: parse received data into `DataGrid`'s expected "Row" data structure
export const ASSET_LEVEL_WELL_PROPERTIES_ROWS = () => [];
