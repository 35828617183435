let timer;

export const doubleClickEvent = (e, clickCb, doubleClickCb, timeout = 200) => {
  clearTimeout(timer);
  timer = setTimeout(() => {
    if (e.detail > 1) {
      doubleClickCb();
    } else {
      clickCb();
    }
  }, timeout);
};
