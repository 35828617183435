import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import PDPGraph from './pdpGraphs';
import AssetViewGraphs from './assetViewGraphs';
import ErrorBoundary from '../../../ErrorBoundary';

const useStyles = makeStyles((theme) => ({
  graphsContainer: {
    ...theme.palette.mapInfoSection,
  },
  radioContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '10px',
    boxSizing: 'border-box',
  },
  radio: {
    color: '#2a2e49',
    '&.Mui-checked': {
      color: '#2a2e49',
    },
  },
}));

export default ({ pipelineSegmentId }) => {
  const classes = useStyles();

  const [isAssetView, setIsAssetView] = useState(false);

  return (
    <ErrorBoundary>
      <div className={classes.graphsContainer}>
        <div className={classes.radioContainer}>
          <FormControl component="fieldset">
            <Typography variant="overline">Graph Type</Typography>
            <RadioGroup
              aria-label="graphType"
              name="graphType"
              value={isAssetView}
              onChange={() => setIsAssetView(!isAssetView)}
            >
              <FormControlLabel
                value={false}
                control={<Radio className={classes.radio} />}
                label="PDP & Undeveloped"
              />
              <FormControlLabel
                value={true}
                control={<Radio className={classes.radio} />}
                label="Asset Level View"
              />
            </RadioGroup>
          </FormControl>
        </div>
        {isAssetView ? (
          <AssetViewGraphs pipelineSegmentId={pipelineSegmentId} />
        ) : (
          <PDPGraph pipelineSegmentId={pipelineSegmentId} />
        )}
      </div>
    </ErrorBoundary>
  );
};
