import {
  RESERVES_AGGREGATES_TYPE_ENUM,
  RESERVES_AGGREGATE_BY_TYPE_ENUM,
  RESERVES_SUM_FIELD,
} from '../types';

import { FilterKeys } from '../../../../Filters';
import { MA_RESERVES_ACQUIRED_QUERY } from '../operations';
import { useFilters } from '../../../FiltersContext';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

export const useGetMaReservesAggregates = (
  by = RESERVES_AGGREGATE_BY_TYPE_ENUM.BUYER,
  type = RESERVES_AGGREGATES_TYPE_ENUM.BUYER,
) => {
  const { filters } = useFilters();
  const filterVariables = useMemo(() => {
    const [startYear, endYear] = filters?.dateSlider || [];

    // If aggregating by Year, use the default orderBy and order of the query
    const { orderBy, order } =
      by === RESERVES_AGGREGATE_BY_TYPE_ENUM.YEAR
        ? { orderBy: undefined, order: undefined }
        : { orderBy: RESERVES_SUM_FIELD, order: 'DESC' };

    return {
      assets: filters[FilterKeys.assets]?.map((a) => Number(a.entityId)) || [],
      countries: filters[FilterKeys.countries]?.map((c) => c.isoCode) || [],
      buyers: filters[FilterKeys.companiesBuyer]?.map((b) => Number(b.id)) || [],
      sellers: filters[FilterKeys.companiesSeller]?.map((s) => Number(s.id)) || [],
      eventTypes: filters[FilterKeys.eventType]?.map((e) => e.name) || [],
      startYear: startYear,
      endYear: endYear,
      hasBuyers: type === RESERVES_AGGREGATES_TYPE_ENUM.BUYER,
      hasSellers: type === RESERVES_AGGREGATES_TYPE_ENUM.SELLER,
      orderBy,
      order,
    };
  }, [filters, by, type]);

  const { data, loading, error } = useQuery(MA_RESERVES_ACQUIRED_QUERY, {
    variables: {
      by,
      ...filterVariables,
    },
  });

  return { data: data?.maTransactionsAggregates || [], loading, error };
};
