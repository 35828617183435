import React, { createContext, useCallback, useContext, useState } from 'react';
import {
  SESSIONS_STORAGE_KEY_MA_FILTERS_YEARS,
  SESSIONS_STORAGE_KEY_MA_FILTERS_COUNTRIES,
  SESSIONS_STORAGE_KEY_MA_FILTERS_TYPES,
  SESSIONS_STORAGE_KEY_MA_FILTERS_ASSETS,
  SESSIONS_STORAGE_KEY_MA_FILTERS_COMPANY_SELLERS,
  SESSIONS_STORAGE_KEY_MA_FILTERS_COMPANY_BUYERS,
} from '../Ma.constants';

const MaFilterContext = createContext({});

const currentYear = new Date().getFullYear();

const isValidYearPreference = (variable) => {
  return (
    variable &&
    variable.from &&
    variable.to &&
    typeof variable.from === 'number' &&
    typeof variable.to === 'number'
  );
};

const getYearSliderInitialValue = () => {
  const sessionPreference = sessionStorage.getItem(SESSIONS_STORAGE_KEY_MA_FILTERS_YEARS);
  const sessionPreferenceParsed = sessionPreference ? JSON.parse(sessionPreference) : null;

  return isValidYearPreference(sessionPreferenceParsed)
    ? sessionPreferenceParsed
    : { from: 1990, to: currentYear };
};

export const useMaFilterContext = () => useContext(MaFilterContext);

const storeToSession = (listNew, sessionKey) => {
  const chosenIdList = [];
  listNew.forEach(({ id, isChosen }) => {
    if (isChosen) {
      chosenIdList.push(id);
    }
  });

  sessionStorage.setItem(sessionKey, JSON.stringify(chosenIdList));
};

export const MaFilterProvider = ({ children }) => {
  const [countryList, setCountryList] = useState();
  const [typeList, setTypeList] = useState();
  const [companySellerList, setCompanySellerList] = useState();
  const [companyBuyerList, setCompanyBuyerList] = useState();
  const [assetList, setAssetList] = useState();
  const [chosenYears, setChosenYears] = useState(getYearSliderInitialValue());

  const updateCountryList = (countryListNew) => {
    setCountryList(countryListNew);
    storeToSession(countryListNew, SESSIONS_STORAGE_KEY_MA_FILTERS_COUNTRIES);
  };

  const updateTypeList = (typeListNew) => {
    setTypeList(typeListNew);
    storeToSession(typeListNew, SESSIONS_STORAGE_KEY_MA_FILTERS_TYPES);
  };

  const updateCompanySellerList = (companyListNew) => {
    setCompanySellerList(companyListNew);
    storeToSession(companyListNew, SESSIONS_STORAGE_KEY_MA_FILTERS_COMPANY_SELLERS);
  };

  const updateCompanyBuyerList = (companyListNew) => {
    setCompanyBuyerList(companyListNew);
    storeToSession(companyListNew, SESSIONS_STORAGE_KEY_MA_FILTERS_COMPANY_BUYERS);
  };

  const updateAssetList = (assetListNew) => {
    setAssetList(assetListNew);
    storeToSession(assetListNew, SESSIONS_STORAGE_KEY_MA_FILTERS_ASSETS);
  };

  const updateChosenYears = (val) => {
    setChosenYears(val);
    sessionStorage.setItem(SESSIONS_STORAGE_KEY_MA_FILTERS_YEARS, JSON.stringify(val));
  };

  const resetFilters = useCallback(() => {
    updateCountryList(countryList.map((v) => ({ ...v, isChosen: false })));
    updateTypeList(typeList.map((v) => ({ ...v, isChosen: false })));
    updateCompanySellerList(companySellerList.map((v) => ({ ...v, isChosen: false })));
    updateCompanyBuyerList(companyBuyerList.map((v) => ({ ...v, isChosen: false })));
    updateAssetList(assetList.map((v) => ({ ...v, isChosen: false })));
  }, [countryList, typeList, companySellerList, companyBuyerList, assetList]);

  return (
    <MaFilterContext.Provider
      value={{
        countryList,
        setCountryList,
        updateCountryList,
        typeList,
        setTypeList,
        updateTypeList,
        companyBuyerList,
        companySellerList,
        setCompanySellerList,
        setCompanyBuyerList,
        updateCompanySellerList,
        updateCompanyBuyerList,
        assetList,
        setAssetList,
        updateAssetList,
        chosenYears,
        setChosenYears,
        updateChosenYears,
        resetFilters,
      }}
    >
      {children}
    </MaFilterContext.Provider>
  );
};
