import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useSearchBarStyles } from './SearchBar.style';
import { SearchField } from '../SearchField/SearchField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

export const SearchBar = ({
  inputText,
  openSearchTray,
  openSearchTrayAndFocus,
  setInputText,
  setIsOpenSearchTray,
  openModal,
  showCloseIcon,
  history,
  onHistoryItemClick,
}) => {
  const classes = useSearchBarStyles();
  if (openSearchTray || openSearchTrayAndFocus) {
    return (
      <SearchField
        inputText={inputText}
        setInputText={setInputText}
        setIsOpenSearchTray={setIsOpenSearchTray}
        focusInput={openSearchTrayAndFocus}
        openModal={openModal}
        showCloseIcon={showCloseIcon}
        history={history}
        onHistoryItemClick={onHistoryItemClick}
      />
    );
  }
  return (
    <Tooltip title="Search" aria-label="Search">
      <IconButton
        className={classes.searchIcon}
        onClick={() => setIsOpenSearchTray(true)}
        size="large"
      >
        <SearchIcon />
      </IconButton>
    </Tooltip>
  );
};
