import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default (props) => (
  <SvgIcon {...props}>
    <path
      d="M10.8415521,11.1708948 L14.2596415,8.72865234 L16.074294,14.909494 L10.8415521,11.1708948 Z M4.73222187,8.75607095 L8.11204309,11.1708948 L2.93806132,14.8676376 L4.73222187,8.75607095 Z M5.76064842,5.25298028 L8.74359156,5.25298028 L8.74359156,9.6720112 L5.20551288,7.14406908 L5.76064842,5.25298028 Z M13.7863505,7.11651802 L10.2564192,9.63876449 L10.2564192,5.25298028 L13.2393624,5.25298028 L13.7863505,7.11651802 Z M18.9648998,19.3530295 L14.515668,4.19756279 C14.4164176,3.85966495 14.124345,3.62971957 13.7941276,3.62971957 L10.2564192,3.62971957 L10.2564192,1.78552056 L12.7665651,1.78552056 C13.2260302,1.78552056 13.5985895,1.38576529 13.5985895,0.892760278 C13.5985895,0.399622813 13.2260302,0 12.7665651,0 L6.23344565,0 C5.77385712,0 5.40142129,0.399622813 5.40142129,0.892760278 C5.40142129,1.38576529 5.77385712,1.78552056 6.23344565,1.78552056 L8.74359156,1.78552056 L8.74359156,3.62971957 L5.20588322,3.62971957 C4.87566583,3.62971957 4.58371663,3.85966495 4.48446625,4.19756279 L0.0351110356,19.3530295 C-0.0903098477,19.7806007 0.130904939,20.2362529 0.529387824,20.3709616 C0.604936623,20.3965258 0.681472989,20.4087119 0.756774897,20.4087119 C1.07822763,20.4087119 1.37634912,20.1871113 1.47806842,19.8407362 L2.21355821,17.3354442 L8.74359156,12.6697784 L8.74359156,20.1883034 C8.74359156,20.636538 9.0823267,21 9.50006712,21 C9.91780754,21 10.2564192,20.636538 10.2564192,20.1883034 L10.2564192,12.7030251 L16.7401604,17.3355767 C16.7584304,17.3485575 16.7775645,17.3583593 16.7963282,17.3694857 L17.5218189,19.8407362 C17.6235382,20.1871113 17.9216597,20.4087119 18.2431125,20.4087119 C18.3184144,20.4087119 18.3949507,20.3965258 18.470623,20.3709616 C18.8689824,20.2362529 19.0903206,19.7806007 18.9648998,19.3530295 L18.9648998,19.3530295 Z"
      id="Fill-1"
      fill={props.color ? '#2a2e49' : '#fff'}
      mask="url(#mask-4)"
    ></path>
  </SvgIcon>
);
